import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import Profiler from "@core/profiler/ProfilerStorage";

import { ReactComponent as TotalIcon } from "./assets/total.svg";
import { ReactComponent as FixedIcon } from "./assets/fixed.svg";
import { ReactComponent as UnrecognizedIcon } from "./assets/unrecognized.svg";
import { ReactComponent as NluIcon } from "./assets/nlu.svg";

import * as S from "./styled";

interface Props {
  profiler: Profiler;
}

const getRateColor = (validationRate: number) => {
  if (validationRate < 50) {
    return "tomato";
  }

  if (validationRate >= 50 && validationRate <= 70) {
    return "#FFBF00";
  }

  return "#00FF9D";
};

const StatisticsPanel: FC<Props> = ({ profiler }) => {
  const rateColor = getRateColor(+((profiler.table?.validationRate || 0) * 100).toFixed(2));

  const cards = [
    {
      title: "Total number of phrases",
      value: profiler.table?.totalPhrases || 0,
      icon: <TotalIcon />,
    },
    {
      title: "Number of fixed phrases",
      value: profiler.table?.fixedPhrases || 0,
      icon: <FixedIcon />,
    },
    {
      title: "Number of unrecognised phrases",
      value: profiler.table?.unrecognizedPhrases || 0,
      icon: <UnrecognizedIcon />,
    },
    {
      title: "Validation rate",
      value: <span style={{ color: rateColor }}>{((profiler.table?.validationRate || 0) * 100).toFixed(2)}%</span>,
      icon: <NluIcon />,
    },
    {
      title: "NLU rate",
      value: <span style={{ color: rateColor }}>{((profiler.table?.nluRate || 0) * 100).toFixed(2)}%</span>,
      icon: <NluIcon />,
    },
  ];

  return (
    <S.Container>
      {cards.map((card, i) => (
        <S.Card key={i}>
          <div>
            <S.CardTitle>{card.title}</S.CardTitle>
            <S.CardValue>{card.value}</S.CardValue>
          </div>
          <div>{card.icon}</div>
        </S.Card>
      ))}
    </S.Container>
  );
};

export default observer(StatisticsPanel);
