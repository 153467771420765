import { PromptAPI } from "./PromptStore";
import {
  IHistoryMessage,
  IHistoryMessageListOptionalValue,
  PromptDataOptionalValue,
  TestCaseDTO,
  TestCaseResultDTO,
  TestOptions,
  TestOptionsOptionalValue,
} from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import { makeAutoObservable, runInAction } from "mobx";
import { gptArgs } from "../../../uikit/gpt/Args/types";
import { HistoryHolder } from "../../../uikit/gpt/History/types";
import { gpt } from "@dasha.ai/sdk/web";

export class TestCase {
  private readonly api: PromptAPI;
  public readonly id: string;
  public name: string;
  public description: string | undefined;
  public status: string;
  public history: HistoryHolder;
  public refResult: HistoryHolder;
  public testResult: TestCaseResultDTO[] = [];
  public testOptions: TestOptions;
  private inititalTestOptions: TestOptions;
  public args: gptArgs;
  constructor(private dto: TestCaseDTO, api: PromptAPI) {
    this.api = api;
    this.name = dto.name!;
    this.id = dto.id!;
    this.description = dto.description ?? undefined;
    this.status = "unknown";
    this.history = new HistoryHolder((dto.history as gpt.IHistoryMessage[]) ?? []);
    this.args = new gptArgs(new Map<string, string | undefined>(Object.entries(dto.testPrompt.promptArgs)));
    this.refResult = new HistoryHolder((dto.expectedResult as gpt.IHistoryMessage[]) ?? []);
    this.testOptions = dto.testOptions ?? {};
    this.inititalTestOptions = dto.testOptions ?? {};
    makeAutoObservable(this);
  }

  public get isDirty(): boolean {
    if (this.name !== this.dto.name || this.description !== (this.dto.description??"")) {
      return true;
    }
    if (this.args.isDirty) {
      return true;
    }
    if (this.refResult.isDirty) {
      return true;
    }
    if (JSON.stringify(this.testOptions) !== JSON.stringify(this.inititalTestOptions)) {
      return true;
    }

    return false;
  }
  public setName(name: string) {
    this.name = name;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setStatus(status: string) {
    this.status = status;
  }

  public setTestResult(dto: TestCaseResultDTO[]) {
    const status = dto.every((x) => x.status === "Succeed") ? "Succeed" : "Failed";
    this.testResult = dto;
    this.setStatus(status);
  }

  public setTestOptions(options: TestOptions) {
    this.testOptions = options;
  }

  public addTestResult(dto: TestCaseResultDTO) {
    if (this.testResult.findIndex((x) => x.id === dto.id) >= 0) {
      // already added
      return;
    }
    this.testResult.push(dto);
  }

  public async saveAsync(): Promise<void> {
    await this.api.updateTestCase(this.id, {
      name: this.name,
      description: this.description,
      expectedResult: this.refResult.Messages as IHistoryMessageListOptionalValue,
      history: this.history.Messages as IHistoryMessageListOptionalValue,
      testOptions: this.testOptions as TestOptionsOptionalValue,
      testPrompt: {
        promptArgs: Object.fromEntries(this.args.Args.entries()),
        prompt: this.dto.testPrompt.prompt,
        functionDescriptions: this.dto.testPrompt.functionDescriptions,
        options: this.dto.testPrompt.options,
      } as PromptDataOptionalValue,
    });
    runInAction(() => {
      this.args.InitialArgs = new Map(this.args.Args);
    });
  }

  public GetUpdates(): string {
    return JSON.stringify({
      args: Object.fromEntries(this.args.Args.entries()),
      testResult: this.testResult,
    });
  }

  public getStatusCount(): string {
    if (this.testResult.length === 0) {
      return "";
    }
    const success = this.testResult.filter((x) => x.status === "Succeed").length;

    return `${success}/${this.testResult.length}`;
  }
  public ApplyUpdates(updates: string) {
    try {
      const data = JSON.parse(updates) as { args: Record<string, string>; testResult: TestCaseResultDTO[] };
      this.args.Replace(new Map<string, string | undefined>(Object.entries(data.args)));
      this.setTestResult(data.testResult ?? []);
    } catch {}
  }
}
