import React, { FC, useEffect, useRef, useState } from "react";
import { StyledFile } from "./styled";

const EditItem = ({ inputRef, defaultValue, style }) => {

  return (
    <StyledFile style={style}>
      <p>
        &nbsp;&nbsp;
      </p>
      <input autoFocus ref={inputRef} defaultValue={defaultValue} />
    </StyledFile>
  );
};


interface PropsPlaceholder {
  onSubmit?: (value: string) => void;
  onCancel: () => void;
  defaultValue?: string;
  style?: any;
}

const PlaceholderInput: FC<PropsPlaceholder> = ({ onSubmit, onCancel, defaultValue, style }) => {
  const inputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
    inputRef.current.addEventListener("keyup", (e) => {
      if (!inputRef.current) return;
      if (e.key === "Enter") onSubmit?.(inputRef.current.value);
      if (e.key === "Escape") {
        onCancel && onCancel();
      }
    });

    inputRef.current.addEventListener("blur", () => {
      if (!inputRef.current) return;
      onSubmit?.(inputRef.current.value);
      onCancel && onCancel();
    });
  }, [inputRef]);
  return (<EditItem style={style} inputRef={inputRef} defaultValue={defaultValue} />);
};

export { PlaceholderInput };
