import { useEffect, useRef } from "react";

const useAutoScroller = <T extends HTMLElement>(): React.RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current == null) return;

    let childrenCount = ref.current.children.length;
    const observer = new MutationObserver(() => {
      const newCount = ref.current?.children.length ?? 0;
      if (!ref.current || childrenCount === newCount) return;

      ref.current.scrollTop = ref.current.scrollHeight;
      childrenCount = newCount;
    });

    observer.observe(ref.current, {
      childList: true,
      subtree: true,
    });

    ref.current.scrollTop = ref.current.scrollHeight;
    return () => observer.disconnect();
  }, []);

  return ref;
};

export default useAutoScroller;
