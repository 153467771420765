import React, { memo } from "react";
import { NodeProps, Handle, Position } from "react-flow-renderer";
import { PureButton, StrokeButton } from "../../uikit/Button";

import useNodeToCenter from "./useNodeToCenter";
import * as S from "./styled";
import { Icon } from "../../uikit";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  useNodeToCenter(xPos, yPos, data.highlight);

  const updateContext = (id, key, value) => {
    data.update({
      context: data.context.map((variable) => {
        if (variable.id === id) return { id, key, value };
        return variable;
      }),
    });
  };

  const addContext = () => {
    const variable = { id: Date.now(), key: "", value: "" };
    data.update({ context: data.context.concat([variable]) });
  };

  const removeContext = (id: number) => {
    data.update({ context: data.context.filter((c) => c.id !== id) });
  };

  return (
    <S.Node className="drag-handle" isSelected={selected || data.highlight}>
      <S.Label style={{ display: "flex", justifyContent: "space-between" }}>SETUP CONTEXT</S.Label>

      {data.context.map((variable) => (
        <div key={variable.id} style={{ display: "flex", marginBottom: 4 }}>
          <S.Input
            value={variable.key}
            style={{ marginRight: 8 }}
            onChange={(e) => updateContext(variable.id, e.target.value, variable.value)}
          />

          <S.Input
            value={variable.value}
            style={{ marginRight: 8 }}
            onChange={(e) => updateContext(variable.id, variable.key, e.target.value)}
          />

          <PureButton onClick={() => removeContext(variable.id)}>
            <Icon name="menu" />
          </PureButton>
        </div>
      ))}

      <StrokeButton
        onClick={addContext}
        style={{ fontSize: 8, width: "100%", padding: 4, height: 22, borderWidth: 1, marginTop: 8 }}
      >
        Add variable
      </StrokeButton>

      <Handle
        id="b"
        type="source"
        position={Position.Right}
        style={{ top: "50%", right: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
    </S.Node>
  );
});
