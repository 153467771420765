import { IAccount } from "../interface";
import RestApi from "./fetch";

export interface ParaphareResponse {
  sourcePhrase: string;
  paraphrases: string[];
}

class ParaphraseApi extends RestApi {
  domain = "api/v1/paraphrase";

  async paraphase(phrases: string[], account: IAccount): Promise<ParaphareResponse[]> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(`batch`, {
      method: "post",
      body: JSON.stringify(phrases)
    });

    const data = await resp.json();
    return data;
  }

  async paraphaseGpt(phrase: string, account: IAccount): Promise<ParaphareResponse[]> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(`gpt`, {
      method: "post",
      body: JSON.stringify(phrase)
    });

    const data = await resp.json();
    return data;
  }
}

export default ParaphraseApi;
