import DslWorker from "./src/DslWorker";
import { wrap } from "comlink";
import * as comlink from "comlink";

function createDslWorker(): comlink.Remote<DslWorker> {
  const worker = new Worker(new URL("src/index", import.meta.url));
  const workerApi = wrap<DslWorker>(worker);
  return workerApi;
}

export class WorkerApi {
  private static _instance?: comlink.Remote<DslWorker>;

  public static get Instance() {
    if (!this._instance) {
      this._instance = createDslWorker();
    }
    return this._instance;
  }
}
