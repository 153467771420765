import { LayerModel } from '@projectstorm/react-canvas-core';

export interface TooltipOptions {
  x: number;
  y: number;
  data: Record<string, string>;
}

class TooltipModel extends LayerModel {
  data: Record<string, string> = {};
  isShow = false;
  x = 0;
  y = 0;

  constructor(options: TooltipOptions) {
    super({
      isSvg: false,
      transformed: false,
      type: 'tooltip',
    });

    this.show(options);
  }

  show({ x, y, data }: TooltipOptions) {
    this.x = x;
    this.y = y;
    this.data = data;
    this.isShow = true;
  }

  serialize() {
    return {
      ...super.serialize(),
      isShow: this.isShow,
      data: this.data,
      x: this.x,
      y: this.y,
    };
  }

  getChildModelFactoryBank(): any {
    return null;
  }
}

export default TooltipModel;
