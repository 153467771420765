import styled from "styled-components";

interface ContainerProps {
  $checked: boolean;
  $isReadOnly?: boolean;
}

export const Container = styled.label<ContainerProps>`
  width: 18px;
  height: 18px;

  border-radius: 2px;

  opacity: ${(props) => (props.$isReadOnly ? ".3" : "1")};

  display: flex;

  cursor: pointer;

  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    margin: 0;
  }
`;
