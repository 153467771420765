import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

import { DoStatement } from "./DoStatement";
import { Expression } from "../expressions";

export class ReturnStatement extends DoStatement implements interfaces.IReturnStatement {
  elementType: DslElementType.ReturnStatement = DslElementType.ReturnStatement;
  returnValue: interfaces.OptionalValue<Expression>;

  constructor(obj: interfaces.IReturnStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    if (obj.returnValue.hasValue) {
      this.returnValue = { hasValue: true, value: helpers.ensureIsExpressionInstance(obj.returnValue.value) };
    } else {
      this.returnValue = { hasValue: false, value: null };
    }
  }
  toJson(): interfaces.IReturnStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      returnValue: interfaces.helpers.createOptionalValue(this.returnValue.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [helpers.indentString(indent), this.returnValue.hasValue ? `return ${this.returnValue.value.toDsl(indent)};` : "return;"],
      " "
    );
  }
}
