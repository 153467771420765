import { IAccount } from "@core/account/interface";
import { makeAutoObservable } from "mobx";
import { ApplicationStore } from "./ApplicationStore";
import { CustomerStore } from "./CustomerStore";
import { GroupsStore } from "./GroupsStore";
import { DiagnosticsStore } from "./DiagnosticsStore";
import { InboundVoIPStore } from "./VoIP/InboundVoIPStore";
import { Explorer } from "@core/explorer/Explorer";
import { AccountsManager } from "@core/account/Manager";
import SIPClient from "@core/SIPClient";
import { selectServers } from "@core/Selector";
import SimpleTtsSpeaker from "@core/SimpleTtsSpeaker";
import Workspace from "@core/workspace/Workspace";
import Project from "@core/explorer/Project";
import { WorkerApi } from "@core/dsl/worker/api";
import RemoteLibrary from "@core/account/projects/RemoteLibrary";
import { IDisposable } from "@core/misc/emitter";
import { PromptStore } from "./prompts/PromptStore";


export class GlobalStore {
    public account: IAccount;
    public applications: ApplicationStore;
    public customer: CustomerStore;
    public groups: GroupsStore;
    public diagnostics: DiagnosticsStore;
    public inboundVoIP: InboundVoIPStore;
    public explorer: Explorer;
    public sip: SIPClient;
    public workspace: Workspace;
    public remoteProjectLibrary: RemoteLibrary;
    public prompts: PromptStore;
    private handleRewrite?: IDisposable;

    constructor(account: IAccount) {
        makeAutoObservable(this);
        this.sip = new SIPClient(selectServers().sip);
        SimpleTtsSpeaker.initialize(account);
        this.SwitchAccount(account);

    }

    public SwitchAccount(account: IAccount) {
        this.account = account;
        this.applications = new ApplicationStore(account);
        this.customer = new CustomerStore(account);
        this.groups = new GroupsStore(account);
        this.diagnostics = new DiagnosticsStore(account);
        this.inboundVoIP = new InboundVoIPStore(account);
        this.remoteProjectLibrary = new RemoteLibrary(account);
        this.explorer = new Explorer(account, this.remoteProjectLibrary);
        this.workspace = new Workspace(this.sip, this.account);
        this.prompts = new PromptStore(account);
        this.explorer.onDidProject(this.changeProjectAsync, this);
        SimpleTtsSpeaker.Instance.account = account;
    }

    public changeProjectAsync(project: Project | null) {
        if (project === null) {
            this.workspace.setProject(null);
        }
        

        if (this.handleRewrite !== undefined) {
            this.handleRewrite.dispose();
        }
        
        this.handleRewrite = project?.onDidRewrite(() => this.workspace.setProject(project));
        SimpleTtsSpeaker.Instance?.stop()
    }
}