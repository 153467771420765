import React, { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

interface Props {
  button: React.ReactElement;
  children: React.ReactElement;
  position?: "left" | "right";
  offsetY?: number;
  onClick?: () => void;
}

export const Dropdown: FC<Props> = ({ onClick, position = "left", offsetY = 0, button, children }) => {
  const [isOpen, setOpen] = useState(false);
  const buttonRef = useRef<HTMLElement>();
  const menuRef = useRef<HTMLDivElement>();
  const clickRef = useRef(false);

  const listEl = React.cloneElement(children, {
    ref: menuRef,
    style: { position: "fixed", zIndex: 1000 },
  });

  const buttonEl = React.cloneElement(button, {
    ref: buttonRef,
    onClick: () => {
      clickRef.current = true;
      setOpen(true);
      onClick?.();
    },
  });

  useEffect(() => {
    if (!buttonRef.current || !menuRef.current) return;
    const box = buttonRef.current.getBoundingClientRect();
    const { width } = menuRef.current.getBoundingClientRect();
    const left = position === "left" ? box.left + offsetY : box.right + offsetY - width;

    menuRef.current.style.top = box.bottom + 8 + "px";
    menuRef.current.style.left = left + "px";
  }, [isOpen, offsetY]);

  useEffect(() => {
    const handler = () => {
      setOpen(clickRef.current);
      clickRef.current = false;
    };
    document.body.addEventListener("click", handler);
    return () => document.body.removeEventListener("click", handler);
  }, []);

  return (
    <>
      {buttonEl}
      {ReactDOM.createPortal(isOpen ? listEl : null, document.body)}
    </>
  );
};


