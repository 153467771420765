import * as React from "react";
import { observer } from "mobx-react-lite";

import DatasetStorage from "../../core/workspace/dataset-storage/DatasetStorage";
import MarkableMessage from "../../core/workspace/session-storage/MarkableMessage";
import { Message, SessionProtocol } from "../../core/workspace/session-storage/types";

import CreateEntity from "../../ProfilerPanel/components/DataMarkup/components/CreateEntity";
import { ActionButton, Icon, StrokeButton } from "../../uikit";
import { PureButton } from "../../uikit/Button";
import { Tooltip } from "../../uikit/popup";
import SearchSelect from "../../uikit/SearchSelect";
import Modal from "../../uikit/Modal";

import MarkupPhrase from "./MarkupPhrase";
import CreateIntent from "./CreateIntent";
import TriggerBadge from "./Trigger";
import * as S from "./styled";

interface Props {
  message: MarkableMessage;
  dataset: DatasetStorage;
  session: SessionProtocol;
  onComplete: (msg: Message) => void;
  onClose: () => void;
}

const TriggersEdit: React.FC<Props> = (props) => {
  const [isCreatorIntent, toggleCreatorIntent] = React.useState(false);
  const [isCreatorEntity, toggleCreatorEntity] = React.useState(false);
  const [isPushIntent, togglePushIntent] = React.useState(false);
  const [phraseForNewEntity, setPhraseForNewEntity] = React.useState<string | null>(null);

  const [editable] = React.useState(() => {
    const editable = new MarkableMessage(props.message.serialize());
    editable.activeAll();
    return editable;
  });

  const handleSave = () => props.onComplete(editable.serialize());
  const handleClose = () => props.onClose();
  const handleSetIntent = (intent: string) => {
    editable.addIntent(intent);
    togglePushIntent(false);
  };

  const intents = props.dataset.intents.filter((v) => editable.intents.includes(v) === false);
  const handleCreateIntent = (intent: string) => {
    toggleCreatorIntent(false);
    editable.addIntent(intent);
  };
  const handleCreateEntity = () => {
    toggleCreatorEntity(false);
  };

  const creatorIntent = (
    <>
      <CreateIntent
        phrase={props.message.message}
        dataset={props.dataset}
        onClose={() => toggleCreatorIntent(false)}
        onCreate={handleCreateIntent}
      />
    </>
  );

  const creatorEntity = (
    <>
      <CreateEntity
        hasArrowBack
        phrase={phraseForNewEntity || ""}
        dataset={props.dataset}
        onClose={() => toggleCreatorEntity(false)}
        onCreate={handleCreateEntity}
      />
    </>
  );

  const phraseMarkup = (
    <>
      <S.Header>
        <h1>Phrase markup</h1>
        <ActionButton onClick={handleSave}>save</ActionButton>
        <StrokeButton onClick={handleClose}>cancel</StrokeButton>
      </S.Header>
      <S.Body>
        <S.Section>
          <MarkupPhrase
            dataset={props.dataset}
            editable={editable}
            onNewEntityClick={(phrase) => {
              toggleCreatorEntity(true);
              setPhraseForNewEntity(phrase);
            }}
          />
        </S.Section>

        <S.Section>
          <S.Title>
            <p>Intents</p>
            <Tooltip text="Intents">
              <PureButton>
                <Icon name="info" />
              </PureButton>
            </Tooltip>
          </S.Title>
          <S.Triggers>
            {editable.triggers
              .filter((v) => v.type === "intent")
              .map((trigger, i) => (
                <TriggerBadge
                  key={i}
                  trigger={trigger}
                  phrase={props.message.message}
                  onMark={(mark) => editable.markupTrigger(trigger.id, mark)}
                />
              ))}

            {isPushIntent && (
              <SearchSelect
                focuses
                selected=""
                options={intents}
                onRemove={() => togglePushIntent(false)}
                onBlur={() => togglePushIntent(false)}
                onChange={handleSetIntent}
              />
            )}

            {!isPushIntent && intents.length > 0 && (
              <S.AddTrigger onClick={() => togglePushIntent(true)}>+ Add intent</S.AddTrigger>
            )}
          </S.Triggers>

          <S.CreateIntent onClick={() => toggleCreatorIntent(true)}>+ Create a new intent</S.CreateIntent>
        </S.Section>

        <S.Section>
          <S.Title>
            <p>Entities</p>
            <Tooltip text="Entities" position="top center">
              <PureButton>
                <Icon name="info" />
              </PureButton>
            </Tooltip>
          </S.Title>
          <S.Triggers>
            {editable.triggers
              .filter((v) => v.type === "entity")
              .map((trigger, i) => (
                <TriggerBadge
                  key={i}
                  trigger={trigger}
                  phrase={props.message.message}
                  onMark={(mark) => editable.markupTrigger(trigger.id, mark)}
                />
              ))}
          </S.Triggers>
        </S.Section>
      </S.Body>
    </>
  );

  return (
    <Modal isOpen style={{ padding: 0 }}>
      {isCreatorIntent && creatorIntent}
      {isCreatorEntity && creatorEntity}
      {!isCreatorEntity && !isCreatorIntent && phraseMarkup}
    </Modal>
  );
};

export default observer(TriggersEdit);
