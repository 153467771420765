type Point = { x: number; y: number };

export function dist(a: Point, b: Point) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function normalize(a: Point) {
  const mag = Math.sqrt(a.x ** 2 + a.y ** 2);

  return { x: a.x / mag, y: a.y / mag };
}

export function getMiddlePoint(a: Point, b: Point) {
  const dir = { x: b.x - a.x, y: b.y - a.y };
  const len = dist({ x: 0, y: 0 }, dir);
  const normal = normalize(dir);

  dir.x = normal.x * (len / 2);
  dir.y = normal.y * (len / 2);

  return { x: a.x + dir.x, y: a.y + dir.y };
}

//convert 3 points to an Arc Path
export function calcCirclePath(a: Point, b: Point, c: Point) {
  const A = dist(b, c);
  const B = dist(c, a);
  const C = dist(a, b);

  const angle = Math.acos((A * A + B * B - C * C) / (2 * A * B));
  const K = 0.5 * A * B * Math.sin(angle);
  let r = (A * B * C) / 4 / K;

  r = Math.round(r * 1000) / 1000;

  const laf = +(Math.PI / 2 > angle);
  const saf = +((b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) < 0);

  return ['M', a.x, a.y, 'A', r, r, 0, laf, saf, b.x, b.y].join(' ');
}

export function perpendicular(v: Point) {
  const angle = Math.atan2(v.y, v.x);
  const length = Math.sqrt(v.x ** 2 + v.y ** 2);

  return {
    x: length * Math.cos(angle + Math.PI / 2),
    y: length * Math.sin(angle + Math.PI / 2),
  };
}

export function generateArcPath(a: Point, b: Point, h: number) {
  const mid = getMiddlePoint(a, b);
  const { x, y } = perpendicular(normalize({ x: a.x - b.x, y: a.y - b.y }));
  const c = { x: x * h + mid.x, y: y * h + mid.y };

  return calcCirclePath(a, b, c);
}
