import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";

export class BuiltinVariableExpression extends Expression implements interfaces.IBuiltinVariableExpression {
  elementType: DslElementType.BuiltinVariableExpression = DslElementType.BuiltinVariableExpression;
  variable: RawElement<string>;

  constructor(obj: interfaces.IBuiltinVariableExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.variable = helpers.ensureIsInstance(obj.variable, RawElement<string>);
  }
  toJson(): interfaces.IBuiltinVariableExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      variable: this.variable.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `#${this.variable.value}`;
  }
}
