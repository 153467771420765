import styled from "styled-components";

export const ActionList = styled.ul`
  background: #1d1e31;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 0;
  padding: 12px;
  list-style-type: none;

  li,
  a {
    text-decoration: none;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.75);
    transition: 0.2s color;
    cursor: pointer;

    display: flex;
    align-items: center;
  }

  li + li {
    margin-top: 8px;
  }

  li:hover,
  a:hover {
    color: #ffffff;
  }
`;

export const TooltipList = styled(ActionList)<{ isOpen: boolean }>`
  z-index: 1000;
  transition: 0.2s opacity;
  opacity: ${(p) => (p.isOpen ? 1 : 0)};
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
  position: absolute;
  right: 0;
`;
