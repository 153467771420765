import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Box = styled.div`
  flex: 1;

  &:first-child {
    margin-right: 16px;
  }
`;

export const Input = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  height: 44px;

  padding: 8px;
  margin-bottom: 8px;

  border-radius: 8px;

  background-color: rgba(255, 255, 255, 0.06);

  box-sizing: border-box;

  cursor: pointer;

  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #d9d9e2;

  > svg {
    position: absolute;
    right: 8px;
    top: 10px;
  }

  label {
    font-family: var(--main-font);
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.3);

    margin-bottom: 2px;
  }
`;

export const PopperBox = styled.div`
  position: absolute;
  bottom: 52px;
  left: 0;

  padding: 32px;

  width: 100%;

  box-sizing: border-box;

  background: #1d1e31;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div``;

export const PopperValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  p {
    width: 32px;
    height: 32px;

    border-radius: 50%;

    margin-bottom: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  p:nth-child(2) {
    background-color: #4790ff;
  }
`;

export const SelectBtn = styled.button`
  background: none;
  border: none;

  cursor: pointer;

  &:first-child {
    margin-bottom: 8px;
  }
`;
