/// <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    Sprig: any;
    pendo: any;
  }
}

class Analytics {
  static readonly SHOW_FEEDBACK_TIMEOUT = 1000 * 60 * 5; // after 5 min
  static readonly SprigFeedbackStorage = "SprigFeedbackStorage";
  static readonly SURVEY_ID = 69796;

  constructor() {
    setTimeout(() => this.showFeedback(), Analytics.SHOW_FEEDBACK_TIMEOUT);
  }

  identity(email: string): void {
    window.Sprig?.("setUserId", email);
    window.analytics?.identify(email);
    window.pendo?.initialize({
      visitor: { id: email },
      account: { id: email },
    });
  }

  logout(): void {
    window.Sprig?.("logout");
    window.analytics?.reset();
  }

  showFeedback(): void {
    const isOpened = window.localStorage.getItem(Analytics.SprigFeedbackStorage);
    if (isOpened) return;
    window.Sprig?.("displaySurvey", Analytics.SURVEY_ID);
    window.localStorage.setItem(Analytics.SprigFeedbackStorage, "1");
  }

  track(event: string, options: object = {}): void {
    window.analytics?.track(event, {
      ...options,
      time: Date.now(),
    });
  }
}

export default new Analytics();
