// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gSlaivZ7niNTqEFLmDDo {
	background-color: rgba(255, 255, 128, .2);
}

.R_ZDdYwM4eZlEEBbEOg9 {
	background-color: rgba(255, 128, 128, .2);
}`, "",{"version":3,"sources":["webpack://./src/core/workspace/plugins/styles.module.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;AAC1C;;AAEA;CACC,yCAAyC;AAC1C","sourcesContent":[".selectedPhraseDecoration {\n\tbackground-color: rgba(255, 255, 128, .2);\n}\n\n.errorPhraseDecoration {\n\tbackground-color: rgba(255, 128, 128, .2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedPhraseDecoration": `gSlaivZ7niNTqEFLmDDo`,
	"errorPhraseDecoration": `R_ZDdYwM4eZlEEBbEOg9`
};
export default ___CSS_LOADER_EXPORT___;
