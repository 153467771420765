import * as React from 'react';
import { AbstractReactFactory, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';

import EditorController from '..';
import Widget from './Widget';
import Model from './Model';

class LabelFactory extends AbstractReactFactory<Model, EditorController> {
  constructor() {
    super(Model.type);
  }

  generateModel(): Model {
    return new Model("label");
  }

  generateReactWidget(event: GenerateWidgetEvent<Model>): JSX.Element {
    return <Widget model={event.model} engine={this.engine} />;
  }
}

export default LabelFactory;
