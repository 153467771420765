import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";

export class UnaryExpression extends Expression implements interfaces.IUnaryExpression {
  elementType: DslElementType.UnaryExpression = DslElementType.UnaryExpression;
  expression: Expression;
  unaryOperationSymbol: "+" | "-" | "!" | "not";

  constructor(obj: interfaces.IUnaryExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.expression = helpers.ensureIsExpressionInstance(obj.expression);
    this.unaryOperationSymbol = obj.unaryOperationSymbol;
  }
  toJson(): interfaces.IUnaryExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      expression: this.expression.toJson(),
      unaryOperationSymbol: this.unaryOperationSymbol,
    };
  }
  toDsl(indent: number): string {
    return `${this.unaryOperationSymbol}${this.expression.toDsl(indent)}`;
  }
}
