import { makeAutoObservable, runInAction } from "mobx";
import * as dasha from "@dasha.ai/sdk/web";
import JSZip from "jszip";

import SIPClient from "../SIPClient";
import Project from "../explorer/Project";
import DSLEditor from "../legacy-graph-storage/DSLEditor";
import { IDisposable } from "../misc/emitter";

import Profiler from "@core/profiler/ProfilerStorage";
import { WorkerApi } from "@core/dsl/worker/api";
import GraphApi from "@core/dsl/to-json/src/GraphApi";
import Editor from "@core/code-editor";

import PhrasemapStorage from "./phrasemap-storage/PhrasemapStorage";
import TextStorage, { FileStorage } from "./TextStorage";
import DatasetStorage from "./dataset-storage/DatasetStorage";
import DashaappStorage from "./DashaappStorage";

import DslStorage from "./dsl-storage/DslStorage";
import RuntimeStorage from "./runtime-storage/RuntimeStorage";
import SessionsStorage from "./session-storage/SessionsStorage";
import { RunnerType } from "./session-storage/types";

import { AppType, RunAppFactory, TerminalLog } from "./sandbox/types";
import Sandbox from "./sandbox";
import { IAccount } from "@core/account/interface";

class Workspace {
  public files: FileStorage[] = [];
  public opened: FileStorage | null = null;
  public project: Project | null = null;
  public sessions?: SessionsStorage;
  public runtime?: RuntimeStorage;

  public storages: {
    graph?: DSLEditor;
    profiler?: Profiler;
    dashaapp?: DashaappStorage;
    phrasemap?: PhrasemapStorage;
    dataset?: DatasetStorage;
    dsl?: DslStorage;
  } = {};

  public logs: TerminalLog[] = [];
  private logger: typeof dasha.log;

  private disposables: IDisposable[] = [];

  constructor(readonly sip: SIPClient, readonly account: IAccount) {
    this.logger = dasha.log.child({ label: "workspace" });

    // TODO: write an independent logger for each workspace.
    // Need support from the dasha-sdk to setup custom logger for each deployed app
    dasha.log.on("data", (log) => this.logsListener(log));
    makeAutoObservable(this);
  }

  public dispose() {
    Editor.Instance.clearSchemas();
    this.disposables.forEach((d) => d.dispose());
    this.getStorages().forEach((storage) => storage.dispose());
    void this.sessions?.dispose();

    this.files.forEach((file) => file.dispose());
    this.files = [];
    this.runtime = undefined;
    this.opened = null;
    this.disposables = [];
  }

  get isRunning() {
    return this.sessions?.isRunning ?? false;
  }

  private logsListener({ name, level, label, message }) {
    // Avoid system error
    if (name === "dasha.CancelError") return;
    if (name === "dasha.OptionalDependencyNotFoundError") return;
    this.logs.push({ name, level, message, timestamp: Date.now(), label: label ?? "sdk" });
  }

  async setupDashaApp() {
    if (this.project == null) return;
    const zip = await this.project?.zip();
    const files = Object.keys(zip?.files ?? {});
    const dashaapp = files.find((path) => path.split(".").pop() === "dashaapp");
    if (dashaapp == null) return;

    this.storages.dashaapp = new DashaappStorage(this.project, dashaapp, this.account);
    await this.storages.dashaapp.updateConfig();
  }

  *setProject(project: Project | null) {
    this.dispose();
    this.project = project;
    if (project == null) return;

    Editor.Instance.loadSchemas();
    this.sessions = new SessionsStorage(project, this.account);
    yield this.sessions.readSessions();

    this.disposables.push(project.onDidCreate((file) => this.addFile(file)));
    this.disposables.push(project.onDidRemove((path) => this.removeByPath(path)));

    yield this.setupDashaApp();
    const dashaapp = this.storages.dashaapp;
    if (dashaapp == null) return;

    this.runtime = new RuntimeStorage(project);
    this.storages.dataset = dashaapp.dataset ? new DatasetStorage(project, dashaapp.dataset) : undefined;
    this.storages.phrasemap = dashaapp.phrasemap
      ? new PhrasemapStorage({ project, path: dashaapp.phrasemap })
      : undefined;

    this.storages.profiler = new Profiler(project, this.account, this.storages.dataset);
    this.storages.dsl = dashaapp.dsl
      ? new DslStorage({
          project,
          phrasemap: this.storages.phrasemap,
          dataset: this.storages.dataset,
          path: dashaapp.dsl,
        })
      : undefined;

    if (project.metadata.customMetaData.projectType === "visual") {
      if (this.storages.dataset == null) return;
      if (this.storages.phrasemap == null) return;
      if (this.storages.dsl == null) return;

      this.storages.graph = new DSLEditor({ dataset: this.storages.dataset, project });
      this.files = [dashaapp, this.storages.phrasemap, this.storages.dataset, this.storages.graph];
      this.selectFile(this.storages.graph);
      return;
    }

    this.files = this.getStorages();
    this.selectFile(this.files[0]);
  }

  async fileFabric(path: string): Promise<FileStorage | null> {
    if (this.project == null) throw Error("project is not defined");
    const ext = path.split(".").pop();

    if (ext === "dsl")
      return new DslStorage({
        project: this.project,
        phrasemap: this.storages.phrasemap,
        path,
      });

    return new TextStorage(this.project, path);
  }

  async addFile(content: string | FileStorage) {
    if (this.project == null) throw Error("project is not defined");
    const findFile = (file) => {
      if (typeof content !== "string") return file === content;
      return file.path === content;
    };

    const file = this.files.find(findFile);
    this.files = this.files.filter((stor) => {
      if (stor.isPinned || stor === file) return true;
      stor.dispose();
    });

    if (file) {
      this.opened = file;
      return;
    }

    const storage = this.getStorages().find(findFile);
    if (storage) {
      this.files.push(storage);
      this.opened = storage;
      return;
    }

    const newFile = typeof content === "string" ? await this.fileFabric(content) : content;
    if (newFile != null) {
      runInAction(() => {
        this.files.push(newFile);
        this.opened = newFile;
      });
    }
  }

  openFile(file: FileStorage) {
    this.files.push(file);
    this.selectFile(file);
  }

  getFile(path: string) {
    return this.files.find((file) => file.path === path);
  }

  getStorages() {
    return Object.values(this.storages).filter((v) => v);
  }

  async removeByPath(path: string) {
    void WorkerApi.Instance.removeModel(path);
    Object.entries(this.storages).find(([key, storage]) => {
      if (storage.path === path) {
        this.closeFile(storage);
        void storage.dispose();
        this.storages[key] = undefined;

        return true;
      }
    });

    this.files.find((file) => {
      if (file.path === path) {
        this.closeFile(file);
        return true;
      }
    });
  }

  closeFile(file: FileStorage) {
    if (this.getStorages().every((s) => file !== s)) {
      file.dispose();
    }

    this.files = this.files.filter((f) => f !== file);
    if (this.opened === file) {
      this.opened = this.files[0];
    }
  }

  selectFile(file: FileStorage) {
    this.opened = file;
  }

  async stop(): Promise<void> {
    await this.sip.hangup();
    await this.sessions?.stopActiveSessions();
  }

  private getAppType(type: RunnerType): AppType {
    switch (type) {
      case RunnerType.Text:
        return AppType.Text;
      case RunnerType.Call:
      case RunnerType.Audio:
        return AppType.Audio;
      case RunnerType.Test:
        return AppType.Text;
    }
    return AppType.Text;
  }
  async run(type: RunnerType, endpoint?: string): Promise<void> {
    if (this.sessions == null || this.sessions.isRunning) return;

    const testJobId = this.sessions?.activeSession?.jobId;

    this.logs = [];
    this.logger.info("run application...");

    const session = this.sessions.createSession(type);
    session.onDidSwitchNode((to) => to && this.storages.graph?.highlightNode(to));

    try {
      const appType = this.getAppType(type);

      const rpc = await this.appFactory(appType, endpoint, type === RunnerType.Test ? testJobId : undefined);
      session.bindCommunicator(rpc);
    } catch (e) {
      this.logger.error(e);
      await session.stop();
    }
  }

  async getGraphApi(zip: JSZip) {
    const files: { filePath: string; rawContent: string }[] = [];
    for (const file in zip.files) {
      if (file.split(".").pop() === "dsl") {
        const raw = await zip.files[file].async("string");
        files.push({ filePath: file, rawContent: raw });
      }
    }

    return new GraphApi(files);
  }

  private appFactory: RunAppFactory = async (type, endpoint, testJobId) => {
    if (this.project == null) throw Error("project is not defined");
    if (this.storages.dashaapp == null) throw Error("dashaapp is not defined");
    if (this.storages.dsl == null) throw Error("dsl is not defined");
    const canBeCancelled = type === AppType.Audio && endpoint != null;
    if (endpoint == null && type === AppType.Audio) {
      endpoint = await this.sip.initialize();
    }

    await this.storages.dsl.syncGraphService();
    await this.runtime?.syncFiles(this.storages.dsl.graphService);

    const account = this.account.connect();
    const zip = await this.project.zip();

    const dsl = this.storages.dsl?.model.getValue() ?? "";
    zip.file(this.storages.dsl.path, dsl);

    const sandbox = new Sandbox();
    sandbox.onDidDslError((errors) => {
      const storage = this.files.find((file) => file.name === errors.filename);
      storage?.highlightErrors?.(errors.markers);
    });

    await sandbox.execute({
      endpoint: endpoint ?? this.sip.endpoint,
      account,
      type,
      zip,
      canBeCancelled: canBeCancelled,
      testAppJobId: testJobId,
    });

    return sandbox;
  };
}

export default Workspace;
