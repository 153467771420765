import React, { ChangeEvent, FC, useState } from "react";
import { observer } from "mobx-react-lite";

import FilterType from "@core/profiler/Filter";
import FilterMenu from "../FilterMenu";

import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import * as S from "./styled";
import { StrokeButton } from "../../../../../uikit";
import Profiler from "@core/profiler/ProfilerStorage";

interface Props {
  profiler: Profiler;
}

const Filter: FC<Props> = ({ profiler }) => {
  const filter = profiler?.table?.filter;

  if (!filter) {
    return null;
  }

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleSearchFocus = () => setIsSearchFocused(true);

  const handleSearchBlur = () => setIsSearchFocused(false);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => filter.setSearchRequest(e.target.value);

  const handleSearchReset = () => filter.setSearchRequest("");

  const filterMenus = [
    {
      name: "Node from",
      searchPlaceholder: "Search by node from",
      actions: filter.nodesFrom,
      onReset: () => filter.resetSelectedFilters("selectedNodesFrom"),
      selected: filter.selectedNodesFrom,
    },
    {
      name: "Node to",
      searchPlaceholder: "Search by node to",
      actions: filter.nodesTo,
      onReset: () => filter.resetSelectedFilters("selectedNodesTo"),
      selected: filter.selectedNodesTo,
    },
    {
      name: "Intents",
      searchPlaceholder: "Search by intents",
      actions: filter.intents,
      onReset: () => filter.resetSelectedFilters("selectedIntents"),
      selected: filter.selectedIntents,
    },
    {
      name: "Entities",
      searchPlaceholder: "Search by entities",
      actions: filter.entities,
      onReset: () => filter.resetSelectedFilters("selectedEntities"),
      selected: filter.selectedEntities,
    },
  ];

  return (
    <S.Container>
      <S.SearchField $isFocused={isSearchFocused}>
        {!filter.searchRequest && <SearchIcon />}
        <S.Search
          type="text"
          placeholder="Search by phrases"
          value={filter.searchRequest}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          onChange={handleSearchChange}
        />
        {filter.searchRequest && (
          <S.ResetBtn onClick={handleSearchReset}>
            <CloseIcon />
          </S.ResetBtn>
        )}
      </S.SearchField>
      <S.FiltersBox>
        <div>
          {filterMenus.map((menu) => (
            <FilterMenu key={menu.name} children {...menu} />
          ))}
        </div>
        <div>
          <StrokeButton onClick={() => profiler.exportProfile()}>Export profile</StrokeButton>
        </div>
      </S.FiltersBox>
    </S.Container>
  );
};

export default observer(Filter);
