import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { makeAutoObservable, runInAction } from "mobx";

export class ApplicationStore {
  private readonly account: IAccount;
  public Loading: boolean;
  public ApplicationNames: string[];
  constructor(account: IAccount) {
    this.account = account;
    this.ApplicationNames = [];
    makeAutoObservable(this);
  }

  public TryReload() {
    if (this.ApplicationNames.length === 0 && !this.Loading) {
      this.Reload();
    }
  }

  private Reload() {
    this.Loading = true;
    const a = this.account.connect();
    dasha.applications
      .getApplications({ account: a })
      .then((apps) => {
        runInAction(() => {
          this.ApplicationNames = Array.from(new Set(apps.map((x) => x.name)));
        });
      })
      .finally(() => {
        runInAction(() => {
          this.Loading = false;
        });
      });
  }
}
