import styled from "styled-components";

export const Container = styled.div``;

export const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;

  span {
    margin-right: 4px;
  }
`;

export const Info = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 8px;
  margin-bottom: 16px;

  li {
    margin-bottom: 4px;
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

export const Key = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 4px;
`;

export const Value = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
`;
