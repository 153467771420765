import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import Profiler from "@core/profiler/ProfilerStorage";
import { formatDate } from "./helpers";
import * as S from "./styled";

interface Props {
  profiler: Profiler;
  dataset?: DatasetStorage;
  onLoad: () => void;
  onUpload: () => void;
}

const ProfileInfo: FC<Props> = ({ profiler, dataset, onUpload, onLoad }) => {
  if (profiler.document == null) {
    return <S.Container />;
  }

  return (
    <S.Container>
      <S.Info>
        {profiler.document.file ? (
          <p>
            <span>File:</span>
            <span>{profiler.document.file}</span>
          </p>
        ) : (
          <p>
            <span>Id:</span>
            <span>{profiler.document.application}</span>
          </p>
        )}
        <p>
          <span>Range:</span>
          <span>
            {formatDate(profiler.document.start)} - {formatDate(profiler.document.end)}
          </span>
        </p>
      </S.Info>

      <S.Flexbox>
        <S.LoadButton onClick={onLoad}>Load profile</S.LoadButton>
        <S.LoadButton onClick={onUpload}>Upload .dashasp</S.LoadButton>
        {dataset && <S.SaveButton onClick={() => profiler.saveToDataset()}>Save to dataset</S.SaveButton>}
      </S.Flexbox>
    </S.Container>
  );
};

export default observer(ProfileInfo);
