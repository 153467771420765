import * as dasha from "@dasha.ai/sdk/web";
import { IAccount } from "./account/interface";
import { Task } from "./legacy-graph-storage/helpers";
import EventEmitter from "eventemitter3";

export type SynthesizeVoiceOptions = {
  speaker?: string;
  lang?: string;
  emotion?: string;
  speed?: number;
  variation?: number;
};

class SimpleTtsSpeaker extends EventEmitter<{ removedAudio: void; playingAudio: void }> {
  private audio?: HTMLAudioElement;
  private readonly defaultVoiceOptions = {
    speaker: "default",
    speed: 1,
    lang: "en-US",
    variation: 1,
    emotion: "",
  };

  private constructor(public account: IAccount) {
    super();
  }

  private static _instance?: SimpleTtsSpeaker;

  public static initialize(account: IAccount) {
    this._instance = new this(account);
  }

  public static get Instance() {
    if (!this._instance) {
      throw new Error(`Tts speaker is not initialized`)
    }
    return this._instance;
  }

  interpolatePhrase(phrase: string, context: { value: string; key: string }[]) {
    //@ts-ignore
    return phrase.replaceAll(/(\$\w+)/g, (tag) => {
      if (tag.startsWith("$")) {
        const key = tag.slice(1);
        const variable = context.find((item) => item.key == key);
        return variable?.value || key;
      }
    });
  }

  removeAudio() {
    if (this.audio == undefined) return;
    window.URL.revokeObjectURL(this.audio.src);
    this.audio.currentTime = this.audio?.duration || 0;
    this.audio = undefined;
    this.emit("removedAudio");
  }

  async play(text: string, voiceOptions: SynthesizeVoiceOptions, context: { value: string; key: string }[] = []) {
    try {
      this.emit("playingAudio");
      if (this.audio) this.removeAudio();
      this.audio = new Audio();
      const task = new Task();
  
      if (context) {
        text = this.interpolatePhrase(text, context);
      }
      const voice = { ...this.defaultVoiceOptions, ...voiceOptions };
      const account = this.account.connect();
      const providerName = "dasha";
      const options = { providerName, account };
  
      const buffer = await dasha.tts.synthesize(text, voice, options);
      const blob = new Blob([buffer], { type: "audio/mp3" });
      const url = window.URL.createObjectURL(blob);
  
      this.audio.src = url;
      this.audio.addEventListener("ended", () => {
        this.removeAudio();
        task.resolve();
      });
  
      await this.audio.play();
      await task.promise;
    } catch (e) {
      if (this.audio) 
        this.removeAudio();
      throw e;
    }
  }

  stop() {
    if (!this.audio) return;
    this.audio?.pause();
    this.removeAudio();
  }

  isActive() {
    return this.audio !== undefined;
  }
}


export default SimpleTtsSpeaker;
