import JSZip from "jszip";
import * as dasha from "@dasha.ai/sdk/web";
import { IDisposable } from "../../misc/emitter";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor;

export enum AppType {
  Text = "text",
  Audio = "audio",
}

export interface ExecuteConfig {
  zip: JSZip;
  endpoint: string;
  account: dasha.Account;
  type: AppType;
  canBeCancelled: boolean;
  testAppJobId?: string;
}

export interface TerminalLog {
  name: string;
  label: string;
  level: string;
  message: string;
  timestamp: number;
}

export interface CompilationError {
  filename: string;
  markers: {
    line: number;
    column: number;
    message: string;
  }[];
}

export type RunAppFactory = (type: AppType, endpoint?: string, setJobId?: string) => Promise<AppCommunicatorProtocol>;

export interface AppCommunicatorProtocol {
  dispose(): Promise<void>;
  send(message: string): Promise<void>;
  onDidDevLog(on: (log: any) => void): IDisposable;
  onLogger(on: (msg: any) => void): IDisposable;
  onDidChangeContext(on: (ctx: Record<string, any>) => void): IDisposable;
  onDidComplete(on: () => void): IDisposable;
  onDidError(on: (error: Error) => void): IDisposable;
  onDidSandboxEvent(on: (event: string) => void): IDisposable;
  iframe?: HTMLIFrameElement;
}
