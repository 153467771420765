import React, { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";

import useNodeToCenter from "./useNodeToCenter";
import * as S from "./styled";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  useNodeToCenter(xPos, yPos, data.highlight);

  return (
    <S.Node isSelected={selected || data.highlight} style={{ height: 60, display: 'flex', alignItems: 'center' }}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
      <S.Label style={{ margin: 0 }}>IF</S.Label>

      <div style={{ top: 8, right: -13, display: 'flex', position: 'absolute' }}>
        <S.Label style={{ margin: 0, marginRight: 24 }}>TRUE</S.Label>
        <Handle
          id="true"
          type="source"
          position={Position.Right}
          style={{ width: 12, height: 12, background: "#555" }}
          isConnectable={isConnectable}
        />
      </div>
      
      <div style={{ bottom: 8, right: -13, display: 'flex', position: 'absolute' }}>
        <S.Label style={{ margin: 0, marginRight: 24 }}>FALSE</S.Label>
        <Handle
          id="false"
          type="source"
          position={Position.Right}
          style={{ width: 12, height: 12, background: "#555" }}
          isConnectable={isConnectable}
        />
      </div>
    </S.Node>
  );
});
