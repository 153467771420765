import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import DslStorage from "./DslStorage";
import { WorkerApi } from "../../dsl/worker/api";
import * as completions from "./static-completions";

export class CompletionItemProvider implements monaco.languages.CompletionItemProvider {
  private storage: DslStorage;
  private editor: monaco.editor.IStandaloneCodeEditor;

  private completions: monaco.languages.CompletionItem[] = [];
  private isReady = false;

  constructor(dslStorage: DslStorage, editor: monaco.editor.IStandaloneCodeEditor) {
    this.storage = dslStorage;
    this.editor = editor;
  }
  triggerCharacters: string[] = [`"`, `#`];
  provideCompletionItems(
    model: monaco.editor.ITextModel,
    position: monaco.Position,
    context: monaco.languages.CompletionContext,
    token: monaco.CancellationToken
  ): monaco.languages.ProviderResult<monaco.languages.CompletionList> {
    if (this.isReady) {
      const returnValue: monaco.languages.CompletionList = { suggestions: [...this.completions], incomplete: false };
      this.completions = [];
      this.isReady = false;
      return returnValue;
    }

    this.getCompletions(model, position, context)
      .then((completions) => {
        this.completions = completions;
        this.isReady = true;
        this.editor.trigger("completion-suggestion", "editor.action.triggerSuggest", { suggestions: this.completions });
      })
      .catch((error) => {
        console.debug(`Could not provide completions, reason: ${error.message}`);
      });
  }

  private async getCompletions(
    model: monaco.editor.ITextModel,
    position: monaco.Position,
    context: monaco.languages.CompletionContext
  ): Promise<monaco.languages.CompletionItem[]> {
    const currentTyped = model.getWordUntilPosition(position).word;

    await WorkerApi.Instance.updateModel(this.storage.path, model.getValue());
    const { pathToPosition, pathsToCutErrors } = await WorkerApi.Instance.getCurrentContexts(
      this.storage.path,
      position
    );

    let isInBlock = [`file`, `blockContent`, `blockDeclaration`].includes(
      pathToPosition[pathToPosition.length - 1].rule
    );
    isInBlock ||= pathToPosition.length === 0 && pathsToCutErrors.length === 0;
    const isInNode = [`nodeDeclaration`, `nodeContent`].includes(pathToPosition[pathToPosition.length - 1]?.rule);
    let isInDigression = pathToPosition[pathToPosition.length - 1].rule === `digressionDeclaration`;
    isInDigression ||=
      pathToPosition.find((p) => p.rule === `digressionDeclaration`) !== undefined &&
      ["nodeContent", "digressionContent"].includes(pathToPosition[pathToPosition.length - 1]?.rule);
    const isInOnexit = pathToPosition[pathToPosition.length - 1].rule === `nodeOnExitSection`;
    const isBuiltinFunctionAvailable =
      pathsToCutErrors.filter((pe) => {
        const lastNode = pe[pe.length - 1];
        if (!lastNode) return false;
        let isAvailable = lastNode.rule === "statement" && model.getValueInRange(lastNode.range).startsWith(`#`);
        isAvailable ||= lastNode.rule === "expression";
        isAvailable ||= lastNode.rule === "namedTransitionDefinition";
        isAvailable ||= lastNode.rule === "builtinReferenceContext";
        isAvailable ||= lastNode.rule === "builtinFunctionNameContext";
        return isAvailable;
      })[0] !== undefined;
    const isInArgumentInvocation =
      pathsToCutErrors.filter((pe) => {
        return pe.find((node) => node.rule === "argumentInvokation") !== undefined;
      })[0] !== undefined;

    let completionItems: monaco.languages.CompletionItem[] = [];
    if (isInArgumentInvocation && context.triggerCharacter !== `#`) {
      completionItems = await this.getBuiltinFunctionArgumentCompletions(model, position);
    }
    if (isBuiltinFunctionAvailable && completionItems.length === 0) {
      completionItems = this.getBuiltinFunctionCompletions(position);
    } else if (isInBlock && context.triggerCharacter === undefined) {
      completionItems = this.getBlockCompletions(position);
    } else if (isInNode && context.triggerCharacter === undefined) {
      completionItems = this.getNodeCompletions(position);
    } else if (isInDigression && context.triggerCharacter === undefined) {
      completionItems = this.getDigressionCompletions(position);
    } else if (isInOnexit && context.triggerCharacter === undefined) {
      completionItems = this.getOnexitCompletions(position);
    } else {
      console.debug("No suggestions provided");
    }

    completionItems = completionItems
      .filter((item) => {
        if (typeof item.label === "string") {
          return item.label.startsWith(currentTyped);
        } else {
          return item.label.label.startsWith(currentTyped);
        }
      })
      .map((item) => {
        return { ...item, insertText: item.insertText.slice(currentTyped.length) };
      });
    return completionItems;
  }

  private getBlockCompletions(position: monaco.IPosition): monaco.languages.CompletionItem[] {
    console.debug("Providing block snippets suggestions");
    return [
      completions.createNodeSnippet(position),
      completions.createDigressionSnippet(position),
      completions.createPreprocessorSnippet(position),
    ];
  }

  private getNodeCompletions(position: monaco.IPosition): monaco.languages.CompletionItem[] {
    console.debug("Providing node snippets suggestions");
    return [
      completions.createTransitionSnippet(position),
      completions.createDoSnippet(position),
      completions.createOnexitSnippet(position),
    ];
  }

  private getDigressionCompletions(position: monaco.IPosition): monaco.languages.CompletionItem[] {
    console.debug("Providing digression snippets suggestions");
    return [
      completions.createTransitionSnippet(position),
      completions.createConditionsSnippet(position),
      completions.createDoSnippet(position),
      completions.createOnexitSnippet(position),
    ];
  }

  private getOnexitCompletions(position: monaco.IPosition): monaco.languages.CompletionItem[] {
    console.debug("Providing onexit snippets suggestions");
    return [completions.createDoSnippet(position)];
  }

  private getBuiltinFunctionCompletions(position: monaco.IPosition): monaco.languages.CompletionItem[] {
    console.debug("Providing builtin function suggestions");
    return completions.createBuiltinFunctionSuggestions(position);
  }

  private async getBuiltinFunctionArgumentCompletions(
    model: monaco.editor.ITextModel,
    position: monaco.IPosition
  ): Promise<monaco.languages.CompletionItem[]> {
    console.debug("Providing builtin function argument suggestions");
    let completionItems: monaco.languages.CompletionItem[];

    const builtinFunctionInfo = await WorkerApi.Instance.getCurrentBuiltinFunction(this.storage.path, position);
    if (builtinFunctionInfo === undefined) return [];
    const { builtinFunctionName, range } = builtinFunctionInfo;
    const endPosition = new monaco.Position(range.endLineNumber, range.endColumn);
    const argumentStr = model.getValueInRange(monaco.Range.fromPositions(endPosition, position));

    const lastQuotePos = argumentStr.lastIndexOf(`"`);
    const firstQuotePos = argumentStr.lastIndexOf(`"`, lastQuotePos - 1);
    const isEndedTypingArgument = firstQuotePos != -1 && firstQuotePos != lastQuotePos;

    let completions: string[] = [];
    if ((builtinFunctionName === "say" || builtinFunctionName === "sayChanneled") && !isEndedTypingArgument) {
      completions = this.getPhrasemapIds();
    }
    if (
      (builtinFunctionName === "messageHasIntent" && !isEndedTypingArgument) ||
      (builtinFunctionName === "messageHasAnyIntent" && argumentStr.includes(`[`))
    ) {
      completions = this.getIntents();
    }
    if (
      (builtinFunctionName === "messageHasData" || builtinFunctionName === "messageGetData") &&
      !isEndedTypingArgument
    ) {
      completions = this.getEntities();
    }
    const nextPosition = new monaco.Position(position.lineNumber, position.column + 1);
    const nextChar = model.getValueInRange(monaco.Range.fromPositions(position, nextPosition));
    const hasClosingQuote = nextChar === `"`;
    completionItems = completions.map((completion) => {
      const insertText = completion + (hasClosingQuote ? `` : `"`);
      return {
        label: `${completion}`,
        insertText: insertText,
        kind: monaco.languages.CompletionItemKind.Constant,
        range: monaco.Range.fromPositions(position),
      };
    });
    return completionItems;
  }

  private getIntents(): string[] {
    return this.storage.dataset?.intents ?? [];
  }

  private getEntities(): string[] {
    return this.storage.dataset?.entities ?? [];
  }

  private getPhrasemapIds(): string[] {
    return this.storage.phrasemap?.getPhraseIds() ?? [];
  }
}
