import styled from "styled-components";
import { StrokeButton } from "../../../../../uikit";

export const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 108px;

  padding: 24px 24px 16px 24px;

  background-color: #161729;
`;

interface SearchFieldProps {
  $isFocused: boolean;
}

export const SearchField = styled.div<SearchFieldProps>`
  display: flex;
  align-items: center;

  position: relative;

  padding-left: 8px;
  padding-right: 24px;

  background: #2b2b3d;
  border: none;
  border-radius: 4px;
  outline: ${(props) => (props.$isFocused ? "1px solid rgba(255, 255, 255, 0.3)" : "none")};

  transition: outline 0.3s, background 0.3s;

  height: 32px;
  width: 100%;

  > svg {
    pointer-events: none;

    position: absolute;
  }

  &:hover {
    background: #343546;
  }
`;

export const Search = styled.input`
  background: none;
  border: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;

  display: block;

  width: 100%;
  height: 100%;

  color: #ffffff;

  outline: none;

  &::placeholder {
    padding-left: 17px;

    font-weight: 400;
    font-size: 13px;
    line-height: 24px;

    color: rgba(255, 255, 255, 0.3);
  }
`;

export const ResetBtn = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: none;

  position: absolute;
  right: 8px;
  height: 24px;

  cursor: pointer;

  path {
    transition: 0.3s fill-opacity;
  }

  &:hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const FiltersBox = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    ${StrokeButton} {
      text-transform: inherit;
      height: 24px;
      font-family: var(--main-font);
      font-weight: 600;
      font-size: 13px;
      line-height: 22px;
    }
  }

  padding-top: 16px;
`;
