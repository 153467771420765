import React from "react";
import { DefaultLinkWidget } from "@projectstorm/react-diagrams";

import EditorController from "..";
import LineSegment from "./LineSegment";
import LinkModel from "./Model";

export default class LinkWidget extends DefaultLinkWidget {
  generateLink(path: string, id: string | number): JSX.Element {
    const ref = React.createRef<SVGPathElement>();
    this.refPaths.push(ref);

    return (
      <LineSegment
        key={`link-${id}`}
        path={path}
        selected={this.state.selected}
        diagramEngine={this.props.diagramEngine as EditorController}
        factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
        onSelection={(selected) => this.setState({ selected: selected })}
        link={this.props.link as LinkModel}
        forwardRef={ref}
      />
    );
  }

  render(): any{
    this.refPaths = [];
    if (this.props.link.getOptions().extras.isHidden) return null;
    return <g>{this.generateLink(this.props.link.getSVGPath(), "0")}</g>;
  }
}
