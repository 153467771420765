import { PortWidget } from "@projectstorm/react-diagrams";
import styled from "styled-components";

export const Node = styled.div`
  background: #161729;
  border: 1px solid #008a69;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
`;

export const PortOut = styled(PortWidget)`
  position: absolute;
  right: -5px;
  top: 50%;
  margin-top: -2.5px;
  width: 5px;
  height: 5px;
`;

export const PortIn = styled(PortWidget)`
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -2.5px;
  width: 5px;
  height: 5px;
`;

export const Name = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11.75px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 108px;
  pointer-events: none;

  overflow-wrap: anywhere;
  position: absolute;
  margin: 0;
  margin-top: 92px;
`;


export const Port = styled(PortWidget)`
  pointer-events: none;
`