import { makeObservable, flow, action, observable } from "mobx";
import SessionRunner from "../../core/workspace/session-storage/SessionRunner";
import { Message } from "../../core/workspace/session-storage/types";
import { getMessagesWithTimeInAudio } from "./helpers";
import { MessageWithTime } from "./types";

class PlayerModel {
  public player: HTMLAudioElement;
  public currentTime: number;
  public isPlaying: boolean;
  public duration: number;
  public playbackRate: number;
  public messagesWithTime: MessageWithTime[] = [];
  public selectedMessageId: string | number;
  public audioLink: string;

  messages: Message[];
  session: SessionRunner;

  constructor(src: string, link: string, messages: Message[], session: SessionRunner) {
    makeObservable(this, {
      player: observable,
      currentTime: observable,
      isPlaying: observable,
      duration: observable,
      playbackRate: observable,
      messages: observable,
      messagesWithTime: observable,
      selectedMessageId: observable,
      initPlayer: action,
      stopAudio: action,
      playAudio: flow,
      setPlaybackRate: action,
      setCurrentTime: action,
      setSelectedMessageId: action,
      handleEnded: action,
      handleTimeUpdate: action,
      handleLoadedMetaData: action,
    });

    this.session = session;
    this.messages = messages;
    this.audioLink = link;
    this.initPlayer(src);
  }

  initPlayer(src: string) {
    this.player = new Audio(src);
    this.player.load();
    this.currentTime = this.player.currentTime;
    this.duration = this.player.duration || 0;
    this.playbackRate = this.player.playbackRate;

    this.player.addEventListener("timeupdate", this.handleTimeUpdate);
    this.player.addEventListener("ended", this.handleEnded);
    this.player.addEventListener("loadedmetadata", this.handleLoadedMetaData);
  }

  handleTimeUpdate = () => {
    this.currentTime = this.player.currentTime;

    this.findCurrentMessage();
  };

  handleEnded = () => {
    this.isPlaying = false;
  };

  handleLoadedMetaData = () => {
    this.duration = this.player.duration;
    this.messagesWithTime = getMessagesWithTimeInAudio(this.messages, this.player.duration);

    if (this.selectedMessageId) {
      const selectedMessage = this.messagesWithTime.find((message) => message.id === this.selectedMessageId);

      if (!selectedMessage) return;

      this.setCurrentTime(selectedMessage.timeStart - 0.5);
    }

    this.findCurrentMessage();
  };

  public *playAudio() {
    try {
      yield this.player.play();
      this.isPlaying = true;
    } catch (err) {
      this.isPlaying = false;
      console.log({ err });
    }
  }

  public stopAudio() {
    try {
      this.player.pause();
      this.isPlaying = false;
    } catch (err) {
      console.log({ err });
    }
  }

  private findCurrentMessage() {
    const currentMessage = this.messagesWithTime.find(
      (message) => this.currentTime >= message.timeStart && this.currentTime <= message.timeEnd
    );

    if (currentMessage) {
      this.session.selectCurrentMessageInAudio(currentMessage.id);
    }
  }

  public setSelectedMessageId(id: string | number) {
    this.selectedMessageId = id;
  }

  public setPlaybackRate(playbackRate: number) {
    this.playbackRate = playbackRate;
    this.player.playbackRate = playbackRate;
  }

  public setCurrentTime(currentTime: number) {
    this.currentTime = currentTime;
    this.player.currentTime = currentTime;
  }

  public destroy() {
    this.player.removeEventListener("timeupdate", this.handleTimeUpdate);
    this.player.removeEventListener("ended", this.handleEnded);
    this.player.removeEventListener("loadedmetadata", this.handleLoadedMetaData);

    this.player.pause();
  }
}

export default PlayerModel;
