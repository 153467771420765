import React, { useEffect } from "react";
import { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import WorkspaceView from "./WorkspaceView";

export const RoutedWorkspaceView: FC<{}> = ({}) => {
  const { projectId, projectVersion } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    //delete old links
    if (searchParams.has("project")) {
      setSearchParams((params) => {
        params.delete("project");
        params.delete("version");
        return params;
      });
    }
  }, [searchParams, setSearchParams]);

  return <WorkspaceView projectId={projectId ?? ""} projectVersion={projectVersion} />;
};
