import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement, RawElement } from "../common";
import { ArgumentDeclaration } from "../arguments";
import { BlockContentDescription } from "./BlockContentDescription";
import { BlockType } from "../../enums/BlockType";

export class Block extends DslElement implements interfaces.IBlock {
  elementType: DslElementType.Block = DslElementType.Block;
  blockType: interfaces.OptionalValue<BlockType>;
  name: RawElement<string>;
  returnType: interfaces.OptionalValue<RawElement<string>>;
  arguments: ArgumentDeclaration[];
  content: BlockContentDescription;

  constructor(obj: interfaces.IBlock, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.blockType = obj.blockType;
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.returnType = helpers.ensureIsOptionalInstance(obj.returnType, RawElement<string>);
    this.arguments = helpers.ensureIsArrayOfInstances(obj.arguments, ArgumentDeclaration);
    this.content = helpers.ensureIsInstance(obj.content, BlockContentDescription);
  }
  toJson(): interfaces.IBlock {
    return {
      elementType: this.elementType,
      location: this.location,
      blockType: this.blockType,
      name: this.name.toJson(),
      returnType: interfaces.helpers.createOptionalValue(this.returnType.value?.toJson()),
      arguments: this.arguments.map((el) => el.toJson()),
      content: this.content.toJson(),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.joinNullableStrings(
        [
          helpers.indentString(indent),
          this.blockType.hasValue ? `${this.blockType.value} ` : null,
          "block ",
          this.name.value,
          `(${this.arguments.map((el) => el.toDsl(indent)).join(", ")})`,
          this.returnType.hasValue ? `: ${this.returnType.value.toDsl(indent)}` : null,
          " {",
        ],
        ""
      ),
      this.content.toDsl(indent + 1),
      "}",
    ].join("\n");
  }
}
