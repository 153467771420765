import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

export const formatDate = (date: number) => {
  const day = dayjs(date).format("DD.MM.YYYY");
  const time = dayjs(date).format("hh:mm A");

  return `${day} at ${time}`;
};
