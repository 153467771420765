import styled from "styled-components";
import { PureButton } from "../../uikit/Button";

export const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  background: rgba(247, 247, 253, 0.02);
  padding-bottom: 124px;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled(PureButton)<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${(p) => (p.isSelected ? "#35317D" : "#1B1B2D")};
  color: ${(p) => (p.isSelected ? "#ffffff" : "#A1A1BD")};

  height: 32px;
  padding: 0 14px;

  width: 100%;
  text-transform: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
  width: 50%;
  height: 40px;
  border-bottom: ${(p) => (p.isSelected ? "2px solid #5855F4" : "1px solid rgba(255, 255, 255, 0.05)")};
  color: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.2s color;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const Header = styled.div`
  padding: 12px 12px 8px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(161, 161, 189, 0.5);
    margin: 0;
  }

  div {
    display: flex;
  }

  button {
    transition: 0.2s opacity;
    margin-left: 12px;
    opacity: 0.75;
  }

  button:hover {
    opacity: 1;
  }
`;

export const File = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  height: 32px;
  width: 100%;
`;


export const StyledTree = styled.div`
  line-height: 1.75;
  z-index: 1;
`;

export const ActionsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  button {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }

  button::before {
    content: "";
    display: block;
    height: 100%;
    right: -32px;
    width: 69px;
    height: 32px;
    background: var(--tree-color);
    transition: 0.2s background;
    filter: blur(5px);
    position: absolute;
  }

  &:hover button {
    opacity: 1;
    pointer-events: all;
    transition: 0.2s;
  }
`;

export const StyledFile = styled.div<{ isSelected?: boolean }>`
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  font-weight: normal;
  --tree-color: ${(p) => (p.isSelected ? "#35317D" : "#1b1c2d")};
  background: var(--tree-color);
  transition: 0.2s background;
  cursor: pointer;

  &:hover {
    --tree-color: ${(p) => (p.isSelected ? "#35317D" : "#232435")};
  }

  svg {
    flex-shrink: 0;
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    height: 32px;
    color: ${(p) => (p.isSelected ? "#FFFFFF" : "#A1A1BD")};
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  input {
    flex: 1;
    width: 100%;
    height: 32px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    outline: none;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #13b8ff;
    background: transparent;
  }
`;

export const Collapse = styled.div<{ isOpen: boolean }>`
  height: max-content;
  max-height: ${(p) => (p.isOpen ? "800px" : "0px")};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

export const VerticalLine = styled.section`
  position: relative;
  :before {
    content: "";
    display: block;
    position: absolute;
    top: -2px; /* just to hide 1px peek */
    left: 1px;
    width: 0;
    height: 100%;
    border: 1px solid #dbdbdd;
    z-index: -1;
  }
`;
