import DslElementType from "../../enums/DslElementType";
import * as interfaces from "../../interfaces";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import * as antlr4 from "antlr4/index";
import * as monacoHelpers from "../../../../monaco-helpers";

export abstract class DslElement implements interfaces.IDslElement {
  elementType: DslElementType;
  location?: monaco.IRange;

  public constructor(obj: interfaces.IDslElement, location: undefined);
  public constructor(obj: interfaces.IDslElement, range: monaco.IRange);
  public constructor(obj: interfaces.IDslElement, ranges: monaco.IRange[]);
  public constructor(obj: interfaces.IDslElement, ctx: antlr4.ParserRuleContext);
  public constructor(obj: interfaces.IDslElement, token: antlr4.Token);
  public constructor(obj: interfaces.IDslElement, locationProvider: monacoHelpers.LocationProvider) {
    this.location = obj.location;
    if (locationProvider !== undefined) {
      try {
        this.location = monacoHelpers.createRange(locationProvider);
      } catch (e) {
        throw new Error(`Could not create location: ${e.message}`);
      }
    }
  }

  abstract toJson(): interfaces.IDslElement;
  abstract toDsl(indent: number): string;
}
