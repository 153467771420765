import { LayerModel } from '@projectstorm/react-canvas-core';
import EditorController from '..';

class GridModel extends LayerModel {
  public gap = 120;
  public column = 56;
  public visible = true;

  constructor(public engine: EditorController) {
    super({
      transformed: true,
      isSvg: true,
      type: 'grid',
    });
  }

  allowRepaint() {
    this.repaintEnabled = true;
  }

  serialize() {
    const { width, height } = this.engine.getCanvas()?.getBoundingClientRect() || {};

    return {
      ...super.serialize(),
      width,
      height,
      gap: this.gap,
      column: this.column,
      visible: this.visible,
      offsetX: this.getParent().getOffsetX(),
      offsetY: this.getParent().getOffsetY(),
      zoom: this.getParent().getZoomLevel() / 100,
    };
  }

  getChildModelFactoryBank(): any {
    return null;
  }
}

export default GridModel;
