interface Shortcut {
  key: string;
  useShift?: boolean;
  action: () => void;
}

class KeyboardManager {
  private _lastAction = 0;

  public shortcuts: Shortcut[] = [];
  public delay = 200;

  constructor() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  dispose() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    const actionKey = navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey;
    const action = this.shortcuts.find((v) => v.key === e.key);
    const diff = Date.now() - this._lastAction > this.delay;
    const addition = action?.useShift ? e.shiftKey : true;

    if (actionKey && addition && action) {
      if (diff) {
        this._lastAction = Date.now();
        action.action();
      }

      e.preventDefault();
      return false;
    }
  };

  addShiftShortcut(key: string, action: () => void) {
    this.shortcuts.push({ key, useShift: true, action });
  }

  addShortcut(key: string, action: () => void) {
    this.shortcuts.push({ key, action });
  }

  clearShortcuts() {
    this.shortcuts = [];
  }
}

export default new KeyboardManager();
