import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { Tooltip } from "../uikit/popup";
import { observer } from "mobx-react-lite";
import { Icon, Message, Popup, Table } from "semantic-ui-react";
import "./styles.css";
import { useStore } from "../core/api/GlobalStoreContext";

export const CustomerQueues: FC<{}> = observer(() => {
  const { diagnostics, groups } = useStore();

  const fetchData = useCallback(() => {
    groups.TryReload();
    diagnostics.Reload();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {diagnostics.Queues && (
        <>
          <h2>Group Queues</h2>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Group Name</Table.HeaderCell>
                <Table.HeaderCell>Queue Length</Table.HeaderCell>
                <Table.HeaderCell>Limit Used</Table.HeaderCell>
                <Table.HeaderCell>Limit Max</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(diagnostics.Queues?.groupLengths).map(([k, v]) => {
                return (
                  <Table.Row>
                    <Table.Cell> {groups.GetById(k)?.[0] ?? k} </Table.Cell>
                    <Table.Cell> {v} </Table.Cell>
                    <Table.Cell> {diagnostics.Limits?.groups?.[k]?.current ?? 0} </Table.Cell>
                    <Table.Cell> {diagnostics.Limits?.groups?.[k]?.limit ?? 0} </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      )}
      {diagnostics.Instances && (
        <>
          <h2>Instances</h2>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Tooltip text="Assigned id of the connected SDK instance">
                    <p>Instance Id</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="Name of the application from dashaapp file">
                    <p>Name</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="SDK version that is being used">
                    <p>SDK</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="Name of group">
                    <p>Group</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="Active - SDK is up and running, Inactive - starting, Unregistering - closing">
                    <p>State</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="Count of conversations currently running">
                    <p>Active</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="Count of conversations waiting for a response from the SDK">
                    <p>Pending</p>
                  </Tooltip>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Tooltip text="Max number of concurrent conversations, that can be processed by this instance">
                    <p>Max</p>
                  </Tooltip>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {diagnostics.Instances.map((x) => {
                return (
                  <Table.Row>
                    <Table.Cell> {x.instanceId} </Table.Cell>
                    <Table.Cell> {x.applicationName} </Table.Cell>
                    <Table.Cell> {x.sdkVersion} </Table.Cell>
                    <Table.Cell> {x.groupId} </Table.Cell>
                    <Table.Cell> {x.state} </Table.Cell>
                    <Table.Cell> {x.activeConversations} </Table.Cell>
                    <Table.Cell> {diagnostics.Queues?.instances[x.instanceId ?? ""]?.pendingLength} </Table.Cell>
                    <Table.Cell> {x.maxConcurrency} </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
});
