export default [
  0, 180, 90, 270, 45, 225, 135, 315, 22, 202, 112, 292, 67, 247, 157, 337, 11, 191, 101, 281, 56, 236, 146, 326, 33,
  213, 123, 303, 78, 258, 168, 348, 5, 185, 95, 275, 50, 230, 140, 320, 28, 208, 118, 298, 73, 253, 163, 343, 16, 196,
  106, 286, 61, 241, 151, 331, 39, 219, 129, 309, 84, 264, 174, 354, 2, 182, 92, 272, 47, 227, 137, 317, 25, 205, 115,
  295, 70, 250, 160, 340, 14, 194, 104, 284, 59, 239, 149, 329, 36, 216, 126, 306, 81, 261, 171, 351, 8, 188, 98, 278,
  53, 233, 143, 323, 30, 210, 120, 300, 75, 255, 165, 345, 19, 199, 109, 289, 64, 244, 154, 334, 42, 222, 132, 312, 87,
  267, 177, 357, 1, 181, 91, 271, 46, 226, 136, 316, 23, 203, 113, 293, 68, 248, 158, 338, 12, 192, 102, 282, 57, 237,
  147, 327, 35, 215, 125, 305, 80, 260, 170, 350, 7, 187, 97, 277, 52, 232, 142, 322, 29, 209, 119, 299, 74, 254, 164,
  344, 18, 198, 108, 288, 63, 243, 153, 333, 40, 220, 130, 310, 85, 265, 175, 355, 4, 184, 94, 274, 49, 229, 139, 319,
  26, 206, 116, 296, 71, 251, 161, 341, 15, 195, 105, 285, 60, 240, 150, 330, 37, 217, 127, 307, 82, 262, 172, 352, 9,
  189, 99, 279, 54, 234, 144, 324, 32, 212, 122, 302, 77, 257, 167, 347, 21, 201, 111, 291, 66, 246, 156, 336, 43, 223,
  133, 313, 88, 268, 178, 314, 360, 179, 89, 269, 44, 224, 134, 358, 24, 204, 114, 294, 69, 249, 159, 339, 13, 190, 100,
  280, 55, 235, 145, 325, 34, 214, 124, 304, 79, 259, 169, 349, 6, 186, 96, 276, 51, 231, 141, 321, 27, 207, 117, 297,
  72, 252, 162, 342, 17, 197, 107, 287, 62, 242, 152, 332, 41, 221, 131, 356, 86, 266, 176, 311, 3, 183, 93, 273, 48,
  228, 138, 318, 31, 211, 121, 301, 76, 256, 166, 346, 20, 193, 103, 283, 58, 245, 148, 328, 38, 200, 128, 308, 83, 290,
  173, 353, 10, 155, 110, 263, 65, 335, 238, 359,
];
