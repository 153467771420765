import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";

import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import { MarkupType, Trigger } from "@core/workspace/session-storage/devlogs";

export interface Value {
  value: string;
  synonyms: Trigger[];
  isEditable: boolean;
}

interface Phrase {
  id: string;
  value: string;
}

class EntityEditor {
  public name = "";
  public values: Value[] = [];
  public phrases: Phrase[] = [];
  public isOpenSet = false;
  public readonly maxNameLength = 60;

  constructor(mainValue: string, readonly dataset: DatasetStorage) {
    makeAutoObservable(this);

    this.values.push({ value: mainValue, synonyms: [], isEditable: false });
  }

  get isValidName() {
    return !this.dataset.entities.includes(this.name);
  }

  get isValid() {
    return this.isValidName && this.name.length > 0 && this.name.length <= 60;
  }

  public setName(name: string) {
    this.name = name.slice(0, 60).replaceAll(" ", "_");
  }

  public toggleIsOpenSet() {
    this.isOpenSet = !this.isOpenSet;
  }

  public addValue(value: string) {
    this.values.push({ value, synonyms: [], isEditable: true });
  }

  public addSynonym(index: number, synonym: string) {
    this.values[index].synonyms.push({
      id: uuid(),
      type: "intent",
      mark: MarkupType.include,
      name: synonym,
      location: { start: 0, end: 0 },
      probability: -1,
    });
  }

  public renameValue(index: number, value: string) {
    this.values[index].value = value;
  }

  public removeValue(index: number) {
    this.values.splice(index, 1);
  }

  public removeSynonym(index: number, id: number | string) {
    const synonymIndex = this.values[index].synonyms.findIndex((s) => s.id === id);

    if (synonymIndex === -1) return;

    this.values[index].synonyms.splice(synonymIndex, 1);
  }

  public addPhrase(phrase: string) {
    this.phrases.push({ id: uuid(), value: phrase });
  }

  public renamePhrase(id: string, phrase: string) {
    const index = this.phrases.findIndex((p) => p.id === id);

    if (index !== -1) return;

    this.phrases[index].value = phrase;
  }

  public removePhrase(id: string) {
    const index = this.phrases.findIndex((p) => p.id === id);

    if (index === -1) return;

    this.phrases.splice(index, 1);
  }

  public serialize() {
    return {
      name: this.name,
      open_set: this.isOpenSet,
      values: this.values.map((value) => ({
        value: value.value,
        synonyms: value.synonyms.map((synonym) => synonym.name),
      })),
      includes: this.phrases.map((phrase) => phrase.value),
    };
  }
}

export default EntityEditor;
