import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement } from "../common";
import { RootBlockContentDescription } from "./RootBlockContentDescription";

export class FileContent extends DslElement implements interfaces.IFileContent {
  elementType: DslElementType.FileContent = DslElementType.FileContent;
  isLibrary: boolean;
  content: RootBlockContentDescription;

  constructor(obj: interfaces.IFileContent, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.isLibrary = obj.isLibrary;
    this.content = helpers.ensureIsInstance(obj.content, RootBlockContentDescription);
  }
  toJson(): interfaces.IFileContent {
    return {
      elementType: this.elementType,
      location: this.location,
      isLibrary: this.isLibrary,
      content: this.content.toJson(),
    };
  }
  toDsl(indent: number): string {
    return [helpers.indentString(indent) + this.isLibrary ? "library" : "", this.content.toDsl(indent)].join("\n");
  }
}
