import * as React from "react";
import { Explorer } from "../core/explorer/Explorer";
import Project from "../core/explorer/Project";

import { ActionButton, Icon, StrokeButton } from "../uikit";
import { ModalTitle } from "../uikit/Modal/styled";
import Modal from "../uikit/Modal";
import * as S from "./styled";

interface Props {
  isOpen: boolean;
  explorer: Explorer;
  project: Project;
  onClose: () => void;
}

const RemoveWidget: React.FC<Props> = ({ isOpen, explorer, project, onClose }) => {
  const [isLoading, setLoading] = React.useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      await explorer.remove(project.id);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal isDisabled={isLoading} isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Delete app</ModalTitle>
      <S.Text>Are you sure you to delete application “{project.metadata.name}”?</S.Text>

      {project.metadata.sharedAccess && (
        <S.Text style={{ marginTop: 8, fontWeight: 500 }}>
          <span style={{ color: "#E3CA71" }}>NOTE:</span> this will deactivate your public link.
        </S.Text>
      )}

      <div style={{ display: "flex", marginTop: 16 }}>
        <StrokeButton disabled={isLoading} style={{ flex: 1 }} onClick={onClose}>
          Close
        </StrokeButton>
        <ActionButton disabled={isLoading} style={{ flex: 1, marginLeft: 16 }} onClick={submit}>
          {isLoading && <Icon name="spinner" style={{ marginRight: 8, width: 18 }} />}
          delete
        </ActionButton>
      </div>
    </Modal>
  );
};

export default RemoveWidget;
