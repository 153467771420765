import { observer } from "mobx-react-lite";
import React, { FC, Suspense, useEffect, useState, useCallback, Fragment } from "react";

import HeaderPanel from "../HeaderPanel";
import { MenuOption } from "../uikit/popup";

import { projectSort, projectTypes, projectWorkflow } from "./types";
import { getIntelemediaPods } from "./IntelemediaPanel/create";
import * as S from "./styled";
import { InspectorPanel } from "../InspectorPanel";
import DiagnosticsPanel from "../DiagnosticsPanel";
import { InboundVoIP } from "../DiagnosticsPanel/InboundVoIP";
import { OutboundVoIP } from "../DiagnosticsPanel/OutboundVoIP";
import { Route, Routes, useNavigate, useLocation, Navigate, useSearchParams, useParams } from "react-router-dom";
import { ProjectsView } from "./Project/ProjectsView";
import { ApiKey } from "../DiagnosticsPanel/ApiKey";
import { Tts } from "../DiagnosticsPanel/Tts";
import { UsagePanel } from "../DiagnosticsPanel/Usage";
import { EventsPanel } from "../DiagnosticsPanel/Events";
import { OnlineProfiler } from "../DiagnosticsPanel/OnlineProfiler";
import LoginAs from "../DiagnosticsPanel/LoginAs";
import { FailedConversations } from "../DiagnosticsPanel/FailedConversations";
import { MissedCalls } from "../DiagnosticsPanel/MissedCalls";
import { GlobalStoreContext, useStore } from "../core/api/GlobalStoreContext";
import { useAccountsStore } from "../core/account/AccountsStoreContext";
import { GlobalStore } from "../core/api/GlobalStore";
import { CustomerAccount } from "../core/account/CustomerAccount";
import { PromptsView } from "../PromptsView";
import { PromptEditor } from "../PromptsView/promptEditor";
import { Icon } from "../uikit";
import GridLayout from "../core/misc/GridLayout";
import { useContext } from "react";
import { WelcomeScreen } from '../RunnerPanel/WelcomeScreen';
const IntelemediaPanel = React.lazy(() => import("./IntelemediaPanel"));

function getCustomerIdFromUrl(url: string): string | undefined {
  //Magic, don't know how to pass customer id in the url and don't break anything
  const splitted = url.split("/");
  //check guid length
  if (splitted.length < 2 || splitted[splitted.length - 1].length != 36) {
    return undefined;
  }

  switch (splitted[splitted.length - 2]) {
    case "inspector":
    case "prompts":
    case "test":
      return undefined;
  }

  return splitted[splitted.length - 1];
}

const ExplorerView: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const { projectId } = useParams();
  const oldProject = searchParams.get("project");
  const oldVersion = searchParams.get("version");
  const [customerIdOverride, setCustomerIdOverride] = useState<string | undefined>(undefined);
  const [customerStore, setCustomerStore] = useState<GlobalStore | undefined>();
  const location = useLocation();

  if (oldProject != null) {
    if (projectId === null || projectId === undefined) {
      if (oldVersion !== null) {
        return <Navigate to={`/project/${oldProject}/version/${oldVersion}`} />;
      } else {
        return <Navigate to={`/project/${oldProject}`} />;
      }
    }
  }

  const { account, workspace } = useStore();
  const { accountManager } = useAccountsStore();
  const [intelemediaPods, setIntelemediaPods] = useState<string[]>([]);
  useEffect(() => {
    if (account === null || account === undefined) {
      setIntelemediaPods([]);
      return;
    }

    void getIntelemediaPods(account).then(setIntelemediaPods);
  }, [account]);

  const imPanel = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <IntelemediaPanel account={account} pods={intelemediaPods} />
      </Suspense>
    );
  };

  const renderView = () => {
    return (
      <Routes>
        {accountManager.isAuth && (
          <>
            <Route path="/welcome" element={<WelcomeScreen onStartBuilding={onStartBuilding} />} />
            <Route path="intelemedia" key="route-intelemedia" Component={imPanel} />
            <Route path="inspector/:id" key="route-inspector/:id" element={<InspectorPanel />} />
            <Route path="inspector" key="route-inspector" element={<InspectorPanel />} />
            <Route path="apikey" key="route-apikey" element={<ApiKey />} />
            <Route path="diagnostics" key="route-diagnostics" element={<DiagnosticsPanel />} />
            <Route path="diagnostics/:customerId" key="route-diagnostics/:customerId" element={<DiagnosticsPanel />} />
            <Route path="failed" key="route-failed" element={<FailedConversations />} />
            <Route path="failed/:customerId" key="route-failed/:customerId" element={<FailedConversations />} />

            <Route path="voip/missed" key="route-voip/missed" element={<MissedCalls />} />
            <Route path="voip/missed/:customerId" key="route-voip/missed/:customerId" element={<MissedCalls />} />
            <Route path="voip/inbound" key="route-voip/inbound" element={<InboundVoIP />} />
            <Route path="voip/inbound/:customerId" key="route-voip/inbound/:customerId" element={<InboundVoIP />} />
            <Route path="voip/outbound" key="route-voip/outbound" element={<OutboundVoIP />} />
            <Route path="voip/outbound/:customerId" key="route-voip/outbound/:customerId" element={<OutboundVoIP />} />
            <Route path="tts" key="route-tts" element={<Tts />} />
            <Route path="usage" key="route-usage" element={<UsagePanel />} />
            <Route path="usage/:customerId" key="route-usage/:customerId" element={<UsagePanel />} />
            <Route path="events" key="route-events" element={<EventsPanel />} />
            <Route path="events/:customerId" key="route-events/:customerId" element={<EventsPanel />} />
            <Route path="loginas" key="route-loginas" element={<LoginAs />} />
            <Route path="conversations" key="route-conversations" element={<OnlineProfiler />} />
            <Route
              path="conversations/:customerId"
              key="route-conversations/:customerId"
              element={<OnlineProfiler />}
            />
            <Route path="prompts" key="route-prompts" element={<PromptsView />} />
            <Route path="prompts/:promptId" key="route-prompts/:promptId" element={<PromptEditor />} />
            <Route
              path="prompts/:promptId/test/:testId"
              key="route-prompts/:promptId/test/:testId"
              element={<PromptEditor />}
            />
          </>
        )}
        {Object.keys(projectTypes).map((id) => (
          <>
            <Route path={`apps/${id}`} element={<ProjectsView filter={id} />} />
          </>
        ))}
        <Route index element={<ProjectsView filter="all" />} />
      </Routes>
    );
  };
  const path = location.pathname;

  const showASide = () => {
    return !path.startsWith("/prompts/");
  };

  useEffect(() => {
    const newCustomerId = getCustomerIdFromUrl(location.pathname);
    if (newCustomerId !== customerIdOverride) {
      setCustomerIdOverride(newCustomerId ?? undefined);
      customerStore?.dispose();
      if (newCustomerId === null || newCustomerId === undefined) {
        setCustomerStore(undefined);
      } else {
        setCustomerStore(new GlobalStore(new CustomerAccount(account, newCustomerId)));
      }
    }
  }, [location, customerIdOverride, customerStore]);

  const hasCompletedOnboarding = account.getValue<boolean>('hasCompletedOnboarding');
  const isFirstLogin = account.getValue<boolean>('isFirstLogin');

  // Check for first login and redirect to welcome
  useEffect(() => {
    if (isFirstLogin !== true) {
      account.setValue("isFirstLogin", true);
      navigate("/welcome");
    }
  }, [isFirstLogin, navigate, account]);

  // Define the onStartBuilding function - only sets hasCompletedOnboarding and navigates
  const onStartBuilding = () => {
    account.setValue("hasCompletedOnboarding", true);
    navigate('/apps/dashaapps');
  };

  return (
    <S.Layout
      hasASide={layout.isExpanded("files")}
      powerbi={!location.pathname.startsWith("/apps") && location.pathname !== "/"}
    >
      <HeaderPanel id="header" useLogo />

      {showASide() && (
        <S.Aside id="aside" isOpen={layout.isExpanded("files")}>
          <S.ToggleButton onClick={() => layout.toggleCollapse("files")} isOpen={layout.isExpanded("files")}>
            <Icon name="sidebar" />
          </S.ToggleButton>

          <S.Header></S.Header>
          <S.Option 
            isSelected={path === "/welcome"} 
            onClick={() => {
              navigate("/welcome");
            }}
          >
            Welcome
          </S.Option>

          <S.Header>All apps</S.Header>
          {Object.entries(projectTypes).map(([id, obj]) => (
            <S.Option key={id} isSelected={"/apps/" + id === path} onClick={() => navigate("/apps/" + id)}>
              {obj.icon} {obj.label}
            </S.Option>
          ))}
          {intelemediaPods.length > 0 && (
            <>
              <S.Header>Сustom settings</S.Header>
              <S.Option isSelected={path === "/intelemedia"} onClick={() => navigate("/intelemedia")} key="im">
                Intelemedia
              </S.Option>
            </>
          )}
          <>
            <S.Option isSelected={path === "/apikey"} onClick={() => navigate("/apikey")} key="apikey">
              Api Key
            </S.Option>
            <S.Option isSelected={path === "/inspector"} onClick={() => navigate("/inspector")} key="inspector">
              Inspector
            </S.Option>
            <S.Option isSelected={path === "/diagnostics"} onClick={() => navigate("/diagnostics")} key="diagnostics">
              Diagnostics
            </S.Option>
            <S.Option
              isSelected={path === "/voip/inbound"}
              onClick={() => navigate("/voip/inbound")}
              key="voip_inbound"
            >
              Inbound VoIP
            </S.Option>
            <S.Option
              isSelected={path === "/voip/outbound"}
              onClick={() => navigate("/voip/outbound")}
              key="voip_outbound"
            >
              Outbound VoIP
            </S.Option>
            <S.Option
              isSelected={path === "/conversations"}
              onClick={() => navigate("/conversations")}
              key="conversations"
            >
              Conversations
            </S.Option>
            <S.Option isSelected={path === "/usage"} onClick={() => navigate("/usage")} key="usage">
              Usage
            </S.Option>
            <S.Option isSelected={path === "/events"} onClick={() => navigate("/events")} key="events">
              Events
            </S.Option>
            <S.Option isSelected={path === "/tts"} onClick={() => navigate("/tts")} key="tts">
              Text to Speech
            </S.Option>
            <S.Option isSelected={path === "/prompts"} onClick={() => navigate("/prompts")} key="prompts">
              Prompts
            </S.Option>
          </>
        </S.Aside>
      )}
      
      {customerStore ? (
        <GlobalStoreContext.Provider value={customerStore}>
          {renderView()}
        </GlobalStoreContext.Provider>
      ) : (
        renderView()
      )}
    </S.Layout>
  );
};

export default observer(ExplorerView);
