import React, { FC, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router-dom';

import Profiler from "../core/profiler/ProfilerStorage";
import GridLayout from "../core/misc/GridLayout";
import Keyboard from "../core/misc/KeyboardManager";
import Gutter from "../uikit/Layout/Gutter";
import { GroupPanel } from "../uikit";

import StatisticsPanel from "../ProfilerPanel/components/StatisticsPanel";
import DialogPanel from "../RunnerPanel/DialogPanel";
import EditorPanel from "../EditorPanel";
import HeaderPanel from "../HeaderPanel";
import RunnerPanel from "../RunnerPanel";
import TerminalPanel from "../TerminalPanel";
import VersionsPanel from "../VersionsPanel";
import FilesPanel from "../FilesPanel";
import * as S from "./styled";
import { useStore } from "../core/api/GlobalStoreContext";
import UIManager from "../core/misc/UIManager";
import BasicModeView from "../BasicMode/BasicModeView";

const WorkspaceView: FC<{ projectId: string, projectVersion: string | undefined }> = (props) => {
  const { projectId, projectVersion } = props;
  const { workspace, explorer, sip } = useStore();
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const [tabs, setTabs] = useState<string[]>([]);
  const [isBasicMode, setIsBasicMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const beforeModeSwitchRef = useRef<() => Promise<boolean>>(() => Promise.resolve(true));
  const onAfterModeSwitchRef = useRef<() => void>(() => {});
  const layoutRef = useRef(layout);
  const isInitialMount = useRef(true);
  const [isResizing, setIsResizing] = useState(false);
  const navigate = useNavigate();
  const [canSwitchMode, setCanSwitchMode] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    console.log('Opening project:', projectId, 'version:', projectVersion);
    explorer.openAsync(projectId, projectVersion).then(() => {
      workspace.setProject(explorer.opened);
      console.log('Project opened successfully:', explorer.opened?.id);
      history.pushState({}, "switch", `/project/${explorer.opened.id}/version/${explorer.opened.version}`);
    }).catch((e) => {
      console.error('Failed to open project:', e);
      UIManager.notice(`Failed to open project: ${e}`);
    })
    .finally(() => setIsLoading(false));
  }, [projectId, projectVersion, explorer, workspace]);
  
  const project = workspace.project;

  useEffect(() => {
    const tabs = ["RANGE STATISTICS", "OUTPUT", "VERSIONS"];
    setTabs(workspace.opened instanceof Profiler ? tabs : tabs.slice(1));
  }, [workspace.opened]);

  useEffect(() => {
    Keyboard.addShiftShortcut("f", () => layout.toggleFullScreen());
    Keyboard.addShiftShortcut("u", () => layout.toggleCollapse("runner"));
    Keyboard.addShiftShortcut("p", () => layout.toggleCollapse("files"));
    Keyboard.addShiftShortcut("o", () => layout.toggleCollapse("tools"));
    Keyboard.addShortcut("s", () => workspace.project?.updateRemote());
    
    if (isInitialMount.current) {
      layoutRef.current.bindGrid("#grid-workspace");
      isInitialMount.current = false;
    }
    
    return () => Keyboard.clearShortcuts();
  }, []);

  useEffect(() => {
    if (!isInitialMount.current && workspace?.mode === 'basic') {
      layoutRef.current.toggleCollapse("files");
      layoutRef.current.toggleCollapse("tools");
    }
  }, [workspace?.mode]);

  useEffect(() => {
    if (workspace?.project) {
      const loadModePreference = async () => {
        try {
          let content: string | null = null;

          try {
            content = await workspace.project.file('agentSettings.json');
            if (content) {
              let loadedSettings;
              try {
                loadedSettings = JSON.parse(content);
                const preferredMode = loadedSettings.preferredMode || 'developer';
                setIsBasicMode(preferredMode === 'basic');
                setCanSwitchMode(true);
              } catch (error) {
                console.error('Failed to parse agentSettings.json:', error);
                setIsBasicMode(false);
                setCanSwitchMode(false);
              }
            } else {
              setIsBasicMode(false);
              setCanSwitchMode(true);
            }
          } catch (error) {
            console.warn('DEBUG: Could not read agentSettings.json:', error);
            setIsBasicMode(false);
            setCanSwitchMode(false);
            console.log('agentSettings.json not found, defaulting to developer mode and disabling mode switch');
          }
        } catch (error) {
          console.error('Error loading mode preference:', error);
          setIsBasicMode(false);
          setCanSwitchMode(false);
        }
      };

      loadModePreference();
    }
  }, [workspace?.project]);

  const handleModeSwitch = async () => {
    console.log('Mode switch requested');
    const canSwitch = await beforeModeSwitchRef.current();
    console.log('Can switch mode:', canSwitch);
    
    if (canSwitch) {
      const newMode = !isBasicMode;
      console.log('Switching to mode:', newMode ? 'basic' : 'developer');
      
      if (workspace?.project) {
        try {
          let settings;
          try {
            const settingsFile = await workspace.project.file('agentSettings.json');
            settings = settingsFile ? JSON.parse(settingsFile) : {};
            console.log('Existing settings:', settings);
          } catch (error) {
            console.log('No existing settings found, creating new');
            settings = {};
          }
          
          settings.preferredMode = newMode ? 'basic' : 'developer';
          console.log('Saving new mode preference:', settings.preferredMode);
          await workspace.project.updateContent('agentSettings.json', JSON.stringify(settings, null, 2));
          console.log('Mode preference saved successfully');
        } catch (error) {
          console.error('Failed to save mode preference:', error);
        }
      }

      if (!newMode) {
        layoutRef.current.toggleCollapse("files");
        layoutRef.current.toggleCollapse("tools");
        workspace.project?.updateFiles();
      }
      
      setIsBasicMode(newMode);
      console.log('Mode state updated to:', newMode ? 'basic' : 'developer');

      requestAnimationFrame(() => {
        layoutRef.current.bindGrid("#grid-workspace");
        onAfterModeSwitchRef.current();
        console.log('Layout updated for new mode');
      });
    }
  };

  const renderRunner = () => {
    if (workspace.opened instanceof Profiler) {
      return (
        <DialogPanel
          id="runner"
          workspace={workspace}
          session={workspace.opened.session}
          isOpen={isBasicMode ? true : layout.isExpanded("runner")}
          layout={layout}
          allowTransitionsDebug={true}
          canHide={!isBasicMode}
        />
      );
    }

    return (
      <RunnerPanel
        id="runner"
        workspace={workspace}
        audioController={sip}
        isOpen={isBasicMode ? true : layout.isExpanded("runner")}
        onChangeOpen={() => layout.toggleCollapse("runner")}
      />
    );
  };

  const renderMainContent = () => {
    if (isLoading) {
      return (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100%',
          color: '#ffffff' 
        }}>
          Loading project...
        </div>
      );
    }

    return isBasicMode ? (
      <BasicModeView 
        key={workspace?.project?.id}
        id="editor"
        workspace={workspace}
        explorer={explorer}
        navigate={navigate}
        onBeforeModeSwitch={beforeModeSwitchRef}
        onAfterModeSwitch={onAfterModeSwitchRef}
        isResizing={isResizing}
      />
    ) : (
      <EditorPanel id="editor" layout={layout} />
    );
  };

  const renderGutters = () => (
    <>
      <Gutter
        id="gutter1"
        disabled={isBasicMode || layout.isDisabled("files")}
        onMove={(dir, init) => layout.resize("files", dir, init)}
      />
      <Gutter
        id="gutter2"
        disabled={layout.isDisabled("runner")}
        onMove={(dir, init) => layout.resize("runner", -dir, init)}
      />
      <Gutter
        vertical
        id="gutter3"
        disabled={layout.isDisabled("tools")}
        onMove={(dir, init) => {
          if (init) {
            // Prevent scrolling during resize
            document.body.style.userSelect = 'none';
            document.body.style.overflow = 'hidden';
            setIsResizing(true);
          } else {
            document.body.style.userSelect = '';
            document.body.style.overflow = '';
            setIsResizing(false);
          }
          layout.resize("tools", dir, init);
        }}
      />
    </>
  );

  return (
    <S.Workspace 
      id="grid-workspace" 
      className={isBasicMode ? 'basic-mode' : 'developer-mode'}
      $isResizing={isResizing}
    >
      <HeaderPanel 
        id="header" 
        onSwitchMode={handleModeSwitch}
        isBasicMode={isBasicMode}
        canSwitchMode={canSwitchMode}
      />
      
      {renderMainContent()}
      {renderRunner()}
      <FilesPanel 
        id="files" 
        workspace={workspace} 
        isOpen={!isBasicMode && layout.isExpanded("files")} 
      />
      <GroupPanel id="tools" tabs={tabs}>
        {workspace.opened instanceof Profiler && <StatisticsPanel profiler={workspace.opened} />}
        <TerminalPanel workspace={workspace} />
        {project?.metadata.isEditable && <VersionsPanel workspace={workspace} />}
      </GroupPanel>
      {renderGutters()}
    </S.Workspace>
  );
};

export default observer(WorkspaceView);
