import { PropertyNode, ValueNode } from "json-to-ast";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

export type BindDecorators = Record<string, Record<string, string[]>>;

export const findLiteralInRange = (part: any, start: number, end: number, path: string[] = []): any[] => {
  if (part.type === "Property") {
    return findLiteralInRange(part.value, start, end, path.concat([part.key.value]));
  }

  if (part.type === "Object") {
    return part.children.flatMap((child, i) => {
      return findLiteralInRange(child, start, end, path);
    });
  }

  if (part.type === "Array") {
    return part.children.flatMap((child, index) => {
      if (child.type !== "Literal") {
        return findLiteralInRange(child, start, end, path.concat([index]));
      }

      if (child.loc.start.line < start || child.loc.end.line > end) return [];
      return [{ value: child.value, index, path }];
    });
  }

  return [];
};

export const findPropertyInRange = (
  part: ValueNode | PropertyNode,
  start: number,
  end: number,
  path: string[] = []
): string => {
  if (part.type === "Property") {
    return findPropertyInRange(part.value, start, end, path.concat([part.key.value]));
  }

  if (part.type === "Object") {
    const nodes = part.children.flatMap((child) => findPropertyInRange(child, start, end, path));
    return nodes.find((v) => v !== "")?.[0] ?? "";
  }

  if (part.type === "Array") {
    const nodes = part.children.flatMap((child, index) => {
      if (child.type !== "Literal") {
        return findPropertyInRange(child, start, end, path.concat([index.toString()]));
      }

      if (child.loc == null) return "";
      if (child.loc.start.line < start || child.loc.end.line > end) return [];
      return path;
    });

    return nodes.find((v) => v !== "")?.[0] ?? "";
  }

  return "";
};

export const findPropertyAST = (
  exact: string,
  part: ValueNode | PropertyNode,
  path: string[] = []
): ValueNode | null => {
  if (part.type === "Property") {
    const newPath = path.concat([part.key.value]);
    if (newPath.join(".") === exact) return part.value;
    return findPropertyAST(exact, part.value, newPath);
  }

  if (part.type === "Object") {
    const list = part.children.map((child) => {
      return findPropertyAST(exact, child, path);
    });
    return list.find((v) => v) ?? null;
  }

  if (part.type === "Array") {
    const list = part.children.map((child, i) => {
      return findPropertyAST(exact, child, path.concat([i.toString()]));
    });
    return list.find((v) => v) ?? null;
  }

  return null;
};

// Find generated phrases for generate monaco icons
export const genDecorators = (map: BindDecorators, ast: ValueNode) => {
  const list = Array.from(Object.entries(map));

  return list.flatMap(([path, set]) => {
    const array = findPropertyAST(path, ast);
    if (array?.type !== "Object" && array?.type !== "Array") return [];
    const decorators = Array.from(Object.entries(set));

    return decorators.flatMap(([phrase, names]) => {
      const literalIndex = array.children.findIndex((child) => {
        return child.type === "Literal" && child.value === phrase;
      });

      if (literalIndex == null) return [];
      const literal = array.children[literalIndex];
      if (literal == null) return [];

      const nextLoc = array.children[literalIndex + 1]?.loc?.start;
      const prevLoc = array.children[literalIndex - 1]?.loc?.end;
      const start = literal.loc?.start;
      const end = literal.loc?.end;

      if (!start || !end) return [];

      const startLine = prevLoc ? prevLoc.line : start.line;
      const startColumn = prevLoc ? prevLoc.column : start.column;
      const endLine = nextLoc ? nextLoc.line : end.line;
      const endColumn = nextLoc ? nextLoc.column : end.column;

      const location = `loc_${startLine}_${startColumn}_${endLine}_${endColumn}`;
      const borderMarker = prevLoc == null || nextLoc == null ? "border" : "";

      return names.map((className) => ({
        range: new monaco.Range(end.line, end.column + 1, end.line, end.column + 1),
        options: { afterContentClassName: [className, location, borderMarker].join(" ") },
      }));
    });
  });
};
