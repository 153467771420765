import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { DslElement, RawElement } from "../common";
import { Expression } from "./Expression";

export class ObjectProperty extends DslElement implements interfaces.IObjectProperty {
  elementType: DslElementType.ObjectProperty = DslElementType.ObjectProperty;
  name: RawElement<string>;
  expression: Expression;

  constructor(obj: interfaces.IObjectProperty, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.expression = helpers.ensureIsExpressionInstance(obj.expression);
  }
  toJson(): interfaces.IObjectProperty {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      expression: this.expression.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `${this.name.toDsl(indent)}: ${this.expression.toDsl(indent)}`;
  }
}
