import React, { FC, useRef, useState } from "react";
import ReactFlow, { ReactFlowProvider, Controls, Background } from "react-flow-renderer";
import { observer } from "mobx-react-lite";

import * as S from "../styled";
import DSLEditor from "../../core/legacy-graph-storage/DSLEditor";

import { ContextItem, ContextMenu } from "./ContextMenu";
import NodeSetContext from "./NodeSetContext";
import NodeCondition from "./NodeCondition";
import NodePhraseTrigger from "./NodePhraseTrigger";
import NodeCodeSnippet from "./NodeCodeSnippet";
import NodeSayText from "./NodeSayText";
import NodeStart from "./NodeStart";
import NodeFinish from "./NodeFinish";
import NodeTimeoutTrigger from "./NodeTimeoutTrigger";
import NodeInterruptible from "./NodeInterruptible";

interface Props {
  storage: DSLEditor;
  isRunning: boolean;
}

const nodeTypes = {
  startNode: NodeStart,
  sayText: NodeSayText,
  reaction: NodePhraseTrigger,
  external: NodeCodeSnippet,
  setContext: NodeSetContext,
  condition: NodeCondition,
  finishDialog: NodeFinish,
  reactionTimeout: NodeTimeoutTrigger,
  interruptible: NodeInterruptible,
};

const VisualEditor: FC<Props> = ({ isRunning, storage }) => {
  const flowRef = useRef<HTMLDivElement>(null);
  const [flowInstance, setFlowInstance] = useState<any>(null);
  const [contextPosition, setContextPosition] = useState<any>(null);

  const onElementsRemove = (rm) => storage.removeElements(rm);
  const onConnect = (params) => storage.connectNode(params);
  const onLoad = (instance) => setFlowInstance(instance);

  function handleContextMenu(event) {
    event.preventDefault();
    setContextPosition({ x: event.clientX, y: event.clientY });
  }

  const handleCreate = (type: string) => (event) => {
    if (!flowRef.current) return;

    const bounds = flowRef.current.getBoundingClientRect();
    const position = flowInstance?.project({
      x: event.clientX - bounds.left,
      y: event.clientY - bounds.top,
    });

    storage.createNode(type, position.x - 50, position.y - 20);
  };

  return (
    <S.VisualContainer ref={flowRef}>
      <ReactFlowProvider>
        <ReactFlow
          elements={storage.graphElements}
          connectionLineStyle={{ strokeWidth: 3 }}
          nodeTypes={nodeTypes}
          snapToGrid={true}
          snapGrid={[15, 15]}
          minZoom={0.1}
          nodesDraggable={!isRunning && !storage.isReadonly}
          nodesConnectable={!isRunning}
          elementsSelectable={!isRunning}
          onLoad={onLoad}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          onContextMenu={handleContextMenu}
          onNodeDragStop={(_, node) => storage.moveNodes([node])}
          onSelectionDragStop={(_, nodes) => storage.moveNodes(nodes)}
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </ReactFlowProvider>

      <ContextMenu position={contextPosition}>
        <ContextItem onClick={handleCreate("sayText")}>#sayText</ContextItem>
        <ContextItem onClick={handleCreate("reaction")}>#phraseTrigger</ContextItem>
        <ContextItem onClick={handleCreate("reactionTimeout")}>#timeoutTrigger</ContextItem>
        <ContextItem onClick={handleCreate("interruptible")}>#interruptible</ContextItem>
        <ContextItem onClick={handleCreate("external")}>#externalFunction</ContextItem>
        <ContextItem onClick={handleCreate("setContext")}>#setContext</ContextItem>
        <ContextItem onClick={handleCreate("finishDialog")}>#finishDialog</ContextItem>
      </ContextMenu>
    </S.VisualContainer>
  );
};

export default observer(VisualEditor);
