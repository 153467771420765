import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { computed, makeObservable, observable, runInAction } from "mobx";
import debounce from "lodash/debounce";

import { WorkerApi } from "@core/dsl/worker/api";
import { IDisposable } from "../misc/emitter";
import Project from "../explorer/Project";

export interface Marker {
  line: number;
  column: number;
  message: string;
}

export interface FileStorage {
  path: string;
  name: string;
  isInvalid?: boolean;
  isDraft?: boolean;
  isReadonly?: boolean;
  isPinned?: boolean;
  isFullscreen?: boolean;

  dispose(): void;
  highlightErrors?(markers: Marker[]): void;
}

class TextStorage implements FileStorage {
  protected disposables: IDisposable[] = [];
  public readonly model: monaco.editor.ITextModel;

  public isInvalid = false;
  public isReadonly = false;
  public isDraft = false;
  public isPinned = false;
  public isInitialized = false;

  private scroll: [number, number] = [0, 0];

  constructor(readonly project: Project, public path: string, uri = path, lang?: string) {
    this.model = monaco.editor.createModel("", lang, monaco.Uri.file(uri));

    this.isReadonly = project.metadata.isEditable === false;
    this.disposables.push(this.model);
    this.disposables.push(
      project.onDidChange(({ file, content, initiator }) => {
        if (file !== path || initiator === this.model.id) return;
        this.model.setValue(content);
      })
    );

    this.disposables.push(
      project.onDidRename(({ old, rename }) =>
        runInAction(() => {
          if (old === this.path) {
            this.path = rename;
            void this.syncValue();
            void WorkerApi.Instance.tryRenameModel(old, rename);
          }
        })
      )
    );

    this.disposables.push(
      this.model.onDidChangeContent(async () => {
        if (this.isInitialized) this.isPinned = true;
        this.isInitialized = true;

        monaco.editor.setModelMarkers(this.model, "error", []);
        await this.updateProject();
      })
    );

    this.disposables.push(
      monaco.editor.onDidChangeMarkers(() =>
        runInAction(() => {
          const markers = monaco.editor.getModelMarkers({ resource: this.model.uri });
          this.isInvalid = markers.length > 0;
        })
      )
    );

    void this.syncValue();
    makeObservable(this, {
      path: observable,
      isInvalid: observable,
      isDraft: observable,
      isReadonly: observable,
      name: computed,
    });
  }

  async syncValue() {
    const content = await this.project.file(this.path);
    this.model.setValue(content);
  }

  updateProject = debounce(async () => {
    await this.project.updateContent(this.path, this.model.getValue(), this.model.id);
  }, 1000);

  get name() {
    return this.path.split("/").pop() ?? this.path;
  }

  get directory() {
    return this.path.split("/").slice(0, -1).join("/");
  }

  openInEditor(editor: monaco.editor.ICodeEditor) {
    editor.setScrollPosition({ scrollLeft: this.scroll[0], scrollTop: this.scroll[1] });
  }

  closeInEditor(editor: monaco.editor.ICodeEditor) {
    this.scroll = [editor.getScrollLeft(), editor.getScrollTop()];
  }

  highlightErrors(errors: Marker[]) {
    const modelMarkers = errors.map<monaco.editor.IMarkerData>(({ line, column, message }) => ({
      message,
      startLineNumber: line,
      endLineNumber: line,
      startColumn: column,
      endColumn: 1000,
      severity: monaco.MarkerSeverity.Error,
    }));
    monaco.editor.setModelMarkers(this.model, "error", modelMarkers);
  }

  dispose() {
    this.updateProject.cancel();
    this.disposables.forEach((d) => d.dispose());
    this.disposables = [];
  }
}

export default TextStorage;
