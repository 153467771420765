import styled, { css } from "styled-components";
import React from 'react'

const input = css`
  font-family: var(--decorate-font);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  border: none;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;

  &,
  &::placeholder {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    color: #D9D9E2;
  }

  &::placeholder {
    color: #7b7b85be;
  }

  &:focus {
    outline: none;
  }
`

const SLabel = styled.span`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.3);
`

const SInput = styled.input`
  ${input};

  &,
  &::placeholder {
    font-weight: 600;
    font-size: 17px;
    color: #ECEFF8;
  }
`;

const STextarea = styled.textarea`
  ${input};
  height: 100px;
  resize: none;
`;

export const Input = ({ label, innerStyle, ...props }: any) => {
  return <label style={innerStyle}>
    <SLabel>{label}</SLabel>
    <SInput {...props} />
  </label>
}

export const Textarea = ({ label, ...props }: any) => {
  return <label>
    <SLabel>{label}</SLabel>
    <STextarea {...props} />
  </label>
}
