enum DslElementType {
  FileContent = "FileContent",
  ContextVariable = "ContextVariable",
  Variable = "Variable",
  BlockContentDescription = "BlockContentDescription",
  RootBlockContentDescription = "RootBlockContentDescription",
  ExternalFunctionDeclaration = "ExternalFunctionDeclaration",
  ArgumentDeclaration = "ArgumentDeclaration",
  Node = "Node",
  GroupCommandStatement = "GroupCommandFragment",
  ExitStatement = "ExitFragment",
  Block = "Block",
  Digression = "Digression",
  TypeAlias = "TypeAlias",
  DigressionVariable = "DigressionVariables",
  OnExitSection = "OnExitSection",
  OnExitNamedDescription = "OnExitDescription",
  TransitionSection = "TransitionSection",
  TransitionDefinition = "TransitionDefinition",
  TransitionTimeoutCondition = "TransitionTimeoutCondition",
  CastExpression = "AsExpression",
  NullCollateExpression = "NullCollateExpression",
  DigressionVariableReferenceExpression = "DigressionVariableReferenceExpression",
  TransitionExpressionCondition = "TransitionExpressionCondition",
  Unconditional = "Unconditional",
  ArrayAccessExpression = "ArrayAccessExpression",
  BinaryExpression = "BinaryExpression",
  BuiltinVariableExpression = "BuiltinVariableExpression",
  ConstantExpression = "ConstantExpression",
  ContextVariableExpression = "ContextVariableExpression",
  DigressionSwitchApplyExpression = "DigressionSwitchApplyExpression",
  DigressionSwitchExpression = "DigressionSwitchExpression",
  FunctionCallExpression = "FunctionCallExpression",
  IsExpression = "IsExpression",
  LocalVariableExpression = "LocalVariableExpression",
  ObjectAccessExpression = "ObjectAccessExpression",
  ObjectExpression = "ObjectExpression",
  ObjectProperty = "ObjectPropertyName",
  ParenthesizedExpression = "ParenthesizedExpression",
  TernaryExpression = "TernaryExpression",
  UnaryExpression = "UnaryExpression",
  RawFragmentStatement = "RawFragment",
  RawElement = "RawElement",
  HighOrderFunctionCallExpression = "HighOrderFunctionCallExpression",
  NamedArgument = "NamedArgument",
  PositionalArgument = "PositionalArgument",
  FunctionArguments = "Arguments",
  ExpressionStatement = "ExpressionFragment",
  IfStatement = "IfFragment",
  GotoStatement = "GotoFragment",
  WaitStatement = "WaitFragment",
  VarStatement = "VarFragment",
  // SetFragment = "SetFragment",
  ReturnStatement = "ReturnFragment",
  ForStatement = "ForFragment",
  AssignmentStatement = "AssignmentFragment"
}

export default DslElementType;
