import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #161729;
  color: #fff;
  font-family: var(--main-font);
  box-sizing: border-box;
  overflow-y: auto;
`;

export const Status = styled.span`
  display: flex;
  align-items: center;

  ::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--indicator);
    margin-right: 4px;
  }
`;

export const Version = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #d9d9e2;

  box-sizing: border-box;
  padding: 8px 24px;

  border-top: 0.5px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  transition: 0.5s border, 0.5s background;

  ${(p) => p.isSelected && "background: rgba(255, 255, 255, 0.1) !important"};

  p {
    margin: 0;
    font-size: 12px;
    border: 1px solid #cccccc40;
    line-height: 22px;
    border-radius: 8px;
    padding: 0 8px;
  }

  input {
    background: none;
    outline: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #d9d9e2;

    border-radius: 8px;
    border: 1px solid #ffffff4d;
    padding: 0 8px;
  }

  button {
    opacity: 0;
    transition: 0.2s opacity;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      height: 26px;
      font-size: 11px;
    }
  }
`;
