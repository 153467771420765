import * as dasha from "@dasha.ai/sdk/web";
import Account from "./Account";

export class CustomerAccount extends Account {
    private readonly customerId: string | undefined;
    constructor(account: Account, customerId: string | undefined) {
        super(account.name, account.token, account.server, account.insecure);
        this.customerId = customerId;
    }

    override connect(): dasha.Account {
        return super.connectAs(this.customerId);
    }
};