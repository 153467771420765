import * as elements from "../index";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";

export class FunctionCallExpression extends Expression implements interfaces.IFunctionCallExpression {
  elementType: DslElementType.FunctionCallExpression = DslElementType.FunctionCallExpression;
  name: RawElement<string>;
  arguments: elements.FunctionArguments;
  parentExpression: Expression;
  optionalAccess: boolean;

  constructor(obj: interfaces.IFunctionCallExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.arguments = helpers.ensureIsInstance(obj.arguments, elements.FunctionArguments);
    this.parentExpression = helpers.ensureIsExpressionInstance(obj.parentExpression);
    this.optionalAccess = obj.optionalAccess;
  }
  toJson(): interfaces.IFunctionCallExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      arguments: this.arguments.toJson(),
      parentExpression: this.parentExpression.toJson(),
      optionalAccess: this.optionalAccess,
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        this.parentExpression.toDsl(indent),
        this.optionalAccess ? "?" : null,
        ".",
        this.name.toDsl(indent),
        `(${this.arguments.toDsl(indent)})`,
      ],
      ""
    );
  }
}
