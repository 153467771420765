import React, { useRef, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import EditorController from '..';
import TooltipModel from './Model';
import { Arrow, Tooltip, Row, Value, Property } from './styled';

interface Props {
  engine: EditorController;
  model: TooltipModel;
}

const TooltipWidget = ({ model, engine }: Props) => {
  const ref = useRef<HTMLDivElement>();
  const [size, setSize] = useState({ width: 0, height: 0 });

  const pad = 25;
  const { width, height } = size;
  const { clientWidth = 0, clientHeight = 0 } = engine.getCanvas() ?? {};

  const isLeft = model.x + width > clientWidth;
  const y = Math.min(Math.max(height / 2, model.y), clientHeight - height / 2) - height / 2;
  const x = isLeft ? model.x - width - pad : model.x + pad;
  const arrowX = isLeft ? model.x - 35 : model.x + 15;

  useEffect(() => {
    const ob = new ResizeObserver(() => {
      const { width = 0, height = 0 } = ref.current?.getBoundingClientRect() || {};
      setSize({ width, height });
    });

    if (ref.current) {
      ob.observe(ref.current);
    }

    return () => ob.disconnect();
  }, [ref]);

  if (Object.entries(model.data).length === 0) {
    return null;
  }

  return (
    <div className="graph-editor-tooltip" style={{ display: model.isShow ? 'block' : 'none' }}>
      <Tooltip style={{ left: x, top: y }} ref={ref as any}>
        {Object.entries(model.data).map(
          ([key, value]) =>
            value != null && (
              <Row key={key}>
                <Property>{key}</Property>
                <Value>{value}</Value>
              </Row>
            )
        )}
      </Tooltip>
      <Arrow dir={isLeft ? 'left' : 'right'} style={{ left: arrowX, top: model.y }} />
    </div>
  );
};

export default TooltipWidget;
