import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Popup, Icon } from "semantic-ui-react";
import "./styles.css";
import { InboundVoIPEditor } from "./InboundVoIPEditor";
import { VoIPCallReceiver } from "./VoIPCallReceiver";
import { Link, useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";
import { Tooltip } from "../uikit/popup";
import { InboundVoipEntry } from "../core/api/VoIP/InboundVoipEntry";

export const InboundVoIP: FC<{}> = observer(() => {
  const { account, inboundVoIP } = useStore();
  const createNewEntry = () => {
    return new InboundVoipEntry({
      id: "",
      applicationName: "",
      priority: 0,
      uri: ""
    }, account, "new configuration", false);
  }
  const [newEntry, setNewEntry] = useState(createNewEntry());

  const onAdd = useCallback(() => {
    inboundVoIP.Add(newEntry);
    setNewEntry(createNewEntry());
  }, [inboundVoIP])

  useEffect(() => {
    inboundVoIP.TryReload();
  }, [inboundVoIP]);

  const fetchStatuses = useCallback(() => {
    inboundVoIP.RefreshStatuses();
  }, [inboundVoIP]);

  useEffect(() => {
    fetchStatuses();
    const interval = setInterval(() => {
      fetchStatuses();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [inboundVoIP]);

  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Inbound VoIP</h2>
      <p>
        <Link to={"/voip/missed"}>
          <h3>Missed calls</h3>
        </Link>
      </p>
      <VoIPCallReceiver/>
      <Loader active={inboundVoIP.Loading}>Loading..</Loader>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Edit</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>
              <Tooltip text="A link that can be used in monitoring system">
                <p>Status</p>
              </Tooltip>
            </Table.HeaderCell>
            <Table.HeaderCell>Application Name</Table.HeaderCell>
            <Table.HeaderCell>Group Name</Table.HeaderCell>
            <Table.HeaderCell>Priority</Table.HeaderCell>
            <Table.HeaderCell>Uri</Table.HeaderCell>
            <Table.HeaderCell>Alias</Table.HeaderCell>
            <Table.HeaderCell>Alias Uri</Table.HeaderCell>
            <Table.HeaderCell>IP ACLs</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {inboundVoIP.Configs.map((c) => {
            return (
              <Table.Row negative={c.Status?.isError === true}>
                <Table.Cell>
                  {" "}
                  <InboundVoIPEditor entry={c} onDelete={()=>inboundVoIP.Delete(c.Name)} key={c.Name} />
                  {" "}
                </Table.Cell>
                <Table.Cell> {c.Name} </Table.Cell>
                <Table.Cell>
                  {" "}
                  <Link
                    to={"https://" + account.server + "/api/v1/sip/endpoint/status/" + c.OriginalConfig.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>{c.Status.status}</p>
                  </Link>{" "}
                </Table.Cell>
                <Table.Cell> {c.CurrentConfig.applicationName} </Table.Cell>
                <Table.Cell> {c.CurrentConfig.groupName} </Table.Cell>
                <Table.Cell> {c.CurrentConfig.priority} </Table.Cell>
                <Table.Cell> {c.CurrentConfig.uri} </Table.Cell>
                <Table.Cell> {c.CurrentConfig.alias} </Table.Cell>
                <Table.Cell> {c.CurrentConfig.aliasUri} </Table.Cell>
                <Table.Cell> {c.CurrentConfig.ipAcls?.join(",")} </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        {
          <Table.Footer>
            <Table.Row>
              <InboundVoIPEditor
              entry={newEntry}
              onDelete={() => inboundVoIP.Delete(newEntry.Name)}
              onAdd={onAdd}
              />
            </Table.Row>
          </Table.Footer>
        }
      </Table>
    </div>
  );
});
