import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { makeAutoObservable, runInAction } from "mobx";
import { InboundVoipEntry } from "./InboundVoipEntry";

export class InboundVoIPStore {
  private readonly account: IAccount;
  public Loading: boolean;
  public Configs: InboundVoipEntry[];
  public Error: string | undefined;
  constructor(account: IAccount) {
    this.account = account;
    this.Configs = [];
    makeAutoObservable(this);
  }

  public RefreshStatuses() {
    for (var e of this.Configs) {
      e.RefreshStatus();
    }
  }
  
  public TryReload() {
    if (this.Configs.length === 0 && !this.Loading) {
      this.Reload();
    }
  }

  public Delete(name: string) {
    this.Error = undefined;
    dasha.sip.inboundConfigs.deleteConfig(name).then(() => {
      this.Configs = this.Configs.filter(x=>x.Name !== name);
    }).catch((e) => {
      this.Error = e.message;
    });
  }

  public Add(entry: InboundVoipEntry) {
    this.Configs.push(entry);
    return entry;
  }

  private Reload() {
    this.Loading = true;
    const a = this.account.connect();
    dasha.sip.inboundConfigs.listConfigs({account: a})
      .then((configs) => {
        runInAction(() => {
          for (var [k, v] of Object.entries(configs)) {
            this.Configs.push(new InboundVoipEntry(v, this.account, k, true));
          }
        });
      })
      .finally(() => {
        runInAction(() => {
          this.Loading = false;
          this.RefreshStatuses();
        });
      });
  }
}
