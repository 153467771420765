import React, { FC, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from "../core/api/GlobalStoreContext";
import { ActionButton, Icon } from '../uikit';
import { FSStructure } from "../core/account/filesystem";

const PageContainer = styled.div<{ $isResizing?: boolean }>`
  padding: 2rem;
  height: 100%;
  overflow-y: ${props => props.$isResizing ? 'hidden' : 'auto'};
  position: relative;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background: #1e1e2d;
  border-radius: 12px;
  padding: 1.5rem;
  position: relative;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  position: relative;
  padding: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    background: #2a2a3c;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 2rem;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;

const GenerateButton = styled(ActionButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  background: #6366f1;
  border-radius: 6px;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: not-allowed;
  white-space: nowrap;
  flex-shrink: 0;
  
  &:hover {
    background: #6366f1;
  }
`;

const WipBadge = styled.span`
  font-size: 10px;
  padding: 2px 6px;
  background: #4b4ba1;
  border-radius: 4px;
  margin-left: 8px;
  text-transform: uppercase;
`;

const PromptInput = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  background: #2a2a3c;
  border: 1px solid #3f3f5f;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  margin-top: 1rem;
  
  &::placeholder {
    color: #6f6f8f;
  }
  
  &:focus {
    outline: none;
    border-color: #4b4ba1;
  }
`;

const ModelDropdown = styled.div`
  position: relative;
  margin-top: 1.5rem;
  width: 100%;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  background: #2a2a3c;
  border: 1px solid #3f3f5f;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  
  &:hover {
    border-color: #4b4ba1;
  }
`;

const DropdownList = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  background: #2a2a3c;
  border: 1px solid #3f3f5f;
  border-radius: 8px;
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: #ffffff;
  
  &:hover {
    background: #3f3f5f;
  }
`;

const Badge = styled.span`
  font-size: 12px;
  padding: 2px 8px;
  background: #6366f1;
  border-radius: 12px;
  margin-left: 8px;
`;

const Section = styled.div`
  & + & {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #2a2a3c;
  }
`;

const CollapseButton = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${props => props.isExpanded ? '180deg' : '0deg'});
  transition: transform 0.2s ease;
  margin-left: 8px;
`;

const PromptSection = styled.div<{ isExpanded: boolean }>`
  margin-top: 1rem;
  display: ${props => props.isExpanded ? 'block' : 'none'};
`;

const Slider = styled.input.attrs({ type: 'range' })`
  width: 100%;
  height: 4px;
  background: #3f3f5f;
  border-radius: 2px;
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #6366f1;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const SliderValue = styled.span`
  color: #6f6f8f;
  font-size: 12px;
  margin-left: 8px;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
  
  &:checked + span {
    background-color: #6366f1;
  }
  
  &:checked + span:before {
    transform: translateX(24px);
  }
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3f3f5f;
  transition: .4s;
  border-radius: 24px;
  
  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background: #2a2a3c;
  border: 1px solid #3f3f5f;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #4b4ba1;
  }
`;

// Define styles for the custom voice ID input field
const CustomVoiceInput = styled(Input)`
  margin-top: 0.5rem;
  width: 100%;
  background-color: #252536;  // Different background color
  border: 1px solid #6366f1;   // Highlighted border color
  color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 8px;

  &:focus {
    outline: none;
    border-color: #818cf8;    // Slightly lighter on focus
  }
`;

const HelperText = styled.p`
  margin-top: 0.25rem;
  font-size: 12px;
  color: #a1a1aa;
`;

// IconWrapper for the dropdown icon
const IconWrapper = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const SaveButtonWrapper = styled.div`
  position: sticky;
  bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  &::before {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: -1.5rem;
    right: -1.5rem;
    height: 8rem;
    background: linear-gradient(to top, #1e1e2d 20%, rgba(30, 30, 45, 0));
    pointer-events: none;
  }
`;

const FloatingSaveButton = styled(ActionButton)<{ $visible: boolean }>`
  position: relative;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(99, 102, 241, 0.4);
  transform: scale(${props => props.$visible ? '1' : '0.8'});
  opacity: ${props => props.$visible ? '1' : '0'};
  transition: all 0.2s ease;
  pointer-events: ${props => props.$visible ? 'auto' : 'none'};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #6366f1;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
  
  &:hover {
    transform: ${props => props.$visible ? 'scale(1.05)' : 'scale(0.8)'};
    background: #4f46e5;
    box-shadow: 0 4px 20px rgba(99, 102, 241, 0.6);
  }

  &:active {
    transform: ${props => props.$visible ? 'scale(0.95)' : 'scale(0.8)'};
  }
`;

const SaveTooltip = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 0.5rem;
  background: #2a2a3c;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transform: translateY(4px);
  transition: all 0.2s ease;
  pointer-events: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  ${FloatingSaveButton}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ResetButton = styled.button`
  position: relative;
  padding: 0.75rem 1rem;
  background: transparent;
  border: 1px solid #3f3f5f;
  color: #6f6f8f;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  font-family: var(--decorate-font);
  font-weight: bold;
  transition: all 0.2s ease;
  
  &:hover:not(:disabled) {
    background: rgba(99, 102, 241, 0.1);
    border-color: #6366f1;
    color: #6366f1;
  }
  
  &:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ResetTooltip = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 0.5rem;
  background: #2a2a3c;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transform: translateY(4px);
  transition: all 0.2s ease;
  pointer-events: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  ${ResetButton}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface BasicModeViewProps {
  workspace: any;
  explorer: any;
  navigate: (path: string) => void;
  onBeforeModeSwitch: React.MutableRefObject<() => Promise<boolean>>;
  onAfterModeSwitch: React.MutableRefObject<() => void>;
  id?: string;
  isResizing?: boolean;
}

interface ModelInfo {
  id: string;
  name: string;
  badge?: 'fastest' | 'smartest' | 'best-value';
}

interface AgentSettings {
  prompt: string;
  model: string;
  voice: {
    voiceId: string;
    voiceModel: 'mu2' | 't25';
    similarityBoost: number;
    stability: number;
    style: number;
    useSpeakerBoost: boolean;
    optimizeStreamingLatency: number;
  };
  preferredMode: "basic" | "developer";
}

const DEFAULT_SETTINGS: AgentSettings = {
  prompt: "You are a helpful AI assistant.",
  model: "openai/gpt-4o-mini",
  voice: {
    voiceId: "fA2TI1m22NPQVhxhw9DE",
    voiceModel: 'mu2',
    similarityBoost: 0.75,
    stability: 0.5,
    style: 0.3,
    useSpeakerBoost: true,
    optimizeStreamingLatency: 4
  },
  preferredMode: "basic"
};

const hasMissingProperties = (loaded: any, defaults: any): boolean => {
  // If loaded is missing entirely, that's definitely a new setting
  if (!loaded) return true;

  // Check all properties in the default settings
  for (const key in defaults) {
    // If the key doesn't exist in loaded settings, it's a new setting
    if (!(key in loaded)) return true;

    // If both values are objects, recursively check their properties
    if (typeof defaults[key] === 'object' && defaults[key] !== null) {
      if (typeof loaded[key] !== 'object' || loaded[key] === null) return true;
      if (hasMissingProperties(loaded[key], defaults[key])) return true;
    }
  }

  return false;
};

const predefinedVoices = [
  {
    id: "fA2TI1m22NPQVhxhw9DE",
    name: "Default Voice (US Female)"
  }
];

// Combine predefined voices with 'Custom Voice ID' option
const allVoiceOptions = [
  ...predefinedVoices,
  { id: 'custom', name: 'Custom Voice ID' }
];

// Function to check if a voice ID is custom
const isVoiceIdCustom = (voiceId: string) => {
  return !predefinedVoices.some(voice => voice.id === voiceId);
};

interface PhraseMap {
  default: {
    voiceInfo: {
      speaker: string;
      options?: {
        similarity_boost: number;
        stability: number;
        style: number;
        use_speaker_boost: boolean;
      };
    };
  };
}

// Only convert the voice-specific settings we want to modify
const convertVoiceSettingsToPhrasemapFormat = (voiceSettings: AgentSettings['voice']) => {
  // Construct speaker string with optimization parameter if needed
  const speakerString = voiceSettings.optimizeStreamingLatency > 0
    ? `${voiceSettings.voiceModel}/${voiceSettings.voiceId}?optimize_streaming_latency=${voiceSettings.optimizeStreamingLatency}`
    : `${voiceSettings.voiceModel}/${voiceSettings.voiceId}`;

  return {
    speaker: speakerString,
    options: {
      similarity_boost: voiceSettings.similarityBoost,
      stability: voiceSettings.stability,
      style: voiceSettings.style,
      use_speaker_boost: voiceSettings.useSpeakerBoost
    }
  };
};

// Find phrasemap.json recursively in project files
const findPhrasemapFile = async (workspace: any): Promise<{ content: any, path: string }> => {
  try {
    // Get the root files structure
    const rootFiles = workspace.project.files;
    console.log('DEBUG: Root files:', rootFiles);

    // Find phrasemap.json recursively
    const phrasemapFile = findPhrasemapInStructure(rootFiles);
    
    if (!phrasemapFile) {
      console.error('DEBUG: No phrasemap.json found in project files');
      throw new Error('phrasemap.json not found in project');
    }

    // Read the file content using the relative path
    try {
      const content = await workspace.project.file(phrasemapFile.path);
      if (content) {
        console.log('DEBUG: Found phrasemap at:', phrasemapFile.path);
        return { content, path: phrasemapFile.path };
      }
    } catch (error: any) {
      console.error(`DEBUG: Error reading phrasemap at ${phrasemapFile.path}:`, error);
      throw error;
    }

    throw new Error(`Found phrasemap at ${phrasemapFile.path} but couldn't read it`);
  } catch (error) {
    console.error('DEBUG: Error finding phrasemap:', error);
    throw error;
  }
};

// Helper function to recursively search through file structure
const findPhrasemapInStructure = (files: FSStructure[]): FSStructure | null => {
  for (const item of files) {
    if (item.type === 'file' && item.name.toLowerCase() === 'phrasemap.json') {
      return item;
    }
    
    if (item.type === 'folder' && item.files) {
      const found = findPhrasemapInStructure(item.files);
      if (found) return found;
    }
  }
  
  return null;
};

const findAndUpdatePhrasemap = async (
  workspace: any, 
  voiceSettings: ReturnType<typeof convertVoiceSettingsToPhrasemapFormat>
): Promise<{ content: any, path: string }> => {
  const { content: existingContent, path } = await findPhrasemapFile(workspace);
  
  console.log('DEBUG: Existing phrasemap content:', existingContent);

  const existingPhrasemap = typeof existingContent === 'string' 
    ? JSON.parse(existingContent)
    : existingContent;

  // Only update speaker and options while preserving everything else
  return {
    path,
    content: {
      ...existingPhrasemap,
      default: {
        ...existingPhrasemap.default,
        voiceInfo: {
          ...existingPhrasemap.default.voiceInfo,
          speaker: voiceSettings.speaker,
          options: voiceSettings.options
        }
      }
    }
  };
};

const BasicModeView: FC<BasicModeViewProps> = ({ 
  workspace,
  explorer,
  navigate,
  onBeforeModeSwitch,
  onAfterModeSwitch,
  id,
  isResizing
}) => {
  const [settings, setSettings] = useState<AgentSettings>(DEFAULT_SETTINGS);
  const [isLoading, setIsLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isCreatingFile, setIsCreatingFile] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPromptExpanded, setIsPromptExpanded] = useState(true);
  const [isModelExpanded, setIsModelExpanded] = useState(false);
  const [isVoiceExpanded, setIsVoiceExpanded] = useState(false);
  const isMounted = useRef<boolean>(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isVoiceModelDropdownOpen, setIsVoiceModelDropdownOpen] = useState(false);
  const modelDropdownRef = useRef<HTMLDivElement>(null);
  const voiceModelDropdownRef = useRef<HTMLDivElement>(null);
  const store = useStore();
  const [isVoiceIdDropdownOpen, setIsVoiceIdDropdownOpen] = useState(false);
  const voiceIdDropdownRef = useRef<HTMLDivElement>(null);
  const [isCustomVoiceId, setIsCustomVoiceId] = useState(false);
  const [tempVoiceId, setTempVoiceId] = useState('');
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    if (workspace?.project) {
      loadSettings();
    }

    onAfterModeSwitch.current = () => {
      if (isMounted.current && workspace?.project) {
        loadSettings();
      }
    };

    return () => {
      isMounted.current = false;
      onBeforeModeSwitch.current = () => Promise.resolve(true);
      onAfterModeSwitch.current = () => {};
    };
  }, [workspace?.project]);

  useEffect(() => {
    onBeforeModeSwitch.current = async () => {
      const hasMissingSettings = hasMissingProperties(settings, DEFAULT_SETTINGS);
      
      // Show save prompt if there are changes or missing settings
      if (hasChanges || hasMissingSettings) {
        const shouldSave = window.confirm('You have unsaved changes. Would you like to save them before switching modes?');
        if (shouldSave) {
          try {
            await saveSettings(settings);
            return true;
          } catch (error) {
            console.error('Failed to save settings:', error);
            return false;
          }
        }
      }
      return true;
    };
  }, [hasChanges, settings]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modelDropdownRef.current && !modelDropdownRef.current.contains(event.target as Node)) {
        setIsModelDropdownOpen(false);
      }
      if (voiceModelDropdownRef.current && !voiceModelDropdownRef.current.contains(event.target as Node)) {
        setIsVoiceModelDropdownOpen(false);
      }
      if (voiceIdDropdownRef.current && !voiceIdDropdownRef.current.contains(event.target as Node)) {
        setIsVoiceIdDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Register basic mode state with global store
    store.basicMode = {
      hasUnsavedChanges: hasChanges,
      saveSettings: () => saveSettings(settings)
    };

    return () => {
      store.basicMode = null;
    };
  }, [hasChanges, settings, store]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        if (hasChanges) {
          saveSettings(settings);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [hasChanges, settings]);

  const loadSettings = async () => {
    if (!workspace?.project) {
      if (isMounted.current) setIsLoading(false);
      return;
    }

    if (isMounted.current) setIsLoading(true);

    try {
      let content;
      try {
        content = await workspace.project.file('agentSettings.json');
      } catch (error: any) {
        if (error.code === 'ENOENT' || error.message.includes('ENOENT')) {
          console.warn('DEBUG: agentSettings.json not found.');
          content = null;
        } else {
          console.error('DEBUG: Error reading agentSettings.json:', error);
          throw error;
        }
      }

      if (!isMounted.current) return;

      if (content) {
        let loadedSettings;
        if (typeof content === 'string') {
          try {
            loadedSettings = JSON.parse(content);
          } catch (parseError) {
            console.warn('DEBUG: Parse error:', parseError);
          }
        } else if (typeof content === 'object') {
          loadedSettings = content;
        }

        if (loadedSettings && typeof loadedSettings === 'object') {
          console.log('DEBUG: Loaded settings:', loadedSettings);
          
          // Check if any default settings are missing from loaded settings
          const hasNewSettings = hasMissingProperties(loadedSettings, DEFAULT_SETTINGS);
          
          // Create merged settings object, ensuring voice settings exist
          const mergedSettings = {
            prompt: loadedSettings.prompt || DEFAULT_SETTINGS.prompt,
            model: loadedSettings.model || DEFAULT_SETTINGS.model,
            voice: {
              ...DEFAULT_SETTINGS.voice,  // Start with default voice settings
              ...(loadedSettings.voice || {})  // Merge any existing voice settings
            },
            preferredMode: loadedSettings.preferredMode ?? DEFAULT_SETTINGS.preferredMode
          };

          setSettings(mergedSettings);

          // If we detected missing properties, mark as having changes
          setHasChanges(hasNewSettings);

          // Update isCustomVoiceId based on the loaded voiceId, with null check
          setIsCustomVoiceId(isVoiceIdCustom(mergedSettings.voice.voiceId));
          return;
        }
      } else {
        // If settings file wasn't found, do not create it
        console.log('DEBUG: No valid settings file found, defaulting to empty settings');
        if (isMounted.current) {
          setSettings(DEFAULT_SETTINGS);
          setIsCreatingFile(false);
        }
      }
    } catch (error) {
      console.warn('DEBUG: Load error:', error);
      if (isMounted.current) {
        setSettings(DEFAULT_SETTINGS);
        setIsCreatingFile(false);
      }
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  };

  // Common function to save settings to a project
  const saveSettingsToProject = async (project: any, settingsToSave: AgentSettings) => {
    const content = JSON.stringify(settingsToSave, null, 2);
    await project.updateContent('agentSettings.json', content);
    
    const voiceSettings = convertVoiceSettingsToPhrasemapFormat(settingsToSave.voice);
    const { content: updatedPhrasemap, path: phrasemapPath } = 
      await findAndUpdatePhrasemap(workspace, voiceSettings);
    
    await project.updateContent(
      phrasemapPath,
      JSON.stringify(updatedPhrasemap, null, 2)
    );
  };

  const saveSettings = async (settingsToSave: AgentSettings) => {
    if (!workspace?.project || isCreatingFile) return;
    
    setIsSaving(true);
    try {
      const project = workspace.project;
      
      // Add logging for isEditable status
      console.log('DEBUG: Project editable status:', {
        isEditable: project.metadata.isEditable,
        projectId: project.id,
        projectName: project.metadata.name
      });
      
      // Check if project is read-only
      if (!project.metadata.isEditable) {
        // Helper function to create a copy of a read-only project
        const createProjectCopy = async (project: any, explorer: any) => {
          try {
            const zip = await project.zip();
            const content = await zip.generateAsync({ type: "uint8array" });
            const { projectType } = project.metadata.customMetaData;
            const newName = `${project.metadata.name} (Copy)`;
            const newDescription = `${project.metadata.description || 'No description'} (Modified version)`;
            
            // Create new project with the same content
            return await explorer.createFromZip({ 
              name: newName, 
              description: newDescription, 
              projectType, 
              content 
            });
          } catch (error) {
            console.error('Failed to create project copy:', error);
            throw new Error('Failed to create a copy of the project. Please try again.');
          }
        };

        const newProject = await createProjectCopy(project, explorer);

        // Save settings to the new project
        await saveSettingsToProject(newProject, settingsToSave);
        
        // Navigate to the new project
        navigate(`/project/${newProject.id}`);
        return;
      }
      
      // Save settings to existing project
      await saveSettingsToProject(project, settingsToSave);
      setHasChanges(false);
    } catch (error) {
      console.error('Save error:', error);
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  const handleSettingChange = <K extends keyof AgentSettings>(
    key: K,
    value: AgentSettings[K]
  ) => {
    setSettings(prev => ({ ...prev, [key]: value }));
    setHasChanges(true);
  };

  const models: ModelInfo[] = [
    { id: 'openai/gpt-35-turbo', name: 'GPT-3.5 Turbo', badge: 'fastest' },
    { id: 'openai/gpt-4o-mini', name: 'GPT-4o mini', badge: 'best-value' }
  ];

  const selectedModel = models.find(m => m.id === settings.model);

  // Update isCustomVoiceId whenever settings change
  useEffect(() => {
    setIsCustomVoiceId(isVoiceIdCustom(settings.voice.voiceId));
  }, [settings.voice.voiceId]);

  // Function to handle voice ID changes
  const handleVoiceIdChange = (voiceId: string) => {
    handleSettingChange('voice', {
      ...settings.voice,
      voiceId: voiceId
    });
  };

  if (isLoading) {
    return (
      <PageContainer>
        <Container>
          <div style={{ textAlign: 'center', color: '#ffffff' }}>
            Loading agent settings...
          </div>
        </Container>
      </PageContainer>
    );
  }

  return (
    <PageContainer id={id} $isResizing={isResizing}>
      <Container>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '2rem' 
        }}>
          <Title>Configure Your AI Agent</Title>
          <ResetButton
            onClick={async () => {
              if (isResetting) return;
              
              const confirmMessage = 
                'This will reset all settings to their default (recommended) values.\n\n' +
                'Your system prompt will NOT be affected.\n\n' +
                'Do you want to continue?';
              
              if (window.confirm(confirmMessage)) {
                setIsResetting(true);
                try {
                  setSettings(prev => ({
                    ...DEFAULT_SETTINGS,
                    prompt: prev.prompt
                  }));
                  setHasChanges(true);
                } finally {
                  setIsResetting(false);
                }
              }
            }}
            disabled={isResetting}
          >
            {isResetting ? 'Resetting...' : 'Reset to Defaults'}
            <ResetTooltip>
              Will reset to default (recommended) settings
              <br />
              (prompt will be preserved)
            </ResetTooltip>
          </ResetButton>
        </div>

        <Section>
          <SectionHeader onClick={() => setIsPromptExpanded(!isPromptExpanded)}>
            <SectionTitle>System prompt</SectionTitle>
            <ButtonGroup>
              <GenerateButton 
                disabled={true}
                title="Coming soon!"
                onClick={(e) => e.stopPropagation()}
              >
                <Icon name="generate" />
                Generate
                <WipBadge>Soon</WipBadge>
              </GenerateButton>
              <CollapseButton isExpanded={isPromptExpanded}>
                <Icon name="chevron-down" />
              </CollapseButton>
            </ButtonGroup>
          </SectionHeader>

          <PromptSection isExpanded={isPromptExpanded}>
            <PromptInput
              value={settings.prompt}
              onChange={(e) => handleSettingChange('prompt', e.target.value)}
              placeholder="Enter system prompt for your Dasha agent here..."
            />
          </PromptSection>
        </Section>

        <Section>
          <SectionHeader onClick={() => setIsModelExpanded(!isModelExpanded)}>
            <SectionTitle>Model</SectionTitle>
            <ButtonGroup>
              <CollapseButton isExpanded={isModelExpanded}>
                <Icon name="chevron-down" />
              </CollapseButton>
            </ButtonGroup>
          </SectionHeader>

          {isModelExpanded && (
            <ModelDropdown ref={modelDropdownRef}>
              <DropdownButton onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}>
                <span>{selectedModel?.name || 'Select Model'}</span>
                {selectedModel?.badge && <Badge>{selectedModel.badge}</Badge>}
              </DropdownButton>
              
              <DropdownList isOpen={isModelDropdownOpen}>
                {models.map(model => (
                  <DropdownItem
                    key={model.id}
                    onClick={() => {
                      handleSettingChange('model', model.id);
                      setIsModelDropdownOpen(false);
                    }}
                  >
                    <span>{model.name}</span>
                    {model.badge && <Badge>{model.badge}</Badge>}
                  </DropdownItem>
                ))}
              </DropdownList>
            </ModelDropdown>
          )}
        </Section>

        <Section>
          <SectionHeader onClick={() => setIsVoiceExpanded(!isVoiceExpanded)}>
            <SectionTitle>Voice Settings</SectionTitle>
            <ButtonGroup>
              <CollapseButton isExpanded={isVoiceExpanded}>
                <Icon name="chevron-down" />
              </CollapseButton>
            </ButtonGroup>
          </SectionHeader>

          {isVoiceExpanded && (
            <div style={{ marginTop: '1rem' }}>
              <FormGroup>
                <Label>Voice ID</Label>
                <ModelDropdown ref={voiceIdDropdownRef}>
                  <DropdownButton 
                    onClick={() => {
                      setIsVoiceIdDropdownOpen(!isVoiceIdDropdownOpen);
                    }}
                  >
                    <span>
                      {
                        // Display the voice name or "Custom Voice ID"
                        isCustomVoiceId
                          ? 'Custom Voice ID'
                          : predefinedVoices.find(v => v.id === settings.voice.voiceId)?.name || 'Select Voice'
                      }
                    </span>
                    <IconWrapper>
                      <Icon name="chevron-down" />
                    </IconWrapper>
                  </DropdownButton>

                  <DropdownList isOpen={isVoiceIdDropdownOpen}>
                    {allVoiceOptions.map(voice => (
                      <DropdownItem
                        key={voice.id}
                        onClick={() => {
                          if (voice.id === 'custom') {
                            handleVoiceIdChange('');
                          } else {
                            handleVoiceIdChange(voice.id);
                          }
                          setIsVoiceIdDropdownOpen(false);
                        }}
                      >
                        <div>
                          <div>{voice.name}</div>
                          {voice.id !== 'custom' && (
                            <div style={{ fontSize: '12px', color: '#6f6f8f' }}>
                              {voice.id}
                            </div>
                          )}
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownList>
                </ModelDropdown>
                
                {isCustomVoiceId && (
                  <>
                    <CustomVoiceInput
                      type="text"
                      value={settings.voice.voiceId}
                      onChange={(e) => {
                        handleVoiceIdChange(e.target.value);
                      }}
                      placeholder="Enter your custom voice ID here..."
                    />
                    <HelperText>
                      Enter the custom voice ID provided to you by our support team.
                    </HelperText>
                  </>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Voice Model</Label>
                <div ref={voiceModelDropdownRef} style={{ position: 'relative' }}>
                  <DropdownButton 
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVoiceModelDropdownOpen(!isVoiceModelDropdownOpen);
                    }}
                    style={{ marginTop: '0.5rem' }}
                  >
                    {settings.voice.voiceModel}
                  </DropdownButton>
                  <DropdownList 
                    isOpen={isVoiceModelDropdownOpen}
                    style={{ 
                      position: 'absolute',
                      width: '100%',
                      zIndex: 1000
                    }}
                  >
                    {['mu2', 't25'].map(model => (
                      <DropdownItem
                        key={model}
                        onClick={() => {
                          handleSettingChange('voice', {
                            ...settings.voice,
                            voiceModel: model as 'mu2' | 't25'
                          });
                          setIsVoiceModelDropdownOpen(false);
                        }}
                      >
                        {model}
                      </DropdownItem>
                    ))}
                  </DropdownList>
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Similarity Boost</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Slider
                    value={settings.voice.similarityBoost}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => handleSettingChange('voice', {
                      ...settings.voice,
                      similarityBoost: parseFloat(e.target.value)
                    })}
                  />
                  <SliderValue>{settings.voice.similarityBoost.toFixed(2)}</SliderValue>
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Stability</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Slider
                    value={settings.voice.stability}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => handleSettingChange('voice', {
                      ...settings.voice,
                      stability: parseFloat(e.target.value)
                    })}
                  />
                  <SliderValue>{settings.voice.stability.toFixed(2)}</SliderValue>
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Style</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Slider
                    value={settings.voice.style}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => handleSettingChange('voice', {
                      ...settings.voice,
                      style: parseFloat(e.target.value)
                    })}
                  />
                  <SliderValue>{settings.voice.style.toFixed(2)}</SliderValue>
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Use Speaker Boost</Label>
                <Switch>
                  <SwitchInput
                    checked={settings.voice.useSpeakerBoost}
                    onChange={(e) => handleSettingChange('voice', {
                      ...settings.voice,
                      useSpeakerBoost: e.target.checked
                    })}
                  />
                  <SwitchSlider />
                </Switch>
              </FormGroup>

              <FormGroup>
                <Label>Optimize Streaming Latency</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Slider
                    value={settings.voice.optimizeStreamingLatency}
                    min={0}
                    max={5}
                    step={1}
                    onChange={(e) => handleSettingChange('voice', {
                      ...settings.voice,
                      optimizeStreamingLatency: parseInt(e.target.value)
                    })}
                  />
                  <SliderValue>{settings.voice.optimizeStreamingLatency}</SliderValue>
                </div>
              </FormGroup>
            </div>
          )}
        </Section>

        <SaveButtonWrapper>
          <FloatingSaveButton
            $visible={hasChanges}
            onClick={() => saveSettings(settings)}
            disabled={!hasChanges || isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
            <SaveTooltip>
              {navigator.platform.toLowerCase().includes('mac') ? '⌘' : 'Ctrl'} + S
            </SaveTooltip>
          </FloatingSaveButton>
        </SaveButtonWrapper>
      </Container>
    </PageContainer>
  );
};

export default observer(BasicModeView); 