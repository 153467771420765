import { DefaultLinkModel } from "@projectstorm/react-diagrams";
import { Point } from "@projectstorm/geometry";
import { findIndex } from "lodash";
import intersects from "intersects";
import { uuid4 } from "@sentry/utils";

import { TransitionInformation } from "@core/profiler/storage/model/stuff";
import TransitionLabel from "../Label/Model";
import NodeModel from "../CircleNode/Model";
import LinkLabelModel from "../Label/Model";
import roundPathCorners from "../roundPath";

export default class DirectionalLinkModel extends DefaultLinkModel {
  static type = "transition-link";

  label = new LinkLabelModel("0");
  transitions: TransitionInformation[] = [];
  isHidden = false;

  styles = {
    areaWidth: 100,
    areaColor: "#008A69",
    areaSelectionOpacity: 0.15,
    areaOpacity: 0.15,

    angle: 0,
    width: 1,
    markerEnd: "link-end",
    markerStart: "link-start",
    color: "#008A69",
    selectionColor: "#008A69",
  };

  constructor(readonly phrase = "") {
    super({
      id: uuid4(),
      type: DirectionalLinkModel.type,
      extras: { isHidden: false },
      curvyness: 24,
    });

    this.addLabel(this.label);
  }

  addTransition(trx: TransitionInformation) {
    this.transitions.push(trx);
    this.label.value = this.decisionCount.toString();
  }

  get decisionCount() {
    return this.transitions.reduce((acc, trx) => acc + trx.decisionCount, 0);
  }

  get uniqueDecisionCount() {
    return this.transitions.reduce((acc, trx) => acc + trx.uniqDecisionCount, 0);
  }

  get transitionType() {
    return this.transitions[0].transitionType;
  }

  get sourceNodePath() {
    return this.transitions[0].sourceNodePath;
  }

  get targetNodePath() {
    return this.transitions[0].targetNodePath;
  }

  get tooltip() {
    return {
      From: this.transitions[0].sourceNodePath,
      To: this.transitions[0].targetNodePath,
      Decision: this.decisionCount,
      "Unique Decision": this.uniqueDecisionCount,
      Phrase: this.phrase,
    };
  }

  setHidden(v: boolean) {
    this.options.extras.isHidden = v;
  }

  setSelected(v: boolean) {
    super.setSelected(v);
    this.setLabelSelected(null);
  }

  getLoopSvgPath() {
    const [a, b, c, d] = this.getOffsetPoints();
    const topRight = b.clone();
    topRight.translate(0, -56);

    const topLeft = c.clone();
    topLeft.translate(0, -56);

    const path = this.getPath([a, b, topRight, topLeft, c, d]);
    return roundPathCorners(path, 10, false);
  }

  getOffsetPoints() {
    const a = this.getFirstPoint().getPosition();
    const b = this.getFirstPoint().getPosition().clone();
    const c = this.getLastPoint().getPosition().clone();
    const d = this.getLastPoint().getPosition();

    if (this.sourcePort) {
      b.translate(...this.calculateControlOffset(this.getSourcePort()));
    }

    if (this.targetPort) {
      c.translate(...this.calculateControlOffset(this.getTargetPort()));
    }

    return [a, b, c, d];
  }

  removeLabels() {
    this.labels.forEach((label) => label.remove());
    this.labels = [];
  }

  getSelectedLabel() {
    return this.getLabels().find((label) => label.isSelected());
  }

  setLabelSelected(id: string | null) {
    const labels = this.getLabels() as TransitionLabel[];
    labels.forEach((label) => label.setSelected(label.getID() === id));
  }

  getTargetNode() {
    return this.getTargetPort()?.getNode() as NodeModel;
  }

  getSourceNode() {
    return this.getSourcePort()?.getNode() as NodeModel;
  }

  getLink(): { id: string; from: string; to: string } {
    const target = this.getTargetNode();
    const source = this.getSourceNode();
    return { id: this.getID(), from: source?.getID(), to: target?.getID() };
  }

  getPath(points: Point[]) {
    return points.reduce((acc, p, i) => (i ? `${acc} L ${p.x} ${p.y}` : `M ${p.x} ${p.y}`), "");
  }

  getSVGPath() {
    if (!this.getTargetPort()) return super.getSVGPath();

    const { from, to } = this.getLink();
    if (from === to) return this.getLoopSvgPath();

    const points = this.getPointsWithOffsets();
    const path = this.getPath(points);
    return roundPathCorners(path, 10, false);
  }

  getPointsWithOffsets() {
    const [a, offsetB, offsetC, d] = this.getOffsetPoints();
    const points = this.getPoints()
      .slice(2, -2)
      .map((p) => p.getPosition());

    const pp = [a, offsetB, ...points, offsetC, d];
    const from = this.getSourceNode();
    const to = this.getTargetNode();

    const collides = pp.flatMap((a, i) => {
      if (i === pp.length - 1) return [a];
      const b = pp[i + 1];

      if (intersects.lineBox(a.x, a.y, b.x, b.y, to.getX(), to.getY(), to.width, to.height)) {
        const ax = to.getPosition().clone();
        ax.translate(to.width + 16, -16);
        offsetC.x = to.getX() - 16;

        const bx = to.getPosition().clone();
        bx.translate(-16, -16);
        return [a, ax, bx];
      }

      return [a];
    });

    return collides.flatMap((a, i) => {
      if (i === collides.length - 1) return [a];
      const b = collides[i + 1];

      if (intersects.lineBox(a.x, a.y, b.x, b.y, from.getX(), from.getY(), from.width, from.height)) {
        const ax = from.getPosition().clone();
        ax.translate(from.width + 16, from.height + 16);
        offsetB.x = from.getX() + from.width + 16;

        const bx = from.getPosition().clone();
        bx.translate(-16, from.height + 16);
        return [a, ax, bx];
      }

      return [a];
    });
  }

  /**
   * The method returns the angle in radians between the center points in the transition path
   * Necessary for the correct rotation of the label in the center of the transition path
   */
  getAngle(): number {
    const { from, to } = this.getLink();
    if (from === to) return 0;

    const line = this.getPointsWithOffsets();
    if (line.length < 2) return 0;

    const getLen = (a: Point, b: Point) => Math.hypot(a.x - b.x, a.y - b.y);

    // get an array of the path lengths
    let lp: Point;
    const length = line.map((p) => {
      const len = lp ? getLen(p, lp) : 0;
      lp = p;
      return len;
    });

    // find the entire length of the path
    const union = length.reduce((acc, v) => acc + v, 0);
    const half = union / 2;

    // find the segment index in the center of the path
    let prev = 0;
    const ind = findIndex(length, (v) => {
      prev += v;
      return prev > half;
    });

    if (ind === -1) return 0;

    // calculate the angle between two points
    const a = line[ind - 1];
    const b = line[ind];
    const vec = { x: b.x - a.x, y: b.y - a.y };

    return Math.atan2(vec.y, vec.x) * (180 / Math.PI);
  }

  serialize() {
    this.styles.angle = this.getAngle();
    this.styles.areaWidth = 4; // 50 * (this.data.decisionCount / this.getSourceNode().transitions);

    return {
      ...super.serialize(),
      ...this.styles,
    };
  }
}
