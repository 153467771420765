import styled from "styled-components";

export const Message = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  margin: 8px;

  &::before {
    background: ${(p) => (p.isSelected ? "rgba(255, 182, 0, 0.1)" : "none")};
    transition: 0.2s background;
    opacity: ${(p) => (p.isSelected ? 1 : 0)};
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    margin: -4px -32px;
    padding: 4px 32px;
    position: absolute;
  }
`;

export const Controls = styled.div`
  position: absolute;
  display: flex;
  height: 28px;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  right: 0;
  padding: 5px 10px;

  button {
    opacity: 0.8;
    transition: 0.2s opacity;
    margin-left: 8px;
  }

  button:hover {
    opacity: 1;
  }

  &::before {
    content: "";
    width: calc(100% - 12px);
    height: 40px;
    background: #242536;
    filter: blur(4px);
    position: absolute;
  }
`;

export const MessageBubble = styled.div<{ isSelected: boolean }>`
  max-width: 336px;
  background: ${(p) => (p.isSelected ? "rgba(255, 182, 0, 0.1)" : "rgb(36, 37, 54)")};
  border-radius: 8px 8px 0px 8px;
  padding: 8px 12px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0);
  transition: 0.2s border;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  ${Controls} {
    opacity: 0;
    transition: 0.2s opacity;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
    ${Controls} {
      opacity: 1;
    }
  }
`;

export const CurrentInAudioMark = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #a1a1bd;
  margin-right: 4px;
`;

export const Phrase = styled.div`
  margin-bottom: 4px;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;

  span {
    color: hsl(var(--hue), 73%, 70%);
  }
`;

export const MessageTime = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  margin: 0;
  margin-top: 4px;
`;

export const Triggers = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
