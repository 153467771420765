import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import EditorController from '..';
import TooltipModel from './Model';
import TooltipWidget from './Widget';

export default class TooltipFactory extends AbstractReactFactory<
  TooltipModel,
  EditorController
> {
  constructor() {
    super('tooltip');
  }

  generateModel() {
    return new TooltipModel({ x: 0, y: 0, data: {} });
  }

  generateReactWidget(event: any) {
    return <TooltipWidget engine={this.engine} model={event.model} />;
  }
}
