import React from "react";
import Project from "../core/explorer/Project";
import { ReactComponent as RecentsIcon } from "./icons/recents.svg";
import { ReactComponent as MyAppsIcon } from "./icons/myapps.svg";
import { ReactComponent as SharedIcon } from "./icons/shared.svg";
import { ReactComponent as DashaIcon } from "./icons/dasha.svg";

export const projectTypes = {
  all: {
    label: "Recents",
    icon: <RecentsIcon />,
    filter: () => true,
  },
  myapps: {
    icon: <MyAppsIcon />,
    label: "My Apps",
    filter: ({ metadata: m }: Project) => m.isEditable && m.customMetaData.tag !== "dasha",
  },
  shared: {
    icon: <SharedIcon />,
    label: "Shared Apps",
    filter: ({ metadata: m }: Project) => !m.isEditable && m.customMetaData.tag !== "dasha",
  },
  dashaapps: {
    icon: <DashaIcon />,
    label: "Dasha Templates",
    filter: ({ metadata: m }: Project) => m.customMetaData.tag === "dasha",
  },
};

export const projectSort = {
  latest: {
    label: "Last viewed",
    sort: ({ metadata: a }: Project, { metadata: b }: Project) =>
      +new Date(b.customMetaData.viewed ?? 0) > +new Date(a.customMetaData.viewed ?? 0) ? 1 : -1,
  },
  created: {
    label: "Date created",
    sort: (a: Project, b: Project) => (+new Date(b.metadata.createdAt) > +new Date(a.metadata.createdAt) ? -1 : 1),
  },
  alphabetical: {
    label: "Alphabetical",
    sort: (a: Project, b: Project) => (b.metadata.name > a.metadata.name ? -1 : 1),
  },
};

export const projectWorkflow = {
  all: {
    label: "All",
    filter: () => true,
  },
  code: {
    label: "Code",
    filter: ({ metadata: a }: Project) => a.customMetaData.projectType === "code",
  },
  nocode: {
    label: "No-code",
    filter: ({ metadata: a }: Project) => a.customMetaData.projectType === "visual",
  },
  shared: {
    label: "Public",
    filter: ({ metadata: a }: Project) => a.sharedAccess,
  },
  unshared: {
    label: "Private",
    filter: ({ metadata: a }: Project) => !a.sharedAccess,
  },
  editable: {
    label: "Editable",
    filter: ({ metadata: a }: Project) => a.isEditable,
  },
  readonly: {
    label: "Readonly",
    filter: ({ metadata: a }: Project) => !a.isEditable,
  },
};
