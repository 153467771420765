export class Pair<A, B> {
  constructor(readonly a: A, readonly b: B) {}
  isEqual(pair: [unknown, unknown]) {
    return (this.a === pair[0] && this.b === pair[1]) || (this.a === pair[1] && this.b === pair[0]);
  }
}

export class Task {
  promise: Promise<any>;
  resolve: () => void;
  reject: () => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = () => resolve(0)
      this.reject = reject;
    });
  }
}
