import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const getPartValues = (values: (string | number)[], currentValue: string | number) => {
  const currentIndex = values.indexOf(currentValue);

  if (currentIndex === 0) {
    return ["", ...values.slice(currentIndex, currentIndex + 2)];
  }

  if (currentIndex === values.length - 1) {
    return [...values.slice(currentIndex - 1, currentIndex + 1), ""];
  }

  return values.slice(currentIndex - 1, currentIndex + 2);
};

export const formatTime = (time: { hours: number; minutes: number; halfDay: string }) => {
  return dayjs(`${time.hours}:${time.minutes}:00 ${time.halfDay}`, "h:m:ss A").format("HH:mm");
};
