import { makeAutoObservable, runInAction } from "mobx";
import { union, difference } from "lodash";

import { ProjectMetadata, UploadProjectConfig } from "../account/api/share";
import { IAccount } from "../account/interface";
import api from "../account/api";

import { delay, downloadBlob } from "../misc/utils";
import { Emitter } from "../misc/emitter";
import Project from "./Project";
import Profiler from "@core/profiler/ProfilerStorage";
import ProfilerStorage from "@core/profiler/storage";
import { SessionProtocol } from "@core/workspace/session-storage/types";
import SessionsStorage from "@core/workspace/session-storage/SessionsStorage";
import { IProjectsLibrary } from "@core/account/projects/interface";

export class Explorer {
  public projects: Project[] = [];
  public opened: Project | null = null;

  public isInitalized = false;
  public isLoading = false;

  public history: Record<string, number> = {};
  public shared_ids: Record<string, string> = {};
  public dashaIds = ["wnJwC", "jeG9v", "90Axz", "K1NLe"];

  private readonly _onDidProject = new Emitter<Project | null>();
  public readonly onDidProject = this._onDidProject.event;

  constructor(readonly account: IAccount, readonly library: IProjectsLibrary) {
    makeAutoObservable(this);
    this.history = this.account?.getValue<Record<string, number>>("explorer") || {};
    this.shared_ids = this.account?.getValue<Record<string, string>>("explorer_shared") || {};
  }

  private getBaseUrl(): string {
    return `${window.location.protocol}//${window.location.host}`;
  }

  public getProjectLink(id: string, version: string): string {
    return `${this.getBaseUrl()}/project/${id}/version/${version}`;
  }

  async copyProjectLink(id: string, version: string) {
    await navigator.clipboard.writeText(this.getProjectLink(id, version));
  }

  setProject(project: Project | null) {
    this.opened = project;
    this._onDidProject.fire(project);

    if (project) {
      const viewed = Date.now();
      this.history[project.id] = viewed;
      this.shared_ids[project.id] = project.version;
      project._metadata.customMetaData.viewed = viewed;
      this.account?.setValue("explorer", this.history);
      this.account?.setValue("explorer_version", this.shared_ids);
    }
  }

  async openAsync(id: string | null, version: string | null) {
    if (id == null) return this.setProject(null);
    if (this.opened?.id === id) return;

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const opened = await this.load(id);
      await opened?.initialize(version ?? this.shared_ids[id ?? ""]);

      this.setProject(opened ?? null);
      this._onDidProject.fire(this.opened);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async load(id: string): Promise<Project> {
    let project = this.projects.find((p) => p.id === id);
    if (project) return project;

    const meta = await api.share.getProject(id, this.account);
    project = new Project(meta, this.library, this.account.storage);
    this.projects.push(project);
    return project;
  }

  async remove(id: string) {
    const project = this.projects.find((p) => p.id === id);
    if (project == null || project.metadata.isEditable === false) return;
    await this.library.deleteProject(id);
    await project.clear();
    this.projects = this.projects.filter((p) => p.id !== id);
  }

  async loadProjects() {
    if (this.account === undefined) {
      return;
    }
    const projects = await this.library.getProjects();
    const sharedIds = union(this.dashaIds, Object.keys(this.history));

    // Load shared projects, but if some project not load, exclude him from history
    const _localIds = projects.map((p) => p.id);
    const ids = difference(sharedIds, _localIds);
    const sharedProjects = await Promise.all(
      ids.map(async (id) => {
        return await api.share.getProject(id, this.account).catch(() => {
          delete this.history[id];
          return null;
        });
      })
    );

    this.account.setValue("explorer", this.history);
    projects.push(...sharedProjects.filter((x): x is ProjectMetadata => x != null));
    const account = this.account;
    if (account !== undefined) {
      runInAction(() => {
        this.projects = projects.map((meta) => {
          meta.customMetaData.viewed = this.history[meta.id] ?? +new Date(meta.updatedAt);
          if (this.dashaIds.includes(meta.id)) meta.customMetaData.tag = "dasha";
          return new Project(meta, this.library, this.account.storage);
        });

        this.isInitalized = true;
      });
    }
  }

  async download(project: Project) {
    const zip = await project.zip();
    const blob = await zip.generateAsync({ type: "blob" });
    downloadBlob(project.metadata.name, blob);
  }

  async createFromZip(config: UploadProjectConfig): Promise<Project> {
    config.description = config.description || `Created ${new Date().toDateString()}`;
    const meta = await this.library.createProject(config);
    const project = new Project(meta, this.library, this.account.storage);
    await project.rewrite(config.content);
    runInAction(() => {
      this.projects = [project, ...this.projects];
    });

    return project;
  }

  async createFromProfile(profile: Uint8Array): Promise<Project> {
    const storage = await ProfilerStorage.create(profile);
    const serialize = await Profiler.serializeStorage(storage);

    const config = {
      name: `Profile: ${serialize.dump.name}`,
      description: `Created ${new Date().toDateString()}`,
      content: serialize.package,
    };

    const meta = await this.library.createProject(config);
    const project = new Project(meta, this.library, this.account.storage);
    await project.rewrite(serialize.package);

    runInAction(() => {
      this.projects.push(project);
    });

    return project;
  }

  async createFromPackage(content: Uint8Array, name: string, inputs: any, session?: SessionProtocol | undefined): Promise<Project> {
    const config = {
      name: name,
      description: `Created ${new Date().toDateString()}`,
      content: content,
    };

    const meta = await this.library.createProject(config);
    const project = new Project(meta, this.library, this.account.storage);
    await project.rewrite(content);
    if (session !== undefined && session !== null) {
      const storage = new SessionsStorage(project, this.account);
      storage.sessions.push(session);
      storage.saveSessions();
    }
    await project.updateContent("input.json", JSON.stringify(inputs, null, 4));
    await project.updateContent(
      "index.js",
      `
    import * as dasha from "@dasha.ai/sdk";
    const inputs = require("./input.json");
    const main = async () => {
      const app = await dasha.deploy("./");
      try {
        await app.start({ concurrency: 1 });

        const conv = app.createConversation();

        conv.input = inputs;
        conv.input.endpoint = process.sandbox.endpoint;

        conv.on("transcription", async (entry) => {
          console.log("transcription", entry)
        });

        const result = await conv.execute();
        console.log(result.output);
      } finally {
        await app.stop();
        app.dispose();
      }
  }
  main()
  `
    );
    this.projects.push(project);
    return project;
  }

  async createBlankProject(name: string, description: string, projectType: "code" | "visual"): Promise<Project> {
    // @ts-ignore
    const appUrl = new URL(`./resources/blank.zip`, import.meta.url);
    const resp = await fetch(appUrl.toString());
    const content = new Uint8Array(await resp.arrayBuffer());
    return await this.createFromZip({ name, description, content, projectType });
  }
}
