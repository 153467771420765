import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import Workspace from "../core/workspace/Workspace";
import useAutoScroller from "../uikit/hooks/useAutoScroller";
import * as S from "./styled";

const TerminalPanel: FC<{ workspace: Workspace }> = ({ workspace }) => {
  const ref = useAutoScroller<HTMLDivElement>();
  const getHighlight = (level) => ({ color: level === "error" ? "tomato" : "#d9dae5" });

  return (
    <S.Container ref={ref}>
      {workspace.logs.map(({ label, level, message }, i) => (
        <code key={i}>
          {`[${label ?? "sdk"}]`} <span style={getHighlight(level)}>{level}</span> {message}
        </code>
      ))}
    </S.Container>
  );
};

export default observer(TerminalPanel);
