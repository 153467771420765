import { makeAutoObservable } from "mobx";
import { getColumns } from "../../ProfilerPanel/components/ConversationsTable/helpers";
import { MenuOption } from "../../uikit/popup";
import { TableSession } from "./ConversationsTable";

type FilterType = "selectedNodesTo" | "selectedNodesFrom" | "selectedColumns";

interface ValueFilter {
  actions: MenuOption[];
  selectedValues: Set<string>;
}

const SELECT_ALL_LABEL = "Select all";

class ConversationsFilter {
  public selectedNodesTo: Set<string> = new Set();
  public selectedNodesFrom: Set<string> = new Set();
  public selectedColumns: Set<string> = new Set();
  public selectedFilters: Set<string> = new Set();
  public nodesFrom: MenuOption[] = [];
  public columns: MenuOption[] = [];
  public valueFilters: { [key: string]: ValueFilter } = {};

  constructor(private data: TableSession[]) {
    makeAutoObservable(this);
    this.initiliaze();
  }

  get filters(): MenuOption[] {
    return Array.from(Object.keys(this.valueFilters)).map((key) => ({
      label: key,
      action: () => this.selectMenuOption(this.selectedFilters, key),
      isEnable: true,
      isSelected: this.selectedFilters.has(key),
    }));
  }

  get nodesTo(): MenuOption[] {
    const menuOptions: MenuOption[] = [];

    this.data.forEach((session) => {
      session.transitions.forEach((transition) => {
        if (!menuOptions.find((node) => node.label === transition.toNode)) {
          menuOptions.push({
            action: () => this.selectMenuOption(this.selectedNodesTo, transition.toNode),
            isSelected: this.selectedNodesTo.has(transition.toNode),
            isEnable: true,
            label: transition.toNode,
          });
        }
      });
    });

    if (this.selectedNodesFrom.size === 0) {
      return menuOptions.map((action) => ({ ...action, isEnable: true }));
    }

    const currentNodesTo = new Set();

    this.data.forEach((session) => {
      session.transitions.forEach((transition) => {
        if (this.selectedNodesFrom.has(transition.fromNode)) {
          currentNodesTo.add(transition.toNode);
        }
      });
    });

    return menuOptions.map((action) => {
      if (!currentNodesTo.has(action.label)) {
        return { ...action, isEnable: false };
      }

      return action;
    });
  }

  get filteredData(): TableSession[] {
    return this.data.filter((session) => {
      let hasTransitions = true;
      let hasValueFilters = true;

      if (this.selectedNodesFrom.size > 0 || this.selectedNodesTo.size > 0) {
        hasTransitions = session.transitions.some(
          (transition) => this.selectedNodesFrom.has(transition.fromNode) || this.selectedNodesTo.has(transition.toNode)
        );
      }

      const valueFiltersKeys = Array.from(Object.keys(this.valueFilters));

      if (valueFiltersKeys.some((key) => this.valueFilters[key].selectedValues.size > 0)) {
        hasValueFilters = valueFiltersKeys.every((key) => {
          if (this.valueFilters[key].selectedValues.size === 0) return true;

          if (
            this.valueFilters[key].selectedValues.has(
              String(
                {
                  ...session,
                  ...session.jobData?.inputData,
                  ...session.jobData?.outputData,
                  ...session.jobData?.sipIncomingInformation?.sip,
                }[key]
              )
            )
          ) {
            return true;
          }

          return false;
        });
      }

      if (hasTransitions && hasValueFilters) {
        return true;
      } else {
        return false;
      }
    });
  }

  private selectMenuOption(selectedSet: Set<string>, menuOptionId: string, actions?: MenuOption[]) {
    if (selectedSet.has(menuOptionId)) {
      selectedSet.delete(menuOptionId);
      selectedSet.delete(SELECT_ALL_LABEL);
    } else {
      selectedSet.add(menuOptionId);

      if (selectedSet.size === actions?.filter((action) => action.label !== SELECT_ALL_LABEL).length) {
        selectedSet.add(SELECT_ALL_LABEL);
      }
    }
  }

  private getValueFilters(data: { [key: string]: number | string }) {
    for (const key in data) {
      if (!data[key]) continue;

      if (!this.valueFilters[key]) {
        this.valueFilters[key] = { actions: [], selectedValues: new Set() };

        const valueFilter = this.valueFilters[key];

        valueFilter.actions.push({
          action: () => {
            this.selectMenuOption(valueFilter.selectedValues, SELECT_ALL_LABEL);
            if (valueFilter.selectedValues.has(SELECT_ALL_LABEL)) {
              valueFilter.actions.forEach(
                (action) => action.label !== SELECT_ALL_LABEL && valueFilter.selectedValues.add(action.label)
              );
            } else {
              valueFilter.selectedValues.clear();
            }
          },
          isSelected: valueFilter.selectedValues.has(SELECT_ALL_LABEL),
          isEnable: true,
          label: "Select all",
        });
      }

      const valueFilter = this.valueFilters[key];
      const value = String(data[key]);
      const isAlreadyExist = valueFilter.actions.find((a) => a.label === value);

      if (valueFilter && !isAlreadyExist) {
        valueFilter.actions.push({
          action: () => this.selectMenuOption(valueFilter.selectedValues, value, valueFilter.actions),
          isSelected: valueFilter.selectedValues.has(value),
          isEnable: true,
          label: value,
        });
      }
    }
  }

  initiliaze() {
    if (this.data.length !== 0) {
      const columns = getColumns(this.data);

      columns.forEach((column) => {
        this.selectedColumns.add(column.Header);
        this.columns.push({
          action: () => this.selectMenuOption(this.selectedColumns, column.Header),
          isSelected: this.selectedColumns.has(column.Header),
          isEnable: true,
          label: column.Header,
        });
      });
    }

    this.data.forEach((session) => {
      this.getValueFilters(session.jobData?.inputData);
      this.getValueFilters(session.jobData?.outputData);
      this.getValueFilters(session.jobData?.sipIncomingInformation?.sip);

      session.transitions.forEach((transition) => {
        if (!this.nodesFrom.find((node) => node.label === transition.fromNode)) {
          this.nodesFrom.push({
            action: () => this.selectMenuOption(this.selectedNodesFrom, transition.fromNode),
            isSelected: this.selectedNodesFrom.has(transition.fromNode),
            isEnable: true,
            label: transition.fromNode,
          });
        }
      });
    });
  }

  public resetSelectedFilters(key: FilterType) {
    this[key].clear();
  }
}

export default ConversationsFilter;
