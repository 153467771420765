import * as monacoHelpers from "@core/dsl/monaco-helpers";
import DslElementType from "../enums/DslElementType";
import * as interfaces from "./index";

export function createOptionalValue<T>(value: T | null | undefined): interfaces.OptionalValue<T> {
  if (value !== null && value !== undefined) {
    return { hasValue: true, value };
  }
  return { hasValue: false, value: null };
}

export function createRawElement<T>(
  value: T,
  locationProvider: monacoHelpers.LocationProvider
): interfaces.IRawElement<T> {
  return {
    elementType: DslElementType.RawElement,
    value,
    location: monacoHelpers.createRange(locationProvider),
  };
}

export function isExpression(obj: any): obj is interfaces.IExpression {
  return obj["isExpression"] === true;
}

export function isVariable(obj: any): obj is interfaces.IVariable {
  return typeof obj === "object" && obj.name !== undefined && obj.type !== undefined && obj.initialValue !== undefined;
}

export function createConstantExpression(
  value: string,
  locationProvider: monacoHelpers.LocationProvider
): interfaces.IConstantExpression {
  return {
    isExpression: true,
    elementType: DslElementType.ConstantExpression,
    constant: createRawElement(value, locationProvider),
    location: monacoHelpers.createRange(locationProvider),
  };
}
