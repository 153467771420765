import { observer } from "mobx-react-lite";
import React, { FC, Fragment, useEffect, useState } from "react";
import { gpt } from "@dasha.ai/sdk/web";
import { gptFunctionsStore } from "../../Functions/types";
import { Divider, Dropdown, Input, Label, Form as SemForm, TextArea } from "semantic-ui-react";
import Form from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";
import { JsonEditor } from "json-edit-react";
import { RJSFSchema } from "@rjsf/utils";

export const isObject = (obj: any) => typeof obj === "object" && obj != null;

export const EditCallResponseItem: FC<{
  functionCallResult: any;
  onChange: (functionCallResult: any) => void;
}> = observer(({ functionCallResult, onChange }) => {

  return (
    <>
      <Divider />
      <Label content="Response" />
      {isObject(functionCallResult) && <JsonEditor data={functionCallResult} setData={onChange} />}
      {!isObject(functionCallResult) && (
        <TextArea
          rows={10}
          className="dasha-textarea"
          value={functionCallResult.toString()}
          onChange={(e, d) => onChange(d.value)}
        />
      )}
    </>
  );
});
