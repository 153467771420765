import styled from "styled-components";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import { PureButton, StrokeButton } from "../uikit/Button";

export const Table = styled.table`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
  user-select: auto;
  box-sizing: border-box;
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  height: 100%;

  button {
    width: 320px;
  }

  p {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
`;

export const GraphEditor = styled(CanvasWidget)`
  width: 100%;
  height: 100%;
  cursor: default;
`;

export const GraphNavigation = styled(PureButton)`
  background: #161729;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  height: 32px;
  padding: 8px;

  position: absolute;
  top: 62px;
  left: 16px;
`;
