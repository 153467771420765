import { useEffect, useRef } from "react";

const useResize = <T extends HTMLElement>(cb: (rect: DOMRectReadOnly) => void): React.RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current == null) return;
  
    const observer = new ResizeObserver((e) => e[0] && cb(e[0].contentRect));

    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [cb]);

  return ref;
};

export default useResize;
