import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import { Trigger, MarkupType } from "../../core/workspace/session-storage/devlogs";
import { PureButton } from "../../uikit/Button";
import { Tooltip } from "../../uikit/popup";
import { Icon } from "../../uikit";
import * as S from "./styled";

interface Props {
  disabled?: boolean;
  readOnly?: boolean;
  isProfilerTrigger?: boolean;
  onClick?: () => void;
  onMark?: (mark: MarkupType) => void;
  phrase: string;
  trigger: Trigger;
}

const TriggerBadge: FC<Props> = ({ phrase, onClick, onMark, disabled, trigger, readOnly, isProfilerTrigger }) => {
  const state = trigger.type === "entity" ? S.entityState[trigger.mark] : S.intentState[trigger.mark];
  const style: any = trigger.type === "entity" ? { "--hue": trigger.hue } : null;

  let tooltip: React.ReactNode = null;
  if (trigger.type === "entity") {
    tooltip = (
      <div>
        <span>Entity</span>
        <span>From: {phrase.slice(trigger.location.start, trigger.location.end)}</span>
        {trigger.value != null && <span>Value: {trigger.value}</span>}
      </div>
    );
  }

  const percent =
    trigger.probability !== -1 && trigger.probability !== null ? `${Math.round(trigger.probability * 100)}%` : "";
  let name = trigger.name;
  if (trigger.type === "entity" && trigger.tag) {
    name += ` :${trigger.tag}`;
  }

  const handleMark = () => {
    const mark = trigger.mark == MarkupType.exclude ? MarkupType.include : MarkupType.exclude;
    onMark?.(mark);
  };

  return (
    <Tooltip mouseEnterDelay={350} text={tooltip} position={["bottom center", "bottom right"]}>
      <S.Badge
        readOnly={readOnly}
        hovered={trigger.type === "entity" && trigger.hovered}
        state={readOnly ? S.entityState.readonly : state}
        style={style}
      >
        <span onClick={onClick}>
          {name} {!readOnly && trigger.type === "entity" && trigger.value && `:${trigger.value}`} {!readOnly && percent}
        </span>

        {trigger.mark !== MarkupType.unmark && !readOnly && !disabled && !trigger.readOnly && (
          <PureButton onClick={handleMark}>
            <Icon name={trigger.mark == MarkupType.exclude ? "plus" : "cross"} />
          </PureButton>
        )}

        {isProfilerTrigger && !readOnly && !disabled && trigger.mark === MarkupType.unmark && !trigger.readOnly && (
          <PureButton onClick={handleMark}>
            <Icon name="cross" />
          </PureButton>
        )}
      </S.Badge>
    </Tooltip>
  );
};

export default observer(TriggerBadge);
