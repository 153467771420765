import * as esbuild from "esbuild-wasm/lib/browser";
import dashaRaw from "!!raw-loader!@dasha.ai/sdk/web";

export const depsPlugin = () => {
  return {
    name: "fiiesystem-plugin",
    setup(build: esbuild.PluginBuild) {
      build.onResolve({ filter: /^fs$/ }, async (args) => {
        return {
          namespace: "fs",
          path: args.path,
        };
      });

      build.onLoad({ filter: /^fs$/, namespace: "fs" }, (args) => {
        return {
          loader: "js",
          contents: `export default window.fs`,
        };
      });

      build.onResolve({ filter: /.+/ }, async (args) => {
        if (["@sentry/tracing", "@dasha.ai/sdk"].includes(args.path)) {
          return {
            namespace: "deps",
            path: args.path,
          };
        }
      });

      build.onLoad({ filter: /.+/, namespace: "deps" }, (args) => {
        if (["@sentry/tracing"].includes(args.path)) {
          return {
            loader: "js",
            contents: "const module = {}; export default module",
          };
        }

        if (args.path === "@dasha.ai/sdk") {
          return {
            loader: "js",
            contents: `
              export const Application = window.dasha.Application; 
              export const CancelToken = window.dasha.CancelToken; 
              export const CircularBuffer = window.dasha.CircularBuffer; 
              export const ConversationQueue = window.dasha.ConversationQueue; 
              export const QueuedConversation = window.dasha.QueuedConversation; 
              export const SingleConversation = window.dasha.SingleConversation; 
              export const account = window.dasha.account; 
              export const applications = window.dasha.applications; 
              export const audio = window.dasha.audio; 
              export const auth = window.dasha.auth; 
              export const chat = window.dasha.chat; 
              export const configure = window.dasha.configure; 
              export const customer = window.dasha.customer; 
              export const deploy = window.dasha.deploy; 
              export const diagnostics = window.dasha.diagnostics; 
              export const errors = window.dasha.errors; 
              export const groups = window.dasha.groups; 
              export const log = window.dasha.log; 
              export const multichannelChat = window.dasha.multichannelChat; 
              export const nlu = window.dasha.nlu; 
              export const profiler = window.dasha.profiler; 
              export const sip = window.dasha.sip; 
              export const stt = window.dasha.stt; 
              export const tts = window.dasha.tts; 
            `,
          };
        }
      });
    },
  };
};

export const dashaPlugin = () => {
  return {
    name: "dasha-plugin",
    setup(build: esbuild.PluginBuild) {
      build.onResolve({ filter: /^fs$/ }, async (args) => {
        return {
          namespace: "fs",
          path: args.path,
        };
      });

      build.onLoad({ filter: /^fs$/ }, (args) => {
        return {
          loader: "js",
          contents: `export default window.fs`,
        };
      });

      build.onResolve({ filter: /.+/ }, async (args) => {
        if (args.path === "@dasha.ai/sdk") {
          return {
            namespace: "dasha",
            path: args.path,
          };
        }
      });

      build.onLoad({ filter: /.+/, namespace: "dasha" }, (args) => {
        if (args.path === "@dasha.ai/sdk") {
          return {
            loader: "js",
            contents: dashaRaw,
          };
        }
      });
    },
  };
};
