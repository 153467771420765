import * as React from "react";

import { Explorer } from "../core/explorer/Explorer";
import { ActionButton, Icon, StrokeButton } from "../uikit";
import { ModalTitle } from "../uikit/Modal/styled";
import Modal from "../uikit/Modal";
import { Tab, Tabs } from "./styled";
import useRename from "./useRename";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  explorer: Explorer;
}

const CreateWidget: React.FC<Props> = ({ isOpen, onClose, explorer }) => {
  const { renameInputs, isDisabled, reset, name, description } = useRename();
  const [isLoading, setLoading] = React.useState(false);
  const [isNoCode, setNoCode] = React.useState(false);
  const navigate = useNavigate();
  const submit = async () => {
    setLoading(true);
    const projectType = isNoCode ? "visual" : "code";
    const project = await explorer.createBlankProject(name, description, projectType);
    setLoading(false);
    onClose();
    reset();
    navigate(`/project/${project.id}`);
  };

  return (
    <Modal isDisabled={isLoading} isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Creat project</ModalTitle>

      <Tabs>
        <Tab onClick={() => setNoCode(false)} isSelected={isNoCode === false}>
          Code
        </Tab>
        <Tab onClick={() => setNoCode(true)} isSelected={isNoCode === true}>
          No-code
        </Tab>
      </Tabs>

      <br />
      {renameInputs()}
      <br />

      <div style={{ display: "flex", marginTop: 16 }}>
        <StrokeButton disabled={isLoading} style={{ flex: 1 }} onClick={onClose}>
          Close
        </StrokeButton>
        <ActionButton disabled={isDisabled || isLoading} style={{ flex: 1, marginLeft: 16 }} onClick={submit}>
          {isLoading && <Icon name="spinner" style={{ marginRight: 8, width: 18 }} />}
          Create
        </ActionButton>
      </div>
    </Modal>
  );
};

export default CreateWidget;
