import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement, RawElement } from "../common";
import { TransitionCondition } from "./TransitionCondition";
import { TransitionType } from "../../enums/TransitionType";

export class TransitionDefinition extends DslElement implements interfaces.ITransitionDefinition {
  elementType: DslElementType.TransitionDefinition = DslElementType.TransitionDefinition;
  transitionType: TransitionType;
  condition: interfaces.OptionalValue<TransitionCondition>;
  name: RawElement<string>;
  targetNodeName: RawElement<string>;

  constructor(obj: interfaces.ITransitionDefinition, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.transitionType = obj.transitionType;
    if (obj.condition.hasValue) {
      this.condition = { hasValue: true, value: helpers.createTransitionCondition(obj.condition.value) };
    } else {
      this.condition = { hasValue: false, value: null };
    }
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.targetNodeName = helpers.ensureIsInstance(obj.targetNodeName, RawElement<string>);
  }
  toJson(): interfaces.ITransitionDefinition {
    return {
      elementType: this.elementType,
      location: this.location,
      transitionType: this.transitionType,
      condition: interfaces.helpers.createOptionalValue(this.condition.value?.toJson()),
      name: this.name.toJson(),
      targetNodeName: this.targetNodeName.toJson(),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + `${this.name.value}: `,
      `goto ${this.targetNodeName.value}`,
      this.condition.hasValue ? ` on ${this.condition.value.toDsl(indent)}` : "",
      ";",
    ].join("");
  }
}
