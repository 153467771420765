import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { Table, Loader, Button, Dropdown } from "semantic-ui-react";
import "./styles.css";
import { useStore } from "../core/api/GlobalStoreContext";
import { observer } from "mobx-react-lite";

export const GroupListDropdown: FC<{ group: string | undefined; selectGroup: (group: string | undefined) => void }> = observer(({
  group,
  selectGroup,
}) => {
  const { groups } = useStore();
  useEffect(() => {
    groups.TryReload();
  }, [groups]);

  return (
    <Dropdown
      placeholder="Select Group"
      fluid
      search
      selection
      options={Array.from(groups.Groups.keys()).map((x) => ({ text: x, key: x, value: x }))}
      loading={groups.Loading}
      value={group}
      onChange={(e, { value }) => selectGroup(value?.toString())}
    />
  );
});
