import React, { FC, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const verticalGutter = css<{ isActive?: boolean }>`
  height: 12px;
  margin-top: -6px;
  display: flex;
  align-items: center;
  cursor: row-resize;

  &::after {
    z-index: 100;
    opacity: 0.12;
    background-color: #ffffff;
    transition: 0.2s opacity, 0.2s width, 0.2s height;
    width: 100%;
    height: 1px;

    content: "";
    display: block;
  }

  &:hover {
    &::after {
      height: 4px;
      opacity: 0.3;
    }
  }

  ${(p) =>
    p.isActive &&
    css`
      &::after {
        height: 4px;
        opacity: 0.3;
      }
    `}
`;

const horizontalGutter = css<{ isActive?: boolean }>`
  width: 12px;
  margin-left: -6px;
  display: flex;
  justify-content: center;
  cursor: col-resize;

  &::after {
    z-index: 100;
    opacity: 0.12;
    background-color: #ffffff;
    transition: 0.2s opacity, 0.2s width, 0.2s height;
    height: 100%;
    width: 1px;

    content: "";
    display: block;
  }

  &:hover {
    &::after {
      width: 4px;
      opacity: 0.3;
    }
  }

  ${(p) =>
    p.isActive &&
    css`
      &::after {
        width: 4px;
        opacity: 0.3;
      }
    `}
`;

export const SGutter = styled.div<{ vertical?: boolean; isActive: boolean; disabled?: boolean }>`
  z-index: 2;
  grid-area: ${(p) => p.id};
  ${(p) => (p.vertical ? verticalGutter : horizontalGutter)};

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`;

interface Props {
  id: string;
  vertical?: boolean;
  disabled?: boolean;
  onMove: (dir: number, init: boolean) => void;
}

const Gutter: FC<Props> = ({ onMove, id, vertical, disabled }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (ref.current == null) return;
    if (disabled) return;

    const dragGutter = (e: MouseEvent, isInit: boolean) => {
      document.body.classList.add(`cursor-${vertical ? "row" : "col"}-resize`);
      const rect = ref.current?.getBoundingClientRect();
      if (rect == null) return;

      if (vertical) return onMove(rect.y - 6 - e.clientY, isInit);
      onMove(e.clientX - rect.x, isInit);
    };

    const handler = (e: MouseEvent) => {
      const handleMove = (e: MouseEvent) => dragGutter(e, false);

      const removeHandleMove = () => {
        document.body.classList.remove(`cursor-${vertical ? "row" : "col"}-resize`);
        window.removeEventListener("mousemove", handleMove);
        setActive(false);
      };

      window.addEventListener("mousemove", handleMove);
      window.addEventListener("mouseup", removeHandleMove);

      dragGutter(e, true);
      setActive(true);
    };

    ref.current.addEventListener("mousedown", handler);
  }, [vertical, disabled]);

  return <SGutter ref={ref} id={id} vertical={vertical} disabled={disabled} isActive={isActive} />;
};

export default Gutter;
