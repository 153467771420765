import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";

export class CastExpression extends Expression implements interfaces.ICastExpression {
  elementType: DslElementType.CastExpression = DslElementType.CastExpression;
  expression: Expression;
  type: RawElement<string>;

  constructor(obj: interfaces.ICastExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.expression = helpers.ensureIsExpressionInstance(obj.expression);
    this.type = helpers.ensureIsInstance(obj.type, RawElement<string>);
  }
  toJson(): interfaces.ICastExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      expression: this.expression.toJson(),
      type: this.type.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `${this.expression.toDsl(indent)} as ${this.type.toDsl(indent)}`;
  }
}
