import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

import { DoStatement } from "./DoStatement";
import { Expression } from "../expressions";
import { RawElement } from "../common";

export class VarStatement extends DoStatement implements interfaces.IVarStatement {
  elementType: DslElementType.VarStatement = DslElementType.VarStatement;
  name: RawElement<string>;
  type: interfaces.OptionalValue<RawElement<string>>;
  initialValue: interfaces.OptionalValue<Expression>;

  constructor(obj: interfaces.IVarStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.type = helpers.ensureIsOptionalInstance(obj.type, RawElement<string>);
    if (obj.initialValue.hasValue) {
      this.initialValue = { hasValue: true, value: helpers.ensureIsExpressionInstance(obj.initialValue.value) };
    } else {
      this.initialValue = { hasValue: false, value: null };
    }
  }
  toJson(): interfaces.IVarStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      type: interfaces.helpers.createOptionalValue(this.type.value?.toJson()),
      initialValue: interfaces.helpers.createOptionalValue(this.initialValue.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        helpers.indentString(indent),
        "var ",
        this.name.value,
        this.type.hasValue ? `: ${this.type.value.toDsl(indent)}` : null,
        this.initialValue.hasValue ? ` = ${this.initialValue.value.toDsl(indent)}` : null,
        ";",
      ],
      ""
    );
  }
}
