import * as elements from "../index";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";
import * as helpers from "../helpers";
import { HighOrderFunctionType } from "../../enums/HighOrderFunctionType";

export class HighOrderFunctionCallExpression extends Expression implements interfaces.IHighOrderFunctionCallExpression {
  elementType: DslElementType.HighOrderFunctionCallExpression = DslElementType.HighOrderFunctionCallExpression;
  arguments: elements.FunctionArguments;
  functionType: HighOrderFunctionType;
  name: RawElement<string>;
  constructor(obj: interfaces.IHighOrderFunctionCallExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.arguments =
      obj.arguments instanceof elements.FunctionArguments
        ? obj.arguments
        : new elements.FunctionArguments(obj.arguments);
    this.functionType = obj.functionType;
    this.name = obj.name instanceof RawElement ? obj.name : new RawElement(obj.name);
  }

  toJson(): interfaces.IHighOrderFunctionCallExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      name: this.name.toJson(),
      functionType: this.functionType,
      arguments: this.arguments.toJson(),
      location: this.location,
    };
  }
  toDsl(indent: number): string {
    const functionTypeToPrefix = {
      [HighOrderFunctionType.Builtin]: "#",
      [HighOrderFunctionType.BlockCall]: "blockcall ",
      [HighOrderFunctionType.ExternalCall]: "external ",
    };
    return [functionTypeToPrefix[this.functionType], this.name.value, `(${this.arguments.toDsl(indent)})`].join("");
  }
}
