import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { RootContextVariable } from "../variables";
import { BlockContentDescription } from "./BlockContentDescription";

export class RootBlockContentDescription
  extends BlockContentDescription
  implements interfaces.IRootBlockContentDescription
{
  elementType: DslElementType.BlockContentDescription = DslElementType.BlockContentDescription;
  isRootBlockContent: true = true as const;
  contextVariables: RootContextVariable[];

  constructor(obj: interfaces.IRootBlockContentDescription, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.contextVariables = helpers.ensureIsArrayOfInstances(obj.contextVariables, RootContextVariable);
  }
  toJson(): interfaces.IRootBlockContentDescription {
    return {
      ...super.toJson(),
      isRootBlockContent: true,
      contextVariables: this.contextVariables.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return super.toDsl(indent);
  }
}
