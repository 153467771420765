import { Column } from "react-table";
import React from "react";
import dayjs from "dayjs";
import { TableSession } from "@core/profiler/ConversationsTable";
import { Tooltip } from "../../../uikit/popup";

const defaultColumns: Column[] = [
  { Header: "ID", Cell: ({ value }) => getValue(value), accessor: "id", minWidth: 200 },
  {
    Header: "Completed Time",
    accessor: "completedTime",
    Cell: ({ value }) => dayjs(value).format("MM.DD.YYYY hh:mm A"),
  },
];

const getValue = (value: any): React.ReactNode => {
  if (!value) return <span>{value}</span>;

  const strValue = String(value);

  if (strValue.length > 15) {
    return (
      <Tooltip text={strValue} position="top center">
        <span>{strValue.substring(0, 15)}...</span>
      </Tooltip>
    );
  }

  return <span>{strValue}</span>;
};

export const getColumns = (data: TableSession[]) => {
  if (data.length === 0) return defaultColumns;

  const columns: Column[] = [];

  for (const inputKey in data[0].jobData?.inputData) {
    columns.push({
      Header: inputKey,
      Cell: ({ value }) => getValue(value),
      minWidth: 200,
      accessor: `jobData.inputData.${inputKey}`,
    });
  }

  for (const outputKey in data[0].jobData?.outputData) {
    columns.push({
      Header: outputKey,
      Cell: ({ value }) => getValue(value),
      minWidth: 200,
      accessor: `jobData.outputData.${outputKey}`,
    });
  }

  for (const sipKey in data[0].jobData?.sipIncomingInformation?.sip) {
    columns.push({
      Header: sipKey,
      Cell: ({ value }) => getValue(value),
      minWidth: 200,
      accessor: `jobData.sipIncomingInformation.sip.${sipKey}`,
    });
  }

  console.log({ columns, data });

  return [...defaultColumns, ...columns];
};
