import { Message, RunnerType, SessionProtocol } from "@core/workspace/session-storage/types";
import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Column } from "react-table";
import get from "lodash/get";
import dayjs from "dayjs";

import ProfilerStorage from "./storage";
import { Session } from "./storage/model/models";
import { createDownloadLink, filterLog } from "./utils";
import SessionRunner from "@core/workspace/session-storage/SessionRunner";
import { TransitionKey } from "./storage/model/stuff";
import ConversationsFilter from "./ConversationsFilter";
import { Emitter } from "@core/misc/emitter";
import { NluSttReason } from "./storage/interfaces";

export interface TableSession extends Session {
  transitions: TransitionKey[];
}

class ConversationsTable {
  public data: TableSession[] = [];
  public filter: ConversationsFilter;
  public isExporting = false;

  private readonly _onDidOpenSession = new Emitter<SessionProtocol | null>();
  public readonly onDidOpenSession = this._onDidOpenSession.event;

  constructor(private readonly storage: ProfilerStorage, readonly account) {
    makeAutoObservable(this);
  }

  public async openSession(sessionId) {
    try {
      this._onDidOpenSession.fire(null);
      const logs = await this.storage?.getLog(sessionId);
      const currentSession = await this.storage?.getSessionById(sessionId);

      const messages: Message[] = logs.filter(filterLog).map((log) => ({
        id: uuid(),
        from: log.incoming ? "human" : "ai",
        transitions: log.transition ? [ log.transition ] : [],
        changeContext: {},
        triggers: [],
        message: log.msg,
        time: dayjs(log.time).unix(),
      }));

      const session = new SessionRunner(
        {
          jobId: sessionId,
          type: RunnerType.Text,
          duration: 0,
          messages,
          isSending: false,
          isReadOnly: false,
          outputData: currentSession?.jobData?.outputData,
          inputData: currentSession?.jobData?.inputData
        },
        this.account
      );

      this._onDidOpenSession.fire(session);
    } catch (err) {
      console.log(err);
    }
  }

  public async prepareData() {
    try {
      const reasons = await this.storage.getNluStt();
      const normalizedSessions = new Map<string, TableSession>();

      for (const reason of reasons) {
        const transitions = await this.storage.getTransitionsByReasonId(reason.id);
        const filteredTransitions = transitions.filter(
          (transition) =>
            ![
              "PreprocessorReturn",
              "Preprocessor",
              "DigressionReturn",
              "GlobalPreprocessor",
              "GlobalPreprocessorReturn",
              "GlobalReturn",
            ].includes(transition.transitionType)
        );

        for (const transition of filteredTransitions) {
          const sessions = await this.storage.getSessionsByReasonIdAndTransitionTableId(reason.id, transition.id);

          sessions.forEach((session) => {
            const normalizedSession = normalizedSessions.get(session.id);

            if (!normalizedSession) {
              normalizedSessions.set(session.id, { ...session, transitions: [transition] });
              return;
            }

            if (!normalizedSession.transitions.find((t) => t.id === transition.id)) {
              normalizedSessions.set(session.id, {
                ...normalizedSession,
                transitions: [...normalizedSession.transitions, transition],
              });
            }
          });
        }
      }

      this.data = [...this.data, ...normalizedSessions.values()];

      this.filter = new ConversationsFilter(this.data);
    } catch (err) {
      console.log(err);
    }
  }

  public exportToCsv(columns: Column[]) {
    try {
      this.isExporting = true;
      const rows = [
        columns.map((column) => column.Header),
        ...this.data.map((conversation) => {
          const row: string[] = [];

          columns.forEach((column) => {
            if (column.accessor === "completedTime") {
              row.push(dayjs(conversation.completedTime).format("MM.DD.YYYY hh:mm A"));
            } else {
              row.push(get(conversation, column.accessor));
            }
          });

          return row;
        }),
      ];

      const csvData = rows.map((r) => r.join(";")).join("\n");
      createDownloadLink([csvData], "text/csv", "conversations.csv");
    } catch (err) {
      console.log(err);
    }

    this.isExporting = false;
  }
}

export default ConversationsTable;
