import React, { FC, useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ApplicationInfo } from "@dasha.ai/sdk/web/applications";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";

import Modal from "../../../uikit/Modal";
import { Dropdown, ActionButton, StrokeButton } from "../../../uikit";
import { ModalTitle } from "../../../uikit/Modal/styled";

import DashaappStorage from "@core/workspace/DashaappStorage";
import { ProfileDump } from "@core/profiler/types";

import DateSelector from "./components/DateSelector";
import TimeSelector from "./components/TimeSelector";
import * as S from "./styled";

dayjs.extend(advancedFormat);

interface Props {
  isOpen: boolean;
  dashaapp?: DashaappStorage;
  onLoad: (dump: ProfileDump) => void;
  onClose: () => void;
}

const APP_CREATED_TIME_FORMAT = "MMM Do YYYY hh:mm a";

const ProfilerWidget: FC<Props> = ({ isOpen, dashaapp, onLoad, onClose }) => {
  const [currentApp, setCurrentApp] = useState<ApplicationInfo | null>(null);
  const [applications, setApplications] = useState<ApplicationInfo[]>([]);
  const [dateRange, setDateRange] = useState({
    start: dayjs().subtract(1, "hour").toDate(),
    end: dayjs().toDate(),
  });

  useEffect(() => {
    if (!isOpen) return;
    setApplications([]);
    void dashaapp?.getApplications().then((applications) => {
      applications.sort((a, b) => b.createdTime.getTime() - a.createdTime.getTime());

      setApplications(applications);
    });
  }, [isOpen]);

  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  const handleLoadProfile = useCallback(() => {
      if (!currentApp) return;
      onLoad({
        name: currentApp.name,
        application: currentApp.id,
        start: +dateRange.start,
        end: +dateRange.end,
        savedDatasets: [],
        marked: {},
      });
  }, [currentApp, dateRange]);

  const handleSelectApp = (app: ApplicationInfo) => {
    setCurrentApp(app);
    setDateRange({ start: new Date(app.createdTime.getTime() - 5*60000), end: new Date(new Date().getTime() - 1000) });
  };

  const dropdownButton = (
    <S.ListBtn id="applications-list" disabled={applications.length === 0}>
      <span>{currentApp ? dayjs(currentApp.createdTime).format(APP_CREATED_TIME_FORMAT) : "Select Application"}</span>
    </S.ListBtn>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Load Profile</ModalTitle>
      <S.Container>
        <S.FieldGroup>
          <label htmlFor="applications-list">ID</label>
          <Dropdown button={dropdownButton}>
            <S.List>
              {applications.map((application) => (
                <S.ListItem key={application.id} onClick={() => handleSelectApp(application)}>
                  <span>{dayjs(application.createdTime).format(APP_CREATED_TIME_FORMAT)}</span>
                  <span>{application.sessionCount} conv.</span>
                </S.ListItem>
              ))}
            </S.List>
          </Dropdown>
        </S.FieldGroup>
        <S.FieldGroup>
          <label htmlFor="date-range">Date range:</label>
          <DateSelector dateRange={dateRange} onChange={handleDateChange} />
        </S.FieldGroup>
        <S.FieldGroup>
          <TimeSelector dateRange={dateRange} onRangeChange={handleDateChange} />
        </S.FieldGroup>
        <div style={{ display: "flex", marginTop: "16px" }}>
          <StrokeButton style={{ flex: "1" }} onClick={onClose}>
            Close
          </StrokeButton>
          <ActionButton style={{ flex: "1", marginLeft: "16px" }} onClick={handleLoadProfile}>
            Load Profile
          </ActionButton>
        </div>
      </S.Container>
    </Modal>
  );
};

export default observer(ProfilerWidget);
