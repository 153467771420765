import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { Tooltip } from "../uikit/popup";

import { Icon } from "../uikit";
import Workspace from "../core/workspace/Workspace";
import { AccountsManager } from "../core/account/Manager";
import { Explorer } from "../core/explorer/Explorer";

import AccountWidget from "./AccountWidget";
import ProjectActions from "./ProjectActions";
import ProjectControl from "./ProjectControl";

import { ReactComponent as LogoIcon } from "./logo.svg";
import * as S from "./styled";
import { PureButton } from "../uikit/Button";
import { ActionButton } from "../uikit/Button";
import { Tag } from "../ExplorerView/Tag";
import { Message } from "semantic-ui-react";
import { useStore } from "../core/api/GlobalStoreContext";
import { Link } from "react-router-dom";

interface Props {
  id: string;
  useLogo?: boolean;
  onSwitchMode?: () => void;
  isBasicMode?: boolean;
  canSwitchMode?: boolean;
}

const HeaderPanel: FC<Props> = ({ id, useLogo, onSwitchMode, isBasicMode, canSwitchMode = true }) => {
  const { workspace, account, explorer } = useStore();
  const { project, isRunning } = workspace;
  const versions = project?.versions || {};
  const customerName = account.getValue<string>("customerName");

  return (
    <S.Container id={id}>
      {useLogo ? (
        <S.Logo target="_blank" rel="noopener noreferrer" href="https://dasha.ai/">
          <LogoIcon />
        </S.Logo>
      ) : (
        <S.Flexbox>
          <Link to={"/"}>
            <PureButton>
              <Icon name="apps" />
            </PureButton>
          </Link>

          {project?.metadata.isEditable === false && <Tag id="readonly" />}
          {project && Object.keys(versions).length > 1 && (
            <Tag id="custom">{versions[project.version].message ?? "Unnamed"}</Tag>
          )}
        </S.Flexbox>
      )}
      {project && (window.location.href.indexOf("/project") >=0) && (
        <S.Fullwrap>
          <ProjectActions
            workspace={workspace}
            explorer={explorer}
            project={project}
            isDisabled={isRunning}
          >
            <S.Title>
              {project.metadata.name}
              <Icon name="chevron-down" style={{ opacity: isRunning ? 0 : 1 }} />
            </S.Title>
          </ProjectActions>
        </S.Fullwrap>
      )}

      <S.Flexbox>
        <ProjectControl />
        {customerName && (
          <S.Flexbox>
            <Message error>Logged as {customerName}</Message>
          </S.Flexbox>
        )}
        {onSwitchMode && canSwitchMode && (
          <Tooltip
            text={!canSwitchMode ? "This app is not available in Basic Mode." : ""}
            position="bottom center"
            disabled={canSwitchMode}
          >
            <div>
              <ActionButton
                onClick={onSwitchMode}
                style={{ marginRight: "1rem" }}
                disabled={!canSwitchMode}
              >
                Switch to {isBasicMode ? "Developer" : "Basic"} Mode
              </ActionButton>
            </div>
          </Tooltip>
        )}
        <AccountWidget />
      </S.Flexbox>
    </S.Container>
  );
};

export default observer(HeaderPanel);
