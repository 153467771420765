import styled from "styled-components";

export const AvatarMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-left: 4px;
  }
`;

export const Avatar = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #ffffff;

  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
`;
