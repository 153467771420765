import styled, { css } from "styled-components";

export const DialogContainer = styled.div`
  padding: 0 16px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  * {
    user-select: text;
    -webkit-user-select: text;
  }
`;

export const System = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  margin: 16px 0;
`;

export const SystemMessage = styled.p`
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
`;


export const ThinkingMessage = styled.p`
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  color:  	rgb(255, 191, 0);
  margin: 0;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  max-width: 336px;
  align-items: flex-start;
`;

export const MessageTime = styled.span`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 4px;
`;

export const MessageBubble = styled.div<{ isCurrentInAudio: boolean }>`
  background: #222343;
  border-radius: 8px 18px 8px 0px;
  box-sizing: border-box;
  padding: 8px 12px;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #ffffff;

  position: relative;

  ${(p) =>
    p.isCurrentInAudio &&
    css`
      &::after {
        content: "";
        display: block;
        top: 0;
        right: -15px;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #a1a1bd;
      }
    `}
`;
