import styled, { createGlobalStyle } from "styled-components";
import { StrokeButton } from "../../../../../uikit";

import { Option } from "../../../../../uikit/popup";
import { FiltersBox as SFiltersBox } from "../../../DataMarkup/components/Filter/styled";

export const GlobalStyles = createGlobalStyle`
  ${Option} {
    color: #fff;
  }
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  padding: 24px;

  background-color: #161729;
`;

export const FiltersBox = styled(SFiltersBox)`
  padding: 0;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    display: flex;
    align-items: flex-start;

    > div {
      margin-bottom: 8px;
    }
  }

  > div:first-child {
    flex-wrap: wrap;
    margin-right: 16px;
  }

  ${StrokeButton} {
    height: 24px;
    padding: 0 6px;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-transform: none;
  }
`;

export const FilterBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: rgba(255, 255, 255, 0.75);

  display: flex;
  align-items: center;

  white-space: nowrap;

  span {
    margin-right: 4px;
  }
`;
