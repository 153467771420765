import styled, { keyframes } from "styled-components";

const animation = keyframes`
   0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`;

const show = keyframes`
  0% {
    background: rgba(22, 23, 41, 0);
  }
  100% {
    background: rgba(22, 23, 41, 0.75);
  }
`;

export const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;

  background: rgba(22, 23, 41, 0.75);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${show} 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
`;

export const Body = styled.div`
  position: relative;
  background: #1d1e31;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
  padding: 24px;
  width: 528px;

  animation: ${animation} 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
`;

export const ModalTitle = styled.h4`
  font-family: var(--decorate-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #eceff8;
  margin: 0;
  margin-bottom: 16px;
`;
