import * as React from "react";
import { DefaultLinkFactory } from "@projectstorm/react-diagrams";

import Engine from "..";
import LinkController from "./Model";

export interface LinkSegmentWidgetProps {
  onSelection: (selected: boolean) => any;
  forwardRef: React.RefObject<SVGPathElement>;
  factory: DefaultLinkFactory;
  diagramEngine: Engine;
  link: LinkController;
  selected: boolean;
  path: string;
}

class LinkSegmentWidget extends React.Component<LinkSegmentWidgetProps> {
  render() {
    const { styles } = this.props.link;
    const isSelected = this.props.selected || this.props.link.isSelected();

    const Bottom = React.cloneElement(
      this.props.factory.generateLinkSegment(this.props.link, isSelected, this.props.path),
      {
        ref: this.props.forwardRef,
        markerEnd: `url(#${styles.markerEnd})`,
        markerStart: `url(#${styles.markerStart})`,
        stroke: isSelected ? styles.selectionColor : styles.color,
        strokeWidth: styles.width,
        style: { pointerEvents: "painted" },
      }
    );

    const Top = React.cloneElement(Bottom, {
      ref: undefined,
      strokeLinecap: "round",
      "data-linkid": this.props.link.getID(),
      onMouseLeave: () => this.props.onSelection(false),
      onMouseEnter: () => this.props.onSelection(true),
      onDoubleClick: () => this.props.diagramEngine.revealTransition(this.props.link),
      strokeOpacity: isSelected ? styles.areaSelectionOpacity : styles.areaOpacity,
      strokeWidth: styles.areaWidth,
      stroke: styles.areaColor,

      markerStart: "",
      markerEnd: "",
      fill: "none",
    });

    return (
      <g>
        {Bottom}
        {Top}
      </g>
    );
  }
}

export default LinkSegmentWidget;
