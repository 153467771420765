import { IHistoryMessage } from "@dasha.ai/sdk/web/gpt";
import React, { FC, useCallback, useState } from "react";
import { Button, Divider, List } from "semantic-ui-react";
import { HistoryItem } from "./historyItem";
import { HistoryHolder, IHistoryMessageWithId } from "./types";
import { observer } from "mobx-react-lite";
import { EditHistoryItemModal } from "./Editor/editHistoryItemModal";
import { gptFunctionsStore } from "../Functions/types";
import { Icon } from "../../Icon";

interface Props {
  history: HistoryHolder;
  prefix?: HistoryHolder;
  canEdit?: boolean;
  canEditPrefix?: boolean;
  functions?: gptFunctionsStore;
}

export const HistoryList: FC<Props> = observer(({ history, prefix, canEdit, canEditPrefix, functions }) => {
  const [openEditor, setOpenEditor] = useState<IHistoryMessageWithId | undefined>();
  const [editorHolder, setEditorHolder] = useState<HistoryHolder | undefined>();

  const addButtons = (x: IHistoryMessageWithId | undefined, holder: HistoryHolder) => {
    if (x !== undefined && x.type === "Text" && (x.thinking === undefined || x.thinking === null) && x.text === "") {
      return <></>;
    }
    return (
      <div>
        <Icon
          style={{ margin: "3px", cursor: "pointer", width: 32, height: 32 }}
          name="addtext"
          onClick={() =>
            setOpenEditor(holder.CreateAfter(x, { type: "Text", text: "Text", source: 0, thinking: undefined }))
          }
        >
          Add text
        </Icon>
        {(functions?.Functions?.length ?? 0) > 0 && (
          <Icon
            style={{ margin: "3px", cursor: "pointer", width: 32, height: 32 }}
            name="addfunction"
            onClick={() =>
              setOpenEditor(
                holder.CreateAfter(
                  x,
                  holder === history
                    ? {
                        type: "call",
                        functionCallName: functions?.Functions[0]?.Name ?? "",
                        functionCallArgs: {},
                      }
                    : {
                        type: "callResponse",
                        functionCallResult: "",
                        functionCallName: functions?.Functions[0]?.Name ?? "",
                        callId: "pg" + (Math.random() * 10000).toString(),
                        request: {
                          type: "call",
                          functionCallName: functions?.Functions[0]?.Name ?? "",
                          functionCallArgs: {},
                        },
                      }
                )
              )
            }
          >
            Add function call
          </Icon>
        )}
      </div>
    );
  };

  return (
    <div style={{ overflowY: "scroll", maxHeight: "60vh", display: "flex", flexDirection: "column-reverse" }}>
      <List>
        {prefix && (
          <>
            {/* {canEditPrefix && addButtons(undefined, prefix)} */}
            {/* {canEditPrefix && <Divider />} */}
            {prefix.Messages.map((x) => {
              return (
                <List.Item>
                  <HistoryItem
                    item={x}
                    onEdit={
                      canEditPrefix
                        ? (item) => {
                            setEditorHolder(prefix);
                            setOpenEditor(x);
                          }
                        : undefined
                    }
                    onDelete={
                      canEditPrefix
                        ? (item) => {
                            prefix.deleteMessage(x);
                          }
                        : undefined
                    }
                  />
                  {/* {canEditPrefix && <Divider />}
                  {canEditPrefix && addButtons(x, prefix)} */}
                </List.Item>
              );
            })}
            <Divider />
          </>
        )}
        {canEdit && addButtons(undefined, history)}
        {canEdit && <Divider />}
        {history.Messages.map((x) => {
          return (
            <List.Item>
              <HistoryItem
                item={x}
                onEdit={
                  canEdit
                    ? (item) => {
                        setEditorHolder(history);
                        setOpenEditor(x);
                      }
                    : undefined
                }
                onDelete={
                  canEdit
                    ? (item) => {
                        history.deleteMessage(x);
                      }
                    : undefined
                }
              />
              {canEdit && <Divider />}
              {canEdit && addButtons(x, history)}
            </List.Item>
          );
        })}
      </List>
      {openEditor && (
        <EditHistoryItemModal
          item={openEditor}
          opened={true}
          onClose={() => setOpenEditor(undefined)}
          onSave={(item) => {
            editorHolder?.Replace(openEditor, item);
            setOpenEditor(undefined);
          }}
          functions={functions}
        />
      )}
    </div>
  );
});
