import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as S from "./styled";

function Modal({ width, isOpen, children, isDisabled, onClose, style = {} }: any) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const modal = (
    <S.Overlay onMouseDown={isDisabled ? null : onClose}>
      <S.Body style={{ width, ...style }} onMouseDown={(e) => e.stopPropagation()}>
        {children}
      </S.Body>
    </S.Overlay>
  );

  return ReactDOM.createPortal(isOpen ? modal : null, document.body);
}

export default Modal;
