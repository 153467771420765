import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button, Pagination, DropdownItemProps } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import DateSelector from "../ProfilerPanel/components/ProfilerWidget/components/DateSelector";
import { Link, useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";
import { OnlineProfilerTable } from "./OnlineProfilerTable";

const getColumnValue = (columnName: string, obj: any) => {
  const subcols = columnName.split(".");
  for (var c of subcols) {
    if (obj === undefined || obj === null) {
      break;
    }
    obj = obj[c];
  }
  return JSON.stringify(obj);
};

const isObject = function (a) {
  return !!a && a.constructor === Object;
};

const buildColumnListRecursive = (obj: any, target: Set<string>, root: string, depth: number) => {
  const columns = obj;
  for (const currentColumn of Object.keys(obj)) {
    const fullPath = root != "" ? `${root}.${currentColumn}` : currentColumn;
    target.add(fullPath);
    if (isObject(obj[currentColumn]) && depth + 1 < 2) {
      buildColumnListRecursive(obj[currentColumn], target, fullPath, depth + 1);
    }
  }
};

const availableStatuses: DropdownItemProps[] = [
  { text: "Failed", value: "Failed" },
  { text: "Completed", value: "Completed" },
];

export const OnlineProfiler: FC<{}> = observer(({}) => {
  const { account } = useStore();
  const { customerId } = useParams();
  const [applicationNames, setApplicationNames] = useState<{ key: string; text: string; value: string }[]>([]);
  const [taskStatuses, setTaskStatuses] = useState<string[]>([]);
  const [selectedApplicationNames, setSelectedApplicationNames] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ end: new Date(), start: new Date(new Date().setDate(1)) });
  const [filters, setFilters] = useState<dasha.filters.IFilter[]>([]);
  const [jobIds, setJobIds] = useState([]);
  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  useEffect(() => {
    const f: dasha.filters.IFilter[] = [
      new dasha.filters.StartedTimeFilter(
        dateRange.start ? new Date(dateRange.start.setHours(0, 0, 0, 0)) : undefined,
        dateRange.end ? new Date(dateRange.end.setHours(23, 59, 59, 999)) : undefined
      ),
    ];
    if (selectedApplicationNames.length > 0) {
      f.push(new dasha.filters.ApplicationNamesFilter(selectedApplicationNames));
    }
    if (customerId !== undefined) {
      f.push(new dasha.filters.CustomerIdsFilter([customerId]));
    }
    if (taskStatuses.length > 0) {
      f.push(new dasha.filters.JobStatusesFilter(taskStatuses));
    }
    setFilters(f);
  }, [customerId, selectedApplicationNames, dateRange, taskStatuses]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const f: dasha.filters.IFilter[] = [
        new dasha.filters.StartedTimeFilter(
          dateRange.start ? new Date(dateRange.start.setHours(0, 0, 0, 0)) : undefined,
          dateRange.end ? new Date(dateRange.end.setHours(23, 59, 59, 999)) : undefined
        ),
      ];

      const a = account.connect();
      if (selectedApplicationNames.length === 0) {
        try {
          const fillFilters = await dasha.onlineprofiler.getFilters(f, [new dasha.filters.ApplicationNamesFilter([])], {
            account: a,
          });
          setApplicationNames(
            (fillFilters[0] as dasha.filters.ApplicationNamesFilter).values.map((x) => ({ text: x, key: x, value: x }))
          );
        } catch (e) {
          console.error(e, "Failed to fetch filters");
        }
      }
    };
    fetchData().finally(() => setLoading(false));
  }, [account, dateRange]);

  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Detailed information</h2>
      <label htmlFor="date-range">Date range:</label>
      <DateSelector dateRange={dateRange} onChange={handleDateChange} />

      <h2>Applications</h2>
      <Dropdown
        placeholder="Applications"
        fluid
        multiple
        search
        selection
        options={applicationNames}
        value={selectedApplicationNames}
        onChange={(e, { value }) => setSelectedApplicationNames(value as string[])}
      />
      <OnlineProfilerTable
        filters={filters}
        download_name={
          selectedApplicationNames.join("_") +
          "_" +
          (dateRange.start?.toDateString() ?? "any") +
          "_" +
          (dateRange.end?.toDateString() ?? "any") +
          ".csv"
        }
        jobIds={jobIds}
        page_size={50}
      />
    </div>
  );
});
