import { FunctionDescription } from "@dasha.ai/sdk/web/rest-api/generated/core";

export interface FactDetails {
  skill: string;
  weight: string;
  likelihood: string;
  location: Location;
}

export interface NluIntentFact extends FactDetails {
  type: "Intent";
  intent: string | null;
  state: string;
  sentenceType: string;
}

export interface NluDataFact extends FactDetails {
  type: "Data";
  data: {
    value: string | null;
    tag: string | null;
  };
  factType: string;
}

export type NluFact = NluDataFact | NluIntentFact;

export interface Location {
  start: string;
  end: string;
}

export interface MsgSwitchState {
  msgId: "NodeSwitchNotificationMessage";
  to: string;
}

export interface MsgStateSwitch {
  msgId: "StateSwitch";
  message?: MsgRecognize;

  callingNodePath: string;
  returningNodePath: string;
  startingNodePath: string;
  continueNodePath: string;
  sourceNodePath: string;
  targetNodePath: string;
  transitionType: string;
}

export interface MsgRecognize {
  msgId: "RecognizedSpeechMessage";
  voiceSegmentId: number;
  time: string;
  __sourceMessageType__: "ConfidentTextChannelMessage" | "FinalTextChannelMessage" | "PotentialTextChannelMessage" | "EmulatedTextChannelMessage";
  results: [
    {
      text: string;
      facts?: NluFact[];
    }
  ];
}

export interface MsgRowText {
  msgId: "RawTextChannelMessage";
  textMsgId: "ConfidentTextChannelMessage" | "FinalTextChannelMessage" | "PotentialTextChannelMessage" | "EmulatedTextChannelMessage";
  time: string;
  text: string;
  phraseSequenceId: number | undefined;
  EndTime: string;
  StartTime: string;
  voiceSegmentId: number;
  Metadata?: {
    SourceName: string;
    SourceData: string;
  };
}

export interface LogMessage {
  msgId: "LogMessage";
  message: string;
  level: number;
}

export interface StoppedPlayingAudioChannelMessage {
  msgId: "StoppedPlayingAudioChannelMessage";
  StartPlayingTime: string;
  EndPlayingTime: string;
  TtsSynthStartTime: string | undefined;
  TtsSynthEndTime: string | undefined;
  PlayedText?: string;
  phraseSequenceId: number | undefined;
  routeId: string;
  Metadata?: {
    SourceName: string;
    SourceData: string;
    CorrelationId?: string;
  };
}

export interface GptFunctionCallRequestMessage {
  msgId: "GptFunctionCallRequestMessage";
  routeId?: string;
  InstanceId: string;
  FunctionName: string;
  Args: any;
  StartTime: string;
}

export interface GptStartMessage {
  msgId: "GptStartMessage";
  routeId: string;
  Prompt: string;
  StartTime: string;
  Options: { [key: string]: string; };
  InstanceId: string;
  Functions: { [key: string]: FunctionDescription;}
  PromptArguments: { [key: string]: string; };
  PromptName: string | undefined;
  CallName: string | undefined;
}

export interface GptResponseMessage {
  msgId: "GptResponseMessage";
  Message: string;
  InstanceId: string;
  routeId: string;
  Metadata: { Thinking: string | undefined };
}

export interface JobCommunicationDebugMessage {
  msgId: "JobCommunicationDebugMessage";
  content: {
    type: string;
    method: string;
    parameters: { [key: string]: string };
  };
}

export interface JobCommunicationRequestMessage {
  type: "request";
  method: string;
  parameters: { [key: string]: string };
}

export interface JobCommunicationResponseMessage {
  type: "response";
  result: string;
}

export interface JobCommunicationErrorMessage {
  type: "error";
  message: string;
}
export interface JobCommunicationMessage {
  msgId: "JobCommunicationMessage";
  content: JobCommunicationRequestMessage | JobCommunicationResponseMessage | JobCommunicationErrorMessage;
};

export interface EventUsageMessage {
  msgId: "EventUsageMessage";
  EventType: string;
  UsageType: string;
  UsageSubType: string;
  Usage: number;
}

export interface FailedOpenSessionChannelMessage {
  msgId: "FailedOpenSessionChannelMessage";
  reason: string;
  details: string;
}

export interface OpenedSessionChannelMessage {
  msgId: "OpenedSessionChannelMessage";
  recordId: string | undefined;
  routeId: string;
}

export interface GptFunctionCallResponseMessage {
  msgId: "GptFunctionCallResponseMessage";
  InstanceId: string;
  FunctionName: string;
  StartTime: string;
  EndTime: string;
  ReturnValue: any;
  routeId: string;
}

export interface SpeechChannelMessage {
  msgId: "SpeechChannelMessage";
  type: "Voice" | "Pause" | "PotentialPause" | "PauseBreak";
  voiceSegmentId: number;
}

export interface JobDataMessage {
  msgId: "JobDataMessage";
  inputData?: { [key: string]: any; }
  outputData: { [key: string]: any; }
  JobId: string;
}

export interface DTMFMessage {
  msgId: "ProtocolSpecificMessage";
  type: "dtmf";
  message: string;
}

export interface  JobAdditionalInformationMessage {
  msgId: "JobAdditionalInformationMessage",
  data: {
    tracking: { [key: string]: { channelType: string }}
  }
}
export type DevLogMessage = | MsgRowText
| MsgRecognize
| MsgSwitchState
| MsgStateSwitch
| StoppedPlayingAudioChannelMessage
| GptResponseMessage
| LogMessage
| GptFunctionCallRequestMessage
| JobCommunicationDebugMessage
| JobCommunicationMessage 
| EventUsageMessage
| FailedOpenSessionChannelMessage
| GptFunctionCallResponseMessage
| OpenedSessionChannelMessage
| GptStartMessage
| SpeechChannelMessage
| JobDataMessage
| DTMFMessage
| JobAdditionalInformationMessage;
export interface DevLog {
  time: string;
  incoming: boolean;
  msg: DevLogMessage;
}

export enum MarkupType {
  unmark,
  exclude,
  include,
}

export interface TriggerData {
  id: number;
  mark: MarkupType;
  probability: number | null;
  readOnly?: boolean;
  location: {
    start: number;
    end: number;
  };
}

export interface IntentTrigger extends TriggerData {
  type: "intent";
  name: string;
}

export interface EntityTrigger extends TriggerData {
  type: "entity";
  tag: string | null;
  hue: number | null;
  name: string;
  value: string | null;
  entityValue?: string | null;
  hovered?: boolean;
}

export type Trigger = IntentTrigger | EntityTrigger;
