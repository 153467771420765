class LocalStorage {
  set(key: string, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  get<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (value == null) return null;
    return JSON.parse(value);
  }

  remove(key: string) {
    return this.set(key, undefined);
  }

  clear() {
    localStorage.clear();
  }
}

export default new LocalStorage();
