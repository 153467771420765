import React, { FC } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import analytics from "./core/misc/analytics";
import { MenuOption, PopupMenu } from "./uikit/popup";
import { Icon } from "./uikit";

const QWidget = styled.div`
  z-index: 10;
  cursor: pointer;
  transition: 0.2s opacity;
  position: fixed;
  bottom: 24px;
  right: 24px;

  &:hover {
    opacity: 0.8;
  }
`;

const HelpWidget: FC<{ offset?: number }> = () => {
  const navigate = useNavigate();

  const actions: MenuOption[] = [
    {
      label: "Start Onboarding",
      action: () => 0,
      isEnable: true,
    },
    {
      isEnable: true,
      label: "Contact Support",
      action: () => {
        window.open("mailto:support+playground@dasha.ai");
      },
    },
    {
      isEnable: true,
      label: "Schedule Onboarding",
      action: () => {
        window.open("https://calendly.com/vlad-chernyshov/onboarding-session", "_blank");
      },
    },
    {
      isEnable: false,
      label: "Ask the Community",
      action: () => {
        analytics.track("OPEN_COMMUNITY");
        window.open("https://community.dasha.ai", "_blank");
      },
    },
    {
      label: "Watch Tutorial",
      action: () => 0,
      isEnable: true,
    },
    {
      label: "Open Documentation",
      action: () => window.open("https://docs.dasha.ai", "_blank"),
      isEnable: true,
    },
    {
      label: "Show Welcome Screen",
      action: () => navigate('/welcome'),
      isEnable: true,
    },
  ];

  return (
    <PopupMenu actions={actions} on="click" position="top right" arrow offsetY={8}>
      <QWidget>
        <Icon name="question" />
      </QWidget>
    </PopupMenu>
  );
};

export default HelpWidget;
