import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Message } from "../core/workspace/session-storage/types";
import { Tooltip } from "../uikit/popup";
import { ReactComponent as TransitionIcon } from "./transition.svg";

const SMenu = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const SContext = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: 1px solid #ffffff55;
  padding: 5px 8px;
  border-radius: 8px;
  margin-top: 6px;

  div {
    margin: 0;
  }
`;

const STrx = styled.div`
  transition: 0.2s color;
  color: rgb(255 255 255 / 82%);
  font-size: 14px;
  margin-top: 6px;
  z-index: 1;
  cursor: pointer;
  width: 100%;

  display: flex;
  align-items: center;
  line-break: anywhere;

  svg {
    margin: 3px 2px 0 4px;
    flex-shrink: 0;
  }

  span {
    white-space: nowrap;
    margin-right: 4px;
  }

  span:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    color: rgb(255 255 255 / 92%);
  }
`;

interface Props {
  message: Message;
  allowContext?: boolean;
  allowTransition?: boolean;
}

const DevMessage: FC<Props> = ({ message, allowContext, allowTransition }) => {
  const trx = message.transitions?.filter((trx) => trx.to != null) ?? [];
  const format = (name: string) => {
    if (name == null) return;
    return name
      .replace("node:", "")
      .split(":")
      .filter((v) => v)
      .join(":");
  };

  const context = Object.entries(message.changeContext ?? {}) ?? [];
  const position: React.CSSProperties = {
    textAlign: message.from === "ai" ? "left" : "right",
    justifyContent: message.from === "ai" ? "flex-start" : "flex-end",
  };

  const transitions = (
    <SMenu>
      {trx.map(({ to }, i) => (
        <span key={i}>{format(to)}</span>
      ))}
    </SMenu>
  );

  return (
    <>
      {context.length > 0 && allowContext && (
        <SContext style={position}>
          {context.map(([key, value]) => (
            <STrx key={key} style={{ display: "block" }}>
              <span>${key} =</span>
              {JSON.stringify(value)}
            </STrx>
          ))}
        </SContext>
      )}

      {trx.length >= 2 && allowTransition && (
        <Tooltip text={transitions} position={["top right", "bottom right"]} keepTooltipInside arrow={false}>
          <STrx style={position}>
            <span style={{ marginTop: 2, flexShrink: 0 }}>{`(${trx.length - 1})  `}</span>
            <TransitionIcon />
            <span>{format(trx[trx.length - 1].to)}</span>
          </STrx>
        </Tooltip>
      )}

      {trx.length === 1 && allowTransition && (
        <STrx style={position}>
          <TransitionIcon />
          <span>{format(trx[0].to)}</span>
        </STrx>
      )}
    </>
  );
};

export default observer(DevMessage);
