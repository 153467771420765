import styled from "styled-components";

export const Workspace = styled.div<{ $isResizing?: boolean }>`
  --gutter-size: 1px;

  display: grid;
  height: 100vh;
  transition: grid-template-columns 0.2s ease-out;

  ${props => props.$isResizing && `
    * {
      overflow: hidden !important;
      pointer-events: none;
    }
    .gutter {
      pointer-events: auto;
    }
  `}

  &.developer-mode {
    grid-template-columns: var(--files) 1px minmax(0, 1fr) 1px var(--runner);
    grid-template-rows: 64px minmax(0, 1fr) 1px var(--tools);
    grid-template-areas:
      "header header header header header"
      "files gutter1 editor gutter2 runner"
      "files gutter1 gutter3 gutter3 gutter3"
      "files gutter1 tools tools tools";
  }

  &.basic-mode {
    grid-template-columns: minmax(0, 1fr) 1px var(--runner);
    grid-template-rows: 64px minmax(0, 1fr) 1px var(--tools);
    grid-template-areas:
      "header header header"
      "editor gutter2 runner"
      "gutter3 gutter3 gutter3"
      "tools tools tools";

    #files, #gutter1 {
      display: none;
    }
  }

  #files { grid-area: files; }
  #header { 
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
  #editor { grid-area: editor; }
  #runner { grid-area: runner; }
  #tools { grid-area: tools; }
`;
