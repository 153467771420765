import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement, RawElement } from "../common";
import { GroupCommandStatement } from "../do-section-statements";

export class OnExitNamedDescription<T extends string>
  extends DslElement
  implements interfaces.IOnExitNamedDescription<T>
{
  elementType: DslElementType.OnExitNamedDescription = DslElementType.OnExitNamedDescription;
  name: RawElement<T>;
  onConfident: interfaces.OptionalValue<GroupCommandStatement>;
  doSection: GroupCommandStatement;

  constructor(obj: interfaces.IOnExitNamedDescription<T>, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<T>);
    this.doSection = helpers.ensureIsInstance(obj.doSection, GroupCommandStatement);
    this.onConfident = helpers.ensureIsOptionalInstance(obj.onConfident, GroupCommandStatement);
  }
  toJson(): interfaces.IOnExitNamedDescription<T> {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      doSection: this.doSection.toJson(),
      onConfident: interfaces.helpers.createOptionalValue(this.onConfident.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        helpers.indentString(indent) + `${this.name.value}: `,
        helpers.indentString(indent) + `do`,
        `${this.doSection.toDsl(indent)}`,
        this.onConfident.hasValue
          ? helpers.indentString(indent) + `on condident ${this.onConfident.value.toDsl(indent)}`
          : null,
      ],
      "\n"
    );
  }
}
