import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DoStatement } from "./DoStatement";

export class ExitStatement extends DoStatement implements interfaces.IExitStatement {
  elementType: DslElementType.ExitStatement = DslElementType.ExitStatement;

  constructor(obj: interfaces.IExitStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
  }
  toJson(): interfaces.IExitStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
    };
  }
  toDsl(indent: number): string {
    return [helpers.indentString(indent), "exit;"].join("");
  }
}
