import styled, { css } from "styled-components";

const leftArrow = css`
  right: -10px;
  border-left: 20px solid #252536;
`;

const rightArrow = css`
  left: -10px;
  border-right: 20px #252536;
`;

export const Tooltip = styled.div`
  background: #252536;
  border: 1px solid #161729;
  border-radius: 6px;

  user-select: none;
  pointer-events: auto;
  position: absolute;
  min-width: 200px;
  max-width: 340px;
  max-height: 95vh;
  padding: 10px;
  padding-right: 0;
  transition: 0.5s opacity;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Arrow = styled.div<{ dir: "left" | "right" }>`
  width: 0;
  height: 0;
  position: absolute;
  margin-top: -20px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;

  ${({ dir }) => dir === "left" && leftArrow}
  ${({ dir }) => dir === "right" && rightArrow}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

export const Property = styled.div`
  max-width: 150px;
  opacity: 0.4;
  font-size: 13px;
  line-height: 1.23;
  text-align: left;
  color: rgba(255, 255, 255, 0.75);
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
`;

export const Value = styled.div`
  font-size: 13px;
  line-height: 1.23;
  white-space: pre-wrap;
  line-break: anywhere;
  word-break: break-all;
  text-align: right;
  color: #fff;

  svg {
    transform: scale(0.6);
    margin: -12px;
    margin-right: -4px;
    margin-left: -7px;
  }
`;
