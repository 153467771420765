import React, { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import * as S from "./styled";

export default memo<NodeProps<any>>(({ data, selected, isConnectable }) => {
  return (
    <S.Node className="drag-handle" style={{ width: 140 }} isSelected={selected || data.highlight}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
      <S.Label>FINISH DIALOG</S.Label>
    </S.Node>
  );
});
