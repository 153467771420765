import React, { FC, useEffect, useState } from "react";

import { IAccount } from "../core/account/interface";
import "./styles.css";
import { Link } from "react-router-dom";
import { CopyInput } from "../uikit/CopyInput";
import { observable } from "mobx";
import { useStore } from "@core/api/GlobalStoreContext";
import { MockAccount } from "@core/account/MockAccount";

export const ApiKey: FC<{ }> = () => {
  const { account } = useStore();
  const [apiKey, setApiKey] = useState("");
  const [server, setServer] = useState("");

  useEffect(() => {
    const a = account.connect();
    setApiKey(a.apiKey);
    setServer(a.server);
  }, [account]);
  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>API Key</h2>
      {
        <>
          <h3>Server</h3>
          <CopyInput clipboard={server} loading={false} tooltipText="Server" type="text" />
          <h3>API Key</h3>
          <CopyInput clipboard={apiKey} loading={false} tooltipText="API Key" type="password" />
          <h3>Environment variables configuration (better for server/docker usage)</h3>
          <CopyInput
            clipboard={`export DASHA_SERVER=${server}\r\nexport DASHA_APIKEY=${apiKey}\r\n`}
            loading={false}
            tooltipText="Environment"
            type="password"
          />
          <h3>
            <Link to="https://www.npmjs.com/package/@dasha.ai/cli">dasha-cli</Link> command for configuration (better
            for local usage)
          </h3>
          <CopyInput
            clipboard={`dasha account add -f --server ${server} --api-key "${apiKey}" prod\r\ndasha account use prod\r\n`}
            loading={false}
            tooltipText="Cli"
            type="password"
          />
        </>
      }
    </div>
  );
};
