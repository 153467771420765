import { ANY_INTENT, ContextVariable, SENTIMENT_NEGATIVE, SENTIMENT_POSITIVE } from "./DSLEditor";

export interface SimpleNodeProperties {
  id: string;
  sayText?: string;
  instantNode?: string;
  externalFunction?: string;
  fromBlock?: boolean;
  isFinish?: boolean;
  speed?: number;

  intents: string[];
  entities: string[];

  transitions: TransitionProperties[];
  setContexts: OperationSetContext[];

  interruptible?: {
    delay?: number;
    triggers: string[];
  };
}

export interface OperationSetContext {
  value: string;
  key: string;
}

export type SentimentType = "positive" | "negative";

export interface TransitionProperties {
  name: string;
  nextNode: string;
  intent?: string;
  isEntity?: boolean;
  sentiment?: SentimentType;
  priority: number;
  anyPhrase?: boolean;
  setContexts?: OperationSetContext[];
  isFinish?: boolean;

  timeoutTrigger?: number;
}

export interface OperationCondition {
  id: string;
  true: string;
  false: string;
  condition: string;
}

const interpolate = (text: string) =>
  text // @ts-ignore
    .replaceAll(/(\$\w+)|(@returned)|(@intent)|(@phrase)/g, (tag) => {
      if (tag.startsWith("$")) return `" + $data.ctx.${tag.slice(1)} + "`;
      if (tag === "@intent") return `" + ($data.intent ?? "") + "`;
      if (tag === "@sentence") return `" + ($data.sentence ?? "") + "`;
      if (tag === "@phrase") return `" + ($data.phrase ?? "") + "`;
    })
    .replaceAll("\n", "");

const generateCondition = (transition: TransitionProperties) => {
  if (transition.timeoutTrigger) {
    return `on timeout ${transition.timeoutTrigger};`;
  }

  let condition = "true";
  if (transition.sentiment != null) {
    condition = `#messageHasSentiment("${transition.sentiment}")`;
  } else if (transition.intent != null) {
    condition = transition.isEntity
      ? `#messageHasData("${transition.intent}")`
      : `#messageHasIntent("${transition.intent}")`;
  }

  return `on ${condition} priority ${transition.priority};`;
};

const generateTransition = (transition: TransitionProperties) => {
  return `${transition.name}: goto ${transition.nextNode} ${generateCondition(transition)}`;
};

const generateTransitionExit = (transition: TransitionProperties) => {
  return `
        ${transition.name}: do {
            ${generateTransitionSetters(transition)}
        }
    `;
};

const generateTransitionSetters = (transition: TransitionProperties) => {
  if (transition.intent == null && transition.anyPhrase == false)
    return `
        ${transition.name}: do {
            set $data.intent = null;
            set $data.sentence = null;
            set $data.phrase = null;
        }
    `;

  if (transition.timeoutTrigger != null) {
    return ``;
  }

  let intentExtractor = "set $data.intent = null;";
  if (transition.isEntity) {
    intentExtractor = `set $data.intent = #messageGetData("${transition.intent}", { value: true })[0]?.value;`;
  }

  return `
        ${intentExtractor}
        set $data.sentence = #getSentenceType();
        set $data.phrase = #getMessageText();
        ${transition.setContexts?.map(operationSetContext).join("\n") ?? ""}
        ${transition.isFinish ? "exit;" : ""}
    `;
};

export const generateConditionalNode = (op: OperationCondition) => {
  return `
        node ${op.id} {
            do {
                if (${op.condition}) {
                    goto ${op.true};
                } else {
                    goto ${op.false};
                }
            }

            transitions {
                ${op.true}: goto ${op.true};
                ${op.false}: goto ${op.false};
            }
        }
    `;
};

export const operationSetContext = (op: OperationSetContext) => {
  return `set $data.ctx.${op.key} = "${interpolate(op.value)}";`;
};

export const operationCallExternal = (name: string) => {
  return `set $data.ctx = external func_${name}(${buildExternalCall});`;
};

export const generateSimpleNode = (props: SimpleNodeProperties) => {
  const options = [`speed: ${props.speed ?? 1}`];
  const interruptible = `interruptible: ${props.interruptible ? "true" : "false"}`;

  if (props.interruptible != null) {
    const conditions =
      props.interruptible?.triggers.map((trigger) => {
        if (trigger === ANY_INTENT) return null;
        if (trigger === SENTIMENT_NEGATIVE) return { sentiment: "negative" };
        if (trigger === SENTIMENT_POSITIVE) return { sentiment: "positive" };
        if (props.entities.includes(trigger)) return { entity: trigger };
        if (props.intents.includes(trigger)) return { intent: trigger };
        return null;
      }) ?? [];

    const json = JSON.stringify(conditions.filter((v) => v));
    const unquotedConditions = json.replace(/"([^"]+)":/g, "$1:");
    if (unquotedConditions === "[]") {
      options.push(`interruptDelay: ${(props.interruptible.delay ?? 100) / 1000}`);
    } else {
      options.push(`interruptConditions: ${unquotedConditions}`);
    }
  }

  const serializedOptions = `options: { ${options.join(",")} }`;

  return `
node ${props.id} {
    do {
        ${props.sayText ? `#sayText("${interpolate(props.sayText)}", ${interruptible}, ${serializedOptions});` : ""}
        ${props.externalFunction ? operationCallExternal(props.id) : ""}
        ${props.setContexts.map(operationSetContext).join("\n")}
        ${props.isFinish ? "exit;" : ""}
        ${
          props.instantNode
            ? "goto instantTransition;"
            : props.transitions.length
            ? "wait *;"
            : props.fromBlock
            ? `return ${buildExternalCall};`
            : "exit;"
        }
    }

    transitions {
        ${props.instantNode ? `instantTransition: goto ${props.instantNode};` : ""}
        ${props.transitions.map(generateTransition).join("\n")}
    }
    
    onexit {
        ${props.transitions.map(generateTransitionExit).join("\n")}
    }
}
`;
};

export const buildExternalCall = `{
    intent: $data.intent,
    phrase: $data.phrase,
    sentence: $data.sentence,
    ctx: $data.ctx
}`;

export const generateDigression = (props: TransitionProperties, blockNodes: string[]) => `
digression ${props.name} {
    conditions { ${generateCondition(props)} }
    do {
        ${generateTransitionSetters(props)}
        set $data = blockcall digressionBlock_${props.name}(${buildExternalCall});
        return;
    }
}

block digressionBlock_${props.name}(data: ExternalCall): ExternalCall {
    start ${blockNodes.join("\n")}

    node terminateNode {
        do { return ${buildExternalCall}; }
    }
}
`;

export const generateCtx = (context: ContextVariable[]) => {
  const types = context.map((variable) => `${variable.key}: string;`).join("");
  const declaration = context.map((variable) => `${variable.key}: "${variable.value}"`).join();
  return { types, declaration };
};

export const generateRoot = (context: ContextVariable[], externals: string[], rootId) => {
  const { types, declaration } = generateCtx(context);

  return `
type ContextType = { ${types} };

type ExternalCall = {
    intent: string?;
    phrase: string?;
    sentence: string?;
    ctx: ContextType;
};

context {
    input endpoint: string;
    output data: ExternalCall = {
        intent: null,
        phrase: null,
        sentence: null,
        ctx: { ${declaration} }
    };
}

${externals.map(generateDefinition).join("\n")}

start node rootNode {
    do {
        #connectSafe($endpoint);
        #waitForSpeech(200);
        goto instantNode;
    }

    transitions {
        instantNode: goto ${rootId};
    }
}

node terminateNode {
    do { exit; }
}
`;
};

// TODO: Make type checking smart
export const generatFunction = (id: string, snippet: string) => `
app.setExternal("func_${id}", async ({
    data: {
        intent: $intent, 
        phrase: $phrase, 
        sentence: $sentence,
        ctx: $ctx
    } 
}, conv) => {
    await (async () => { ${snippet} })()
    return $ctx
})
`;

export const generateAppInit = () => `
const output = await sandbox.execute({
	endpoint: sandbox.endpoint
});
		
console.log(output);
`;

export const generateDefinition = (name: string) => `
external function func_${name}(data: ExternalCall): ContextType;
`;
