import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import debounce from "lodash/debounce";

import Project from "../../explorer/Project";
import { IDisposable } from "../../misc/emitter";

import TextStorage from "../TextStorage";
import DatasetStorage from "../dataset-storage/DatasetStorage";
import PhrasemapStorage from "../phrasemap-storage/PhrasemapStorage";
import SimpleTtsSpeaker from "../../SimpleTtsSpeaker";

import { CompletionItemProvider } from "./CompletionItemProvider";
import PlayPhrasePlugin, { ParseSynthParamsFunctionSignature } from "../plugins/PlayPhrasePlugin";
import StopTtsSpeakerPlugin from "../plugins/StopTtsSpeakerPlugin";
import { WorkerApi } from "../../dsl/worker/api";
import { FSStructure } from "@core/account/filesystem";
import GraphService from "./GraphService";

interface Options {
  phrasemap?: PhrasemapStorage;
  dataset?: DatasetStorage;
  project: Project;
  path: string;
}

class DslStorage extends TextStorage {
  phrasemap?: PhrasemapStorage;
  dataset?: DatasetStorage;

  private sayTextPlayPhrasePlugin: PlayPhrasePlugin | null = null;
  private stopTtsSpeakerPlugin: StopTtsSpeakerPlugin | null = null;
  private completionItemProvider: CompletionItemProvider | null = null;

  private completionItemDisposer: IDisposable;

  private dslWorker = WorkerApi.Instance;
  private debounceUpdateAndValidate = debounce(() => this.updateAndValidate(), 300);

  readonly graphService = new GraphService();

  constructor(options: Options) {
    super(options.project, options.path);
    this.isPinned = true;

    this.phrasemap = options.phrasemap;
    this.dataset = options.dataset;

    try {
      const speaker = SimpleTtsSpeaker.Instance;
      this.sayTextPlayPhrasePlugin = new PlayPhrasePlugin(speaker, this.tryParseSayTextSynthParams);
      this.stopTtsSpeakerPlugin = new StopTtsSpeakerPlugin(speaker);
    } catch (e) {
      console.log(`Could not initialize playPhrase plugin in DslStorage: ${e.message}`);
    }

    this.model.onDidChangeContent(async () => {
      void this.debounceUpdateAndValidate();
    });
  }

  async getDslFiles(files: FSStructure[], raw: Record<string, string> = {}) {
    const promises = files.map(async (file) => {
      if (file.type === "file" && file.path.split(".").pop() === "dsl") {
        raw[file.path] = await this.project.file(file.path);
      }

      if (file.type === "folder") {
        await this.getDslFiles(file.files, raw);
      }
    });

    await Promise.all(promises);
    return raw;
  }

  async syncGraphService() {
    const files = await this.getDslFiles(this.project.files);
    this.graphService.updateFiles(this.path, files);
  }

  openInEditor(editor: monaco.editor.IStandaloneCodeEditor): void {
    super.openInEditor(editor);
    this.completionItemProvider = new CompletionItemProvider(this, editor);
    this.completionItemDisposer = monaco.languages.registerCompletionItemProvider("dsl", this.completionItemProvider);
    this.sayTextPlayPhrasePlugin?.activate(editor);
    this.stopTtsSpeakerPlugin?.activate(editor);
  }

  closeInEditor(editor: monaco.editor.ICodeEditor): void {
    super.closeInEditor(editor);
    this.completionItemDisposer.dispose();
    this.sayTextPlayPhrasePlugin?.dispose();
    this.stopTtsSpeakerPlugin?.dispose();
  }

  private tryParseSayTextSynthParams: ParseSynthParamsFunctionSignature = async (position: monaco.Position) => {
    await this.debounceUpdateAndValidate.flush();
    return await WorkerApi.Instance.tryParseSayTextSynthParams(this.path, position);
  };

  private async updateAndValidate() {
    await this.dslWorker.updateModel(this.path, this.model.getValue());
    console.log("Updated model in worker");
    const markers = await this.dslWorker.validateModel(this.path);
    if (markers) {
      monaco.editor.setModelMarkers(this.model, "error", markers);
    }
  }
}

export default DslStorage;
