import styled, { css } from "styled-components";
import { PureButton } from "../Button";

export const Container = styled.div<{ $position?: string }>`
  background: #1d1e31;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  padding: 4px 0;
  position: absolute;
  box-sizing: border-box;

  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  min-width: 160px;
  ${(p) =>
    p.$position === "bottom"
      ? css`
          bottom: calc(100% + 4px);
        `
      : css`
          top: 24px;
        `}
  left: -1px;

  z-index: 100;
`;

export const Field = styled.div<{ isInvalid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  background: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  border: 1px solid ${(p) => (p.isInvalid ? "red" : "#5855f4")};

  height: 20px;
  padding: 0 4px;

  box-sizing: border-box;
  min-width: 1em;
  width: min-content;

  > button {
    margin-left: 4px;
  }

  span {
    visibility: hidden;
    white-space: pre;
  }

  input {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;

    position: absolute;
    left: 4px;
  }

  span,
  input {
    font-family: var(--main-font);
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    max-width: 138px;
  }
`;

export const Item = styled(PureButton)`
  background: transparent;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
  padding: 1px 4px;
  width: 100%;
  margin: 0;

  text-transform: none;
  font-family: var(--main-font);
  justify-content: flex-start;

  &:hover {
    background: rgba(255, 255, 255, 0.12);
  }
`;

export const List = styled.div``;

export const SubListLabel = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  padding: 2px;

  button:first-child {
    background: none;
    border: none;
    outline: none;
    transform: rotate(${(p) => (p.isOpen ? "90deg" : "0")});
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: 12px;
  }

  button:last-child {
    padding-left: 0;
  }
`;

export const SubList = styled.div`
  margin-left: 7.5px;
  border-left: 1px solid rgba(255, 255, 255, 0.06);

  button {
    padding-left: 5.5px;
  }
`;
