export const ceilDate = (_date: Date): Date => {
  return new Date(Math.min(+_date, Date.now()));
};

export const getMaxDate = (): Date => new Date();

export const getMinToday = () => {
  const date = getMaxDate();

  return new Date(date.setHours(date.getHours() - 1));
};

export const isToday = (date: Date) => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

// formatting
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const zeroFormat = (v: number) => (v < 10 ? `0${v}` : v);

export const getDateWithTime = (date: Date, time = "00:00"): Date => {
  const [hh, mm] = time.split(":");

  return new Date(date.setHours(+hh, +mm, 0));
};

export const formatTime = (date: Date): string => {
  const hours = zeroFormat(date.getHours());
  const munites = zeroFormat(date.getMinutes());

  return `${hours}:${munites}`;
};

/**
 * Creates a date string with a format 'Jan 01'
 * @param date {Date}
 */
export const formatDate = (date: Date): string => {
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day} ${formatTime(date)}`;
};

/**
 * Creates a date range string with a format 'Jan 01 - Jan 02'
 * @param start {Date | null} - A start point of a range
 * @param end {Date | null} - An end point of a range
 */
export const formatDateRange = (start: Date | null, end: Date | null): string => {
  const startString = start === null ? " " : formatDate(start);
  const endString = end === null ? " " : formatDate(end);

  if (!start && !end) {
    return "";
  }

  return `${startString} - ${endString}`;
};
