import { observer } from "mobx-react-lite";
import { IHistoryMessage } from "../../../../../sdk/web/gpt";
import { FC } from "react";
import MarkableMessage from "../../../core/workspace/session-storage/MarkableMessage";
import ClientMessage from "../../../RunnerPanel/ClientMessage";
import DashaMessage from "../../../RunnerPanel/DashaMessage";
import React from "react";
import { Icon } from "../../Icon";

export const HistoryItem: FC<{
  item: IHistoryMessage;
  onEdit?: (item: IHistoryMessage) => void;
  onDelete?: (item: IHistoryMessage) => void;
}> = observer(({ item, onEdit, onDelete }) => {
  switch (item.type) {
    case "Text":
      if (item.source === 1) {
        const markable = new MarkableMessage({
          from: "human",
          isSystem: false,
          message: item.text,
          thinking: undefined,
          id: "",
          time: 0,
          actions: (
            <div style={{marginRight: "auto"}}>
              {onEdit!==undefined && <Icon style={{ margin: "3px", cursor: "pointer" }} name="edit" onClick={()=>onEdit(item)}/>}
              {onDelete!==undefined && <Icon style={{ margin: "3px", cursor: "pointer" }} name="trash" onClick={()=>onDelete(item)} />}
            </div>
          ),          
        });
        return (
          <ClientMessage
            onTrigger={() => {}}
            onCommit={() => {}}
            onEdit={() => {}}
            message={markable}
            editable={false}
            selected={false}
          />
        );
      }
      return (
        <DashaMessage
          msg={{
            from: "ai",
            message: item.text,
            thinking: item.thinking,
            time: 0,
            id: 0,
            isSystem: false,
            actions: (
              <div style={{marginRight: "auto"}}>
                {onEdit!==undefined && <Icon style={{ margin: "3px", cursor: "pointer" }} name="edit" onClick={()=>onEdit(item)}/>}
                {onDelete!==undefined && <Icon style={{ margin: "3px", cursor: "pointer" }} name="trash" onClick={()=>onDelete(item)} />}
              </div>
            ),
          }}
        />
      );
    case "callResponse": 
      return (
        <>
        <DashaMessage
          msg={{
            from: "ai",
            message: `Start call ${item.request.functionCallName}(${JSON.stringify(item.request.functionCallArgs)})`,
            thinking: undefined,
            time: 0,
            id: 0,
            isSystem: true,
            actions: (
              <div style={{marginRight: "auto"}}>
                {onEdit!==undefined && <Icon style={{ margin: "3px", cursor: "pointer"}} name="edit" onClick={()=>onEdit(item)}/>}
                {onDelete!==undefined && <Icon style={{ margin: "3px", cursor: "pointer"}} name="trash" onClick={()=>onDelete(item)} />}
              </div>
            ),
          }}
        />

        <DashaMessage
          msg={{
            from: "ai",
            message: `Finish call = ${JSON.stringify(item.functionCallResult)}`,
            thinking: undefined,
            time: 0,
            id: 0,
            isSystem: true,
          }}
        />
        
        </>);
    case "call":
      return (
        <DashaMessage
          msg={{
            from: "ai",
            message: `Start call ${item.functionCallName}(${JSON.stringify(item.functionCallArgs)})`,
            thinking: undefined,
            time: 0,
            id: 0,
            isSystem: true,
            actions: (
              <div style={{marginRight: "auto"}}>
                {onEdit!==undefined && <Icon style={{ margin: "3px", cursor: "pointer"}} name="edit" onClick={()=>onEdit(item)}/>}
                {onDelete!==undefined && <Icon style={{ margin: "3px", cursor: "pointer"}} name="trash" onClick={()=>onDelete(item)} />}
              </div>
            ),
          }}
        />
      );
    case "Warning":
      return (
        <DashaMessage
          msg={{
            from: "ai",
            transitions: [],
            changeContext: {},
            triggers: [],
            message: item.message,
            thinking: undefined,
            time: 0,
            id: 0,
            isSystem: true,
          }}
        />
      );
    default:
      return <div>Unexpected message {item.type}</div>;
  }
});
