import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button } from "semantic-ui-react";
import "./styles.css";
import { OutboundConfig } from "@dasha.ai/sdk/web/sip/outbound-configs";
import { Loader } from "semantic-ui-react";
import { createDownloadLink } from "@core/profiler/utils";
import DateSelector from "../ProfilerPanel/components/ProfilerWidget/components/DateSelector";
import { useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";

const columns = [
  {
    key: "date",
    text: "Date",
    value: "date",
  },
  {
    key: "applicationId",
    text: "Application Id",
    value: "applicationId",
  },
  {
    key: "applicationName",
    text: "Application Name",
    value: "applicationName",
  },
  {
    key: "groupName",
    text: "Group Name",
    value: "groupName",
  },
  {
    key: "eventType",
    text: "Event Type",
    value: "eventType",
  },
  {
    key: "usageType",
    text: "Usage Type",
    value: "usageType",
  },
  {
    key: "usageSubType",
    text: "Usage Sub Type",
    value: "usageSubType",
  },
];

const eventTypesAllowed = [
  "JobSessionDuration:Audio",
  "GPT",
  "STT",
  "TTS",
  "JobSessionDuration:Chat",
  "JobChatMessage",
  "JobMultiplySessionDuration:Audio",
  "JobMultiplySessionDuration:Chat",
  "VoIP",
];
export const UsagePanel: FC<{}> = ({}) => {
  const { account } = useStore();
  const { customerId } = useParams();
  const [usageRows, setUsageRows] = useState<dasha.usage.BillableUsageEntity[]>([]);
  const [aggregatedRows, setAggregatedRows] = useState<dasha.usage.BillableUsageEntity[]>([]);
  const [applicationNames, setApplicationNames] = useState<{ text: string; key: string; value: string }[]>([]);

  const [eventTypes, setEventTypes] = useState<{ text: string; key: string; value: string }[]>(
    eventTypesAllowed.map((x) => ({ key: x, text: x, value: x }))
  );
  const [usageTypes, setUsageTypes] = useState<{ text: string; key: string; value: string }[]>([]);
  const [usageSubTypes, setUsageSubTypes] = useState<{ text: string; key: string; value: string }[]>([]);

  const [selectedApplicationNames, setSelectedApplicationNames] = useState<string[]>([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
  const [selectedUsageTypes, setSelectedUsageTypes] = useState<string[]>([]);
  const [selectedUsageSubTypes, setSelectedUsageSubTypes] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ end: new Date(), start: new Date(new Date().setDate(1)) });

  const [selectedColumns, setSelectedColumns] = useState([
    "date",
    "applicationName",
    "eventType",
    "usageType",
    "usageSubType",
  ]);

  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const a = account.connect();
      const fillFilters = await dasha.usage.getUsageFilters(
        [new dasha.filters.DateRangeFilter(dateRange.start, dateRange.end)],
        [
          new dasha.filters.ApplicationNamesFilter([]),
          new dasha.filters.UsageTypesFilter([]),
          new dasha.filters.UsageSubTypesFilter([]),
        ],
        {
          account: a,
        }
      );
      setApplicationNames(
        (fillFilters[0] as dasha.filters.ApplicationNamesFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );

      setUsageTypes(
        (fillFilters[1] as dasha.filters.UsageTypesFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );
      setUsageSubTypes(
        (fillFilters[2] as dasha.filters.UsageSubTypesFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );
    };
    fetchData().finally(() => setLoading(false));
  }, [account, dateRange, customerId]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const a = account.connect();
      const filters: dasha.filters.IFilter[] = [new dasha.filters.DateRangeFilter(dateRange.start, dateRange.end)];
      if (selectedEventTypes.length > 0) {
        filters.push(new dasha.filters.EventTypeFilter(selectedEventTypes));
      } else {
        filters.push(new dasha.filters.EventTypeFilter(eventTypesAllowed));
      }

      if (selectedUsageTypes.length > 0) {
        filters.push(new dasha.filters.UsageTypesFilter(selectedUsageTypes));
      }

      if (selectedUsageSubTypes.length > 0) {
        filters.push(new dasha.filters.UsageSubTypesFilter(selectedUsageSubTypes));
      }

      if (selectedApplicationNames.length > 0) {
        filters.push(new dasha.filters.ApplicationNamesFilter(selectedApplicationNames));
      }

      if (customerId !== undefined) {
        filters.push(new dasha.filters.CustomerIdsFilter([customerId]));
      }

      const result: dasha.usage.BillableUsageEntity[] = [];
      let fetchedRows = 0;
      let idx = 0;
      do {
        const c = await dasha.usage.getUsage(1000, idx, filters, { account: a });
        fetchedRows = c.length;
        idx += fetchedRows;
        c.forEach((element) => {
          result.push(element);
        });
      } while (fetchedRows === 1000);

      setUsageRows(result);
    };
    fetchData().finally(() => setLoading(false));
  }, [
    account,
    dateRange,
    selectedApplicationNames,
    selectedEventTypes,
    selectedUsageTypes,
    selectedUsageSubTypes,
    customerId,
  ]);

  const exportToCsv = useCallback(() => {
    const rows = [];
    const headerRow = selectedColumns.map((c) => columns.filter((x) => x.value === c)[0].text);
    headerRow.push("Billable Usage");
    headerRow.push("Count");
    rows.push(headerRow);
    for (const row of aggregatedRows) {
      const r = [];
      for (const c of selectedColumns) {
        r.push(row[c]);
      }
      r.push(row.billableUsage);
      r.push(row.count);
      rows.push(r);
    }
    const csvData = rows.map((r) => r.join(";")).join("\n");
    createDownloadLink([csvData], "text/csv", "usage.csv");
  }, [account, aggregatedRows, selectedColumns]);
  useEffect(() => {
    setLoading(true);
    setAggregatedRows(dasha.usage.aggregateUsage(usageRows, new Set<string>(selectedColumns)));
    setLoading(false);
  }, [selectedColumns, usageRows]);
  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Usage</h2>
      <label htmlFor="date-range">Date range:</label>
      <DateSelector dateRange={dateRange} onChange={handleDateChange} />

      <h2>Filters</h2>
      <div>
        <Dropdown
          placeholder="Applications"
          fluid
          multiple
          search
          selection
          options={applicationNames}
          value={selectedApplicationNames}
          onChange={(e, { value }) => setSelectedApplicationNames(value)}
        />
        <Dropdown
          placeholder="Event type"
          fluid
          multiple
          search
          selection
          options={eventTypes}
          value={selectedEventTypes}
          onChange={(e, { value }) => setSelectedEventTypes(value)}
        />
        <Dropdown
          placeholder="Usage type"
          fluid
          multiple
          search
          selection
          options={usageTypes}
          value={selectedUsageTypes}
          onChange={(e, { value }) => setSelectedUsageTypes(value)}
        />
        <Dropdown
          placeholder="Usage subtype"
          fluid
          multiple
          search
          selection
          options={usageSubTypes}
          value={selectedUsageSubTypes}
          onChange={(e, { value }) => setSelectedUsageSubTypes(value)}
        />
      </div>
      <h2>Columns</h2>
      <Dropdown
        placeholder="Columns"
        fluid
        multiple
        search
        selection
        options={columns}
        value={selectedColumns}
        onChange={(e, { value }) => setSelectedColumns(value)}
      />
      <Loader active={loading}>Loading..</Loader>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {selectedColumns.map((c) => (
              <Table.HeaderCell>{columns.filter((x) => x.value === c)[0].text}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>Billable Usage</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(aggregatedRows).map(([k, c]) => {
            return (
              <Table.Row>
                {selectedColumns.map((col) => (
                  <Table.Cell>{c[col]}</Table.Cell>
                ))}
                <Table.Cell> {c.billableUsage} </Table.Cell>
                <Table.Cell> {c.count} </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Button onClick={exportToCsv}>Download CSV</Button>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};
