import styled from "styled-components";
import { PureButton } from "../../uikit/Button";

export const ActionMenu = styled(PureButton)`
  position: absolute;
  top: 20px;
  right: 16px;

  &::before {
    content: "";
    background: #1e1f33;
    filter: blur(5px);
    width: 30px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ProjectCard = styled.div`
  background: #1e1f33;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.2s border;
  min-height: 240px;
  border: 1px solid transparent;
  position: relative;

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    color: #eceff8;
    margin: 0;
  }

  ${ActionMenu} {
    transition: 0.2s opacity;
    opacity: 0;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.3);
    ${ActionMenu} {
      opacity: 1;
    }
  }
`;

export const Description = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #d9d9e2;
  margin: 0;
  margin-bottom: 12px;
`;

export const Time = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

export const Tags = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  padding-top: 12px;
  margin-top: auto;
  display: flex;
  gap: 8px;
`;
