import { NluResponseContainer, TransitionInformation, TransitionKey, NluFact, TypeOfSttResponse } from "./model/stuff";
import {
  Decision,
  Reason,
  UnknownReason,
  SessionToDecision,
  Cluster,
  Session,
  NlgResponseWithSessionUserAction,
  TransitionUserAction,
  ReasonWithSessionUserAction,
  ReasonUserAction,
  SttOrNlgResponse,
  ApplicationInformation,
} from "./model/models";
import { Transition } from "@core/workspace/session-storage/types";

export interface SessionFilterRequest {
  startedTime?: {
    start: Date;
    end: Date;
  };
  completedTime?: {
    start: Date;
    end: Date;
  };
  labels?: { [x in string]: string };
}

export interface ReasonUserActionSetRequest {
  reasonId: string;
  fact: NluFact;
  type: "FalsePositive" | "FalseNegative";
}

export interface NluSttReason {
  id: string;
  nluResponseId: string;
  sttResponseId: string;
  sttResponse: string; //recognized phrase from interlocutor
  nluResponse: NluResponseContainer; //understanding phrase from interlocutor
  clusterId?: string; //unique identifier of cluster
}

export enum NluField {
  Skill = "skill",
  Weight = "weight",
  Likelihood = "likelihood",
  Intent = "intent",
  State = "state",
  SentenceType = "sentenceType",
  Data = "data",
  FactType = "factType",
}

export interface MessageLogProfiled {
  time: string;
  incoming: boolean;
  msg: string;
  voiceSegmentId: number | null;
  nlgResponseId?: string;
  reasonId?: string;
  sttResponseId?: string;
  typeofSpeech?: TypeOfSttResponse;
  transition: Transition | undefined;
}

export type ClusterWithReasonIdAndSttId = Cluster & { reasonId: string; sttResponseId: string };

export interface Profiler {
  getApplications(): Promise<ApplicationInformation[]>

  //#region set filtered context
  setFilteredContext(filter?: SessionFilterRequest): Promise<void>;
  //#endregion

  getLabelsDictionary(): Promise<Record<string, string[]>>;

  //#region get min or max specified time
  getMinStartedTime(): Promise<Date>;
  getMaxStartedTime(): Promise<Date>;
  getMinCompletedTime(): Promise<Date>;
  getMaxCompletedTime(): Promise<Date>;
  //#endregion

  //#region get decision count methods
  getTransitionInformation(request: TransitionKey): Promise<TransitionInformation>;
  getTransitionsInformation(): Promise<TransitionInformation[]>;
  getTransitionsByReasonId(reasonId: string): Promise<TransitionKey[]>;
  getTransitionsByReasonSessionId(reasonId: string, sessionId: string): Promise<TransitionKey[]>;
  getTransitionsBySttResponseSubString(subString: string): Promise<TransitionKey[]>;
  //#endregion

  //#region getDecision
  //TODO: When the filter format is selected, you need to implement its logic
  getDecisions(request: TransitionKey, filter?: never): Promise<Decision[]>;
  getDecisionById(id: string): Promise<Decision | undefined>;
  getDecisionsByIds(ids: string[]): Promise<Decision[]>;
  //#endregion

  //#region get reason for decision
  getReasons(request: TransitionKey): Promise<Reason[]>;
  getUnknownReasons(request: TransitionKey): Promise<UnknownReason[]>;
  //TODO: When the filter format is selected, you need to implement its logic
  getNluSttReasons(request: TransitionKey, filter?: never): Promise<NluSttReason[]>;
  //#endregion

  //#region get cluster meta information
  getCluster(id: string): Promise<Cluster | undefined>;
  getClusters(ids: string[]): Promise<Cluster[]>;
  getClustersByTransitionKey(request: TransitionKey): Promise<ClusterWithReasonIdAndSttId[]>;
  hasClustersToResponsesTable(): boolean;
  //#endregion

  //#region get Session
  getSessionsByTransition(request: TransitionKey): Promise<string[]>;
  getSessionById(id: string): Promise<Session | undefined>;
  getSessionsByIds(ids: string[]): Promise<Session[]>;
  getSessionsByReasonId(reasonId: string): Promise<Session[]>;
  getSessionsByReasonIds(reasonId: string[], transition: TransitionKey): Promise<Record<string, Session[]>>;
  //#endregion

  //#region get session to dialog manager decision
  getSessionToDecisionById(id: string): Promise<SessionToDecision | undefined>;
  getSessionToDecisionsByIds(ids: string[]): Promise<SessionToDecision[]>;
  getSessionToDecisionsByTupleSessionIdWithReasonId(sessionId: string, reasonId: string): Promise<SessionToDecision[]>;
  getSessionToDecisionsBySessionId(sessionId: string): Promise<SessionToDecision[]>;
  //#endregion

  getNluStt(): Promise<NluSttReason[]>;

  //#region get stt or nlg response
  getSttOrNlgResponseById(id: string): Promise<SttOrNlgResponse | undefined>;
  getSttOrNlgResponsesByIds(ids: string[]): Promise<SttOrNlgResponse[]>;
  getSttOrNlgResponsesByTupleSessionIdWithSttId(sessionId: string, sttId: string): Promise<SttOrNlgResponse[]>;
  getSttOrNlgResponsesByTupleSessionIdWithNlgId(sessionId: string, nlgId: string): Promise<SttOrNlgResponse[]>;
  getSttOrNlgResponsesBySessionId(sessionId: string): Promise<SttOrNlgResponse[]>;
  getSttOrNlgResponsesBySessionToDecisionId(sessionToDecisionId: string): Promise<SttOrNlgResponse[]>;
  //#endregion

  //#region get NluResponse
  getNluResponseFieldValues(field: NluField.Data, type: "Value"): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.Data, type: "Field"): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.FactType): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.Intent): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.SentenceType): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.Skill): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.State): Promise<string[]>;
  getNluResponseFieldValues(field: NluField.FactType): Promise<string[]>;
  //#endregion

  //#region work with Nlg with session User Action
  getNlgResponseWithSessionUserActions(): Promise<NlgResponseWithSessionUserAction[]>;
  getNlgResponseWithSessionUserAction(
    sessionId: string,
    nlgResponseId: string
  ): Promise<NlgResponseWithSessionUserAction | undefined>;
  setNlgResponseWithSessionUserAction(sessionId: string, nlgResponseId: string, mark: boolean): Promise<void>;
  //#endregion

  //#region work with Reason with session User Action
  getReasonWithSessionUserActions(): Promise<ReasonWithSessionUserAction[]>;
  getReasonWithSessionUserAction(sessionId: string, reasonId: string): Promise<ReasonWithSessionUserAction | undefined>;
  setReasonWithSessionUserAction(sessionId: string, reasonId: string, mark: boolean): Promise<void>;
  //#endregion

  //#region work with Transition User Action
  getTransitionUserActions(): Promise<TransitionUserAction[]>;
  getTransitionUserAction(transition: TransitionKey): Promise<TransitionUserAction | undefined>;
  setTransitionUserAction(transition: TransitionKey, verified: boolean): Promise<void>;
  //#endregion

  //#region work with Transition User Action
  getReasonUserActions(): Promise<ReasonUserAction[]>;
  getReasonUserActionByReasonId(reasonId: string): Promise<ReasonUserAction | undefined>;
  setReasonUserActions(ations: ReasonUserActionSetRequest[]): Promise<void>;
  unsetReasonUserActions(actions: ReasonUserActionSetRequest[]): Promise<void>;
  //#endregion

  getLog(sessionId: string): Promise<MessageLogProfiled[]>;

  close(): Promise<void>;

  dispose(): Promise<void>;
}
