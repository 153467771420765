import styled from "styled-components";
import { PureButton } from "../../../uikit/Button";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;

  height: 48px;
  padding: 12px 24px;
  z-index: 10;
  background-color: #1f1f31;
`;

export const Flexbox = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 8px;
`;

export const Info = styled.div`
  width: calc(100% - 356px);
  margin-right: 16px;
  display: flex;

  p:first-child {
    border-radius: 6px 0 0 6px;
  }

  p:last-child {
    border-radius: 0 6px 6px 0;
  }

  p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;

    background: rgba(255, 255, 255, 0.06);
    border-radius: 6px;
    padding: 4px 6px;
    color: #a1a1bd;
    height: 24px;
    line-height: 0;

    > span:first-child {
      margin-right: 2px;

      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: #a1a1bd;
    }

    > span:last-child {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: #d9d9e2;
    }
  }
`;

export const LoadButton = styled(PureButton)`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  padding: 0 6px;
  height: 24px;

  text-transform: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
`;

export const SaveButton = styled(PureButton)`
  background: #5855f4;
  border-radius: 6px;
  padding: 0 8px;
  height: 24px;

  text-transform: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
`;
