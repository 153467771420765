import graphParser from "../../antlr/generated/graphParser";
import graphVisitor from "../../antlr/generated/graphVisitor";
import * as interfaces from "./interfaces";
import DslElementType from "./enums/DslElementType";
import * as monacoHelpers from "../../monaco-helpers";
import { IDoStatement } from "./interfaces";
import { BlockType } from "./enums/BlockType";
import { HighOrderFunctionType } from "./enums/HighOrderFunctionType";
import { TransitionType } from "./enums/TransitionType";

function throwNotImpl(ctx) {
  throw new Error(`Visitor for this rule is not implemented`);
}

export default class JsonEmitterVisitor extends graphVisitor {
  visitTerminal(ctx): interfaces.IConstantExpression | interfaces.IRawElement<string> {
    switch (ctx.symbol.type) {
      case graphParser.StringLiteral:
      case graphParser.DecimalLiteral:
      case graphParser.BooleanLiteral:
      case graphParser.DecimalIntegerLiteral:
        return {
          isExpression: true,
          elementType: DslElementType.ConstantExpression,
          constant: {
            elementType: DslElementType.RawElement,
            value: ctx.getText(),
            location: monacoHelpers.createRange(ctx),
          },
          location: monacoHelpers.createRange(ctx),
        };
      default:
        return {
          elementType: DslElementType.RawElement,
          value: ctx.getText(),
          location: monacoHelpers.createRange(ctx),
        };
    }
  }

  visitFile(ctx): interfaces.IFileContent {
    const isLibrary = ctx.LIBRARY() !== null;
    return {
      elementType: DslElementType.FileContent,
      isLibrary,
      content: ctx.mainBlock.accept(this),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#blockContent.
  visitBlockContent(ctx): interfaces.IRootBlockContentDescription {
    const imports = ctx.imports.map((importCtx) =>
      interfaces.helpers.createRawElement(importCtx.path.text, importCtx.path)
    );

    const externalFunctions = ctx.externalFunctions.map((extFuncCtx) => extFuncCtx.accept(this));
    const contextVariables = ctx.contextType?.elements.map((contextElement) => contextElement.accept(this)) ?? [];
    const types = ctx.types.map((typeDefinitionCtx) => typeDefinitionCtx.accept(this));

    const nodes = ctx.nodes.map((nodeCtx) => nodeCtx.accept(this));
    const digressions = ctx.digressions.map((digressionCtx) => digressionCtx.accept(this));
    const blocks = ctx.blocks.map((blockCtx) => blockCtx.accept(this));
    return {
      elementType: DslElementType.BlockContentDescription,
      isRootBlockContent: true,
      imports,
      externalFunctions,
      contextVariables,
      types,
      nodes,
      digressions,
      blocks,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#contextElementDescription.
  visitContextElementDescription(ctx): interfaces.IRootContextVariable {
    const name = interfaces.helpers.createRawElement(ctx.name.getText(), ctx.name);
    const type = interfaces.helpers.createRawElement(ctx.type.getText(), ctx.type);

    const mod = ctx.input !== null ? "input" : ctx.output !== null ? "output" : null;
    const modificator: interfaces.OptionalValue<"input" | "output"> = interfaces.helpers.createOptionalValue(mod);

    const initialValue = interfaces.helpers.createOptionalValue<interfaces.IExpression>(ctx.value?.accept(this));
    return {
      elementType: DslElementType.ContextVariable,
      isRootContextVariable: true,
      name,
      type,
      modifier: modificator,
      initialValue,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#blockDeclaration.
  visitBlockDeclaration(ctx): interfaces.IBlock {
    const parsedContent: interfaces.IRootBlockContentDescription = ctx.content.accept(this);

    const contextVariables: interfaces.IContextVariable[] = parsedContent.contextVariables.map(
      (rootContextVariable: interfaces.IRootContextVariable) => {
        return { ...rootContextVariable, modificator: undefined, isRootContextVariable: undefined };
      }
    );

    const content: interfaces.IBlockContentDescription = {
      ...parsedContent,
      contextVariables,
      elementType: DslElementType.BlockContentDescription,
    };

    const blockType = interfaces.helpers.createOptionalValue(
      ctx.async !== null ? BlockType.Async : ctx.sync !== null ? BlockType.Sync : null
    );
    const name = ctx.blockId().accept(this);
    const returnType = interfaces.helpers.createOptionalValue(
      ctx.returnType === null ? null : interfaces.helpers.createRawElement(ctx.returnType.getText(), ctx.returnType)
    );

    const parameters: interfaces.IArgumentDeclaration[] = ctx
      .parameterList()
      .parameters.map((paramCtx) => paramCtx.accept(this));
    return {
      elementType: DslElementType.Block,
      arguments: parameters,
      content,
      blockType,
      name,
      returnType,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#nodeDeclaration.
  visitNodeDeclaration(ctx): interfaces.INode {
    const isStartNode = ctx.START() !== null;
    const name = ctx.name.accept(this);
    const nodeContentCtx = ctx.nodeContent();
    const commentSection = interfaces.helpers.createOptionalValue(
      ctx.comment() === null ? null : interfaces.helpers.createRawElement(
        ctx.comment().StringLiteral().getText(), ctx.comment().StringLiteral()
      )
    );
    const doSection: interfaces.IGroupCommandStatement = nodeContentCtx.doSec.accept(this);
    const transitionSection: interfaces.OptionalValue<interfaces.ITransitionSection> =
      interfaces.helpers.createOptionalValue(
        nodeContentCtx.transitions === null ? null : nodeContentCtx.transitions.accept(this)
      );
    const onExitSection: interfaces.OptionalValue<interfaces.IOnExitSection> = interfaces.helpers.createOptionalValue(
      nodeContentCtx.onExit === null ? null : nodeContentCtx.onExit.accept(this)
    );
    return {
      elementType: DslElementType.Node,
      name,
      isStartNode,
      commentSection,
      doSection,
      transitionSection,
      onExitSection,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#digressionDeclaration.
  visitDigressionDeclaration(ctx): interfaces.IDigression {
    const isGlobal = ctx.GLOBAL() !== null;
    const isPreprocessor = ctx.PREPROCESSOR() !== null;
    const name = ctx.name.accept(this);
    const digressionContentCtx = ctx.digressionContent();
    const conditions = digressionContentCtx.transitions.map((onMessageCtx) => onMessageCtx.accept(this));
    const sharedVariables: interfaces.IDigressionVariable[] = digressionContentCtx.sharedState.map((ssCtx) => {
      const variable: interfaces.IVariable = ssCtx.accept(this);
      return {
        elementType: DslElementType.DigressionVariable,
        isShared: true,
        location: monacoHelpers.createRange(ssCtx),
        ...variable,
      };
    });
    const simpleVariables = digressionContentCtx.copiedState.map((csCtx) => {
      const variable: interfaces.IVariable = csCtx.accept(this);
      return {
        elementType: DslElementType.DigressionVariable,
        isShared: false,
        location: monacoHelpers.createRange(csCtx),
        ...variable,
      };
    });
    const variables = sharedVariables.concat(simpleVariables);

    const nodeContentCtx = digressionContentCtx.nodeContent();
    const doSection: interfaces.IGroupCommandStatement = nodeContentCtx.doSec.accept(this);
    const transitionSection: interfaces.OptionalValue<interfaces.ITransitionSection> =
      interfaces.helpers.createOptionalValue(
        nodeContentCtx.transitions === null ? null : nodeContentCtx.transitions.accept(this)
      );
    const onExitSection: interfaces.OptionalValue<interfaces.IOnExitSection> = interfaces.helpers.createOptionalValue(
      nodeContentCtx.onExit === null ? null : nodeContentCtx.onExit.accept(this)
    );
    return {
      elementType: DslElementType.Digression,
      name,
      isGlobal,
      isPreprocessor,
      conditions,
      variables,
      doSection,
      transitionSection,
      onExitSection,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#doSection.
  visitDoSection(ctx): interfaces.IGroupCommandStatement {
    return ctx.blockBody().accept(this);
  }

  // Visit a parse tree produced by graphParser#nodeTransitionSection.
  visitNodeTransitionSection(ctx): interfaces.ITransitionSection {
    const transitions: interfaces.ITransitionDefinition[] = ctx.list.map((transitionDefinitionCtx) =>
      transitionDefinitionCtx.accept(this)
    );
    return {
      elementType: DslElementType.TransitionSection,
      transitions,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#namedTransitionDefinition.
  visitNamedTransitionDefinition(ctx): interfaces.ITransitionDefinition {
    const name = ctx.transitionName().accept(this);
    const targetNodeName = ctx.target.accept(this);
    const parsedCondition: interfaces.ITransitionCondition | interfaces.IUnconditional = ctx
      .transitionDefinition()
      .accept(this);
    let type: TransitionType;
    let condition: interfaces.OptionalValue<interfaces.ITransitionCondition>;
    switch (parsedCondition.elementType) {
      case DslElementType.Unconditional:
        type = TransitionType.Unconditional;
        condition = interfaces.helpers.createOptionalValue<interfaces.ITransitionCondition>(null);
        break;
      case DslElementType.TransitionTimeoutCondition:
        type = TransitionType.Timeout;
        condition = interfaces.helpers.createOptionalValue(parsedCondition);
        break;
      case DslElementType.TransitionExpressionCondition:
        type = TransitionType.Conditional;
        condition = interfaces.helpers.createOptionalValue(parsedCondition);
        break;
      default:
        throw new Error(`Could not identify transition condition: ${JSON.stringify(parsedCondition)}`);
    }
    return {
      elementType: DslElementType.TransitionDefinition,
      name,
      targetNodeName,
      transitionType: type,
      condition,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#timeoutTransitionDefinition.
  visitTimeoutTransitionDefinition(ctx): interfaces.ITransitionTimeoutCondition {
    const timeOut = interfaces.helpers.createRawElement<number>(
      new Number(ctx.timeout.getText()).valueOf(),
      ctx.timeout
    );
    return {
      isTransitionCondition: true,
      elementType: DslElementType.TransitionTimeoutCondition,
      timeOut,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#conditionalTransitionDefinition.
  visitConditionalTransitionDefinition(ctx): interfaces.ITransitionExpressionCondition {
    const condition = ctx.condition.accept(this);
    const priority = interfaces.helpers.createOptionalValue(
      ctx.priority === null
        ? null
        : interfaces.helpers.createRawElement(new Number(ctx.priority.text).valueOf(), ctx.priority)
    );
    const tags = ctx.tags.map((tagCtx) => interfaces.helpers.createRawElement(tagCtx.getText(), tagCtx));
    const isOnConfident = ctx.confident !== null;
    return {
      isTransitionCondition: true,
      elementType: DslElementType.TransitionExpressionCondition,
      condition,
      priority,
      tags,
      isOnConfident,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#unconditionalTransitionDefinition.
  visitUnconditionalTransitionDefinition(ctx): interfaces.IUnconditional {
    return {
      elementType: DslElementType.Unconditional,
      condition: null,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#nodeOnExitSection.
  visitNodeOnExitSection(ctx): interfaces.IOnExitSection {
    const defaultDo: interfaces.OptionalValue<interfaces.IOnExitNamedDescription<"default">> =
      interfaces.helpers.createOptionalValue(
        ctx.defaultDo === null
          ? null
          : { ...ctx.defaultDo.accept(this), name: interfaces.helpers.createRawElement("default", ctx.DEFAULT()) }
      );

    const digressionDo: interfaces.OptionalValue<interfaces.IOnExitNamedDescription<"digression">> =
      interfaces.helpers.createOptionalValue(
        ctx.digressionDo === null
          ? null
          : {
              ...ctx.digressionDo.accept(this),
              name: interfaces.helpers.createRawElement("digression", ctx.DIGRESSION()),
            }
      );

    const descriptions = ctx.nodeOnExitSectionItem().flatMap((itemCtx) => {
      const section: interfaces.IOnExitDescription = itemCtx.onExitDoWithConfident().accept(this);
      return itemCtx.transitionName().map((nameCtx) => {
        return {
          name: nameCtx.accept(this),
          elementType: section.elementType,
          onConfident: section.onConfident,
          doSection: section.doSection,
        };
      });
    });
    return {
      elementType: DslElementType.OnExitSection,
      descriptions,
      default: defaultDo,
      digression: digressionDo,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#onExitDoWithConfident.
  visitOnExitDoWithConfident(ctx): interfaces.IOnExitDescription {
    const doSection = ctx.doSec.accept(this);
    const onConfident = interfaces.helpers.createOptionalValue(
      ctx.confidentDo === null ? null : ctx.confidentDo.accept(this)
    );
    return {
      elementType: DslElementType.OnExitNamedDescription,
      doSection,
      onConfident,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#statement.
  visitStatement(ctx): IDoStatement {
    const statementsOrExpression: interfaces.IDoStatement | interfaces.IExpression | interfaces.IVariable =
      ctx.children[0].accept(this);
    let statement: interfaces.IDoStatement;
    if (interfaces.helpers.isExpression(statementsOrExpression)) {
      const expressionStatement: interfaces.IExpressionStatement = {
        isDoSectionStatement: true,
        elementType: DslElementType.ExpressionStatement,
        expression: statementsOrExpression,
        location: monacoHelpers.createRange(ctx),
      };
      statement = expressionStatement;
    } else if (interfaces.helpers.isVariable(statementsOrExpression)) {
      const varStatement: interfaces.IVarStatement = {
        ...statementsOrExpression,
        elementType: DslElementType.VarStatement,
        isDoSectionStatement: true,
        location: monacoHelpers.createRange(ctx),
      };
      statement = varStatement;
    } else {
      statement = statementsOrExpression;
    }
    return statement;
  }

  // Visit a parse tree produced by graphParser#blockBody.
  visitBlockBody(ctx): interfaces.IGroupCommandStatement {
    const commandsCtxs = ctx.commands;
    const commands = commandsCtxs.map((commandCtx) => commandCtx.accept(this));
    return {
      elementType: DslElementType.GroupCommandStatement,
      isDoSectionStatement: true,
      commands,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#exitCommand.
  visitExitCommand(ctx): interfaces.IExitStatement {
    return {
      elementType: DslElementType.ExitStatement,
      isDoSectionStatement: true,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#externalFunctionDeclaration.
  visitExternalFunctionDeclaration(ctx): interfaces.IExternalFunctionDeclaration {
    const name = ctx.name.accept(this);
    const returnType = interfaces.helpers.createRawElement(ctx.returnType.getText(), ctx.returnType);
    const parametersCtx = ctx.parameters;
    const parameters: interfaces.IArgumentDeclaration[] = parametersCtx.parameters.map((paramCtx) =>
      paramCtx.accept(this)
    );
    return {
      elementType: DslElementType.ExternalFunctionDeclaration,
      name,
      returnType,
      arguments: parameters,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#parameter.
  visitParameter(ctx): interfaces.IArgumentDeclaration {
    const name = interfaces.helpers.createRawElement(ctx.name.getText(), ctx.name);
    const type = interfaces.helpers.createRawElement(ctx.type.getText(), ctx.type);
    const defaultValue = interfaces.helpers.createOptionalValue(
      ctx.defaultValue === null ? null : ctx.defaultValue.accept(this)
    );
    return {
      elementType: DslElementType.ArgumentDeclaration,
      name,
      type,
      defaultValue,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#typeDefinition.
  visitTypeDefinition(ctx): interfaces.ITypeAlias {
    const name = interfaces.helpers.createRawElement(ctx.typeAlias().getText(), ctx.typeAlias());
    const type = interfaces.helpers.createRawElement(ctx.typeDescription().getText(), ctx.typeDescription());
    return { elementType: DslElementType.TypeAlias, name, type, location: monacoHelpers.createRange(ctx) };
  }

  // Visit a parse tree produced by graphParser#tupleLiteralExpression.
  visitTupleLiteralExpression(ctx): interfaces.IConstantExpression {
    return {
      isExpression: true,
      elementType: DslElementType.ConstantExpression,
      constant: {
        elementType: DslElementType.RawElement,
        value: ctx.getText(),
        location: monacoHelpers.createRange(ctx),
      },
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#nullLiteralExpression.
  visitNullLiteralExpression(ctx): interfaces.IConstantExpression {
    return {
      isExpression: true,
      elementType: DslElementType.ConstantExpression,
      constant: interfaces.helpers.createRawElement("null", ctx),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#builtinFunctionCallExpression.
  visitBuiltinFunctionCallExpression(ctx): interfaces.IHighOrderFunctionCallExpression {
    const builtinFunctionCallCtx = ctx.builtinFunctionCall();
    const name = interfaces.helpers.createRawElement(
      builtinFunctionCallCtx.name.getText(),
      builtinFunctionCallCtx.name
    );
    const args = builtinFunctionCallCtx.arguments.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.HighOrderFunctionCallExpression,
      functionType: HighOrderFunctionType.Builtin,
      name,
      arguments: args,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#argumentInvokation.
  visitArgumentInvokation(ctx): interfaces.IFunctionArguments {
    const positional = ctx.argumentList().positional?.arguments.map((posArgCtx) => posArgCtx.accept(this)) ?? [];
    const named = ctx.argumentList().named?.arguments.map((namedArgCtx) => namedArgCtx.accept(this)) ?? [];
    return {
      elementType: DslElementType.FunctionArguments,
      positional,
      named,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#positionalArgument.
  visitPositionalArgument(ctx): interfaces.IPositionalArgument {
    return {
      elementType: DslElementType.PositionalArgument,
      value: ctx.expression().accept(this),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#namedArgument.
  visitNamedArgument(ctx): interfaces.INamedArgument {
    return {
      elementType: DslElementType.NamedArgument,
      name: ctx.argumentName().accept(this),
      value: ctx.expression().accept(this),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#unaryOpExpression.
  visitUnaryOpExpression(ctx): interfaces.IUnaryExpression {
    const unarySymbolCtx = ctx.MINUS() ?? ctx.PLUS() ?? ctx.NotOperator();
    const unarySymbol = unarySymbolCtx.getText();
    const expression = ctx.expression().accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.UnaryExpression,
      unaryOperationSymbol: unarySymbol,
      expression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#localReference.
  visitLocalReference(ctx): interfaces.ILocalVariableExpression {
    const variable = interfaces.helpers.createRawElement(ctx.refName.getText(), ctx.refName);
    return {
      isExpression: true,
      elementType: DslElementType.LocalVariableExpression,
      variable,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#variableDefinition.
  visitVariableDefinition(ctx): interfaces.IVariable {
    const variableNameCtx = ctx.variableDeclaration().variableName();
    const name = interfaces.helpers.createRawElement(variableNameCtx.getText(), variableNameCtx);
    const variableType = interfaces.helpers.createOptionalValue(
      ctx.type === null ? null : interfaces.helpers.createRawElement(ctx.type.getText(), ctx.type)
    );
    const expression = interfaces.helpers.createOptionalValue<interfaces.IExpression>(
      ctx.value === null ? null : ctx.value.accept(this)
    );
    return {
      name,
      type: variableType,
      initialValue: expression,
    };
  }

  // Visit a parse tree produced by graphParser#blockFunctionCallExpression.
  visitBlockFunctionCallExpression(ctx): interfaces.IHighOrderFunctionCallExpression {
    const blockFunctionCallCtx = ctx.blockFunctionCall();
    const name = blockFunctionCallCtx.name.accept(this);
    const args = blockFunctionCallCtx.arguments.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.HighOrderFunctionCallExpression,
      functionType: HighOrderFunctionType.BlockCall,
      name,
      arguments: args,
      location: monacoHelpers.createRange(ctx),
    };
  }
  // Visit a parse tree produced by graphParser#externFunctionCallExpression.
  visitExternFunctionCallExpression(ctx): interfaces.IHighOrderFunctionCallExpression {
    const externalFunctionCallCtx = ctx.externalFunctionCall();
    const name = externalFunctionCallCtx.name.accept(this);
    const args = externalFunctionCallCtx.arguments.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.HighOrderFunctionCallExpression,
      functionType: HighOrderFunctionType.ExternalCall,
      name,
      arguments: args,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#ternaryOpExpression.
  visitTernaryOpExpression(ctx): interfaces.ITernaryExpression {
    const conditionExpr = ctx.condition.accept(this);
    const thenExpr = ctx.thenExpression.accept(this);
    const elseExpr = ctx.elseExpression.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.TernaryExpression,
      condition: conditionExpr,
      then: thenExpr,
      else: elseExpr,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#binaryOpExpression.
  visitBinaryOpExpression(ctx): interfaces.IBinaryExpression {
    const binaryOperationSymbol = ctx.op.text;
    const leftExpression = ctx.left.accept(this);
    const rightExpression = ctx.right.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.BinaryExpression,
      binaryOperationSymbol,
      leftExpression,
      rightExpression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#lValueParenthesizedExpression.
  visitLValueParenthesizedExpression(ctx): interfaces.IParenthesizedExpression {
    return {
      isExpression: true,
      elementType: DslElementType.ParenthesizedExpression,
      expression: ctx.lValue().accept(this),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#parenthesizedExpression.
  visitParenthesizedExpression(ctx): interfaces.IParenthesizedExpression {
    return {
      isExpression: true,
      elementType: DslElementType.ParenthesizedExpression,
      expression: ctx.expression().accept(this),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#objectLiteralExpression.
  visitObjectLiteralExpression(ctx): interfaces.IObjectExpression {
    const object = ctx.fields.map((fieldCtx) => fieldCtx.accept(this));
    return {
      isExpression: true,
      elementType: DslElementType.ObjectExpression,
      properties: object,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#objectPropertyExpressionPart.
  visitObjectPropertyExpressionPart(ctx): interfaces.IObjectProperty {
    const name = interfaces.helpers.createRawElement(ctx.name.getText(), ctx.name);
    const expression = ctx.expression().accept(this);
    return {
      elementType: DslElementType.ObjectProperty,
      name,
      expression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#lValueDotAccessExpression.
  visitLValueDotAccessExpression(ctx): interfaces.IObjectAccessExpression {
    const parentExpression = ctx.parent.accept(this);
    const propertyName = ctx.member.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ObjectAccessExpression,
      parentExpression,
      propertyName,
      optionalAccess: false,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#dotAccessExpression.
  visitDotAccessExpression(ctx): interfaces.IObjectAccessExpression {
    const parentExpression = ctx.parent.accept(this);
    const propertyName = ctx.member.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ObjectAccessExpression,
      parentExpression,
      propertyName,
      optionalAccess: false,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#optionalDotAccessExpression.
  visitOptionalDotAccessExpression(ctx): interfaces.IObjectAccessExpression {
    const parentExpression = ctx.parent.accept(this);
    const propertyName = ctx.member.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ObjectAccessExpression,
      parentExpression,
      propertyName,
      optionalAccess: true,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#isNullExpression.
  visitIsNullExpression(ctx): interfaces.IIsExpression {
    const expression = ctx.expression().accept(this);
    const checkType = interfaces.helpers.createRawElement(ctx.NULL().getText(), ctx.NULL());
    const not = ctx.NOT() !== null;
    return {
      isExpression: true,
      elementType: DslElementType.IsExpression,
      checkType,
      expression,
      not,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#callDotAccessExpression.
  visitCallDotAccessExpression(ctx): interfaces.IFunctionCallExpression {
    const parentExpression = ctx.parent.accept(this);
    const functionName = ctx.member.accept(this);
    const args = ctx.arguments.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.FunctionCallExpression,
      parentExpression,
      name: functionName,
      arguments: args,
      optionalAccess: false,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#optionalCallDotAccessExpression.
  visitOptionalCallDotAccessExpression(ctx): interfaces.IFunctionCallExpression {
    const parentExpression = ctx.parent.accept(this);
    const functionName = ctx.member.accept(this);
    const args = ctx.arguments.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.FunctionCallExpression,
      parentExpression,
      optionalAccess: true,
      name: functionName,
      arguments: args,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#enableDigression.
  visitEnableDigression(ctx): interfaces.IDigressionSwitchExpression {
    const command = "enable";
    const listDigressions = ctx.spec.list.map((digressionIdCtx) => digressionIdCtx.accept(this));
    return {
      isExpression: true,
      elementType: DslElementType.DigressionSwitchExpression,
      command,
      digressionList: listDigressions,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#disableDigression.
  visitDisableDigression(ctx): interfaces.IDigressionSwitchExpression {
    const command = "disable";
    const listDigressions = ctx.spec.list.map((digressionIdCtx) => digressionIdCtx.accept(this));
    return {
      isExpression: true,
      elementType: DslElementType.DigressionSwitchExpression,
      command,
      digressionList: listDigressions,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#saveDigression.
  visitSaveDigression(ctx): interfaces.IDigressionSwitchExpression {
    const command = "save";
    const listDigressions = ctx.spec.list.map((digressionIdCtx) => digressionIdCtx.accept(this));
    return {
      isExpression: true,
      elementType: DslElementType.DigressionSwitchExpression,
      command,
      digressionList: listDigressions,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#applyDigression.
  visitApplyDigression(ctx): interfaces.IDigressionSwitchApplyExpression {
    const variable = ctx.mask.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.DigressionSwitchApplyExpression,
      variable,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#contextReference.
  visitContextReference(ctx): interfaces.IContextVariableExpression {
    const variable = interfaces.helpers.createOptionalValue(ctx.refName === null ? null : ctx.refName.accept(this));
    return {
      isExpression: true,
      elementType: DslElementType.ContextVariableExpression,
      variable,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#builtinReference.
  visitBuiltinReference(ctx): interfaces.IBuiltinVariableExpression {
    const variable = ctx.refName.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.BuiltinVariableExpression,
      variable,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#lValueLiteralIndexAccessExpression.
  visitLValueLiteralIndexAccessExpression(ctx): interfaces.IArrayAccessExpression {
    const index = interfaces.helpers.createConstantExpression(ctx.DecimalIntegerLiteral().getText(), ctx.index);
    const parentExpression = ctx.parent.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ArrayAccessExpression,
      index,
      parentExpression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#lValueExpressionIndexAccessExpression.
  visitLValueExpressionIndexAccessExpression(ctx): interfaces.IArrayAccessExpression {
    const index = ctx.index.accept(this);
    const parentExpression = ctx.parent.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ArrayAccessExpression,
      index,
      parentExpression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#literalIndexAccessExpression.
  visitLiteralIndexAccessExpression(ctx): interfaces.IArrayAccessExpression {
    const index = interfaces.helpers.createConstantExpression(ctx.DecimalIntegerLiteral().getText(), ctx.index);
    const parentExpression = ctx.parent.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ArrayAccessExpression,
      index,
      parentExpression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#expressionIndexAccessExpression.
  visitExpressionIndexAccessExpression(ctx): interfaces.IArrayAccessExpression {
    const index = ctx.index.accept(this);
    const parentExpression = ctx.parent.accept(this);
    return {
      isExpression: true,
      elementType: DslElementType.ArrayAccessExpression,
      index,
      parentExpression,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#setCommand.
  visitSetCommand(ctx): interfaces.IAssignmentStatement {
    const lValue = interfaces.helpers.createRawElement(ctx.ref.getText(), ctx.ref);
    const operation = "=";
    const rValue = ctx.rightPartExpression.accept(this);
    return {
      elementType: DslElementType.AssignmentStatement,
      isDoSectionStatement: true,
      lValue,
      operation,
      rValue,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#assignOpCommand.
  visitAssignOpCommand(ctx): interfaces.IAssignmentStatement {
    const lValue = interfaces.helpers.createRawElement(ctx.ref.getText(), ctx.ref);
    const operation = ctx.AddOp() ? "+=" : ctx.RemoveOp() ? "-=" : null;
    if (operation === null) throw new Error(`Unknown assignment operation`);
    const rValue = ctx.rightPartExpression.accept(this);
    return {
      elementType: DslElementType.AssignmentStatement,
      isDoSectionStatement: true,
      lValue,
      operation,
      rValue,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#conditionalCommand.
  visitConditionalCommand(ctx): interfaces.IIfStatement {
    const condition = ctx.condition.accept(this);
    const thenCommand = ctx.thenCommand.accept(this);
    const elseCommand = interfaces.helpers.createOptionalValue(
      ctx.elseCommand === null ? null : ctx.elseCommand.accept(this)
    );
    return {
      elementType: DslElementType.IfStatement,
      isDoSectionStatement: true,
      condition,
      then: thenCommand,
      else: elseCommand,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#gotoCommand.
  visitGotoCommand(ctx): interfaces.IGotoStatement {
    const targetTransition = ctx.transitionName().accept(this);
    return {
      elementType: DslElementType.GotoStatement,
      isDoSectionStatement: true,
      targetTransition,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#waitCommand.
  visitWaitCommand(ctx): interfaces.IWaitStatement {
    return ctx.activatedTransitionList().accept(this);
  }

  // Visit a parse tree produced by graphParser#allActivatedTransitionList.
  visitAllActivatedTransitionList(ctx): interfaces.IWaitStatement {
    const transitions = interfaces.helpers.createOptionalValue<interfaces.IRawElement<string>[]>(null);
    return {
      elementType: DslElementType.WaitStatement,
      isDoSectionStatement: true,
      transitions,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#selectedActivatedTransitionList.
  visitSelectedActivatedTransitionList(ctx): interfaces.IWaitStatement {
    const transitions = interfaces.helpers.createOptionalValue(
      ctx.transitionName().map((trNameCtx) => trNameCtx.accept(this))
    );
    return {
      elementType: DslElementType.WaitStatement,
      isDoSectionStatement: true,
      transitions,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#returnCommand.
  visitReturnCommand(ctx): interfaces.IReturnStatement {
    const returnValue = interfaces.helpers.createOptionalValue(
      ctx.returnValue === null ? null : ctx.returnValue.accept(this)
    );
    return {
      elementType: DslElementType.ReturnStatement,
      isDoSectionStatement: true,
      returnValue,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#foreachCommand.
  visitForeachCommand(ctx): interfaces.IForStatement {
    const body = ctx.body.accept(this);
    const container = ctx.container.accept(this);
    const variableNameCtx = ctx.variableDeclaration().variableName();
    const iteratorVariableName = variableNameCtx.accept(this);
    return {
      elementType: DslElementType.ForStatement,
      isDoSectionStatement: true,
      body,
      containerName: container,
      iteratorName: iteratorVariableName,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#castExpression.
  visitCastExpression(ctx): interfaces.ICastExpression {
    return {
      isExpression: true,
      elementType: DslElementType.CastExpression,
      expression: ctx.expression().accept(this),
      type: interfaces.helpers.createRawElement(ctx.typeDescription().getText(), ctx.typeDescription()),
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#digressionReference.
  visitDigressionReference(ctx): interfaces.IDigressionVariableReferenceExpression {
    const digressionName = interfaces.helpers.createOptionalValue(ctx.name?.accept(this));
    const isShared: boolean = ctx.shared !== null;
    const variableName = interfaces.helpers.createOptionalValue(ctx.refName?.accept(this));
    return {
      isExpression: true,
      elementType: DslElementType.DigressionVariableReferenceExpression,
      isShared,
      digressionName,
      variableName,
      location: monacoHelpers.createRange(ctx),
    };
  }

  // Visit a parse tree produced by graphParser#nullCollateExpression.
  visitNullCollateExpression(ctx): interfaces.INullCollateExpression {
    return {
      isExpression: true,
      elementType: DslElementType.NullCollateExpression,
      nullable: ctx.nullable.accept(this),
      defaultValue: ctx.defaultValue.accept(this),
      location: monacoHelpers.createRange(ctx),
    };
  }

  //---------SINGLE-CHILD-RULES-------

  // // Visit a parse tree produced by graphParser#externalFunctionName.
  visitExternalFunctionName = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#argumentName.
  visitArgumentName = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#blockId.
  visitBlockId = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#nodeId.
  visitNodeId = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#transitionDefinition.
  visitTransitionDefinition = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#transitionDefinitionOnMessage.
  visitTransitionDefinitionOnMessage = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#cycleCommand.
  visitCycleCommand = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#lValueReferenceExpression.
  visitLValueReferenceExpression(ctx) {
    return ctx.children[0].accept(this);
  }

  // Visit a parse tree produced by graphParser#stringLiteralExpression.
  visitStringLiteralExpression = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#numberLiteralExpression.
  visitNumberLiteralExpression = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#anyNumber.
  visitAnyNumber = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#referenceExpression.
  visitReferenceExpression = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#booleanLiteralExpression.
  visitBooleanLiteralExpression(ctx) {
    return ctx.children[0].accept(this);
  }

  // Visit a parse tree produced by graphParser#oneOrGroupedCommand.
  visitOneOrGroupedCommand(ctx): interfaces.IDoStatement {
    return ctx.children[0].accept(this);
  }

  // Visit a parse tree produced by graphParser#nodeCommand.
  visitNodeCommand(ctx): interfaces.IDoStatement {
    return ctx.children[0].accept(this);
  }

  // Visit a parse tree produced by graphParser#statementBody.
  visitStatementBody(ctx): interfaces.IDoStatement {
    return ctx.children[0].accept(this);
  }

  // Visit a parse tree produced by graphParser#memberName.
  visitMemberName = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#digressionControlExpression.
  visitDigressionControlExpression = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#digressionId.
  visitDigressionId = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#referenceName.
  visitReferenceName = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#transitionName.
  visitTransitionName = function (ctx) {
    return ctx.children[0].accept(this);
  };

  // Visit a parse tree produced by graphParser#variableName.
  visitVariableName = function (ctx) {
    return ctx.children[0].accept(this);
  };

  //-----------------------NOT-USED-RULES---------------------------

  // Visit a parse tree produced by graphParser#nodeOnExitSectionItem.
  visitNodeOnExitSectionItem = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#referenceFile.
  visitReferenceFile = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#expressionFile.
  visitExpressionFile = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#digressionContent.
  visitDigressionContent = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#comment.
  visitComment = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#nodeOnDigressionReturnSection.
  visitNodeOnDigressionReturnSection = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#logExpressionTypeCommand.
  visitLogExpressionTypeCommand = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#digressionSpec.
  visitDigressionSpec = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#isDefinedExpression.
  visitIsDefinedExpression = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#externalFunctionCall.
  visitExternalFunctionCall = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#blockFunctionCall.
  visitBlockFunctionCall = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#argumentList.
  visitArgumentList = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#namedArgumentList.
  visitNamedArgumentList = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#positionalArgumentList.
  visitPositionalArgumentList = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#tagName.
  visitTagName = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#factName.
  visitFactName = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#fieldName.
  visitFieldName = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#parameterName.
  visitParameterName = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#propertyName.
  visitPropertyName = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#builtinFunctionName.
  visitBuiltinFunctionName = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#typeEof.
  visitTypeEof = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#type_.
  visitType_ = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#presentQuestionMark.
  visitPresentQuestionMark = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#noQuestionMark.
  visitNoQuestionMark = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#objectTypeElementSeparator.
  visitObjectTypeElementSeparator = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#predefinedTypeRef.
  visitPredefinedTypeRef = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#arrayType.
  visitArrayType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#stringLiteralType.
  visitStringLiteralType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#tupleType.
  visitTupleType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#typeReference.
  visitTypeReference = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#angleUnionType.
  visitAngleUnionType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#parenthesisedType.
  visitParenthesisedType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#objectType.
  visitObjectType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#unknownType.
  visitUnknownType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#numberType.
  visitNumberType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#booleanType.
  visitBooleanType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#stringType.
  visitStringType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#emptyType.
  visitEmptyType = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#propertySignature.
  visitPropertySignature = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#indexSignature.
  visitIndexSignature = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#typeAnnotation.
  visitTypeAnnotation = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#typeAlias.
  visitTypeAlias = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#nodeContent.
  visitNodeContent = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#parameterList.
  visitParameterList(ctx) {
    throwNotImpl(ctx);
  }

  // Visit a parse tree produced by graphParser#typeDescription.
  visitTypeDescription(ctx) {
    throwNotImpl(ctx);
  }

  // Visit a parse tree produced by graphParser#importDefinition.
  visitImportDefinition(ctx) {
    throwNotImpl(ctx);
  }

  // Visit a parse tree produced by graphParser#contextDescription.
  visitContextDescription = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#variableDeclaration.
  visitVariableDeclaration = function (ctx) {
    throwNotImpl(ctx);
  };

  // Visit a parse tree produced by graphParser#builtinFunctionCall.
  visitBuiltinFunctionCall(ctx) {
    throwNotImpl(ctx);
  }
}
