import React, { FC } from "react";
import { Input } from "semantic-ui-react";
import CopyButton from "./CopyButton";

interface Props {
  tooltipText: React.ReactNode;
  clipboard: string;
  loading: boolean;
  type: string;
}

export const CopyInput: FC<Props> = ({ tooltipText, clipboard, loading, type }) => {
  return (
    <CopyButton tooltipText={"Copy " + tooltipText} clipboard={clipboard} hasIcon={false}>
      <Input
        action={{
          color: "teal",
          labelPosition: "right",
          icon: "copy",
          content: "Copy " + tooltipText,
          style: { width : '15em'}
        }}
        
        type={type}
        defaultValue={clipboard}
        loading={loading}
      />
    </CopyButton>
  );
};
