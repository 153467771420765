import React, { useState } from "react";

import { MenuOption, PopupMenu } from "../../uikit/popup";
import { PureButton } from "../../uikit/Button";
import { Icon } from "../../uikit";

import { useTreeContext } from "./TreeContext";
import { ActionsWrapper, StyledFile } from "./Tree.style";
import { PlaceholderInput } from "./TreePlaceholderInput";
import FILE_ICONS, { TextIcon } from "./FileIcons";

const File = ({ name, indent, node }) => {
  const { isEditable, onSelect, selected, onRename, onDelete } = useTreeContext();
  const [isEditing, setEditing] = useState(false);
  const ext = name?.split(".").pop() ?? "";

  const handleCancel = () => setEditing(false);
  const toggleEditing = () => setEditing(!isEditing);
  const commitDelete = () => onDelete?.(node);
  const commitEditing = (name: string) => {
    onRename?.(node, name);
    setEditing(false);
  };

  const actions: MenuOption[] = [
    {
      label: "Rename file",
      action: toggleEditing,
      isEnable: true,
    },
    {
      label: "Remove file",
      action: commitDelete,
      isEnable: true,
    },
  ];

  if (isEditing)
    return (
      <PlaceholderInput
        type="file"
        style={{ paddingLeft: indent }}
        defaultValue={name}
        onSubmit={commitEditing}
        onCancel={handleCancel}
      />
    );

  return (
    <StyledFile isSelected={node.path === selected} onClick={() => onSelect?.(node)} style={{ paddingLeft: indent }}>
      <ActionsWrapper>
        <p>
          {FILE_ICONS[ext] ?? <TextIcon />}
          &nbsp;&nbsp;{name}
        </p>

        {isEditable && (
          <PopupMenu actions={actions} closeAfterSelect on="click" position={["bottom center", "top center"]}>
            <PureButton>
              <Icon name="menu" />
            </PureButton>
          </PopupMenu>
        )}
      </ActionsWrapper>
    </StyledFile>
  );
};

export { File };
