import Ajv from "ajv";
import phrasemapScheme from "../../code-editor/resources/schemas/phrasemap.json";
import { IPhrase, IArgumentId, OneOrManyPhrasemapEntrySingle } from "./generated/phrasemap-types.d";

const ajv = new Ajv({ schemas: [phrasemapScheme] });

export function isIPhrase(data: any): data is IPhrase {
  const keyRef = "#/definitions/IPhrase";
  const validate = ajv.getSchema<IPhrase>(keyRef);
  if (validate === undefined) {
    throw new Error(`Could not load schema for '${keyRef}'`);
  }
  return validate(data) ? true : false;
}

export function isIArgumentId(data: any): data is IArgumentId {
  const keyRef = "#/definitions/IArgumentId";
  const validate = ajv.getSchema<IArgumentId>(keyRef);
  if (validate === undefined) {
    throw new Error(`Could not load schema for '${keyRef}'`);
  }
  return validate(data) ? true : false;
}

export function isOneOrManyPhrasemapEntrySingle(data: any): data is OneOrManyPhrasemapEntrySingle {
  const keyRef = "#/definitions/OneOrMany<PhrasemapEntrySingle>";
  const validate = ajv.getSchema<IArgumentId>(keyRef);
  if (validate === undefined) {
    throw new Error(`Could not load schema for '${keyRef}'`);
  }
  return validate(data) ? true : false;
}
