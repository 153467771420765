import React, { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { cloneDeep } from "lodash";

import useNodeToCenter from "./useNodeToCenter";
import SearchSelect from "../../uikit/SearchSelect";
import * as S from "./styled";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  useNodeToCenter(xPos, yPos, data.highlight);

  const setTrigger = (i, value) => {
    const triggers = cloneDeep(data.triggers);
    triggers[i] = value;
    data.update({ triggers });
  };

  const removeTrigger = (i) => {
    const triggers = cloneDeep(data.triggers);
    triggers.splice(i, 1);
    data.update({ triggers });
  };

  const addTrigger = () => {
    const triggers = cloneDeep(data.triggers);
    triggers.push("");
    data.update({ triggers });
  };

  return (
    <S.Node style={{ width: 260 }} isSelected={selected || data.highlight}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
      <S.Label className="drag-handle">INTERRUPTIBLE</S.Label>

      {data.triggers.length === 0 && (
        <S.RangeSlider>
          <span style={{ color: "#fff" }}>Delay ms [{data.interruptibleDelay}]:</span>
          <input
            value={data.interruptibleDelay}
            onChange={(e) => data.update({ interruptibleDelay: e.target.value })}
            type="range"
            min={0}
            max={10000}
            step={100}
          />
        </S.RangeSlider>
      )}

      <S.Triggers>
        {data.triggers.map((trigger, i) => (
          <SearchSelect
            key={i}
            focuses={trigger == ""}
            selected={trigger}
            options={data.intents.concat(data.entities)}
            onChange={(opt) => setTrigger(i, opt)}
            onRemove={() => removeTrigger(i)}
            onFocus={() => data.update({}, 100)}
            onBlur={() => data.update({}, 1)}
          />
        ))}

        <S.AddTrigger onClick={() => addTrigger()}>+ Add trigger</S.AddTrigger>
      </S.Triggers>
    </S.Node>
  );
});
