import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { IHistoryMessage, TextHistoryMessage, FunctionCallMessage, FunctionCallResponseMessage, WarningCallMessage } from "@dasha.ai/sdk/web/gpt";
import { Button, Form, Label, Modal, TextArea } from "semantic-ui-react";
import { EditTextItem } from "./editTextItem";
import { EditCallItem } from "./editCallItem";
import { gptFunctionsStore } from "../../Functions/types";
import { EditCallResponseItem } from "./editCallResponseItem";

export const EditHistoryItemModal: FC<{
  item: IHistoryMessage;
  opened: boolean;
  functions?: gptFunctionsStore;
  onClose: () => void;
  onSave: (item: IHistoryMessage) => void;
}> = observer(({ item, opened, onClose, onSave, functions }) => {
  const [modified, setModified] = useState(item);
  return (
    <Modal open={opened} onClose={onClose}>
      <Modal.Header>Edit item</Modal.Header>
      <Modal.Content>
        {item.type === "Text" && (
          <EditTextItem item={modified as TextHistoryMessage} onChange={(x) => setModified(x)} />
        )}
        {item.type === "call" && (
          <EditCallItem functions={functions} item={modified as FunctionCallMessage} onChange={(x) => setModified(x)} />
        )}
        {
          item.type === "callResponse" && (<>
            <EditCallItem functions={functions} item={(modified as FunctionCallResponseMessage).request} onChange={(x) => setModified({...modified, ...{request: x, functionCallName: x.functionCallName} })} />
            <EditCallResponseItem functionCallResult={(modified as FunctionCallResponseMessage).functionCallResult} onChange={(x) => setModified({...modified, ...{functionCallResult: x} })} />
            </>)
        }
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => onSave(modified)}>
          Save
        </Button>
        <Button positive onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
});
