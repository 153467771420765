import styled from "styled-components";

export const App = styled.div`
  position: relative;
  background: #161729;
  height: 100vh;
  overflow-y: clip;
`;

export const AppBG = styled.div`
  position: relative;
  background: #161729;
  overflow-y: clip;
`;

export const LoadingApp = styled(App)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 18px;
    color: #fff;
    font-family: var(--decorate-font);
  }
`;
