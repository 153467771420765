import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Dropdown } from "semantic-ui-react";
import "./styles.css";
import { useStore } from "@core/api/GlobalStoreContext";
import { trace } from "mobx"

export const ApplicationListDropdown: FC<{ selectedApplication: string|undefined,  selectApplication: (appName: string)=> void}> = observer(({ selectedApplication, selectApplication }) => {
  const { applications } = useStore();
  useEffect(() => {
    applications.TryReload();
  }, [applications]);
  return (
    <Dropdown
      placeholder="Select Application"
      fluid
      search
      selection
      options={applications.ApplicationNames.map((x)=> ({text: x, key:x, value: x}))}
      loading={applications.Loading}
      value={selectedApplication}
      onChange={(e, {value}) => selectApplication(value?.toString()??"")}
    />
  );
});
