import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { DslElement } from "../common";
import { Expression } from "../expressions";

export class PositionalArgument extends DslElement implements interfaces.IPositionalArgument {
  elementType: DslElementType.PositionalArgument = DslElementType.PositionalArgument;
  value: Expression;

  constructor(obj: interfaces.IPositionalArgument, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.value = helpers.ensureIsExpressionInstance(obj.value);
  }
  toJson(): interfaces.IPositionalArgument {
    return {
      elementType: this.elementType,
      location: this.location,
      value: this.value.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `${this.value.toDsl(indent)}`;
  }
}
