import React from "react";
import { ReactComponent as JSIcon } from "../icons/js.svg";
import { ReactComponent as JsonIcon } from "../icons/json.svg";
import { ReactComponent as DslIcon } from "../icons/dsl.svg";
import { ReactComponent as MdIcon } from "../icons/md.svg";
import { ReactComponent as TextIcon } from "../icons/text.svg";
import styled from "styled-components";

export { TextIcon };
export { ReactComponent as DirOpen } from "../icons/dir-open.svg";
export { ReactComponent as DirClose } from "../icons/dir-close.svg";

const FILE_ICONS = {
  js: <JSIcon />,
  ts: <JSIcon />,
  json: <JsonIcon />,
  dsl: <DslIcon />,
  md: <MdIcon />,
};

const Icon = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InvalidIndicator = styled(Icon)`
  &::after {
    content: "";
    background: #f24649;
    border: 1px solid #161729;
    border-radius: 50%;
    position: absolute;
    width: 6px;
    height: 6px;
    bottom: 0;
    right: 0;
  }
`;

export const FileIcon = ({ name, isInvalid, style }) => {
  const icon = FILE_ICONS[name?.split(".").pop()] ?? <TextIcon />;
  if (!isInvalid) return <Icon style={style}>{icon}</Icon>;
  return <InvalidIndicator style={style}>{icon}</InvalidIndicator>;
};

export default FILE_ICONS;
