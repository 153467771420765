import styled from 'styled-components'
import { PureButton } from '../../uikit/Button'

export const Textarea = styled.textarea`
    color: rgb(255, 255, 255);
    background: transparent;
    border: 1px solid rgb(62, 62, 62);
    border-radius: 8px;
    resize: none;
    outline: none;
    max-height: 200px;
    box-sizing: border-box;
    width: 100%;
`

export const Input = styled.input`
    color: rgb(255, 255, 255);
    background: transparent;
    border: 1px solid rgb(62, 62, 62);
    border-radius: 6px;
    resize: none;
    outline: none;
    width: 100%;
    flex: 1;

    box-sizing: border-box;
    padding: 0;
    height: 22px;
`

export const Label = styled.p`
    color: #fff;
    margin: 0;
    margin-bottom: 4px;
    font-size: 12px;
`

export const Select = styled.select`
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-left: -3px;
    text-transform: uppercase;
    width: fit-content;
    max-width: 100%;

    option {
        color: #000;
    }
`

export const Node = styled.div<{ isSelected: boolean }>`
    position: relative;
    max-width: 250px;
    min-width: 100px;
    padding: 12px;
    border-radius: 16px;
    background: rgb(36 37 54);
    box-sizing: border-box;
    border: ${p => p.isSelected ? '2px solid #ccc' : '2px solid transparent'};
`

export const Triggers = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 4px;
`;

export const AddTrigger = styled(PureButton)`
  text-transform: none;
  font-family: var(--main-font);
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  transition: 0.2s color;

  &:hover {
    color: #fff;
  }
`;

export const RangeSlider = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 11px;
    margin: 4px 0;
  }

  input[type="range"] {
    height: 10px;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    margin: 0;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: rgba(255,255,255,0.16);
    border-radius: 6px;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 8px;
    width: 12px;
    border-radius: 5px;
    background: #888;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2px;
  }
`;
