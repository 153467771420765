import styled, { css } from "styled-components";

const GridTemplateWithAudio = css`
  grid-template-columns: 1px minmax(0, 1fr) 1px var(--runner);
  grid-template-rows: minmax(0, 1fr) 1px;

  grid-template-areas:
    "gutter1 editor gutter2 runner"
    "gutter1 gutter3 gutter3 gutter3";
`;

const GridTemplateWithoutAudio = css`
  grid-template-columns: 1px minmax(0, 1fr);
  grid-template-rows: 10vh minmax(0, 1fr);

  grid-template-areas:
    "gutter1 editor"
    "gutter1 runner";
`;

export const Workspace = styled.div<{ hasAudio: boolean }>`
  --gutter-size: 1px;

  display: grid;
  height: 90vh;

  ${({hasAudio}) => hasAudio && GridTemplateWithAudio }
  ${({hasAudio}) => !hasAudio && GridTemplateWithoutAudio }


  #header {
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  #editor {
    grid-area: editor;
  }

  #runner {
    grid-area: runner;
  }
`;
