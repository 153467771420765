import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";

export class ObjectAccessExpression extends Expression implements interfaces.IObjectAccessExpression {
  elementType: DslElementType.ObjectAccessExpression = DslElementType.ObjectAccessExpression;
  propertyName: RawElement<string>;
  parentExpression: Expression;
  optionalAccess: boolean;

  constructor(obj: interfaces.IObjectAccessExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.propertyName = helpers.ensureIsInstance(obj.propertyName, RawElement<string>);
    this.parentExpression = helpers.ensureIsExpressionInstance(obj.parentExpression);
    this.optionalAccess = obj.optionalAccess;
  }
  toJson(): interfaces.IObjectAccessExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      propertyName: this.propertyName.toJson(),
      parentExpression: this.parentExpression.toJson(),
      optionalAccess: this.optionalAccess,
    };
  }
  toDsl(indent: number): string {
    return [this.parentExpression.toDsl(indent), this.optionalAccess ? "?" : "", ".", this.propertyName.value].join("");
  }
}
