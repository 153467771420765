import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import Account from "@core/account/Account";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Input, Button, Message } from "semantic-ui-react";
import "./styles.css";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerQueues } from "./CustomerQueues";
import { InboundVoIP } from "./InboundVoIP";
import { OutboundVoIP } from "./OutboundVoIP";
import { useAccountsStore } from "../core/account/AccountsStoreContext";
import { useStore } from "../core/api/GlobalStoreContext";
interface Props {

}

const LoginAsPannel: FC<Props> = ({ }) => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string|undefined>(undefined);
  const [customerId, setCustomerId] = useState<string|null|undefined>(account.getValue<string>("customerId"));

  const updateCustomerId = useCallback(() => {
    const fetchData = async () => {
      const oldCustomerId = store.account.getValue<string>("customerId");
      try {
        store.account.setValue("customerId", customerId);
        const ci = await dasha.customer.getCustomerInfo({account: store.account.connect()});

        store.account.setValue("customerName", ci.name);
        const newAccount = new Account(store.account.name, store.account.token, store.account.server);
        newAccount.setValue("customerId", customerId);
        store.SwitchAccount(newAccount);        
      } catch (e) {
        setError("Failed to switch to customer");
        store.account.setValue("customerId", oldCustomerId);
        return;
      }
    };
    fetchData();
  }, [store, customerId]);

  const clearCustomerId = useCallback(() => {
    store.account.setValue("customerId", null);
    store.account.setValue("customerName", null);
    const newAccount = new Account(store.account.name, store.account.token, store.account.server);
    store.SwitchAccount(newAccount);
  }, [store]);
  
  return (
    <div className="defaultRoot" id="grid-workspace">
        {error && <Message error>{error}</Message>}
        <Input style={{ width: "100%" }} value={customerId} onChange={(e) => setCustomerId(e.target.value)}/>
        <Button onClick={clearCustomerId}>Logout</Button>
        <Button onClick={updateCustomerId}>Switch</Button>
        
        <CustomerInfo></CustomerInfo>
    </div>
  );
};

export default observer(LoginAsPannel);
