import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

import { DoStatement } from "./DoStatement";
import { Expression } from "../expressions";

export class IfStatement extends DoStatement implements interfaces.IIfStatement {
  elementType: DslElementType.IfStatement = DslElementType.IfStatement;
  condition: Expression;
  then: DoStatement;
  else: interfaces.OptionalValue<DoStatement>;

  constructor(obj: interfaces.IIfStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.condition = helpers.ensureIsExpressionInstance(obj.condition);
    this.then = helpers.ensureIsDoStatementInstance(obj.then);
    if (obj.else.hasValue) {
      this.else = { hasValue: true, value: helpers.ensureIsDoStatementInstance(obj.else.value) };
    } else {
      this.else = { hasValue: false, value: null };
    }
  }
  toJson(): interfaces.IIfStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      condition: this.condition.toJson(),
      then: this.then.toJson(),
      else: interfaces.helpers.createOptionalValue(this.else.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        helpers.indentString(indent) + `if (${this.condition.toDsl(indent)}) ${this.then.toDsl(indent)}`,
        this.else.hasValue ? helpers.indentString(indent) + `else ${this.else.value?.toDsl(indent)}` : null,
      ],
      "\n"
    );
  }
}
