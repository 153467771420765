import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Icon } from "../uikit";
import { PureButton } from "../uikit/Button";
import { Tooltip } from "../uikit/popup";

import { PlaceholderInput } from "./Tree/TreePlaceholderInput";
import Workspace from "../core/workspace/Workspace";
import Tree from "./Tree/Tree";
import * as S from "./styled";

const FilesView: FC<{ workspace: Workspace }> = ({ workspace }) => {
  const [editable, setEditable] = useState<"dir" | "file" | null>(null);
  const isEditable = workspace.project?.metadata.isEditable ?? false;
  const handleSelect = (node) => {
    if (node.type === "folder") return;
    void workspace.addFile(node.path);
  };

  return (
    <>
      <S.Header>
        <h2>Files</h2>

        {isEditable && (
          <div>
            <Tooltip text="Create new directory" position={"top center"}>
              <PureButton onClick={() => setEditable("dir")}>
                <Icon name="create-dir" />
              </PureButton>
            </Tooltip>

            <Tooltip text="Create new file" position={"top center"}>
              <PureButton onClick={() => setEditable("file")}>
                <Icon name="create-file" />
              </PureButton>
            </Tooltip>
          </div>
        )}
      </S.Header>

      {editable === "file" && (
        <PlaceholderInput
          type="file"
          style={{ paddingLeft: 16 }}
          onCancel={() => setEditable(null)}
          onSubmit={(name) => {
            void workspace.project?.createFile(name);
            setEditable(null);
          }}
        />
      )}

      {editable === "dir" && (
        <PlaceholderInput
          type="folder"
          style={{ paddingLeft: 16 }}
          onCancel={() => setEditable(null)}
          onSubmit={(name) => {
            void workspace.project?.createFolder(name);
            setEditable(null);
          }}
        />
      )}

      <Tree
        isEditable={isEditable}
        data={workspace.project?.files ?? []}
        selected={workspace.opened?.path}
        onCreateFile={workspace.project?.createFile}
        onCreateFolder={workspace.project?.createFolder}
        onDelete={workspace.project?.deleteFile}
        onRename={workspace.project?.renameFile}
        onSelect={handleSelect}
        ignore={[".nlu.decorators", ".sessions", ".profile"]}
      />
    </>
  );
};

export default observer(FilesView);
