import styled from "styled-components";
import { Icon } from "../../uikit";

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  flex-shrink: 0;
  
  display: flex;
  justify-content: space-between;
  background: rgba(247, 247, 253, 0.02);
`;

export const InvalidIndicator = styled(Icon)`
  margin-top: 2px;
  margin-right: 6px;
  circle {
    fill: tomato;
  }
`;
