export const downloadBlob = (name: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  document.body.appendChild(a);
  a.hidden = true;
  a.href = url;
  a.download = name + ".zip";
  a.click();
  window.URL.revokeObjectURL(url);
};

export const delay = (ms: number) => new Promise((resolve) => setInterval(resolve, ms));
