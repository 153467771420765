import React, { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import useNodeToCenter from "./useNodeToCenter";
import * as S from "./styled";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  useNodeToCenter(xPos, yPos, data.highlight);

  return (
    <S.Node className="drag-handle" isSelected={selected || data.highlight}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />

      <S.Label>TRIGGER BY TIMEOUT:</S.Label>
      <label style={{ display: "flex", marginTop: 8, alignItems: "center" }}>
        <S.Label style={{ marginRight: 8 }}>Timemout ms.</S.Label>
        <S.Input
          type="number"
          style={{ width: 60 }}
          value={data.timeoutTrigger}
          onChange={(e) => data.update({ timeoutTrigger: e.target.value })}
        />
      </label>
  
      <Handle
        id="b"
        type="source"
        position={Position.Right}
        style={{ top: "50%", right: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
    </S.Node>
  );
});
