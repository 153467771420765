import { Action, InputType } from "@projectstorm/react-canvas-core";
import { DragNewLinkState } from "@projectstorm/react-diagrams";
import TooltipModel from "../Tooltip/Model";

export default class ShowTooltipAction extends Action {
  tooltip?: TooltipModel;

  constructor() {
    super({
      type: InputType.MOUSE_MOVE,
      fire: (actionEvent: any) => {
        const { clientX: x, clientY: y } = actionEvent.event;
        const state = this.engine.getStateMachine().getCurrentState();

        if (state instanceof DragNewLinkState) {
          return this.removeTooltip();
        }

        const element = this.engine.getMouseElement(actionEvent.event);
        const pos = this.engine.getRelativePoint(x, y);
        const data = (element as any)?.tooltip;

        if (!data) return this.removeTooltip();
        const tooltip = this.getTooltip();

        tooltip.show({ x: pos.x, y: pos.y, data });
        this.engine.repaintCanvas();
      },
    });
  }

  getTooltip() {
    const model = this.engine.getModel();

    if (this.tooltip && this.tooltip.getParentCanvasModel() !== model) {
      this.tooltip.isShow = false;
    }

    if (this.tooltip && this.tooltip.getParentCanvasModel() === model) {
      return this.tooltip;
    }

    this.tooltip = new TooltipModel({ x: 0, y: 0, data: {} });
    model.addLayer(this.tooltip);
    this.tooltip.isShow = false;

    return this.tooltip;
  }

  removeTooltip() {
    if (this.tooltip) {
      this.tooltip.isShow = false;
      this.engine.repaintCanvas();
    }
  }
}
