import React from "react";
import { Input, Textarea } from "../uikit/input";
import Project from "../core/explorer/Project";

const useRename = (project?: Project, needCopy = false) => {
  const [name, setName] = React.useState("");
  const [description, setDesc] = React.useState("");
  const isDisabled = name.length == 0;
  const reset = () => {
    setName("");
    setDesc("");
  };

  React.useEffect(() => {
    if (!project) return;
    setName(project.metadata.name + (needCopy ? " (copy)" : ""));
    setDesc(project.metadata.description);
  }, [project, needCopy]);

  const renameInputs = (nameLabel = "App name", descriptionLabel = "Description") => (
    <>
      <Input
        value={name}
        label={`${nameLabel} (${name.length}/60 characters)`}
        onChange={(e) => setName(e.target.value.substring(0, 60))}
      />

      <Textarea
        max={140}
        value={description}
        label={`${descriptionLabel} (${description.length}/140 characters)`}
        onChange={(e) => setDesc(e.target.value.substring(0, 140))}
      />
    </>
  );

  return { renameInputs, reset, isDisabled, name, description };
};

export default useRename;
