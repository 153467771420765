import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { DslElement, RawElement } from "../common";
import { Expression } from "../expressions";

export class DigressionVariable extends DslElement implements interfaces.IDigressionVariable {
  elementType: DslElementType.DigressionVariable = DslElementType.DigressionVariable;
  isShared: boolean;
  name: RawElement<string>;
  type: interfaces.OptionalValue<RawElement<string>>;
  initialValue: interfaces.OptionalValue<Expression>;

  constructor(obj: interfaces.IDigressionVariable, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.isShared = obj.isShared;
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.type = helpers.ensureIsOptionalInstance(obj.type, RawElement<string>);
    if (obj.initialValue.hasValue) {
      this.initialValue = interfaces.helpers.createOptionalValue(helpers.ensureIsExpressionInstance(obj.initialValue.value));
    } else {
      this.initialValue = interfaces.helpers.createOptionalValue<Expression>(null);
    }
  }
  toJson(): interfaces.IDigressionVariable {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      isShared: this.isShared,
      type: interfaces.helpers.createOptionalValue(this.type.value?.toJson()),
      initialValue: interfaces.helpers.createOptionalValue(this.initialValue.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + (this.isShared ? "shared " : ""),
      "var ",
      this.name.value,
      this.type.hasValue ? `: ${this.type.value.value}` : "",
      this.initialValue.hasValue ? ` = ${this.initialValue.value.toDsl(indent)}` : "",
    ].join("");
  }
}
