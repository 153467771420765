import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { MarkupType } from "../../core/workspace/session-storage/devlogs";
import { PureButton, StrokeButton, ActionButton } from "../../uikit/Button";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  height: 64px;
  padding: 0 16px;

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #eceff8;
    margin: 0;
    margin-right: auto;
  }

  ${ActionButton}, ${StrokeButton} {
    margin-left: 16px;
    height: 32px;
    width: 80px;
  }
`;

export const Phrase = styled.div`
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const PhraseToken = styled(PureButton)<{ isDisabled: boolean; isSelected: boolean }>`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;

  text-transform: none;
  color: hsl(var(--hue), 73%, 70%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.2s border;
  border-radius: 4px;
  cursor: pointer;
  padding: 1px;

  &:hover {
    border-color: rgba(255, 255, 255, 0.6);
  }

  ${(p) =>
    p.isDisabled &&
    css`
      border: 1px solid transparent;
    `}

  ${(p) =>
    p.isSelected &&
    css`
      background: #5c5d69;
    `}
`;

export const ButtonBack = styled(PureButton)``;

export const Attention = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: rgba(255, 211, 0, 0.75);
  margin: 0;
`;

export const Body = styled.div`
  overflow-y: auto;
  height: calc(90vh - 80px);
`;

export const Section = styled.div`
  padding: 24px 16px 0;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.3);
    margin: 0;
  }

  svg {
    margin-left: 4px;
    cursor: pointer;
  }
`;

export const Triggers = styled.div`
  background: rgba(255, 255, 255, 0.04);
  width: calc(100% + 32px);
  min-height: 34px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  margin: 0 -16px;
`;

export const AddTrigger = styled(PureButton)`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  text-transform: none;
`;

export const CreateIntent = styled(PureButton)`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  margin-top: 8px;
  text-transform: none;
`;

export const AddPhraseField = styled.div`
  position: relative;

  input {
    background: #2b2b3d;
    border-radius: 4px;
    width: 100%;
    height: 32px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;

    transition: 0.2s opacity;
    border: 1px solid rgba(255, 255, 255, 0);
    box-sizing: border-box;
    padding: 0 8px;
    outline: none;

    &:focus {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  button {
    text-transform: none;
    position: absolute;
    right: 8px;
    top: 0;

    transition: 0.2s color;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.75);
    height: 100%;

    &:hover {
      color: #ffffff;
    }
  }
`;

export const intentState = {
  [MarkupType.unmark]: css`
    background: transparent;
    color: rgba(255, 255, 255, 0.75);
  `,

  [MarkupType.include]: css`
    background: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.75);
    border: 1px solid transparent;
  `,

  [MarkupType.exclude]: css`
    background: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;

    &::after {
      content: "";
      width: calc(100% - 21px);
      height: 1px;
      background: #d3d3d7;
      position: absolute;
      top: 10px;
    }
  `,
};

export const entityState = {
  readonly: css`
    padding: 0;
    background: transparent;
    color: rgba(255, 255, 255, 0.75);

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 50%;
      margin-right: 4px;
      background: hsl(var(--hue), 35%, 57%);
    }
  `,

  [MarkupType.unmark]: css`
    background: transparent;
    color: rgba(255, 255, 255, 0.75);

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 50%;
      margin-right: 4px;
      background: hsl(var(--hue), 35%, 57%);
    }
  `,

  [MarkupType.include]: css`
    background: hsl(var(--hue), 35%, 30%);
    color: rgba(255, 255, 255, 0.75);
    border: 1px solid transparent;

    &:hover {
      color: #ffffff;
      border: 1px solid transparent;
    }
  `,

  [MarkupType.exclude]: css`
    background: hsl(var(--hue), 35%, 30%, 0.5);
    color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;

    &::after {
      content: "";
      width: calc(100% - 21px);
      height: 1px;
      background: #d3d3d7;
      position: absolute;
      top: 10px;
    }
  `,
};

export const Badge = styled.div<{ state: FlattenSimpleInterpolation; readOnly?: boolean; hovered?: boolean }>`
  position: relative;
  transition: 0.2s opacity background color border;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  height: 18px;

  button {
    height: 100%;
    margin-right: -4px;
    width: 16px;
  }

  ${(p) => p.state};

  ${(p) =>
    p.readOnly &&
    css`
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: transparent;

      &:hover {
        border: 1px solid rgba(0, 0, 0, 0);
      }
    `}

  ${(p) =>
    p.hovered &&
    css`
      border: 1px solid rgba(255, 255, 255, 0.5);
    `}
`;

export const PhraseField = styled.div<{ isLoading?: boolean }>`
  min-height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: -16px;
  padding: 0 16px;

  border-top: 1px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: 0.2s border;

  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.02);
  }

  span {
    background: none;
    outline: none;
    border: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #d9d9e2;
    height: 100%;
    display: flex;

    overflow-x: hidden;
    align-items: baseline;
    max-width: 100%;
    overflow-wrap: anywhere;
    margin: 8px 0;
  }

  div:last-child {
    opacity: 0;
    display: flex;
    align-items: center;
    transition: 0.2s opacity;
    margin-left: auto;

    button {
      opacity: 0.8;
      margin-left: 12px;
      transition: 0.2s opacity;
    }

    button:hover {
      opacity: 1;
    }

    button:disabled {
      opacity: 0.2;
    }
  }

  &:hover {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    div {
      opacity: 1;
    }
  }

  ${(p) =>
    p.isLoading &&
    css`
      div:last-child {
        opacity: 1;
      }
    `}
`;

export const GeneratedDecorator = styled.div`
  background: #ffd300;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 8px;
`;

export const OpenSearchButton = styled(PureButton)`
  width: 14px;
  height: 14px;
  background: #5855f4;
  border-radius: 50%;
  padding: 3px;
  padding-top: 4px;
`;
