import React, { FC, useState } from "react";

import { Icon } from "../../uikit";
import * as S from "../styled";
import { TestCase } from "../../core/api/prompts/TestCase";
import { Prompt } from "../../core/api/prompts/PromptStore";
import UIManager from "../../core/misc/UIManager";
import { MenuOption, PopupMenu } from "../../uikit/popup";
import { PlaceholderInput } from "../../uikit/Input/placeholderInput";
import { ActionsWrapper } from "../TestList/styled";
import { PureButton } from "../../uikit/Button";
import { observer } from "mobx-react-lite";

interface Props {
  item: TestCase;
  prompt: Prompt;
  onSelect(id: string);
  onRun(id: string, times: number);
}

export const TestCard: FC<Props> = observer(({ item, prompt, onSelect, onRun }) => {
  const [isEditing, setEditing] = useState(false);

  const handleCancel = () => setEditing(false);
  const toggleEditing = () => setEditing(!isEditing);
  const commitDelete = () =>
    prompt.deleteTestCase(item.id).catch((e) => UIManager.notice(`Failed to delete test case ${e}`));

  const commitEditing = (name: string) => {
    item.setName(name);
    item.saveAsync().catch((e) => UIManager.notice(`Failed to save test case ${e}`));
    setEditing(false);
  };

  const actions: MenuOption[] = [
    {
      label: "Rename",
      action: toggleEditing,
      isEnable: true,
    },
    {
      label: "Remove",
      action: commitDelete,
      isEnable: true,
    },
    {
      label: "Run",
      action: () => onRun(item.id, 1),
      isEnable: true,
    },
    {
      label: "Run x3",
      action: () => onRun(item.id, 3),
      isEnable: true,
    },
    {
      label: "Duplicate",
      action: () => prompt.copyTestCaseAsync(item.id)
        .catch((e) => UIManager.notice("Failed to copy test case"))
        .then(() => UIManager.success("Test case copy success")),
      isEnable: true,
    },    
  ];

  return (
    <S.TestCard onClick={() => onSelect(item.id)} status={item.status}>
      <S.ActionMenu>
          {
            <PopupMenu actions={actions} closeAfterSelect on="click" position={["bottom center", "top center"]}>
              <PureButton>
                <Icon name="menu" />
              </PureButton>
            </PopupMenu>
          }
      </S.ActionMenu>
      {isEditing && <PlaceholderInput defaultValue={item.name} onSubmit={commitEditing} onCancel={handleCancel} />}
      {!isEditing && <h2>{item.name}</h2>}
      <S.Description>{item.description ?? ""}</S.Description>
    </S.TestCard>
  );
});
