import styled from "styled-components";
import { PureButton } from "../../uikit/Button";

export const Buttons = styled.div`
  display: flex;
  gap: 33px;
  padding: 24px 5%;
`;

export const CreateProjectButton = styled(PureButton)`
  flex: 1;
  border: 2px solid #5855f4;
  border-radius: 12px;
  height: 80px;
  display: flex;
  padding: 24px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  transition: 0.2s border;

  &:hover {
    border-color: #6F6CFA;
  }

  @media (max-width: 800px) {
    justify-content: center;
  }

  svg:first-child {
    flex-shrink: 0;
    margin-right: 20px;
    @media (max-width: 1092px) {
      display: none;
    }

    @media (max-width: 800px) {
      display: block;
      margin: 0;
    }
  }

  svg:last-child {
    margin-left: auto;
    width: 14px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  div {
    max-width: 100%;
  }

  h2 {
    font-family: "Open Sans";
    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    text-align: left;
    color: #eceff8;
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 800px) {
      display: none;
    }
  }

  p {
    font-family: "Open Sans";
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 800px) {
      display: none;
    }
  }
`;
