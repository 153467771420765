import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import toAST, { ValueNode } from "json-to-ast";
import debounce from "lodash/debounce";
import uniq from "lodash/uniq";

import api from "../../account/api";
import Project from "../../explorer/Project";
import TextStorage from "../TextStorage";
import ParaphrasePlugin from "./ParaphrasePlugin";
import { BindDecorators, findPropertyAST, genDecorators } from "./ast.helpers";
import { DatasetDocument, IntentObject, EntityObject } from "./types";
import styles from "./styles.module.css";

class DatasetStorage extends TextStorage {
  intents: string[] = [];
  entities: string[] = [];
  entitiesWithValues: { label: string; items: string[] }[] = [];

  ast: ValueNode | null = null;
  document: DatasetDocument | null = null;
  paraphrases = new ParaphrasePlugin(this);

  decorations: string[] = [];
  binds: Record<string, Record<string, string[]>> = {};
  nluDecoratorsKey = ".nlu.decorators";

  private editor?: monaco.editor.IStandaloneCodeEditor;

  constructor(readonly project: Project, readonly path: string) {
    super(project, path);

    this.isPinned = true;
    this.disposables.push(
      this.model.onDidChangeContent(async () => {
        try {
          this.document = JSON.parse(this.model.getValue());
          this.ast = toAST(this.model.getValue());
          this.intents = Object.keys(this.document?.intents ?? {});
          this.entities = Object.keys(this.document?.entities ?? {});
          this.entitiesWithValues = Object.keys(this.document?.entities ?? {}).map((entityName) => ({
            label: entityName,
            items: this.document ? this.document.entities[entityName].values.map((value) => value.value) : [],
          }));
          this.updateDecorators();
        } catch {
          this.document = null;
          this.ast = null;
        }
      })
    );

    void this.loadDecorators();
  }

  createIntent({ name, includes, excludes, includesParaphrases, excludesParaphrases }: IntentObject) {
    if (this.document == null) return;
    this.document.intents[name] = { includes, excludes };
    this.document.intents[name].includes.push(...includesParaphrases);
    this.document.intents[name].excludes.push(...excludesParaphrases);
    this.intents.push(name);

    const content = JSON.stringify(this.document, null, 2);
    this.addDecorators(`intents.${name}.includes`, includesParaphrases, styles.paraphrase);
    this.addDecorators(`intents.${name}.excludes`, excludesParaphrases, styles.paraphrase);
    this.model.setValue(content);
  }

  createEntity({ name, values, open_set, includes }: EntityObject) {
    if (this.document == null) return;
    if (!this.document.entities) this.document.entities = {};
    this.document.entities[name] = { values, open_set, includes, excludes: [] };
    this.entities.push(name);

    const content = JSON.stringify(this.document, null, 2);
    this.model.setValue(content);
  }

  async getParaphrase(text: string) {
    const result = await api.paraphrase.paraphase([text], this.project.account);
    return result[0].paraphrases;
  }

  unsetDataset(dataset: DatasetDocument) {
    const exclude = (arr: string[], rm: string[]) => {
      return arr.filter((v) => rm.includes(v) === false);
    };

    ["intents", "entities"].forEach((variant: "intents" | "entities") => {
      Object.entries(dataset[variant]).forEach(([name, set]) => {
        if (this.document == null) return;
        if (this.document[variant][name] == null) {
          this.document[variant][name] = { includes: [], excludes: [] };
        }

        const { includes = [], excludes = [] } = this.document[variant][name];
        this.document[variant][name].excludes = exclude(excludes, set.excludes);
        this.document[variant][name].includes = exclude(includes, set.includes);
        this.removeDecorators([variant, name, "excludes"].join("."), set.excludes, styles.debugger);
        this.removeDecorators([variant, name, "includes"].join("."), set.includes, styles.debugger);
      });
    });

    const content = JSON.stringify(this.document, null, 2);
    this.model.setValue(content);
  }

  mergeDataset(dataset: DatasetDocument) {
    const reveals: { value: string; path: string }[] = [];

    ["intents", "entities"].forEach((variant: "intents" | "entities") => {
      Object.entries(dataset[variant]).forEach(([name, set]) => {
        if (this.document == null) return;
        if (this.document[variant][name] == null && variant === "intents") {
          this.document[variant][name] = { includes: [], excludes: [] };
        }

        if (this.document[variant][name] == null && variant === "entities") {
          // @ts-ignore
          this.document[variant][name] = set;
        }

        const { includes = [], excludes = [] } = this.document[variant][name] ?? {};
        this.document[variant][name].excludes = uniq([...excludes, ...set.excludes]);
        this.document[variant][name].includes = uniq([...includes, ...set.includes]);

        if (variant === "entities") {
          const values: { value: string; synonyms: string[] }[] = [];
          const exist = this.document[variant][name].values ?? [];

          // @ts-ignore
          [...exist, ...(set.values ?? [])].forEach((value) => {
            const index = values.findIndex((v) => value.value === v.value);
            if (index !== -1) {
              values[index].synonyms = uniq([...(values[index]?.synonyms ?? []), ...(value?.synonyms ?? [])]);
            } else {
              values.push(value);
            }
          });

          this.document[variant][name].values = values;
        }

        this.addDecorators([variant, name, "excludes"].join("."), set.excludes, styles.debugger);
        this.addDecorators([variant, name, "includes"].join("."), set.includes, styles.debugger);

        if (set.includes.length) {
          const path = [variant, name, "includes"].join(".");
          set.includes.forEach((value) => reveals.push({ path, value }));
        }

        if (set.excludes.length) {
          const path = [variant, name, "excludes"].join(".");
          set.excludes.forEach((value) => reveals.push({ path, value }));
        }
      });
    });

    const content = JSON.stringify(this.document, null, 2);
    this.model.setValue(content);
    this.revealDecorators(reveals);
  }

  openInEditor(editor: monaco.editor.IStandaloneCodeEditor): void {
    super.openInEditor(editor);
    this.editor = editor;
    if (this.project.metadata.isEditable) {
      this.paraphrases.activate(editor);
    }
  }

  closeInEditor(editor: monaco.editor.IStandaloneCodeEditor): void {
    super.closeInEditor(editor);
    this.editor = undefined;
    this.paraphrases.dispose();
  }

  revealTrigger(type: "entities" | "intents", name: string) {
    if (this.ast == null) return;
    const node = findPropertyAST(`${type}.${name}`, this.ast);
    if (node?.loc?.start == null || node?.loc?.end == null) return;

    const end = node.loc.end.line;
    const start = node.loc.start.line;
    const selection = new monaco.Selection(start, 0, end, 0);
    this.editor?.revealLines(start, end, 0);
    this.editor?.setSelection(selection);
  }

  async loadDecorators() {
    this.binds = await this.project.json<BindDecorators>(this.nluDecoratorsKey).catch(() => ({}));
    // TODO: Make clean up unuse values from decorators cache...
    this.updateDecorators();
  }

  // TODO: Do it more effectly. By diff from binds
  revealDecorators(decs: { value: string; path: string }[]) {
    if (this.ast == null) return;
    const ast = this.ast;
    const ranges = decs.flatMap(({ path, value }) => {
      const [decorator] = genDecorators({ [path]: { [value]: [""] } }, ast);
      if (decorator == null) return [];
      return [decorator.range];
    });

    if (ranges.length === 0) return;
    this.editor?.revealRangeInCenter(ranges[0], 0);
    this.editor?.setSelections(
      ranges.map(({ startLineNumber, endColumn, endLineNumber }) => {
        return new monaco.Selection(startLineNumber, 0, endLineNumber, endColumn);
      })
    );
  }

  addDecorators(path: string, values: string[], decorator: string) {
    if (this.binds[path] == null) this.binds[path] = {};
    values.forEach((value) => {
      if (this.binds[path][value] == null) this.binds[path][value] = [];
      if (this.binds[path][value].some((v) => v === decorator)) return;
      this.binds[path][value].push(decorator);
    });

    this.updateDecorators();
  }

  removeDecorators(path: string, values: string[], decorator: string | null = null) {
    if (this.binds[path] == null) this.binds[path] = {};
    const ref = this.binds[path];

    values.forEach((value) => {
      if (decorator == null) {
        ref[value] = [];
        return;
      }

      if (ref[value] == null) ref[value] = [];
      ref[value] = ref[value].filter((v) => v !== decorator);
      if (ref[value].length === 0) delete ref[value];
    });

    this.updateDecorators();
  }

  saveDecorators = debounce(async () => {
    await this.project.updateContent(this.nluDecoratorsKey, this.binds);
  }, 1000);

  updateDecorators = debounce(() => {
    if (this.ast == null) return;
    const decorators = genDecorators(this.binds, this.ast);
    this.decorations = this.model.deltaDecorations(this.decorations, decorators);
    void this.saveDecorators();
  }, 10);
}

export default DatasetStorage;
