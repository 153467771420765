import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DoStatement } from "./DoStatement";
import { Expression } from "../expressions";

export class ExpressionStatement extends DoStatement implements interfaces.IExpressionStatement {
  elementType: DslElementType.ExpressionStatement = DslElementType.ExpressionStatement;
  expression: Expression;

  constructor(obj: interfaces.IExpressionStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.expression = helpers.ensureIsExpressionInstance(obj.expression);
  }
  toJson(): interfaces.IExpressionStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      expression: this.expression.toJson(),
    };
  }
  toDsl(indent: number): string {
    return [helpers.indentString(indent), this.expression.toDsl(indent), ";"].join("");
  }
}
