import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import { Message } from "../../core/workspace/session-storage/types";
import { MessageWithTime } from "./types";

dayjs.extend(durationPlugin);

export const formatAudioTime = (sec: number) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);

  let minutesStr = "";
  let secondsStr = "";

  if (minutes < 10) {
    minutesStr = `0${minutes}`;
  }

  if (seconds < 10) {
    secondsStr = `0${seconds}`;
  }

  return `${minutesStr || minutes}:${secondsStr || seconds}`;
};

export const getMessagesWithTimeInAudio = (messages: Message[], duration: number): MessageWithTime[] => {
  const messagesWithTimeRange: MessageWithTime[] = [];
  let prevMessagesDuration = 0;

  messages.forEach((message, index, messages) => {
    let start: number | null = null;
    let end: number | null = null;

    const nextMessage = messages[index + 1] || null;
    const prevMessage = messages[index - 1] || null;

    if (index === 0) {
      start = 0;
    } else if (prevMessage) {
      start = prevMessagesDuration;
    }

    if (nextMessage) {
      // const messageDuration = dayjs.duration(dayjs(nextMessage.time).diff(dayjs(message.time)));
      // const messageDurationInFloat = +`${messageDuration.seconds()}.${messageDuration.milliseconds()}`;
      const messageDuration = nextMessage.time - message.time;
      end = prevMessagesDuration + messageDuration;
      prevMessagesDuration += messageDuration;
    } else {
      end = duration;
    }

    if (start !== null && end !== null) {
      messagesWithTimeRange.push({ ...message, timeStart: start / 1000, timeEnd: end / 1000 });
    }
  });

  return messagesWithTimeRange;
};

export const getAudioMarkProps = (message: MessageWithTime, duration: number, sliderWidth: number) => {
  console.log({ message, duration, sliderWidth });
  const messageDuration = message.timeEnd - message.timeStart;
  const percentFromDuration = 100 / (duration / messageDuration);
  const markWidth = (sliderWidth / 100) * percentFromDuration;
  const markOffset = (sliderWidth / 100) * (100 / (duration / message.timeStart));

  return [markWidth, markOffset];
};
