import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { IAccount } from "../core/account/interface";
import { ActionButton } from "../uikit";
import { Button, Divider, Input, Loader, Message } from "semantic-ui-react";
import RestApi from "../core/account/api/fetch";
import CopyButton from "../uikit/CopyButton";

import "./index.css";
import SessionRunner from "../core/workspace/session-storage/SessionRunner";
import { RunnerType, SessionProtocol } from "../core/workspace/session-storage/types";
import DialogPanel from "../RunnerPanel/DialogPanel";
import GridLayout from "../core/misc/GridLayout";
import * as S from "./styled";
import { parseIntents } from "../core/workspace/session-storage/utils";
import * as dasha from "@dasha.ai/sdk/web";
import { useStore } from "../core/api/GlobalStoreContext";
import * as types from "../core/workspace/session-storage/types";
import { DevLog, GptStartMessage } from "../core/workspace/session-storage/devlogs";
import { AddTestModal, TestCreateData } from "../TestViewer/AddTestModal";
import UIManager from "../core/misc/UIManager";
import { HistoryHolder } from "../uikit/gpt/History/types";
import { useNavigate } from "react-router-dom";
import { gptFunctionsStore } from "../uikit/gpt/Functions/types";
interface Props {
  jobId: string;
  devLog: DevLog[];
  canHide: boolean;
}

const dashaApi = new RestApi();

export const InspectorPanelInternal: FC<Props> = observer(({ jobId, devLog, canHide }) => {
  const { account, workspace, explorer, prompts } = useStore();
  const [sessionRunner, setSessionRunner] = useState<SessionRunner | undefined>(undefined);
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const [inputs, setInputs] = useState<{ [key: string]: string } | undefined>();
  const navigate = useNavigate();
  const createProjectFromJob = useCallback(() => {
    const fetchData = async () => {
      const fetch = await dashaApi.signFetch(account);
      const response = await (await fetch(`api/v1/logs/entity/${jobId}`)).json();
      const appId = response["applicationId"];
      const c = account.connect();
      const packageContent = await dasha.applications.getApplicationPackageById(appId, { account: c });
      const app = await explorer.createFromPackage(packageContent.content, `from job: ${jobId}`, inputs, sessionRunner);
      navigate(`/project/${app.id}`);
    };

    fetchData().catch((e) => UIManager.notice("Failed to load:" + e.message));
  }, [account, explorer, jobId, devLog, sessionRunner, inputs]);

  useEffect(() => {
    layout.bindGrid("#grid-workspace");
  }, [layout]);

  const createTestCase = useCallback(
    (instanceId: string, data: TestCreateData) => {
      const addAsync = async () => {
        try {
          await prompts.addTestAsync({
            name: data.TestName,
            promptName: data.PromptName,
            description: data.TestDescription,
            originalJobId: jobId,
            gptInstanceId: instanceId,
            expectedResult: data.expectedHistory.Messages,
            debugLog: devLog,
          });
          UIManager.success("Test added");
        } catch (e) {
          UIManager.notice(`Failed to create test: ${e}`);
        }
      };
      addAsync();
    },
    [devLog, jobId]
  );

  useEffect(() => {
    if (jobId === undefined) {
      return;
    }
    const fetch = async () => {
      var outputData: { [key: string]: any } | undefined = undefined;
      var inputData: { [key: string]: any } | undefined = undefined;
      var refTime: number | undefined;
      const logMessages: types.Message[] = [];
      var idCounter = 0;
      const visitedVoiceSegments = new Set<number>();
      const callRequests = new Map<string, DevLog>();
      const promptStarts = new Map<string, GptStartMessage>();
      const shownGptInstances = new Set<string>();
      const accountInternal = account.connect();
      const usageRaw = await dasha.usage.getUsageForJob(jobId!, { account: accountInternal });
      const usage = dasha.usage.aggregateUsage(usageRaw ?? [], new Set(["usageType", "usageSubType", "eventType"]));

      for (var message of devLog) {
        if (message.msg.msgId === "GptStartMessage") {
          promptStarts.set(message.msg.InstanceId, message.msg);
        }
        if (message.msg.msgId === "GptFunctionCallRequestMessage") {
          callRequests[message.msg.InstanceId + message.msg.FunctionName] = message.msg;
        }
        if (message.msg.msgId === "GptResponseMessage" && !shownGptInstances.has(message.msg.InstanceId)) {
          const instanceId = message.msg.InstanceId;
          const start = promptStarts.get(instanceId);
          if (start?.CallName === "askGPT") {
            const functions = new gptFunctionsStore(Object.values(promptStarts.get(message.msg.InstanceId)?.Functions??[]));
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `askGPT called`,
              time: new Date(start.StartTime).valueOf(),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              actions: shownGptInstances.has(message.msg.InstanceId) ? undefined : (
                <AddTestModal
                  functions={functions}
                  instanceId={message.msg.InstanceId}
                  initialPromptName={promptStarts.get(message.msg.InstanceId)?.PromptName}
                  onCommit={function (data: TestCreateData): void {
                    createTestCase(instanceId, data);
                  }}
                  history={HistoryHolder.fromLogs(
                    devLog.map((x) => x.msg),
                    message.msg.InstanceId
                  )}
                />
              ),
            });
            shownGptInstances.add(message.msg.InstanceId);
          }
        }
        if (message.msg.msgId === "JobCommunicationDebugMessage") {
          if (message.msg.content.type === "notification" && message.msg.content.method === "debugLog") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: message.msg.content.parameters.message,
              time: new Date(message.time).valueOf(),
              from: "ai",
              isSystem: true,
            });
          }
        }
        if (message.msg.msgId === "JobCommunicationMessage") {
          if (message.msg.content.type === "request") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `Start external call ${message.msg.content.method.replace("graphCall/", "")}(${JSON.stringify(
                message.msg.content.parameters
              )})`,
              time: new Date(message.time).valueOf(),
              from: "ai",
              isSystem: true,
            });
          }
          if (message.msg.content.type === "response") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `Finish external call = ${JSON.stringify(message.msg.content.result)}`,
              time: new Date(message.time).valueOf(),
              from: "ai",
              isSystem: true,
            });
          }
          if (message.msg.content.type === "error") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `Failed external call = ${message.msg.content.message}`,
              time: new Date(message.time).valueOf(),
              from: "ai",
              isSystem: true,
            });
          }
        }
        if (message.msg.msgId === "EventUsageMessage") {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Event ${message.msg.EventType}/${message.msg.UsageType}/${message.msg.UsageSubType}=${message.msg.Usage}`,
            time: new Date(message.time).valueOf(),
            from: "ai",
            isSystem: true,
          });
        }
        if (message.msg.msgId === "LogMessage" && message.msg.level >= 40) {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: message.msg.message,
            time: new Date(message.time).valueOf(),
            from: "ai",
            isSystem: true,
          });
        }

        if (message.msg.msgId === "FailedOpenSessionChannelMessage") {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `${message.msg.reason} ${message.msg.details}`,
            time: new Date(message.time).valueOf(),
            from: "ai",
            isSystem: true,
          });
        }

        if (message.msg.msgId === "GptFunctionCallResponseMessage") {
          const msg2 = callRequests[message.msg.InstanceId + message.msg.FunctionName];
          const instanceId = message.msg.InstanceId;
          const functions = new gptFunctionsStore(Object.values(promptStarts.get(message.msg.InstanceId)?.Functions??[]));
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Start call ${message.msg.FunctionName}(${JSON.stringify(msg2.Args)})`,
            time: new Date(msg2.StartTime).valueOf(),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            actions: shownGptInstances.has(message.msg.InstanceId) ? undefined : (
              <AddTestModal
                functions={functions}
                instanceId={message.msg.InstanceId}
                initialPromptName={promptStarts.get(message.msg.InstanceId)?.PromptName}
                onCommit={function (data: TestCreateData): void {
                  createTestCase(instanceId, data);
                }}
                history={HistoryHolder.fromLogs(
                  devLog.map((x) => x.msg),
                  message.msg.InstanceId
                )}
              />
            ),
          });
          shownGptInstances.add(message.msg.InstanceId);
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Finish call ${message.msg.FunctionName}(${JSON.stringify(msg2.Args)}) = ${JSON.stringify(
              message.msg.ReturnValue
            )}`,
            time: new Date(message.msg.EndTime).valueOf(),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
          });
        }

        if (message.msg.msgId === "OpenedSessionChannelMessage" && refTime === undefined) {
          refTime = new Date(message.time).valueOf();
        }

        if (
          message.msg.msgId === "StoppedPlayingAudioChannelMessage" &&
          message.msg.PlayedText !== null &&
          message.msg.PlayedText !== undefined &&
          message.msg.PlayedText !== ""
        ) {
          var thinking: string | undefined = undefined;
          if (message.msg.Metadata?.SourceName === "Thinking") {
            thinking = message.msg.Metadata?.SourceData;
          }
          const gptInstanceId = message.msg.Metadata?.CorrelationId ?? undefined;
          const functions = new gptFunctionsStore(Object.values(promptStarts.get(gptInstanceId)?.Functions??[]));
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: message.msg.phraseSequenceId,
            message: message.msg.PlayedText,
            time: +new Date(message.msg.StartPlayingTime),
            from: "ai",
            actions:
              gptInstanceId === undefined || shownGptInstances.has(gptInstanceId) ? undefined : (
                <>
                  <Divider />
                  <AddTestModal
                    functions={functions}
                    instanceId={gptInstanceId}
                    initialPromptName={promptStarts.get(gptInstanceId)?.PromptName}
                    onCommit={function (data: TestCreateData): void {
                      createTestCase(gptInstanceId, data);
                    }}
                    history={HistoryHolder.fromLogs(
                      devLog.map((x) => x.msg),
                      gptInstanceId
                    )}
                  />
                </>
              ),
            thinking: thinking,
          });
          if (gptInstanceId !== undefined) {
            shownGptInstances.add(gptInstanceId);
          }
        }

        if (message.msg.msgId === "JobDataMessage") {
          outputData = message.msg.outputData;
          inputData = message.msg.inputData;
        }

        if (
          message.msg.msgId === "RecognizedSpeechMessage" &&
          message.msg.__sourceMessageType__ === "FinalTextChannelMessage"
        ) {
          if (!visitedVoiceSegments.has(message.msg.voiceSegmentId)) {
            visitedVoiceSegments.add(message.msg.voiceSegmentId);
            logMessages.push({
              id: idCounter++,
              triggers: parseIntents(message.msg),
              message: message.msg.results[0].text,
              voiceSegmentId: message.msg.voiceSegmentId,
              time: +new Date(message.time),
              changeContext: {},
              transitions: [],
              from: "human",
            });
          }
        }

        if (message.msg.msgId === "ProtocolSpecificMessage" && message.msg.type === "dtmf") {
          logMessages.push({
            id: idCounter++,
            triggers: [],
            message: message.msg.message,
            voiceSegmentId: 0,
            time: +new Date(message.time),
            changeContext: {},
            transitions: [],
            from: "human",
          });
        }
      }
      logMessages.sort((a, b) => a.time - b.time);
      for (var usageEntry of usage ?? []) {
        if (usageEntry.usageType === "dasha.ai") {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Total usage ${usageEntry.usageSubType} ${usageEntry.eventType} = ${usageEntry.billableUsage}`,
            time: devLog.length > 0 ? new Date(devLog[devLog.length - 1]?.time).valueOf() : 0,
            from: "ai",
            isSystem: true,
          });
        }
      }

      const runner = new SessionRunner(
        {
          type: RunnerType.Text,
          isSending: false,
          isReadOnly: true,
          inputData: inputData,
          outputData: outputData,
          messages: logMessages,
          jobId: jobId,
          timeStarted: +new Date(devLog[0].time).valueOf(),
          timeInitialized: +new Date(devLog[0].time).valueOf(),
          timeEnded: +new Date(devLog[devLog.length - 1].time).valueOf(),
        },
        account
      );
      setSessionRunner(runner);
      setInputs(inputData);
      layout.expandPanel("runner");
    };
    if (devLog.length > 0) {
      fetch();
    }
  }, [devLog]);

  return (
    <DialogPanel
      id="runner"
      workspace={workspace}
      session={sessionRunner}
      isOpen={layout.isExpanded("runner")}
      layout={layout}
      canHide={canHide}
    >
      <a
        download={jobId + ".json"}
        href={URL.createObjectURL(new Blob([JSON.stringify(devLog)], { type: "application/json" }))}
      >
        Download DevLog
      </a>
      <ActionButton onClick={createProjectFromJob} style={{ marginTop: "1em" }}>
        Create project in playground from this converstation
      </ActionButton>
    </DialogPanel>
  );
});
