import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "./Expression";
import * as helpers from "../helpers";

export class DigressionSwitchApplyExpression
  extends Expression
  implements interfaces.IDigressionSwitchApplyExpression
{
  elementType: DslElementType.DigressionSwitchApplyExpression = DslElementType.DigressionSwitchApplyExpression;
  variable: Expression;

  constructor(obj: interfaces.IDigressionSwitchApplyExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.variable = helpers.isExpressionInstance(obj.variable)
      ? obj.variable
      : helpers.createExpressionInstace(obj.variable);
  }
  toJson(): interfaces.IDigressionSwitchApplyExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      variable: this.variable.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `digression apply ${this.variable.toDsl(indent)}`
  }
}
