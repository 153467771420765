import React, { FC } from "react";


import { Icon } from "../uikit";
import * as S from "./styled";
import { Prompt } from "@core/api/prompts/PromptStore";

interface Props {
  prompt: Prompt;
}

export const PromptCard: FC<Props> = ({ prompt }) => {
  return (
    <S.PromptCard>
      <h2>{prompt.name}</h2>
      <S.Description>{prompt.description??""}</S.Description>
    </S.PromptCard>
  );
};

