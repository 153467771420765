import React, { memo, useEffect, useRef, useState } from "react";
import { NodeProps, Handle, Position } from "react-flow-renderer";

import { PureButton } from "../../uikit/Button";
import { Icon } from "../../uikit";

import useNodeToCenter from "./useNodeToCenter";
import * as S from "./styled";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [isPlaying, setPlayState] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useNodeToCenter(xPos, yPos, data.highlight);

  useEffect(() => {
    if (!ref.current) return;

    const handler = () => {
      if (!ref.current) return;
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    };

    ref.current.setAttribute("style", "height:" + ref.current.scrollHeight + "px;overflow-y:hidden;");
    ref.current.addEventListener("input", handler, false);
    return () => ref.current?.removeEventListener("input", handler);
  }, []);

  const sayText = async () => {
    if (isPlaying) {
      await data.speaker.removeAudio();
      setPlayState(false);
      return;
    }

    setPlayState(true);
    await data.speaker.play(data.sayText, data.speed, data.context);
    setPlayState(false);
  };

  return (
    <S.Node isSelected={selected || data.highlight}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
      <div
        className="drag-handle"
        style={{ marginBottom: 8, display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <S.Label>SAY TEXT:</S.Label>

        <div style={{ display: "flex", alignItems: "center" }}>
          <PureButton onClick={() => setOpen((v) => !v)} style={{ marginTop: -4 }}>
            <Icon name="cursor" angle={isOpen ? 0 : -180} color="#fff" style={{ opacity: 0.4 }} />
          </PureButton>

          <PureButton onClick={sayText} style={{ marginTop: -4, marginLeft: 8 }}>
            <Icon name="microphone" style={{ opacity: isPlaying ? 1 : 0.6 }} />
          </PureButton>
        </div>
      </div>

      <S.Textarea
        ref={ref}
        onPointerMove={(e) => e.preventDefault()}
        onChange={(e) => data.update({ sayText: e.target.value })}
        value={data.sayText}
        style={{ width: "100%" }}
      />

      {isOpen && (
        <S.RangeSlider>
          <span style={{ color: "#fff" }}>Speed [{data.speed}]:</span>
          <input
            value={data.speed}
            onChange={(e) => data.update({ speed: e.target.value })}
            type="range"
            min={0}
            max={2}
            step={0.1}
          />
        </S.RangeSlider>
      )}

      <Handle
        id="b"
        type="source"
        position={Position.Right}
        style={{ top: "50%", right: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
    </S.Node>
  );
});
