import styled, { css, createGlobalStyle } from "styled-components";

export const Container = styled.div`
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const GlobalStyles = createGlobalStyle`
  #editor > div {
    overflow: hidden !important;
  }
`;

export const Table = styled.div`
  border-collapse: collapse;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const actionColStyles = css`
  min-width: 48px;
  max-width: 48px;
  padding: 0;
`;

export const Header = styled.div<{ $isActionCol?: boolean }>`
  min-height: 32px;
  background-color: #252536;

  padding: 8px 16px;

  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;

  color: #d9d9e2;

  border-right: 1px solid rgba(255, 255, 255, 0.1);

  ${(p) => p.$isActionCol && actionColStyles}

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }

  .resizer {
    display: inline-block;
    background: #61616d;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;

    &.isResizing {
      background: #aaaac4;
    }
  }
`;

export const SortIcon = styled.span`
  position: relative;
  top: 4px;
  left: 4px;
`;

export const Cell = styled.div<{ $isActionCol?: boolean }>`
  padding: 8px 16px;

  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: center;

  white-space: nowrap;

  color: #d9d9e2;

  border-right: 1px solid rgba(255, 255, 255, 0.1);

  ${(p) => p.$isActionCol && actionColStyles}

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }
`;

export const Row = styled.div`
  &:nth-child(2n) {
    background-color: #161729;
  }

  &:nth-child(2n + 1) {
    background-color: #1b1c2d;
  }
`;

export const ActionsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const TableHead = styled.thead`
  position: sticky;
  top: 0;
`;
