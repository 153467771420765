import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 200px;
  border-radius: 12px;
  background: #28293c;
  z-index: 1000;
  padding: 6px 0;
  box-shadow: 0 1px 6px 0px #0000004f;
  overflow: hidden;
`;

export const ContextItem = styled.div`
  padding: 8px 12px;
  width: 100%;
  color: #fff;
  cursor: pointer;
  transition: 0.2s background;
  box-sizing: border-box;
  font-size: 13px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ContextMenu = ({ position, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = () => {
      if (ref.current == null) return;
      ref.current.style.display = "none";
    };

    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.display = position ? "block" : "none";
    if (position != null) {
      ref.current.style.top = position.y + "px";
      ref.current.style.left = position.x + "px";
    }
  }, [position]);

  return (
    <Container style={{ display: "none" }} ref={ref}>
      {children}
    </Container>
  );
};
