import styled from "styled-components";

export const Container = styled.div``;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  cursor: pointer;
`;

export const TriggerBox = styled.button<{ selected?: boolean }>`
  height: 20px;

  margin-right: 8px;

  border: none;
  background: ${(p) => (p.selected ? "#0063A6" : "#383955")};
  border-radius: 4px;
  transition: 0.3s background;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: rgba(255, 255, 255, 0.75);

  cursor: pointer;

  &:hover {
    background: ${(p) => (p.selected ? "#0063A6" : "#4b4b78")};
  }

  > span {
    margin-right: 4px;
  }

  ${CloseBtn} {
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const TooltipText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: left;

  max-width: 200px;

  span:first-child {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: rgba(217, 217, 226, 0.5);
  }

  span:last-child {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.75);
  }
`;

export const Header = styled.div`
  position: relative;
  padding: 6px;
`;

export const Name = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(217, 217, 226, 0.5);
`;

export const SearchField = styled.div`
  display: flex;
  align-items: center;

  position: relative;

  margin-top: 4px;

  background: #1d1e31;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 2px;

  padding-right: 10px;

  height: 24px;

  input {
    outline: none;
    background: none;
    border: none;
    height: 100%;
    width: 100%;

    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;

    &::placeholder {
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);

      padding-left: 16px;
    }
  }

  > svg {
    position: absolute;
    left: 0;
    top: 3px;
    pointer-events: none;
  }

  ${CloseBtn} {
    top: -2px;
    right: 5px;
  }
`;

export const Actions = styled.div`
  max-height: 282px;
  max-width: 200px;

  overflow-y: auto;
  overflow-x: hidden;

  label {
    width: 20px;
    margin-right: 4px;
  }
`;

export const ClearBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 24px;

  padding: 0;
  margin-top: 6px;
  margin-bottom: 4px;

  span {
    margin-left: 8px;
  }

  color: rgba(255, 255, 255, 0.3);
`;
