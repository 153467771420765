import React, { FC } from "react";
import styled from "styled-components";

export const tags = {
  readonly: {
    name: () => "Readonly",
    color: "#7BBCF8",
    background: "rgba(123, 188, 248, 0.24)",
  },
  code: {
    name: () => "Code",
    color: "#A0ECBD",
    background: "rgba(160, 236, 189, 0.24)",
  },
  nocode: {
    name: () => "No-code",
    color: "#FEEAB6",
    background: "rgba(254, 234, 182, 0.24)",
  },
  custom: {
    name: (child) => child,
    color: "#fff",
    background: "rgba(255, 255, 255, 0.1)",
  },
};

export const STag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  width: fit-content;
  height: 22px;
  padding: 0 8px;
  border-radius: 11.5px;
`;

export const Tag: FC<{ id: keyof typeof tags }> = ({ id, children }) => {
  return <STag style={{ ...tags[id] }}>{tags[id].name(children)}</STag>;
};
