import styled from "styled-components";
import { PureButton } from "../../uikit/Button";
import { Icon } from "../../uikit";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InvalidIndicator = styled(Icon)`
  margin-top: 2px;
  margin-right: 6px;
  circle {
    fill: tomato;
  }
`;

export const ShareWidget = styled.div`
  display: flex;
  margin-right: 8px;

  button {
    height: 32px;
  }
`;

export const ShareLink = styled.div`
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  padding: 5px 8px;
  margin-right: 8px;
  transition: 0.2s color;
  color: rgba(255, 255, 255, 0.25);

  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.75);
  }

  span {
    color: rgba(255, 255, 255, 0.75);
    margin-left: 4px;
  }

  &:hover {
    span {
      color: rgba(255, 255, 255, 1);
    }
    svg {
      stroke: rgba(255, 255, 255, 1);
    }
  }
`;

export const Controls = styled.div`
  margin-left: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const Button = styled(PureButton)`
  position: relative;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1280px) {
    display: none;
  }

  font-family: var(--main-font);
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  text-transform: none;
  margin-right: 16px;
  transition: 0.2s color;

  color: rgba(255, 255, 255, 0.75);
  svg {
    fill: rgba(255, 255, 255, 0.75);
    margin-top: 4px;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
    svg {
      fill: rgba(255, 255, 255, 1);
    }
  }

  &:disabled {
    cursor: default;
    color: rgba(255, 255, 255, 0.3);
    svg {
      fill: rgba(255, 255, 255, 0.3);
    }
  }
`;
