import styled from "styled-components";
import { PureButton } from "../uikit/Button";

export const Text = styled.p`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.75);
  margin: 0;
`;

export const CheckboxLabel = styled.label`
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled(PureButton)<{ isSelected: boolean }>`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  margin-right: 12px;
  text-transform: none;

  padding-bottom: 4px;
  color: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  border-bottom: 2px solid ${(p) => (p.isSelected ? "#5855F4" : "transparent")};
`;
