import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import _ from "lodash";

export default class MonacoDecorationManager {
  private decorationIds: string[] = [];
  private decorationIdToDecoration: Record<string, monaco.editor.IModelDeltaDecoration> = {};
  private editor: monaco.editor.IStandaloneCodeEditor;

  constructor(editor: monaco.editor.IStandaloneCodeEditor) {
    this.editor = editor;
  }

  /**
   * adds decorations to editor
   * @param ranges ranges of decorations to add
   * @param options options that will be used for all added decorations
   */
  addDecorations(ranges: monaco.IRange[], options: monaco.editor.IModelDecorationOptions) {
    const newDecorations = ranges.map((range) => {
      return { range, options };
    });
    const newDecorationIds = this.editor.deltaDecorations([], newDecorations);
    this.decorationIds.push(...newDecorationIds);
    for (let i = 0; i < newDecorationIds.length; ++i) {
      const id = newDecorationIds[i];
      const dec = newDecorations[i];
      this.decorationIdToDecoration[id] = dec;
    }
    // console.log("added decorations, current:", this.decorationIds);
  }
  /**
   * removes decorations from editor
   * @param options if provided, then it will be used to identify decorations to remove,
   *                otherwise all decorations are removed
   */
  dropDecorations(options?: monaco.editor.IModelDecorationOptions) {
    let decorationIdsToRemove;
    if (options) {
      decorationIdsToRemove = this.decorationIds.filter((id) => {
        const decoration = this.decorationIdToDecoration[id];
        return _.isEqual(options, decoration.options);
      });
    } else {
      decorationIdsToRemove = this.decorationIds;
    }
    this.editor.deltaDecorations(decorationIdsToRemove, []);
    this.decorationIds = this.decorationIds.filter((id) => !decorationIdsToRemove.includes(id));
  }
}
