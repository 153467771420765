import { makeAutoObservable, toJS } from "mobx";
import { Trigger } from "@core/workspace/session-storage/devlogs";
import { Transition } from "./storage/model/models";
import { Phrase } from "./types";

export enum MarkedState {
  ALL,
  NONE,
  PARTICULARY,
  READ_ONLY,
}

class Cluster {
  public isExpand = false;

  constructor(public readonly id: string, public title: string, public readonly phrases: Phrase[] = []) {
    makeAutoObservable(this);
  }

  addPhrase(phrase: Phrase) {
    this.phrases.push(phrase);
  }

  toggleExpand(is: boolean) {
    this.isExpand = is;
  }

  toggleMarkAll() {
    if (this.markedState === MarkedState.NONE) {
      this.phrases.forEach(({ markableMessage }) => markableMessage.activeAll());
      return;
    }

    this.phrases.forEach(({ markableMessage }) => markableMessage.deactiveAll());
  }

  get markedState() {
    const phrases = this.phrases.filter(({ markableMessage: m }) => m.hasInteractiveTriggers);
    const checked = phrases.filter(({ markableMessage: m }) => m.isMarked);
    const unrecognizedPhrases = this.phrases.length - phrases.length;

    if (unrecognizedPhrases === this.phrases.length) return MarkedState.READ_ONLY;
    if (checked.length === phrases.length) return MarkedState.ALL;
    if (checked.length > 0) return MarkedState.PARTICULARY;
    return MarkedState.NONE;
  }

  get triggers() {
    const triggers = new Map<string, Trigger>();

    this.phrases.flatMap((phrase) => {
      phrase.markableMessage.triggers.forEach((trigger) => {
        if (triggers.has(trigger.name)) return;
        triggers.set(trigger.name, trigger);
      });
    });

    return Array.from(triggers.values());
  }
}

export default Cluster;
