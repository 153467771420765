import styled, { css } from "styled-components";

const label = css`
  font-family: var(--main-font);
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #d9d9e2;
  text-align: left;
`;

export const Container = styled.div``;

export const List = styled.ul`
  box-sizing: border-box;

  list-style: none;
  padding: 16px 0;
  margin: 0;

  width: 478px;
  max-height: 300px;
  overflow-y: auto;

  background: #1d1e31;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  z-index: 100;
`;

export const ListItem = styled.li`
  ${label}

  padding: 4px 16px;
  display: flex;
  justify-content: space-between;

  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const ListBtn = styled.button`
  ${label}

  width: 100%;
  height: 44px;

  padding: 8px;
  margin-bottom: 8px;

  background-color: rgba(255, 255, 255, 0.06);

  border: none;
  border-radius: 8px;

  box-sizing: border-box;

  cursor: pointer;
`;

export const FieldGroup = styled.div`
  margin-bottom: 8px;

  label {
    font-family: var(--main-font);
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.3);

    margin-bottom: 2px;
  }
`;
