import { MouseEvent, TouchEvent } from "react";
import { DiagramEngine, DragDiagramItemsState, DragNewLinkState, PortModel } from "@projectstorm/react-diagrams";
import {
  SelectingState,
  State,
  Action,
  InputType,
  ActionEvent,
  DragCanvasState,
} from "@projectstorm/react-canvas-core";

class InteractiveStates extends State<DiagramEngine> {
  readonly childStates = [new SelectingState()];
  readonly dragCanvas = new DragCanvasState();
  readonly dragNewLink = new DragNewLinkState({ allowLooseLinks: false });
  readonly dragItems = new DragDiagramItemsState();

  constructor() {
    super({ name: "editor-states" });

    // determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent>) => {
          const element = this.engine.getActionEventBus().getModelForEvent(event);

          // the canvas was clicked on, transition to the dragging canvas state
          if (!element) {
            this.transitionWithEvent(this.dragCanvas, event);
            return;
          }

          // initiate dragging a new link
          if (element instanceof PortModel) {
            this.transitionWithEvent(this.dragNewLink, event);
            return;
          }

          // move the items (and potentially link points)
          this.transitionWithEvent(this.dragItems, event);
        },
      })
    );

    // touch drags the canvas
    this.registerAction(
      new Action({
        type: InputType.TOUCH_START,
        fire: (event: ActionEvent<TouchEvent>) => {
          this.transitionWithEvent(this.dragCanvas, event);
        },
      })
    );
  }
}

export default InteractiveStates;
