import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

import { DoStatement } from "./DoStatement";
import { Expression } from "../expressions";
import { RawElement } from "../common";

export class ForStatement extends DoStatement implements interfaces.IForStatement {
  elementType: DslElementType.ForStatement = DslElementType.ForStatement;
  iteratorName: RawElement<string>;
  containerName: Expression;
  body: DoStatement;

  constructor(obj: interfaces.IForStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.iteratorName = helpers.ensureIsInstance(obj.iteratorName, RawElement<string>);
    this.containerName = helpers.ensureIsExpressionInstance(obj.containerName);
    this.body = helpers.ensureIsDoStatementInstance(obj.body);
  }
  toJson(): interfaces.IForStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      iteratorName: this.iteratorName.toJson(),
      containerName: this.containerName.toJson(),
      body: this.body.toJson(),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + `for (var ${this.iteratorName.toDsl(indent)} in ${this.containerName.toDsl(indent)}) `,
      this.body.toDsl(indent),
    ].join("\n");
  }
}
