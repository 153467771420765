import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button } from "semantic-ui-react";
import "./styles.css";
import { OutboundConfig } from "@dasha.ai/sdk/web/sip/outbound-configs";
import { Loader } from "semantic-ui-react";
import { createDownloadLink } from "@core/profiler/utils";
import DateSelector from "../ProfilerPanel/components/ProfilerWidget/components/DateSelector";
import { useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";
import UIManager from "../core/misc/UIManager";
import { OnlineProfilerTable } from "./OnlineProfilerTable";

const columns = [
  {
    key: "date",
    text: "Date",
    value: "date",
  },
  {
    key: "applicationId",
    text: "Application Id",
    value: "applicationId",
  },
  {
    key: "applicationName",
    text: "Application Name",
    value: "applicationName",
  },
  {
    key: "groupName",
    text: "Group Name",
    value: "groupName",
  },
  {
    key: "eventType",
    text: "Event Type",
    value: "eventType",
  },
  {
    key: "usageType",
    text: "Usage Type",
    value: "usageType",
  },
  {
    key: "usageSubType",
    text: "Usage Sub Type",
    value: "usageSubType",
  },
];

const getDetailedFilter = (
  data: dasha.events.AggregatedEventEntity,
  defaultStartDate: Date,
  defaultEndDate: Date
): dasha.filters.IFilter[] => {
  //TODO: add backend method for fetching timezone
  const timezone = "-05:00";
  const result: dasha.filters.IFilter[] = [];
  if (data.customerId !== null && data.customerId !== undefined) {
    result.push(new dasha.filters.CustomerIdsFilter([data.customerId]));
  }

  if (data.applicationName !== null && data.applicationName !== undefined) {
    result.push(new dasha.filters.ApplicationNamesFilter([data.applicationName]));
  }

  if (data.eventType !== null && data.eventType !== undefined) {
    result.push(new dasha.filters.EventTypeFilter([data.eventType]));
  }

  if (data.usageType !== null && data.usageType !== undefined) {
    result.push(new dasha.filters.UsageTypesFilter([data.usageType]));
  }

  if (data.usageSubType !== null && data.usageSubType !== undefined) {
    result.push(new dasha.filters.UsageSubTypesFilter([data.usageSubType]));
  }

  if (data.date !== null && data.date !== undefined) {
    result.push(
      new dasha.filters.TimeStampRangeFilter(data.date + "T00:00:00" + timezone, data.date + "T23:59:59.999" + timezone)
    );
  } else {
    result.push(dasha.filters.TimeStampRangeFilter.fromDate(defaultStartDate, defaultEndDate, timezone));
  }

  return result;
};

const EventsRow: FC<{
  account: IAccount;
  data: dasha.events.AggregatedEventEntity;
  selectedColumns: string[];
  defaultStartDate: Date;
  defaultEndDate: Date;
}> = ({ account, data, selectedColumns, defaultStartDate, defaultEndDate }) => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobIds, setJobIds] = useState<string[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  useEffect(() => {
    if (!expanded || dataFetched) {
      return;
    }
    setLoading(true);

    const loadJobs = async () => {
      const a = account.connect();
      const filters = getDetailedFilter(data, defaultStartDate, defaultEndDate);
      setJobIds(await dasha.events.getJobIds(1000, 0, filters, { account: a }));
    };
    loadJobs()
      .catch((e) => UIManager.notice("Failed to load events"))
      .finally(() => {
        setLoading(false);
        setDataFetched(true);
      });
  }, [expanded, dataFetched]);

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Button onClick={() => setExpanded(!expanded)}>{expanded ? "Hide" : "Details"}</Button>
        </Table.Cell>
        {selectedColumns.map((col) => (
          <Table.Cell>{data[col]}</Table.Cell>
        ))}
        <Table.Cell> {data.total} </Table.Cell>
        <Table.Cell> {data.count} </Table.Cell>
      </Table.Row>
      {expanded && (
        <>
          <Table.Row>
            <Table.Cell colspan={3 + selectedColumns.length}>
              {loading && <Loader active={true} />}
              {jobIds.length > 0 && (
                <OnlineProfilerTable download_name="" filters={[]} jobIds={jobIds} page_size={20} />
              )}
            </Table.Cell>
          </Table.Row>
        </>
      )}
    </>
  );
};

export const EventsPanel: FC<{}> = observer(({}) => {
  const { account } = useStore();
  const { customerId } = useParams();
  const [usageRows, setUsageRows] = useState<dasha.events.EventEntity[]>([]);
  const [aggregatedRows, setAggregatedRows] = useState<dasha.events.AggregatedEventEntity[]>([]);
  const [applicationNames, setApplicationNames] = useState<{ text: string; key: string; value: string }[]>([]);
  const [eventTypes, setEventTypes] = useState<{ text: string; key: string; value: string }[]>([]);
  const [usageTypes, setUsageTypes] = useState<{ text: string; key: string; value: string }[]>([]);
  const [usageSubTypes, setUsageSubTypes] = useState<{ text: string; key: string; value: string }[]>([]);

  const [selectedApplicationNames, setSelectedApplicationNames] = useState<string[]>([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
  const [selectedUsageTypes, setSelectedUsageTypes] = useState<string[]>([]);
  const [selectedUsageSubTypes, setSelectedUsageSubTypes] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ end: new Date(), start: new Date(new Date().setDate(1)) });

  const [selectedColumns, setSelectedColumns] = useState([
    "date",
    "applicationName",
    "eventType",
    "usageType",
    "usageSubType",
  ]);

  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const a = account.connect();
      const filters: dasha.filters.IFilter[] = [new dasha.filters.DateRangeFilter(dateRange.start, dateRange.end)];
      if (selectedApplicationNames.length > 0) {
        filters.push(new dasha.filters.ApplicationNamesFilter(selectedApplicationNames));
      }
      if (selectedEventTypes.length > 0) {
        filters.push(new dasha.filters.EventTypeFilter(selectedEventTypes));
      }
      if (selectedUsageTypes.length > 0) {
        filters.push(new dasha.filters.UsageTypesFilter(selectedUsageTypes));
      }
      if (selectedUsageSubTypes.length > 0) {
        filters.push(new dasha.filters.UsageSubTypesFilter(selectedUsageSubTypes));
      }
      if (customerId !== undefined) {
        filters.push(new dasha.filters.CustomerIdsFilter([customerId]));
      }
      const result: dasha.events.EventEntity[] = [];
      let fetchedRows = 0;
      let idx = 0;
      do {
        const c = await dasha.events.getEvents(1000, idx, filters, { account: a });
        fetchedRows = c.length;
        idx += fetchedRows;
        c.forEach((element) => {
          result.push(element);
        });
      } while (fetchedRows === 1000);

      setUsageRows(result);
    };
    fetchData().finally(() => setLoading(false));
  }, [account, dateRange, selectedApplicationNames, selectedEventTypes, selectedUsageTypes, selectedUsageSubTypes, customerId]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const a = account.connect();
      const fillFilters = await dasha.events.getEventsFilters(
        [new dasha.filters.DateRangeFilter(dateRange.start, dateRange.end)],
        [
          new dasha.filters.ApplicationNamesFilter([]),
          new dasha.filters.EventTypeFilter([]),
          new dasha.filters.UsageTypesFilter([]),
          new dasha.filters.UsageSubTypesFilter([]),
        ],
        {
          account: a,
        }
      );
      setApplicationNames(
        (fillFilters[0] as dasha.filters.ApplicationNamesFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );
      setEventTypes(
        (fillFilters[1] as dasha.filters.EventTypeFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );
      setUsageTypes(
        (fillFilters[2] as dasha.filters.UsageTypesFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );
      setUsageSubTypes(
        (fillFilters[3] as dasha.filters.UsageSubTypesFilter).values.map((x) => ({ text: x, key: x, value: x }))
      );
    };
    fetchData().finally(() => setLoading(false));
  }, [account, dateRange, customerId]);

  const exportToCsv = useCallback(() => {
    const rows = [];
    const headerRow = selectedColumns.map((c) => columns.filter((x) => x.value === c)[0].text);
    headerRow.push("Total");
    headerRow.push("Count");
    rows.push(headerRow);
    for (const row of aggregatedRows) {
      const r = [];
      for (const c of selectedColumns) {
        r.push(row[c]);
      }
      r.push(row.total);
      r.push(row.count);
      rows.push(r);
    }
    const csvData = rows.map((r) => r.join(";")).join("\n");
    createDownloadLink([csvData], "text/csv", "usage.csv");
  }, [account, aggregatedRows, selectedColumns]);
  useEffect(() => {
    setLoading(true);
    setAggregatedRows(dasha.events.aggregateEvent(usageRows, new Set<string>(selectedColumns)));
    setLoading(false);
  }, [selectedColumns, usageRows]);
  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Events</h2>
      <label htmlFor="date-range">Date range:</label>
      <DateSelector dateRange={dateRange} onChange={handleDateChange} />

      <h2>Filters</h2>
      <div>
      <Dropdown
        placeholder="Applications"
        fluid
        multiple
        search
        selection
        options={applicationNames}
        value={selectedApplicationNames}
        onChange={(e, { value }) => setSelectedApplicationNames(value)}
      />
      <Dropdown
        placeholder="Event type"
        fluid
        multiple
        search
        selection
        options={eventTypes}
        value={selectedEventTypes}
        onChange={(e, { value }) => setSelectedEventTypes(value)}
        />
              <Dropdown
        placeholder="Usage type"
        fluid
        multiple
        search
        selection
        options={usageTypes}
        value={selectedUsageTypes}
        onChange={(e, { value }) => setSelectedUsageTypes(value)}
        />
          <Dropdown
        placeholder="Usage subtype"
        fluid
        multiple
        search
        selection
        options={usageSubTypes}
        value={selectedUsageSubTypes}
        onChange={(e, { value }) => setSelectedUsageSubTypes(value)}
        />
      </div>

      <h2>Columns</h2>
      <Dropdown
        placeholder="Columns"
        fluid
        multiple
        search
        selection
        options={columns}
        value={selectedColumns}
        onChange={(e, { value }) => setSelectedColumns(value)}
      />
      <Loader active={loading}>Loading..</Loader>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            {selectedColumns.map((c) => (
              <Table.HeaderCell>{columns.filter((x) => x.value === c)[0].text}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(aggregatedRows).map(([k, c]) => {
            return (
              <EventsRow
                account={account}
                data={c}
                selectedColumns={selectedColumns}
                defaultStartDate={dateRange.start}
                defaultEndDate={dateRange.end}
              />
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Button onClick={exportToCsv}>Download CSV</Button>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
});
