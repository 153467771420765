import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement } from "../common";
import { TransitionDefinition } from "./TransitionDefinition";

export class TransitionSection extends DslElement implements interfaces.ITransitionSection {
  elementType: DslElementType.TransitionSection = DslElementType.TransitionSection;
  transitions: TransitionDefinition[];

  constructor(obj: interfaces.ITransitionSection, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.transitions = helpers.ensureIsArrayOfInstances(obj.transitions, TransitionDefinition);
  }
  toJson(): interfaces.ITransitionSection {
    return {
      elementType: this.elementType,
      location: this.location,
      transitions: this.transitions.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + "transitions {",
      ...this.transitions.map((el) => el.toDsl(indent + 1)),
      helpers.indentString(indent) + "}",
    ].join("\n");
  }
}
