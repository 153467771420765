import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button, Pagination } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import { createDownloadLink } from "@core/profiler/utils";
import DateSelector from "../ProfilerPanel/components/ProfilerWidget/components/DateSelector";
import { Link, useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";



const getColumnValue = (columnName: string, obj: any) => {
  const subcols = columnName.split(".");
  for (var c of subcols) {
    if (obj === undefined || obj === null) {
      break;
    }
    obj = obj[c];
  }
  return JSON.stringify(obj);
}

export const FailedConversations: FC<{ }> = ({ }) => {
  const { account } = useStore();
  const { customerId } = useParams();
  const [dataRows, setDataRows] = useState<dasha.jobs.SessionLogEntity[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ end: new Date(), start: new Date(new Date().setDate(1)) });

  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const a = account.connectAs(customerId);
      const result = await dasha.jobs.getSessionEntities(
        dateRange.start?.toISOString(), 
        dateRange.end?.toISOString(), "Failed" as any ,(page - 1)*50, 50, { account: a });
      setTotalPages(Math.ceil(result.totalCount/50));
      setDataRows(result.entites);
    };
    fetchData().finally(() => setLoading(false));
  }, [account, page, dateRange]);

  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Failed conversations</h2>
      <label htmlFor="date-range">Date range:</label>
      <DateSelector dateRange={dateRange} onChange={handleDateChange} />
      <Loader active={loading}>Loading..</Loader>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Inspect</Table.HeaderCell>
            <Table.HeaderCell>JobId</Table.HeaderCell>
            <Table.HeaderCell>JobKey</Table.HeaderCell>
            <Table.HeaderCell>ApplicationId</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader active={loading} />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(dataRows).map(([k, c]) => {
            return (
              <Table.Row>
                <Table.Cell>{c.completedTime}</Table.Cell>
                <Table.Cell><Link to={`/inspector/${c.id}`} target="_blank" rel="noopener noreferrer">Inspect</Link></Table.Cell>
                <Table.Cell>{c.id}</Table.Cell>
                <Table.Cell>{c.customerJobId}</Table.Cell>
                <Table.Cell>{c.applicationId}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>

          </Table.Row>
        </Table.Footer>
      </Table>
      <Pagination activePage={page} totalPages={totalPages} onPageChange={(e, value) => setPage(value.activePage as number)} />
    </div>
  );
};
