import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { makeAutoObservable, runInAction } from "mobx";

export class InboundVoipEntry {
  private readonly account: IAccount;
  private isExisting: boolean;
  public Name: string;
  public OriginalConfig: dasha.sip.inboundConfigs.InboundConfig;
  public CurrentConfig: dasha.sip.inboundConfigs.InboundConfig;
  public SaveError: string | undefined;
  public Status: dasha.sip.reports.EndpointStatus;
  public NewPassword: string | undefined | null;

  constructor(original: dasha.sip.inboundConfigs.InboundConfig, account: IAccount, name: string, existing: boolean) {
    this.OriginalConfig = { ...original };
    this.CurrentConfig = { ...original };
    this.account = account;
    this.Name = name;
    this.isExisting = existing;
    this.Status = { isError: false, status: "Unknown" };
    makeAutoObservable(this);
  }

  public IsNewEntry() {
    return !this.isExisting;
  }

  public SetName(name: string) {
    if (this.IsNewEntry()) {
      this.Name = name;
    }
  }

  public SetAlias(alias: string) {
    this.CurrentConfig.alias = alias;
  }

  public SetPriority(priority: number) {
    this.CurrentConfig.priority = priority;
  }

  public SetNewPassword(password: string | null) {
    this.NewPassword = password;
  }

  public SetIPAcls(ipAcls: string) {
    this.CurrentConfig.ipAcls = ipAcls.split(",");
  }
  public RefreshStatus() {
    if (!this.isExisting) {
      return;
    }
    dasha.sip.reports
      .getEndpointStatus(this.OriginalConfig.id, 1, { account: this.account.connect() })
      .then((x) => {
        runInAction(() => {
          this.Status = x;
        });
      })
      .catch((e) =>
        runInAction(() => {
          this.Status = { isError: true, status: e.message };
        })
      );
  }

  public ResetChanges() {
    this.CurrentConfig = this.OriginalConfig;
  }

  public async SaveChangesAsync(): Promise<boolean> {
    const a = this.account.connect();
    this.SaveError = undefined;
    const passwordObj = {};
    if (this.NewPassword !== undefined) {
      passwordObj["password"] = this.NewPassword;
    }

    try {
      if (this.isExisting) {
        const x = await dasha.sip.inboundConfigs.updateConfig(
          this.Name,
          { ...this.CurrentConfig, ...passwordObj },
          { account: a }
        );
        runInAction(() => {
          this.CurrentConfig = x;
          this.OriginalConfig = x;
          this.NewPassword = undefined;
        });
      } else {
        const x = await dasha.sip.inboundConfigs.createConfig(
          this.Name,
          { ...this.CurrentConfig, ...passwordObj },
          { account: a }
        );
        runInAction(() => {
          this.CurrentConfig = x;
          this.OriginalConfig = x;
          this.isExisting = true;
          this.NewPassword = undefined;
        });
      }
      return true;
    } catch (e) {
      runInAction(() => { this.SaveError = e.message ?? "Failed to save"; });
      return false;
    }
  }
}
