import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import UIManager from "../../misc/UIManager";
import { IDisposable } from "../../misc/emitter";
import styles from "./styles.module.css";
import SimpleTtsSpeaker, { SynthesizeVoiceOptions } from "../../SimpleTtsSpeaker";
import MonacoDecorationManager from "../utils/MonacoDecorationManager";

export type ParseSynthParamsFunctionSignature = (position: monaco.IPosition) => Promise<{
  playableData: {
    text: string;
    voiceOptions: SynthesizeVoiceOptions;
  };
  playableDataRanges?: monaco.IRange[];
}>;

export default class PlayPhrasePlugin {
  private isPlayableDataCondition?: monaco.editor.IContextKey<boolean>;
  private speakerIsActiveCondition?: monaco.editor.IContextKey<boolean>;

  private actionDisposer?: IDisposable;
  private mouseListenerDisposer?: IDisposable;

  private speaker: SimpleTtsSpeaker;
  private decorationManager?: MonacoDecorationManager;

  private tryParseSynthParamsCallback: ParseSynthParamsFunctionSignature;

  private text?: string;
  private voiceOptions: SynthesizeVoiceOptions = {};
  private playableDataRanges: monaco.IRange[] = [];

  private _realOnContextMenu: any;
  private _contextmenu: any;

  constructor(speaker: SimpleTtsSpeaker, tryParseSynthParams: ParseSynthParamsFunctionSignature) {
    this.speaker = speaker;
    this.tryParseSynthParamsCallback = tryParseSynthParams;
  }

  activate(editor: monaco.editor.IStandaloneCodeEditor) {
    this.decorationManager = new MonacoDecorationManager(editor);
    this.isPlayableDataCondition = editor.createContextKey("is-playable-data-condition", false);
    this.speakerIsActiveCondition = editor.createContextKey("speaker-is-active-condition", false);

    this.speaker.on("playingAudio", this.handleTtsSpeakerPlayAudio);
    this.speaker.on("removedAudio", this.handleTtsSpeakerRemoveAudio);

    this._contextmenu = editor.getContribution("editor.contrib.contextmenu") as any;
    this._realOnContextMenu = this._contextmenu._onContextMenu;

    this._contextmenu._onContextMenu = async (e) => {
      e.event.browserEvent.preventDefault();

      this.isPlayableDataCondition?.reset();
      if (!this.speakerIsActiveCondition?.get()) {
        this.decorationManager?.dropDecorations();
      }

      const position = e.target.position;
      if (position === null) return;

      await this.updateIsPlayableCondition(position);

      /** if successfully parsed playable object, mark it with decoration */
      this.decorationManager?.dropDecorations({ className: styles.errorPhraseDecoration });
      const style = this.speaker?.isActive() ? styles.errorPhraseDecoration : styles.selectedPhraseDecoration;
      this.decorationManager?.addDecorations(this.playableDataRanges, { className: style });
      this._realOnContextMenu.call(this._contextmenu, e);
    };

    this.actionDisposer = editor.addAction({
      id: "play-phrase-action",
      label: "Play Phrase",
      precondition: "is-playable-data-condition && !speaker-is-active-condition",
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1.5,

      run: async () => {
        this.isPlayableDataCondition?.reset();
        if (this.text === undefined) {
          UIManager.notice("Something went wrong. Could not synthesize empty phrase.");
          return;
        }

        if (typeof this.voiceOptions.emotion === "string" && !this.voiceOptions.emotion.startsWith("from text:")) {
          const msg = "Emotion parameter must be of format: 'from text: <your_emotion>'";
          UIManager.notice(msg);
          throw new Error(msg);
        }

        await this.speaker
          ?.play(this.text, this.voiceOptions)
          .then(() => {
            this.text = undefined;
            this.voiceOptions = {};
            this.playableDataRanges = [];
          })
          .catch((error: any) => {
            console.debug("speaker error:", error);
            UIManager.notice(
              `Something went wrong. Could not synthesize phrase, reason: ${error.message ?? error.response.data}.`
            );
          });

        this.decorationManager?.dropDecorations();
      },
    });
  }

  dispose() {
    this.actionDisposer?.dispose();
    this.mouseListenerDisposer?.dispose();
    this._contextmenu._onContextMenu = this._realOnContextMenu;

    this.speaker.off("playingAudio", this.handleTtsSpeakerPlayAudio);
    this.speaker.off("removedAudio", this.handleTtsSpeakerRemoveAudio);
  }

  private handleTtsSpeakerPlayAudio = () => {
    this.speakerIsActiveCondition?.set(true);
  };
  private handleTtsSpeakerRemoveAudio = () => {
    this.speakerIsActiveCondition?.set(false);
    this.decorationManager?.dropDecorations();
  };

  private async updateIsPlayableCondition(position: monaco.Position) {
    let value;
    try {
      const { playableData, playableDataRanges } = await this.tryParseSynthParamsCallback(position);
      const { text, voiceOptions } = playableData;
      this.text = text;
      this.voiceOptions = voiceOptions;
      this.playableDataRanges = playableDataRanges ?? [];
      value = true;
    } catch (e: any) {
      this.text = undefined;
      this.voiceOptions = {};
      this.playableDataRanges = [];
      value = false;
    }
    console.debug("Updating playPhrase condition with", value);
    this.isPlayableDataCondition?.set(value);
    return value;
  }
}
