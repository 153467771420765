import styled, { createGlobalStyle } from "styled-components";

import { Container as SearchSelectContainer } from "../../../../../uikit/SearchSelect/styled";
import { Section as CreateIntentSection } from "../../../../../RunnerPanel/TriggersEdit/styled";
import { AddTrigger } from "../../../../../RunnerPanel/TriggersEdit/styled";

export const GlobalStyle = createGlobalStyle`
  ${CreateIntentSection} {
    padding: 0;
  }
`;

export const DialogBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  position: absolute;
  right: 8px;

  path {
    transition: 0.3s;
  }

  &:hover path {
    fill-opacity: 1;
  }
`;

export const Container = styled.div<{ $selected?: boolean }>`
  display: flex;
  min-height: 32px;

  padding-left: 13px;

  position: relative;

  border: 1px solid ${(props) => (props.$selected ? "rgb(88, 85, 244)" : "rgba(0, 0, 0, 0)")};

  > div:first-child {
    width: 100%;
    max-width: 440px;
    min-width: 440px;

    position: relative;

    padding: 4px 28px 4px 10px;

    display: flex;
    align-items: center;

    border-left: 1px solid rgba(255, 255, 255, 0.06);
    border-right: 1px solid rgba(255, 255, 255, 0.06);

    &:hover ${DialogBtn} {
      opacity: 1;
      visibility: visible;
    }
  }

  > div:nth-child(2) {
    display: flex;
    position: relative;
    padding-right: 32px;

    ${AddTrigger} {
      opacity: 0;
      margin-top: 8px;
      height: 18px;
      visibility: hidden;
    }

    &:hover {
      ${AddTrigger} {
        opacity: 1;
        visibility: visible;
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding-bottom: 6px;

      > div {
        margin-right: 6px;
        margin-top: 8px;
      }
    }
  }

  > div {
    flex: 1;
  }

  ${SearchSelectContainer} {
    max-height: 156px;
    padding-bottom: 0;
  }
`;

export const CheckboxContainer = styled.span`
  display: flex;
  align-items: flex-start;
  padding: 8px;
`;

export const NewIntentBtn = styled.button`
  position: sticky;
  bottom: -4px;

  cursor: pointer;

  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  width: 100%;

  background-color: #1d1e31;

  padding-bottom: 5px;

  display: flex;
  align-items: center;

  i {
    margin-left: 2px;
    color: #6f6cfa;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    font-family: var(--main-font);

    visibility: visible;
  }
`;

export const Node = styled.div`
  display: flex;

  position: absolute;
  right: 0;
  top: 0;

  flex: 1;

  padding: 8px;

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(217, 217, 226, 0.75);

  white-space: nowrap;

  & > span {
    display: flex;
    align-items: flex-start;
  }

  & > span:first-child {
    margin-right: 4px;
  }
`;

export const NodeTooltip = styled.div`
  display: flex;
  flex-direction: column;

  b,
  i {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
  }

  b {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 6px;
  }

  i {
    font-style: normal;
    color: rgba(255, 255, 255, 0.75);
  }
`;
