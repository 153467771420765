import React, { FC } from "react";

import { FolderStructure, FSStructure } from "../../core/account/filesystem";
import { TreeProps, TreeContext, useTreeContext } from "./TreeContext";
import { StyledTree } from "./Tree.style";
import { Folder } from "./TreeFolder";
import { File } from "./TreeFile";

const Tree: FC<TreeProps> = (props) => {
  return (
    <TreeContext.Provider value={props}>
      <StyledTree>
        <TreeRecusive indent={16} data={props.data} />
      </StyledTree>
    </TreeContext.Provider>
  );
};

const isInclude = (dir: FolderStructure, path: string) => {
  return dir.files.some((file) => {
    if (file.type === "folder") return isInclude(file, path);
    return file.path === path;
  });
};

const TreeRecusive: FC<{ data: FSStructure[]; indent: number }> = ({ data, indent }) => {
  const { ignore, selected } = useTreeContext();

  const elements = data.map((item) => {
    if (ignore.includes(item.path)) return null;

    if (item.type === "file") {
      return <File key={item.path} indent={indent} name={item.name} node={item} />;
    }

    if (item.type === "folder") {
      const forceOpen = selected ? isInclude(item, selected) : false;
      return (
        <Folder key={item.path} selected={selected} forceOpen={forceOpen} indent={indent} name={item.name} node={item}>
          <TreeRecusive data={item.files} indent={indent + 16} />
        </Folder>
      );
    }

    return null;
  });

  return <>{elements}</>;
};

export default Tree;
