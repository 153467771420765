import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { ActionButton } from "../uikit";
import { Button, Divider, Form, Input, Label, Loader, Message, Popup } from "semantic-ui-react";
import RestApi from "../core/account/api/fetch";
// Import WaveSurfer
import "./index.css";
import GridLayout from "../core/misc/GridLayout";
import * as S from "./styled";

import { useNavigate, useParams } from "react-router-dom";
import * as dasha from "@dasha.ai/sdk/web";
import { useStore } from "../core/api/GlobalStoreContext";

import { DevLog } from "../core/workspace/session-storage/devlogs";
import { AudioViewer } from "./AudioViewer";
import { InspectorPanelInternal } from "./InspectorPanelInternal";
import SessionRunner from "../core/workspace/session-storage/SessionRunner";
import { isMobile } from "react-device-detect";

interface Props {}

const extractId = (id: string | undefined) => {
  const m = id?.match(/(([0-9A-Fa-f]{8}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{12}))/);
  if (m === null || m === undefined) return undefined;
  return m[1];
};

const dashaApi = new RestApi();

export const InspectorPanel: FC<Props> = observer(({}) => {
  const { account, explorer, workspace } = useStore();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [devLog, setDevLog] = useState<DevLog[]>([]);
  const [inputId, setInputId] = useState(extractId(id));
  const [jobId, setJobId] = useState(id);
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const [error, setError] = useState<string | undefined>(undefined);
  const [showAudo, setShowAudio] = useState<boolean>(false);

  useEffect(() => {
    layout.bindGrid("#grid-workspace");
  }, [layout]);

  const load = useCallback(
    (jobIdExtracted: string) => {
      setError(undefined);
      if (jobIdExtracted === undefined) {
        return;
      }
      setLoading(true);

      const fetchData = async () => {
        const fetch = await dashaApi.signFetch(account);
        const response = await fetch(`api/v1/logs/debug/${jobIdExtracted}`);
        const devlog: DevLog[] = await response.json();

        var chat = true;
        for (var track of devlog) {
          if (track.msg.msgId === "JobAdditionalInformationMessage") {
            for (var [k, v] of Object.entries(track.msg.data.tracking)) {
              if (v.channelType !== "Chat") {
                chat = false;
                break;
              }
            }
          }
        }
        setShowAudio(!chat && !isMobile);
        setJobId(jobIdExtracted);
        setDevLog(devlog);
      };
      fetchData()
        .catch((e) => setError("Failed to load:" + e.message))
        .finally(() => setLoading(false));
    },
    [account]
  );

  useEffect(() => {
    const extracted = extractId(id);
    if (extracted !== undefined) {
      load(extracted);
    }
  }, [id]);

  const loadCb = useCallback(() => {
    if (inputId !== undefined) {
      const jobIdExtracted = extractId(inputId);
      if (jobIdExtracted !== undefined) {
        history.pushState({}, "newVersion", `/inspector/${jobIdExtracted}`);
      }
      if (jobIdExtracted !== undefined) {
        load(jobIdExtracted);
      }
    }
  }, [inputId]);

  return (
    <S.Workspace id="grid-workspace" hasAudio={showAudo}>
      {jobId !== undefined && (
        <>
          <InspectorPanelInternal devLog={devLog} jobId={jobId} canHide={showAudo} />
        </>
      )}

      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
        id="editor"
      >
        {error && <Message negative>{error}</Message>}
        <Loader active={loading}>Loading</Loader>
        <Form>
          <Form.Field>
            <Popup
              content="JobId or record url"
              trigger={
                <Input
                  onChange={(e) => setInputId(e.target.value)}
                  value={inputId}
                  action={{
                    color: "teal",
                    labelPosition: "right",
                    icon: "cloud download",
                    content: "Load",
                    onClick: loadCb,
                    disabled: extractId(inputId) === undefined,
                    style: { marginRight: "1em" }
                  }}
                />
              }
            />
          </Form.Field>
        </Form>
        <Divider />
        {showAudo && <AudioViewer devLog={devLog} />}
      </div>
    </S.Workspace>
  );
});
