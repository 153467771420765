import React, { FC, useEffect, useRef, useState } from "react";
import { StyledFile } from "./Tree.style";
import FILE_ICONS, { DirOpen, TextIcon } from "./FileIcons";

const FileEdit = ({ ext, inputRef, updateExt, defaultValue, style }) => {
  const extension = FILE_ICONS[ext] ?? <TextIcon />;

  return (
    <StyledFile style={style}>
      <p>
        {extension}
        &nbsp;&nbsp;
      </p>
      <input autoFocus ref={inputRef} onChange={updateExt} defaultValue={defaultValue} />
    </StyledFile>
  );
};

const FolderEdit = ({ inputRef, defaultValue, style }) => {
  return (
    <StyledFile style={style}>
      <p>
        <DirOpen />
        &nbsp;&nbsp;
      </p>
      <input autoFocus ref={inputRef} defaultValue={defaultValue} />
    </StyledFile>
  );
};

interface PropsPlaceholder {
  type: "folder" | "file";
  onSubmit?: (value: string) => void;
  onCancel: () => void;
  defaultValue?: string;
  style?: any;
}

const PlaceholderInput: FC<PropsPlaceholder> = ({ type, onSubmit, onCancel, defaultValue, style }) => {
  const [ext, setExt] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const updateExt = () => {
    const splitted = inputRef.current?.value.split(".");
    const ext = splitted && splitted[splitted.length - 1];
    if (ext) setExt(ext);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
    inputRef.current.addEventListener("keyup", (e) => {
      if (!inputRef.current) return;
      if (e.key === "Enter") onSubmit?.(inputRef.current.value);
      if (e.key === "Escape") {
        onCancel && onCancel();
      }
    });

    inputRef.current.addEventListener("blur", () => {
      if (!inputRef.current) return;
      onSubmit?.(inputRef.current.value);
      onCancel && onCancel();
    });
  }, [inputRef]);

  return type === "file" ? (
    <FileEdit ext={ext} style={style} updateExt={updateExt} inputRef={inputRef} defaultValue={defaultValue} />
  ) : (
    <FolderEdit style={style} inputRef={inputRef} defaultValue={defaultValue} />
  );
};

export { PlaceholderInput };
