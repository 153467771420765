import { DslElement } from "../common/DslElement";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

/** Should be used only for primitive types */
export class RawElement<T> extends DslElement implements interfaces.IRawElement<T> {
  elementType: DslElementType.RawElement = DslElementType.RawElement;
  value: T;
  constructor(obj: interfaces.IRawElement<T>, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.value = obj.value;
  }
  toJson(): interfaces.IRawElement<T> {
    return {
      elementType: this.elementType,
      location: this.location,
      value: this.value,
    };
  }
  toDsl(indent: number): string {
    return `${this.value}`;
  }
}
