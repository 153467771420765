import FS from "@isomorphic-git/lightning-fs";
import JSZip from "jszip";

export interface FileStructure {
  type: "file";
  path: string;
  name: string;
}

export interface FolderStructure {
  type: "folder";
  path: string;
  name: string;
  files: FSStructure[];
}

export type FSStructure = FileStructure | FolderStructure;

class LocalStorage {
  readonly fs: FS;

  constructor(readonly id: string) {
    this.fs = new FS(id);
  }

  async delete(path: string): Promise<string[]> {
    const removes: string[] = [];
    const walkDir = async (dir: string) => {
      const stat = await this.fs.promises.lstat(dir);
      if (stat.isDirectory()) {
        const pathes = await this.fs.promises.readdir(dir);
        await Promise.all(pathes.map((path) => walkDir(dir + "/" + path)));
        await this.fs.promises.rmdir(dir);
      } else {
        removes.push(dir);
        await this.fs.promises.unlink(dir);
      }
    };

    const isExist = await this.exist(path);
    if (isExist) await walkDir(path);
    return removes;
  }

  async readString(file: string) {
    const content = await this.fs.promises.readFile(file, "utf8");
    if (typeof content !== "string") throw Error("only string");
    return content;
  }

  async readJSON<T>(file: string): Promise<T> {
    const content = await this.readString(file);
    return JSON.parse(content);
  }

  async write(file: string, content: string) {
    await this.fs.promises.writeFile(file, content);
  }

  /**
   * Creates a directory at the specified path if it doesn't exist.
   * @param dir - The directory path to create.
   */
  async mkdir(dir: string): Promise<void> {
    const exists = await this.exist(dir);
    if (!exists) {
      try {
        await this.fs.promises.mkdir(dir);
      } catch (e) {
        // Handle any unexpected errors
        console.error(`Failed to create directory '${dir}':`, e);
        throw e;
      }
    }
  }

  async exist(dir: string) {
    return this.fs.promises
      .stat(dir)
      .then(() => true)
      .catch(() => false);
  }

  async writeFromZip(dir: string, data: Uint8Array | JSZip) {
    const project = data instanceof JSZip ? data : await new JSZip().loadAsync(data);
    project.remove("__MACOSX");

    await this.delete(dir);
    await this.mkdir(dir);

    for (const [path, file] of Object.entries(project.files)) {
      let dist = dir;
      const parts = path.split("/");
      const head = parts.pop();
      for (const part of parts) {
        dist += "/" + part;
        await this.mkdir(dist);
      }

      dist += "/" + head;
      if (file.dir) {
        await this.mkdir(dist);
        continue;
      }

      const content = await file.async("uint8array");
      await this.fs.promises.writeFile(dist, content);
    }
  }

  async files(source: string): Promise<FSStructure[]> {
    const structure: FSStructure[] = [];

    const sortFiles = (a, b) => {
      if (a.type === "folder") return -1;
      if (b.type === "folder") return 1;
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    };

    const walkDir = async (path: string, parentNode?: FolderStructure) => {
      const name = path.split("/").pop() ?? path;
      const localpath = path.replace(source + "/", "");
      const stat = await this.fs.promises.lstat(path);
      if (stat.isDirectory()) {
        const pathes = await this.fs.promises.readdir(path);
        const folder: FolderStructure = { type: "folder", path: localpath, files: [], name };
        if (parentNode) parentNode.files.push(folder);
        else structure.push(folder);

        await Promise.all(pathes.map((file) => walkDir(path + "/" + file, folder)));
        folder.files = folder.files.sort(sortFiles);
      } else {
        const file: FileStructure = { type: "file", path: localpath, name };
        if (parentNode) parentNode.files.push(file);
        else structure.push(file);
      }
    };

    const pathes = await this.fs.promises.readdir(source);
    await Promise.all(pathes.map((file) => walkDir(source + "/" + file)));
    return structure.sort(sortFiles);
  }

  async zipDirectory(source: string): Promise<JSZip> {
    const zip = new JSZip();

    const walkDir = async (dir: string) => {
      const filepath = dir.replace(source + "/", "");

      const stat = await this.fs.promises.lstat(dir);
      if (stat.isDirectory()) {
        const pathes = await this.fs.promises.readdir(dir);
        if (dir !== source) zip.file(filepath, null, { dir: true });
        await Promise.all(pathes.map((path) => walkDir(dir + "/" + path)));
      } else {
        const content = await this.fs.promises.readFile(dir);
        zip.file(filepath, content);
      }
    };

    await walkDir(source);
    return zip;
  }
}

export default LocalStorage;
