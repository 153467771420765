import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";
import * as helpers from "../helpers";

export class DigressionVariableReferenceExpression
  extends Expression
  implements interfaces.IDigressionVariableReferenceExpression
{
  elementType: DslElementType.DigressionVariableReferenceExpression =
    DslElementType.DigressionVariableReferenceExpression;
  digressionName: interfaces.OptionalValue<RawElement<string>>;
  isShared: boolean;
  variableName: interfaces.OptionalValue<RawElement<string>>;

  constructor(obj: interfaces.IDigressionVariableReferenceExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.digressionName = helpers.ensureIsOptionalInstance(obj.digressionName, RawElement<string>);
    this.isShared = obj.isShared;
    this.variableName = helpers.ensureIsOptionalInstance(obj.variableName, RawElement<string>);
  }
  toJson(): interfaces.IDigressionVariableReferenceExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      digressionName: interfaces.helpers.createOptionalValue(this.digressionName.value),
      isShared: this.isShared,
      variableName: interfaces.helpers.createOptionalValue(this.variableName.value),
      location: this.location,
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        `digression`,
        this.digressionName.hasValue ? `.${this.digressionName.value.toDsl(indent)}` : null,
        this.isShared ? `.shared` : null,
        this.variableName.hasValue ? `.${this.variableName.value.toDsl(indent)}` : null,
      ],
      ""
    );
  }
}
