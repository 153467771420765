export const uploadProfile = async () =>
  new Promise<{ name: string; content: Uint8Array }>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.style.display = "none";
    document.body.appendChild(input);
    input.click();

    input.addEventListener("change", (e: any) => {
      document.body.removeChild(input);
      if (e.target.files?.length !== 1) reject();
      const file = e.target.files[0];

      if (file.name.split(".").pop() !== "dashasp") reject();

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onerror = () => reject();
      reader.onabort = () => reject();

      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          const content = new Uint8Array(reader.result);
          resolve({ name: file.name, content });
        }
      };
    });
  });
