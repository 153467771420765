import styled from "styled-components";
import { PureButton } from "../../uikit/Button";

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 48px;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: center;

  display: flex;
  justify-content: space-between;
  background: rgba(247, 247, 253, 0.02);
  padding: 0 16px 0 24px;
`;

export const SessionDate = styled(PureButton)`
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #d9d9e2;
  text-transform: none;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  svg {
    margin-right: 4px;
  }
`;

export const Control = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.04);
  mix-blend-mode: normal;
  border-radius: 4px;
  height: 24px;
  padding: 0 5px;

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;
    margin: 0 4px;
  }

  button {
    opacity: 0.75;
    transition: 0.2s opacity;
    margin-left: 6px;
  }

  button:hover {
    opacity: 1;
  }
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  button {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    opacity: 0.75;
    transition: 0.2s opacity;
    text-transform: none;
    margin-left: 12px;

    span {
      margin-left: 4px;
    }
  }

  button:hover {
    opacity: 1;
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
