import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { ContextVariable } from "../variables";
import { DslElement, RawElement } from "../common";
import { ExternalFunctionDeclaration, TypeAlias } from "../sections";
import { Node } from "./Node";
import { Digression } from "./Digression";
import { Block } from "./Block";
export class BlockContentDescription extends DslElement implements interfaces.IBlockContentDescription {
  elementType: DslElementType.BlockContentDescription = DslElementType.BlockContentDescription;
  imports: RawElement<string>[];
  externalFunctions: ExternalFunctionDeclaration[];
  contextVariables: ContextVariable[];
  types: TypeAlias[];
  nodes: Node[];
  digressions: Digression[];
  blocks: Block[];

  constructor(obj: interfaces.IBlockContentDescription, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.imports = helpers.ensureIsArrayOfInstances(obj.imports, RawElement<string>);
    this.externalFunctions = helpers.ensureIsArrayOfInstances(obj.externalFunctions, ExternalFunctionDeclaration);

    this.contextVariables = helpers.ensureIsArrayOfInstances(obj.contextVariables, ContextVariable);
    this.types = helpers.ensureIsArrayOfInstances(obj.types, TypeAlias);
    this.nodes = helpers.ensureIsArrayOfInstances(obj.nodes, Node);
    this.digressions = helpers.ensureIsArrayOfInstances(obj.digressions, Digression);
    this.blocks = helpers.ensureIsArrayOfInstances(obj.blocks, Block);
  }
  toJson(): interfaces.IBlockContentDescription {
    return {
      elementType: this.elementType,
      location: this.location,
      imports: this.imports.map((el) => el.toJson()),
      externalFunctions: this.externalFunctions.map((el) => el.toJson()),
      contextVariables: this.contextVariables.map((el) => el.toJson()),
      types: this.types.map((el) => el.toJson()),
      nodes: this.nodes.map((el) => el.toJson()),
      digressions: this.digressions.map((el) => el.toJson()),
      blocks: this.blocks.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        this.imports.map((el) => helpers.indentString(indent) + `import ${el.value};`).join("\n") ?? null,
        this.types.map((el) => helpers.indentString(indent) + el.toDsl(indent)).join("\n") ?? null,
        [
          helpers.indentString(indent) + "context {",
          ...this.contextVariables.map((el) => el.toDsl(indent + 1)),
          helpers.indentString(indent) + "}",
        ].join("\n"),
        this.externalFunctions.map((el) => el.toDsl(indent)).join("\n"),
        this.blocks.map((el) => el.toDsl(indent)).join("\n"),
        this.nodes.map((el) => el.toDsl(indent)).join("\n"),
        this.digressions.map((el) => el.toDsl(indent)).join("\n"),
      ],
      "\n\n"
    );
  }
}
