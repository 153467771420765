import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import builtinFunctionsDescription from "../../../../../../../DSL/DSL.Library/builtinFunctions.json";

function createBuiltinFunctionDetails(name, description) {
  return `${description.isAsync ? "async " : ""} ${name}(${description.args
    .map((arg) => arg.name + ": " + arg.type)
    .join(", ")}): ${description.returnType}`;
}

export function createBuiltinFunctionSuggestions(position: monaco.IPosition): monaco.languages.CompletionItem[] {
  return Object.keys(builtinFunctionsDescription).map((bfName) => {
    const bfDescription = builtinFunctionsDescription[bfName];
    return {
      label: bfName,
      insertText: bfName,
      kind: monaco.languages.CompletionItemKind.Function,
      range: monaco.Range.fromPositions(position),
      detail: createBuiltinFunctionDetails(bfName, bfDescription),
      documentation: { value: bfDescription.documentation },
    };
  });
}

export function createNodeSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "node",
    insertText: ["node ${1:nodeName} {", `\tdo {\n\t\n\t}`, `}`].join("\n"),
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}

export function createDigressionSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "digression",
    insertText: ["digression ${1:digressionName} {", `\tconditions {\n\t\t\n\t}`, `\tdo {\n\t\t\n\t}`, `}`].join("\n"),
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}

export function createPreprocessorSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "preprocessor",
    insertText: [
      "preprocessor digression ${1:preprocessorName} {",
      `\tconditions {\n\t\t\n\t}`,
      `\tdo {\n\t\t\n\t}`,
      `}`,
    ].join("\n"),
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}

export function createDoSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "do",
    insertText: `do {\n\t$0\n}`,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}

export function createTransitionSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "transitions",
    insertText: `transitions {\n\t$0\n}`,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}

export function createOnexitSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "onexit",
    insertText: `onexit {\n\t$0\n}`,
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}

export function createConditionsSnippet(position: monaco.IPosition): monaco.languages.CompletionItem {
  return {
    label: "conditions",
    insertText: `conditions {\n\n}`,
    kind: monaco.languages.CompletionItemKind.Snippet,
    range: monaco.Range.fromPositions(position),
  };
}
