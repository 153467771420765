import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { DslElement, RawElement } from "../common";
import { ArgumentDeclaration } from "../arguments/ArgumentDeclaration";

export class ExternalFunctionDeclaration extends DslElement implements interfaces.IExternalFunctionDeclaration {
  elementType: DslElementType.ExternalFunctionDeclaration = DslElementType.ExternalFunctionDeclaration;
  name: RawElement<string>;
  returnType: RawElement<string>;
  arguments: ArgumentDeclaration[];

  constructor(obj: interfaces.IExternalFunctionDeclaration, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.returnType = helpers.ensureIsInstance(obj.returnType, RawElement<string>);
    this.arguments = helpers.ensureIsArrayOfInstances(obj.arguments, ArgumentDeclaration);
  }
  toJson(): interfaces.IExternalFunctionDeclaration {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      returnType: this.returnType.toJson(),
      arguments: this.arguments.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + "external function ",
      this.name.value,
      `(${this.arguments.map((arg) => arg.toDsl(indent)).join(",")})`,
      `: ${this.returnType.value}`,
      ";",
    ].join("");
  }
}
