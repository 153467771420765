import React, { FC, useEffect, useState } from "react";
import { TestCase } from "../core/api/prompts/TestCase";
import { observer } from "mobx-react-lite";
import { Checkbox, Form, Input, Label, TextArea } from "semantic-ui-react";
import { ActionButton } from "../uikit";
import UIManager from "../core/misc/UIManager";

const clamp = (value: number) => {
  if (value < 0) return 0;
  if (value > 100) return 100;
  return value;
};

export const TestCaseOptions: FC<{ testCase: TestCase }> = observer(({ testCase }) => {
  const [name, setName] = useState(testCase.name);
  const [description, setDescription] = useState(testCase.description ?? "");
  const [comparePrompt, setComparePrompt] = useState(testCase.testOptions.comparePrompt ?? "");
  const [smartThreshold, setSmartThreshold] = useState((testCase.testOptions.smartThreshold ?? 0.8) * 100);
  const [ratioTextThreshold, setRatioTextThreshold] = useState((testCase.testOptions.ratioTextThreshold ?? 0.85) * 100);
  const [ratioFunctionsThreshold, setRatioFunctionsThreshold] = useState(
    (testCase.testOptions.ratioFunctionsThreshold ?? 0.85) * 100
  );
  const [compareThinking, setCompareThinking] = useState(testCase.testOptions.compareThinking ?? false);
  useEffect(() => {
    testCase.setDescription(description);
    testCase.setName(name);
    testCase.setTestOptions({
      comparePrompt: comparePrompt,
      smartThreshold: smartThreshold / 100,
      ratioTextThreshold: ratioTextThreshold / 100,
      ratioFunctionsThreshold: ratioFunctionsThreshold / 100,
      compareThinking: compareThinking,
    });
  }, [name, description, comparePrompt, smartThreshold, ratioTextThreshold, ratioFunctionsThreshold, compareThinking]);
  return (
    <Form>
      <Form.Field>
        <Label content="Name" />
        <Input value={name} onChange={(e, d) => setName(d.value)} />
      </Form.Field>
      <Form.Field>
        <Label content="Description" />
        <TextArea value={description} onChange={(e, d) => setDescription(d.value?.toString() ?? "")} />
      </Form.Field>
      <Form.Field>
        <Label content="Compare results instruction" />
        <TextArea
          placeholder='Special Instruction: For the functionName function, compare the argName argument based on the intention rather than an exact match. For example, "no" and "not ready yet" should be considered similar if they convey the same intention. Minor wording differences should not significantly impact the similarity score as long as the intention remains consistent.'
          value={comparePrompt}
          onChange={(e, d) => setComparePrompt(d.value)}
        />
      </Form.Field>
      <Form.Field>
        <Label content="Smart threshold, %" />
        <Input
          type="number"
          value={smartThreshold}
          onChange={(e, d) => setSmartThreshold(clamp(parseFloat(d.value)))}
        />
      </Form.Field>
      <Form.Field>
        <Label content="Text ratio threshold, %" />
        <Input
          type="number"
          value={ratioTextThreshold}
          onChange={(e, d) => setRatioTextThreshold(clamp(parseFloat(d.value)))}
        />
      </Form.Field>
      <Form.Field>
        <Label content="Functions ratio threshold, %" />
        <Input
          type="number"
          value={ratioFunctionsThreshold}
          onChange={(e, d) => setRatioFunctionsThreshold(clamp(parseFloat(d.value)))}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox toggle label="Compare thinking" value={compareThinking} onChange={(e, d) => setCompareThinking(d.value)} />
      </Form.Field>
      <ActionButton
        onClick={() =>
          testCase
            .saveAsync()
            .then(() => UIManager.success("Test saved"))
            .catch((e) => UIManager.notice("Failed to save test changes"))
        }
      >
        {" "}
        Save test changes{" "}
      </ActionButton>
    </Form>
  );
});
