import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";

export class BinaryExpression extends Expression implements interfaces.IBinaryExpression {
  elementType: DslElementType.BinaryExpression = DslElementType.BinaryExpression;
  leftExpression: Expression;
  rightExpression: Expression;
  binaryOperationSymbol:
    | "+"
    | "-"
    | "*"
    | "/"
    | "%"
    | "??"
    | "<"
    | ">"
    | "<="
    | ">="
    | "=="
    | "!="
    | "&&"
    | "||"
    | "or"
    | "and";

  constructor(obj: interfaces.IBinaryExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.leftExpression = helpers.ensureIsExpressionInstance(obj.leftExpression);
    this.rightExpression = helpers.ensureIsExpressionInstance(obj.rightExpression);
    this.binaryOperationSymbol = obj.binaryOperationSymbol;
  }
  toJson(): interfaces.IBinaryExpression {
    return {
      elementType: this.elementType,
      isExpression: true,
      location: this.location,
      leftExpression: this.leftExpression.toJson(),
      rightExpression: this.rightExpression.toJson(),
      binaryOperationSymbol: this.binaryOperationSymbol,
    };
  }
  toDsl(indent: number): string {
    return `${this.leftExpression.toDsl(indent)} ${this.binaryOperationSymbol} ${this.rightExpression.toDsl(indent)}`;
  }
}
