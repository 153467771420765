import _ from "lodash";
import { MarkupType, MsgRecognize, Trigger } from "./devlogs";

export const parseIntents = (msg: MsgRecognize): Trigger[] => {
  return msg.results.flatMap((result) => {
    const facts: Trigger[] = [];
    result.facts?.forEach((fact, id) => {
      const location = {
        start: +fact.location.start,
        end: +fact.location.end,
      };

      if (location.start !== 0) {
        location.start -= 1;
      }

      if (fact.type == "Data") {
        facts.push({
          id,
          hue: null,
          mark: MarkupType.unmark,
          type: "entity",
          value: fact.data.value,
          tag: fact.data.tag,
          name: fact.factType,
          probability: +fact.weight,
          location,
        });
      }

      if (fact.type == "Intent" && fact.intent) {
        facts.push({
          id,
          mark: MarkupType.unmark,
          type: "intent",
          name: fact.intent,
          probability: +fact.weight,
          readOnly: fact.intent == null || +fact.weight === -1,
          location,
        });
      }
    });

    return facts;
  });
};

export function deepDiff(fromObject: object, toObject: object) {
  const changes = {};
  const buildPath = (path: string | undefined, key: string) => {
    return _.isUndefined(path) ? key : `${path}.${key}`;
  };

  const walk = (fromObject, toObject, path?: string) => {
    for (const key of _.keys(fromObject)) {
      const currentPath = buildPath(path, key);
      if (!_.has(toObject, key)) {
        changes[currentPath] = _.get(fromObject, key);
      }
    }

    for (const [key, to] of _.entries(toObject)) {
      const currentPath = buildPath(path, key);
      if (!_.has(fromObject, key)) {
        changes[currentPath] = to;
        continue;
      }

      const from = _.get(fromObject, key);
      if (!_.isEqual(from, to)) {
        if (_.isObjectLike(to) && _.isObjectLike(from)) {
          walk(from, to, currentPath);
        } else {
          changes[currentPath] = to;
        }
      }
    }
  };

  walk(fromObject, toObject);
  return changes;
}
