import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

class UIManager {
  static notice = (text: string) => {
    Toastify({
      text,
      duration: 3000,
      position: "left",
      gravity: "bottom",
      style: { fontFamily: "var(--main-font)" },
    }).showToast();
  };

  static success = (text: string) => {
    Toastify({
      text,
      duration: 1500,
      position: "center",
      gravity: "top",
      offset: { y: 9 },
      style: {
        boxShadow: 'none',
        fontFamily: "var(--main-font)",
        background: "#01A3AA",
        borderRadius: '8px',
        padding: "8px 16px",
        fontStyle: "normal",
        width: "512px",
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '28px',
        color: "#FFFFFF",
      },
    }).showToast();
  };
}

export default UIManager;
