import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { RawElement } from "../common";
import { TransitionCondition } from "./TransitionCondition";

export class TransitionTimeoutCondition extends TransitionCondition implements interfaces.ITransitionTimeoutCondition {
  elementType: DslElementType.TransitionTimeoutCondition = DslElementType.TransitionTimeoutCondition;
  timeOut: RawElement<number>;

  constructor(obj: interfaces.ITransitionTimeoutCondition, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.timeOut = helpers.ensureIsInstance(obj.timeOut, RawElement<number>);
  }
  toJson(): interfaces.ITransitionTimeoutCondition {
    return {
      isTransitionCondition: true,
      elementType: this.elementType,
      location: this.location,
      timeOut: this.timeOut.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `timeout ${this.timeOut.value}`;
  }
}
