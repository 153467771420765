import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { ActionButton, Icon } from "../uikit";
import { Button, Checkbox, Dropdown, Grid, Input, Label, List, Loader, Message } from "semantic-ui-react";
import RestApi from "../core/account/api/fetch";

// Import WaveSurfer
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/plugins/regions";
import Spectrogram from "wavesurfer.js/plugins/spectrogram";
import "./index.css";
import Colormap from "colormap";
import { RangeSlider } from "../EditorPanel/LegacyGraphEditor/styled";
import { useStore } from "../core/api/GlobalStoreContext";
import { RegionParams, Region } from "wavesurfer.js/plugins/regions";
import { DevLog, GptStartMessage, SpeechChannelMessage } from "../core/workspace/session-storage/devlogs";
import { Tooltip } from "../uikit/popup";

interface Props {
  devLog: DevLog[];
}

const random = (min, max) => Math.random() * (max - min) + min;
const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;

export const AudioViewer: FC<Props> = observer(({ devLog }) => {
  const { account } = useStore();
  const [audioUrl, setAudioUrl] = useState<string | undefined>("");
  const [zoom, setZoom] = useState(10);
  const [dashaRegions, setDashaRegions] = useState<RegionParams[]>([]);
  const containerRef = useRef<HTMLDivElement>();
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [activeRegions, setActiveRegions] = useState<Region[]>([]);
  const [routeIds, setRouteIds] = useState<string[]>([]);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const [showMel, setShowMel] = useState<boolean>(false);

  const onPlayClick = useCallback(() => {
    wavesurfer?.isPlaying() ? wavesurfer?.pause() : wavesurfer?.play();
  }, [wavesurfer]);

  const processRegion = (region: Region) => {
    const divider = showMel ? 4 : 2;
    const defaultTop = region.channelIdx * (100 / divider);
    const defaultHeight = 100 / divider;

    let top = defaultTop;
    let height = defaultHeight;
    const currentStyle = region.element.getAttribute("style")!;
    let newStyle = currentStyle;
    if (region.content?.textContent?.includes("NR") || region.content?.textContent?.includes("FR") || region.content?.textContent?.startsWith("Call: ")) {
      if (showMel) {
        top = defaultTop - 4;
      } else {
        top = defaultTop + 1;
      }

      height = showMel ? 12 : 24;
    } else if (region.content?.textContent?.includes("PotentialPause")) {
      if (showMel) {
        top = defaultTop;
      } else {
        top = defaultTop + 3;
      }
      height = showMel ? 11 : 22;
    } else {
      if (showMel) {
        top = defaultTop + (region.channelIdx === 0 ? 5 : 2);
      } else {
        top = defaultTop + (region.channelIdx === 0 ? 12.5 : 12.5);
      }
      height = showMel ? 12.5 : 25;
    }
    newStyle += ` top: ${top}%; height: ${height}%;`;
    region.element.setAttribute("style", newStyle);
  };

  useEffect(() => {
    if (!wavesurfer) return;

    setCurrentTime(0);
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("timeupdate", (currentTime) => setCurrentTime(currentTime)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  useEffect(() => {
    const routes = devLog
      .map((x) =>
        x.msg.msgId === "OpenedSessionChannelMessage" && x.msg.recordId !== undefined ? x.msg.routeId : undefined
      )
      .filter((x) => x !== undefined);
    if (routes.length == 0) {
      setAudioUrl(undefined);
      setRouteIds([]);
      if (selectedRoute !== "") {
        setSelectedRoute("");
      }
      return;
    }
    setRouteIds(routes);
    if (!(selectedRoute in routes)) {
      setAudioUrl(undefined);
    }
    const speechRegions: RegionParams[] = [];
    const replaceRegions = new Map<number, RegionParams>();
    const gptStartMessages = new Map<string, GptStartMessage>();
    var refTime: number | undefined;
    var recordId: string | undefined = undefined;

    const diffTime = (time: string): number => {
      if (refTime === undefined) {
        return 0;
      }
      return (new Date(time).valueOf() - refTime.valueOf()) / 1000;
    };

    for (var idx in devLog) {
      const message = devLog[idx];
      if (message.msg.msgId === "GptFunctionCallResponseMessage") {
        const route = message.msg.routeId;
        if (message.msg.routeId !== selectedRoute && routes.findIndex((x) => x === route) >=0 ) {
          continue;
        }

        speechRegions.push({
          start: diffTime(message.msg.StartTime),
          end: diffTime(message.msg.EndTime),
          content: "Call: " + message.msg.FunctionName,
          color: "rgba(255, 255, 0, 0.5)",
          drag: false,
          resize: false,
          channelIdx: 1,
        });

        const start = gptStartMessages.get(message.msg.InstanceId);
        if (start === null || start === undefined) {
          continue;
        }
        gptStartMessages.delete(message.msg.InstanceId);
        speechRegions.push({
          start: diffTime(start.StartTime),
          end: diffTime(message.msg.StartTime),
          content: "FR " + message.msg.FunctionName,
          color: "rgba(255, 0, 0, 0.3)",
          drag: false,
          resize: false,
          channelIdx: 1,
        });

      }

      if (message.msg.msgId === "GptStartMessage" && message.msg.routeId === selectedRoute) {
        gptStartMessages.set(message.msg.InstanceId, message.msg);
      }

      if (
        message.msg.msgId === "OpenedSessionChannelMessage" &&
        refTime === undefined &&
        message.msg.routeId === selectedRoute
      ) {
        refTime = new Date(message.time).valueOf();
        recordId = message.msg.recordId;
      }

      if (
        message.msg.msgId === "StoppedPlayingAudioChannelMessage" &&
        message.msg.PlayedText !== null &&
        message.msg.PlayedText !== undefined &&
        message.msg.PlayedText !== "" &&
        message.msg.routeId === selectedRoute
      ) {
        const startTime = diffTime(message.msg.StartPlayingTime);
        const endTime = diffTime(message.msg.EndPlayingTime);
        speechRegions.push({
          id: idx.toString(),
          start: startTime,
          end: endTime,
          content: message.msg.PlayedText,
          color: randomColor(),
          drag: false,
          resize: false,
          channelIdx: 1,
          minLength: endTime - startTime,
          maxLength: endTime - startTime,
        });

        const corr = message.msg.Metadata?.CorrelationId;
        if (corr !== null && corr !== undefined) {
          const start = gptStartMessages.get(corr);
          if (start !== null && start !== undefined) {
            gptStartMessages.delete(corr);
            speechRegions.push({
              start: diffTime(start.StartTime),
              end: diffTime(message.msg.TtsSynthStartTime ?? message.msg.StartPlayingTime),
              content: "FR",
              color: "rgba(255, 0, 0, 0.3)",
              drag: false,
              resize: false,
              channelIdx: 1,
            });
          }
        }
      }

      if (message.msg.msgId === "SpeechChannelMessage" && message.msg.type === "PotentialPause") {
        speechRegions.push({
          start: diffTime(message.time),
          end: diffTime(message.time),
          content: "PotentialPause",
          color: randomColor(),
          drag: false,
          resize: false,
          channelIdx: 0,
        });
      }

      if (
        message.msg.msgId === "RawTextChannelMessage" &&
        (message.msg.textMsgId === "FinalTextChannelMessage" || message.msg.textMsgId === "ConfidentTextChannelMessage")
      ) {
        const startTime = diffTime(message.msg.StartTime);
        const endTime = diffTime(message.msg.EndTime);
        if (message.msg.textMsgId === "FinalTextChannelMessage") {
          const region = {
            start: startTime,
            end: endTime,
            content: message.msg.text,
            color: randomColor(),
            drag: false,
            resize: false,
            channelIdx: 0,
            minLength: endTime - startTime,
            maxLength: endTime - startTime,
          };
          speechRegions.push(region);
          replaceRegions[message.msg.voiceSegmentId] = region;
        }

        if (message.msg.textMsgId === "ConfidentTextChannelMessage") {
          replaceRegions[message.msg.voiceSegmentId].content = message.msg.text;
        }
      }

      if (message.msg.msgId === "ProtocolSpecificMessage" && message.msg.type === "dtmf") {
        const startTime = diffTime(message.time);
        const endTime = 0.3 + diffTime(message.time);
        const region = {
          start: startTime,
          end: endTime,
          content: message.msg.message,
          color: randomColor(),
          drag: false,
          resize: false,
          channelIdx: 0,
          minLength: endTime - startTime,
          maxLength: endTime - startTime,
        };
        speechRegions.push(region);
      }
    }

    setDashaRegions(speechRegions);
    setAudioUrl(`http${account.insecure ? "" : "s"}://${account.server}/api/v1/records/${recordId}`);
  }, [devLog, selectedRoute]);

  useEffect(() => {
    if (!containerRef.current) return;
    const ws = WaveSurfer.create({
      container: containerRef.current,
      url: audioUrl,
      plugins: [],
      height: 200,
      splitChannels: [
        {
          waveColor: "rgb(200, 0, 200)",
          progressColor: "rgb(100, 0, 100)",
          container: containerRef.current,
        },
        {
          waveColor: "rgb(0, 200, 200)",
          progressColor: "rgb(0, 100, 100)",
          container: containerRef.current,
        },
      ],
    });

    const wsRegions = ws.registerPlugin(RegionsPlugin.create());
    if (showMel) {
      const colormap = Colormap({ colormap: "jet", nshades: 256, format: "float", alpha: 1 });
      const spec = Spectrogram.create({
        labels: true,
        container: ws.getWrapper(),
        splitChannels: true,
        colorMap: colormap,
      });
      ws.registerPlugin(spec);
    }

    ws.on("decode", () => {
      wsRegions.clearRegions();
      for (var x of dashaRegions) {
        wsRegions.addRegion(x);
      }
      wsRegions.getRegions().forEach((x) => processRegion(x));
      ws.zoom(zoom);
    });

    {
      wsRegions.on("region-in", (region) => {
        setActiveRegions([region]);

      });
      wsRegions.on("region-out", (region) => {
        //removeActiveRegion(region);
      });
      // wsRegions.on("region-clicked", (region, e) => {
      //   setActiveRegions([region]);
      // });
      // Reset the active region when the user clicks anywhere in the waveform
      ws.on("interaction", () => {
        setActiveRegions([]);
        //setActiveRegions([]);
      });
    }

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [containerRef, audioUrl, showMel]);

  if (routeIds.length === 0) {
    return <></>;
  }

  const renderActiveRegion = (activeRegion: Region) => {
    const diffTime = (end: string | undefined, start: string | undefined): number | undefined => {
      if (end === undefined || start === undefined) {
        return undefined;
      }
      return (new Date(end).valueOf() - new Date(start).valueOf()) / 1000;
    };
    if (activeRegion?.id === null || activeRegion?.id === undefined) {
      return <></>;
    }
    const message: DevLog = devLog[parseInt(activeRegion.id)];
    if (message === undefined) {
      return <></>;
    }
    if (message.msg.msgId === "StoppedPlayingAudioChannelMessage") {
      const instanceId = message.msg.Metadata?.CorrelationId;
      const playedText = message.msg.PlayedText ?? "";
      const ttsTime = diffTime(message.msg.TtsSynthEndTime, message.msg.TtsSynthStartTime);
      const startPlayingTime = new Date(message.msg.StartPlayingTime).valueOf();
      const lastPauseTime = devLog
        .filter((x) => x.msg.msgId === "SpeechChannelMessage" && new Date(x.time).valueOf() < startPlayingTime)
        .at(-1);
      const pauseToResponseTime = diffTime(message.msg.StartPlayingTime, lastPauseTime?.time);
      const event = lastPauseTime?.msg as SpeechChannelMessage;
      const gptResponse = instanceId
        ? devLog.filter((x) => x.msg.msgId === "GptStartMessage" && x.msg.InstanceId === instanceId)[0]
        : undefined;
      const gptTimeDiff = diffTime(message.msg.TtsSynthStartTime, gptResponse?.time);

      return (
        <>
          <List.Item>
            Played: <b>{playedText}</b>
          </List.Item>
          {ttsTime && (
            <List.Item>
              Time to synthesize: <b>{ttsTime}</b> seconds
            </List.Item>
          )}
          {pauseToResponseTime && (
            <List.Item>
              Time from {event.type}: <b>{pauseToResponseTime}</b> seconds
            </List.Item>
          )}
          {gptTimeDiff && (
            <List.Item>
              Gpt latency: <b>{gptTimeDiff}</b> seconds
            </List.Item>
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column>
            <Icon name={isPlaying ? "pause" : "run"} onClick={onPlayClick}></Icon>
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              options={routeIds.map((x) => ({ text: x === "" ? "main" : `channel${x}`, value: x, key: x }))}
              value={selectedRoute}
              onChange={(e, data) => setSelectedRoute(data.value?.toString() ?? "")}
              placeholder="Select channel"
            ></Dropdown>
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              toggle
              checked={showMel}
              onChange={(e, data) => setShowMel(data.checked ?? false)}
              label="Show Spectrogram"
            />
          </Grid.Column>
          <Grid.Column>
            <Label> Zoom x{zoom} </Label>
            <RangeSlider>
              <input
                value={zoom}
                onChange={(e) => {
                  if (!wavesurfer) return;

                  var number = Number.parseFloat(e.target.value);
                  setZoom(number);
                  wavesurfer.zoom(number);
                }}
                type="range"
                min={10}
                max={1000}
                step={10}
              />
            </RangeSlider>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <List>
              <List.Item>Current time: {currentTime}</List.Item>
              {activeRegions.map((x)=>renderActiveRegion(x))}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {containerRef && <div ref={containerRef} className="waveform" />}
    </>
  );
});
