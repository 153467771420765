import React from "react";
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    text-align: center;
    padding: 10%;
    font-family: var(--main-font);
    font-size: 21px;
    color: #ffffff;
  }
`;

const ErrorBoundary = () => (
  <Container>
    <h1>Sorry, an unexpected error has occurred, we are already fixing this issue.</h1>
  </Container>
);

export default ErrorBoundary;
