import styled from "styled-components";

export const PureButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  font-family: var(--decorate-font);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  flex-shrink: 0;
  padding: 0;

  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
`;

export const ActionButton = styled(PureButton)`
  transition: 0.2s background;
  background: #5855f4;
  border-radius: 8px;
  height: 40px;
  padding: 0 12px;

  &:hover {
    background: #6f6cfa;
  }

  &:disabled {
    cursor: default;
    color: rgba(255, 255, 255, 0.5);
    background: #5855f4;
  }
`;

export const StrokeButton = styled(ActionButton)`
  height: 40px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  transition: 0.2s border-color;
  background: transparent;

  &:hover {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    cursor: default;
    background: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);

    path {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
`;
