import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

import { EntityTrigger, IntentTrigger, MarkupType, Trigger } from "../workspace/session-storage/devlogs";
import { MessageLogProfiled } from "./storage/interfaces";
import { NluDataFact, NluIntentFact, TypeOfSttResponse } from "./storage/model/stuff";
import MarkableMessage from "@core/workspace/session-storage/MarkableMessage";
import { Session } from "./storage/model/models";

export const getEntities = (responses: NluDataFact[], phrase: string): EntityTrigger[] => {
  const entities: EntityTrigger[] = [];
  const tokens = MarkableMessage.getTokens(phrase);

  responses.forEach((res) => {
    if (res.factType && res.data) {
      const readOnly = +res.weight === -1;
      const location = {
        start: +res.location.start,
        end: +res.location.end,
      };

      // Location of value for system and custom entities are calculated differently
      if (readOnly) {
        try {
          location.start = tokens[location.start].start;
          location.end = tokens[location.end].end;
        }
        catch(ex) {
          console.warn(`Can't find token for ${JSON.stringify(res)}`);
          location.start = +phrase.length;
          location.end = +phrase.length;
        }
      }

      return entities.push({
        type: "entity",
        id: uuid(),
        tag: null,
        hue: null,
        readOnly,
        name: res.factType,
        entityValue: null,
        value: res.data.value,
        mark: MarkupType.unmark,
        probability: +res.weight === -1 ? null : +res.weight,
        location,
      });
    }
  });

  return entities;
};

export const getIntents = (responses: NluIntentFact[]): IntentTrigger[] => {
  const intents: IntentTrigger[] = [];

  responses.forEach((res) => {
    if (res.type === "Intent") {
      return intents.push({
        id: uuid(),
        mark: MarkupType.unmark,
        readOnly: res.intent == null || +res.weight === -1,
        probability: +res.weight === -1 ? null : +res.weight,
        name: res.intent ? res.intent : `${res.skill}:${res.state}`,
        location: { start: +res.location.start, end: +res.location.end },
        type: "intent",
      });
    }
  });

  return intents;
};

export const getTriggerType = (type: "entity" | "intent") => (type === "entity" ? "entities" : "intents");

export const hasExcludedTriggers = (triggers: Trigger[]) => {
  let hasExcluded = false;

  for (const trigger of triggers) {
    if (trigger.mark === MarkupType.exclude) {
      hasExcluded = true;
      break;
    }
  }

  return hasExcluded;
};

export const filterLog = (logItem: MessageLogProfiled, index: number, log: MessageLogProfiled[]) => {
  const message = logItem.msg ? logItem.msg.trim() : "";

  if (message === "") {
    return false;
  }

  const { voiceSegmentId, typeofSpeech } = logItem;

  if (voiceSegmentId === null || typeofSpeech === undefined) {
    return true;
  }

  if (
    (typeofSpeech === TypeOfSttResponse.Confident || typeofSpeech === TypeOfSttResponse.Final) &&
    logItem.reasonId !== undefined &&
    logItem.reasonId !== null
  ) {
    return true;
  }

  if (
    typeofSpeech === TypeOfSttResponse.Confident &&
    log.find((item) => item.voiceSegmentId === voiceSegmentId && item.typeofSpeech === TypeOfSttResponse.Final)
  ) {
    return false;
  }

  if (
    typeofSpeech === TypeOfSttResponse.Final &&
    log.find(
      (item) =>
        item.voiceSegmentId === voiceSegmentId &&
        item.typeofSpeech === TypeOfSttResponse.Confident &&
        item.reasonId !== null &&
        item.reasonId !== undefined
    )
  ) {
    return false;
  }

  if (
    typeofSpeech === TypeOfSttResponse.Final &&
    (logItem.reasonId === null || logItem.reasonId === undefined) &&
    log.find(
      (item) =>
        item.voiceSegmentId === voiceSegmentId &&
        item.typeofSpeech === TypeOfSttResponse.Final &&
        item.reasonId !== null &&
        item.reasonId !== undefined
    )
  ) {
    return false;
  }

  if (
    typeofSpeech === TypeOfSttResponse.Confident &&
    (logItem.reasonId === null || logItem.reasonId === undefined) &&
    log.find(
      (item) =>
        item.voiceSegmentId === voiceSegmentId &&
        item.typeofSpeech === TypeOfSttResponse.Confident &&
        item.reasonId !== null &&
        item.reasonId !== undefined
    )
  ) {
    return false;
  }

  return true;
};

export const hasItemInFiltersItem = (item: string, filterItems: Set<string>) =>
  filterItems.size > 0 ? filterItems.has(item) : true;

export const getDuration = (job?: Session) => {
  const startDate = dayjs(job?.startedTime);
  const endDate = dayjs(job?.completedTime);

  return endDate.diff(startDate, "second");
};

export const createDownloadLink = (data: BlobPart[], type: string, title: string) => {
  const blob = new Blob(data, { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", title);
  a.click();
  a.remove();
};
