import { makeAutoObservable, runInAction } from "mobx";

import { IDisposable } from "../../misc/emitter";
import Project from "../../explorer/Project";

import { RunnerType, SessionData, SessionProtocol } from "./types";
import SessionRunner from "./SessionRunner";
import { IAccount } from "@core/account/interface";

class SessionsStorage {
  public MAX_SESSIONS = 5;
  public root = ".sessions";

  public sessions: SessionProtocol[] = [];
  public activeSession: SessionProtocol | null = null;

  public allowContextDebug = false;
  public allowTransitionDebug = false;
  private disposables: IDisposable[] = [];

  constructor(readonly project: Project, readonly account: IAccount) {
    makeAutoObservable(this);

    this.allowContextDebug = account.getValue("runner:allowContextDebug") || false;
    this.allowTransitionDebug = account.getValue("runner:allowTransitionDebug") || false;
  }

  async readSessions() {
    const sessions = await this.project.json<SessionData[]>(this.root).catch(() => []);
    runInAction(() => {
      sessions.forEach((session) => {
        session.messages.forEach((m) => {
          m.actions = null;
        });
      });
      this.sessions = sessions.map((data) => new SessionRunner(data, this.account));
      this.activeSession = this.sessions[this.sessions.length - 1] ?? null;
    });
  }

  get lastSession() {
    if (this.sessions.length === 0) return null;
    return this.sessions[this.sessions.length - 1];
  }

  get isRunning() {
    return this.sessions.some((session) => session.timeEnded == null);
  }

  async dispose() {
    await this.stopActiveSessions();
    this.disposables.forEach((v) => v.dispose());
  }

  public toggleContextDebug() {
    this.allowContextDebug = !this.allowContextDebug;
    this.account.setValue("runner:allowContextDebug", this.allowContextDebug);
  }

  public toggleTransitionDebug() {
    this.allowTransitionDebug = !this.allowTransitionDebug;
    this.account.setValue("runner:allowTransitionDebug", this.allowTransitionDebug);
  }

  public async saveSessions() {
    const sessions = this.sessions.map((session) => session.serialize());
    await this.project.updateContent(this.root, sessions);
  }

  async stopActiveSessions() {
    await Promise.all(this.sessions.map((session) => session.stop()));
  }

  createSession(type: RunnerType) {
    const session = new SessionRunner({ type, timeInitialized: Date.now() }, this.account);
    session.onDidDispose(() => this.saveSessions());

    this.sessions.push(session);
    this.selectSession(session);
    if (this.sessions.length > this.MAX_SESSIONS) {
      this.sessions = this.sessions.slice(1);
    }

    return session;
  }

  selectSession(session: SessionProtocol | null) {
    this.activeSession = session;
  }
}

export default SessionsStorage;
