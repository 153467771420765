import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import EditorController from '..';
import GridModel from './Model';
import GridWidget from './Widget';

export default class GridFactory extends AbstractReactFactory<GridModel> {
  constructor() {
    super('grid');
  }

  generateModel() {
    return new GridModel(this.engine as EditorController);
  }

  generateReactWidget(event: any) {
    return <GridWidget model={event.model} />;
  }
}
