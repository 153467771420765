import { makeAutoObservable, runInAction } from "mobx";
import DatasetStorage from "../../core/workspace/dataset-storage/DatasetStorage";

export interface IncludeIntent {
  id: number;
  text: string;
  generated: boolean;
  generating: boolean;
}

class IntentEditor {
  name = "";
  templatePhrase = "";
  phrases: IncludeIntent[] = [];
  maxNameLength = 60;

  constructor(readonly dataset: DatasetStorage) {
    makeAutoObservable(this);
  }

  get isValidName() {
    return this.dataset.intents.includes(this.name) === false;
  }

  get isValid() {
    return this.phrases.length >= 5 && this.name.length > 0 && this.isValidName;
  }

  setTemplatePhrase(name: string) {
    this.templatePhrase = name;
  }

  setName(name: string) {
    this.name = name.slice(0, 60).replaceAll(" ", "_");
  }

  applyTemplate() {
    if (this.templatePhrase.length === 0) return;
    this.phrases.push({ id: Date.now(), text: this.templatePhrase, generated: false, generating: false });
    this.templatePhrase = "";
  }

  removePhrase(id: number) {
    const index = this.phrases.findIndex((v) => v.id === id);
    if (index === -1) return;
    this.phrases.splice(index, 1);
  }

  renamePhrase(phrase: IncludeIntent, text: string) {
    phrase.text = text;
    phrase.generated = false;
  }

  async paraphrase(phrase: IncludeIntent) {
    phrase.generating = true;
    let texts = await this.dataset.getParaphrase(phrase.text).catch(() => []);
    texts = texts.filter((text) => this.phrases.every((v) => v.text !== text));

    const index = this.phrases.findIndex((v) => v.id === phrase.id);
    if (index === -1) return;

    runInAction(() => {
      let id = Date.now();
      const phrases = texts.map((text) => ({ id: id++, text, generated: true, generating: false }));
      this.phrases.splice(index + 1, 0, ...phrases);
      phrase.generating = false;
    });
  }

  serialize() {
    return {
      name: this.name,
      includes: this.phrases.filter((v) => !v.generated).map((v) => v.text),
      includesParaphrases: this.phrases.filter((v) => v.generated).map((v) => v.text),
      excludesParaphrases: [],
      excludes: [],
    };
  }
}

export default IntentEditor;
