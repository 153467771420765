import * as React from "react";
import { uuid4 } from "@sentry/utils";
import { GenerateWidgetEvent } from "@projectstorm/react-canvas-core";
import { DefaultLinkFactory } from "@projectstorm/react-diagrams";

import Widget from "./Widget";
import Model from "./Model";

class StraightLinkFactory extends DefaultLinkFactory<Model> {
  constructor() {
    super(Model.type);
  }

  generateModel(): Model {
    return new Model({
      id: uuid4(),
      decisionCount: 0,
      transitionType: "Normal",
      transitionId: "",
      sourceNodePath: "",
      targetNodePath: "",
      transitionPath: null,
      blockPath: "",
      blockName: "",
      fromNode: "",
      toNode: "",
    });
  }

  generateReactWidget(event: GenerateWidgetEvent<Model>): JSX.Element {
    return <Widget link={event.model} diagramEngine={this.engine} />;
  }
}

export default StraightLinkFactory;
