import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as utils from "../helpers";

import { DoStatement } from "./DoStatement";

export class GroupCommandStatement extends DoStatement implements interfaces.IGroupCommandStatement {
  elementType: DslElementType.GroupCommandStatement = DslElementType.GroupCommandStatement;
  commands: DoStatement[];

  constructor(obj: interfaces.IGroupCommandStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.commands = utils.ensureIsArrayOfDoStatementInstances(obj.commands);
  }
  toJson(): interfaces.IGroupCommandStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      commands: this.commands.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + "{",
      ...this.commands.map((command) => command.toDsl(indent + 1)),
      helpers.indentString(indent) + "}",
    ].join("\n");
  }
}
