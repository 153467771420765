import React, { FC, useEffect, useState } from "react";
import { projectTypes, projectWorkflow, projectSort } from "../types";
import ProjectCard from ".";
import Actions from "../Actions";
import * as S from "../styled";
import { MenuOption, PopupMenu } from "../../uikit/popup";
import { Icon } from "../../uikit/Icon";
import { observer } from "mobx-react-lite";
import { useStore } from "../../core/api/GlobalStoreContext";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";

export const ProjectsView: FC<{ filter: string }> = observer(({ filter }) => {
  const { explorer, workspace } = useStore();
  const [workflow, setWorkflow] = useState<keyof typeof projectWorkflow>("all");
  const [sort, setSort] = useState<keyof typeof projectSort>("latest");
  const [loading, setLoading] = useState(true);
  const stubFilter = () => true;
  const projects = explorer.projects
    .filter(projectTypes[filter]?.filter ?? stubFilter)
    .filter(projectWorkflow[workflow].filter)
    .sort(projectSort[sort].sort);

  useEffect(() => {
    setLoading(true);
    explorer.loadProjects().finally(() => setLoading(false));
  }, [explorer]);

  const filterActions: MenuOption[] = [
    {
      label: projectWorkflow.all.label,
      action: () => setWorkflow("all"),
      isSelected: workflow === "all",
      isEnable: true,
    },
    {
      label: projectWorkflow.code.label,
      action: () => setWorkflow("code"),
      isSelected: workflow === "code",
      isEnable: true,
    },
    {
      label: projectWorkflow.nocode.label,
      action: () => setWorkflow("nocode"),
      isSelected: workflow === "nocode",
      isEnable: true,
    },
  ];

  const sortActions: MenuOption[] = [
    {
      label: projectSort.latest.label,
      action: () => setSort("latest"),
      isSelected: sort === "latest",
      isEnable: true,
    },
    {
      label: projectSort.alphabetical.label,
      action: () => setSort("alphabetical"),
      isSelected: sort === "alphabetical",
      isEnable: true,
    },
    {
      label: projectSort.created.label,
      action: () => setSort("created"),
      isSelected: sort === "created",
      isEnable: true,
    },
  ];

  return (
    <>
      <Actions explorer={explorer} />
      <S.Content id="projects">
        <S.Title>{projectTypes[filter]?.label}</S.Title>
        <S.Filters>
          <p>
            Workflow:
            <PopupMenu actions={filterActions} on="click" closeAfterSelect>
              <span>
                {projectWorkflow[workflow].label}
                <Icon name="chevron-down" />
              </span>
            </PopupMenu>
          </p>

          <p>
            Sort:
            <PopupMenu actions={sortActions} on="click" closeAfterSelect>
              <span>
                {projectSort[sort].label}
                <Icon name="chevron-down" />
              </span>
            </PopupMenu>
          </p>
        </S.Filters>
        <Loader active={loading} content={"Loading projects"}/>
        {!loading && (
          <S.Projects>
            {projects.map((project) => (
              <Link to={`/project/${project.id}`}>
                <ProjectCard
                  key={project.id}
                  project={project}
                  explorer={explorer}
                  workspace={workspace}
                  onSelect={() => {}}
                />
              </Link>
            ))}
          </S.Projects>
        )}
      </S.Content>
    </>
  );
});
