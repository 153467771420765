import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";

export class ArrayAccessExpression extends Expression implements interfaces.IArrayAccessExpression {
  elementType: DslElementType.ArrayAccessExpression = DslElementType.ArrayAccessExpression;
  index: Expression;
  parentExpression: Expression;

  constructor(obj: interfaces.IArrayAccessExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.index = helpers.ensureIsExpressionInstance(obj.index);
    this.parentExpression = helpers.ensureIsExpressionInstance(obj.parentExpression);
  }
  toJson(): interfaces.IArrayAccessExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      index: this.index.toJson(),
      parentExpression: this.parentExpression.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `${this.parentExpression.toDsl(indent)}[${this.index.toDsl(indent)}]`;
  }
}
