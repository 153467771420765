import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Modal, Input, Dropdown, Message, Icon, Confirm } from "semantic-ui-react";
import "./styles.css";
import { ApplicationListDropdown } from "./ApplicationListDropdown";
import { GroupListDropdown } from "./GroupListDropdown";
import { InboundVoipEntry } from "../core/api/VoIP/InboundVoipEntry";
import { ActionButton } from "../uikit/Button";
import { useStore } from "../core/api/GlobalStoreContext";

function GetTrigger(isNewEntry) {
  if (isNewEntry) {
    return <Button>Add configuration</Button>;
  }
  return <Icon name="edit" />;
}
export const InboundVoIPEditor: FC<{
  entry: InboundVoipEntry;
  isOpened?: boolean;
  onDelete: () => void;
  onAdd?: () => void;
}> = observer(({ entry, isOpened, onDelete, onAdd }) => {
  const { account } = useStore();
  const priorities = Array.from([0, 1, 2, 3, 4, 5, 6, 7]).map((x) => ({ text: x, value: x, key: x }));
  const [open, setOpen] = React.useState(isOpened ?? false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const saveChanges = useCallback(() => {
    const isNew = entry.IsNewEntry();
    entry.SaveChangesAsync().then((x) => {
      if (x) {
        setOpen(false);
        if (isNew) {
          onAdd?.();
        }
      }
    });
  }, [entry]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={GetTrigger(entry.IsNewEntry())}
    >
      <Modal.Header>Inbound VoIP Configuration</Modal.Header>
      {entry.SaveError && <Message negative>{entry.SaveError}</Message>}
      <Modal.Content>
        <h3>Name</h3>
        <Input
          disabled={!entry.IsNewEntry()}
          placeholder="Configuration name"
          value={entry.Name}
          onChange={(e, { value }) => entry.SetName(value)}
        />
        <h3>Application</h3>
        {
          entry.CurrentConfig.applicationName === "" && <Message negative>Select application</Message>
        }
        <ApplicationListDropdown
          selectedApplication={entry.CurrentConfig.applicationName}
          selectApplication={(name) => {
            entry.CurrentConfig.applicationName = name;
          }}
        ></ApplicationListDropdown>
        <h3>Group</h3>
        {
          entry.CurrentConfig.groupName === undefined && <Message negative>Select group</Message>
        }
        <GroupListDropdown
          group={entry.CurrentConfig.groupName}
          selectGroup={(name) => {
            entry.CurrentConfig.groupName = name;
          }}
        />
        <h3>Priority</h3>
        <Dropdown
          placeholder="Select Priority"
          fluid
          search
          selection
          options={priorities}
          value={entry.CurrentConfig.priority}
          onChange={(e, { value }) => {
            entry.SetPriority(Number.parseInt(value?.toString() ?? "0"));
          }}
        />
        <h3>Global unique alias</h3>
        sip:
        <Input
          placeholder="Global unique alias"
          value={entry.CurrentConfig.alias}
          onChange={(e, { value }) => {
            entry.SetAlias(value);
          }}
        />
        @{account.server.replace("app", "sip")}
        <h3>IP Acls (Optional)</h3>
        <Input
          placeholder="168.86.128.0/18"
          value={entry.CurrentConfig.ipAcls}
          onChange={(e, { value }) => entry.SetIPAcls(value)}
          style={{ width: "100%" }}
        />
        <h3>Password (Optional)</h3>
        <Input
          placeholder="Password"
          value={entry.NewPassword}
          onChange={(e, { value }) => {
            entry.SetNewPassword(value);
          }}
          type="password"
        />
        <Button
          content="Clear password"
          labelPosition="right"
          onClick={() => entry.SetNewPassword(null)}
          disabled={entry.NewPassword === null}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            entry.ResetChanges();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        {entry.IsNewEntry() && (
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={() => saveChanges()}
            positive
            disabled={entry.Name === undefined || entry.Name === "" || entry.CurrentConfig.applicationName === "" || entry.CurrentConfig.groupName === undefined}
          />
        )}
        {!entry.IsNewEntry() && (
          <>
            <Button content="Update" labelPosition="right" icon="checkmark" onClick={() => saveChanges()} positive />
            <Button negative onClick={() => setConfirmDelete(true)}>
              Delete
            </Button>
            <Confirm
              mini
              content={<p>Are you sure delete <b>{entry.Name}</b></p>}
              open={confirmDelete}
              onCancel={() => setConfirmDelete(false)}
              onConfirm={() => { onDelete(); setOpen(false); }}
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
});
