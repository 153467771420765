import JsonEmitterVisitor from "./JsonEmitterVisitor";
import * as antlrHelpers from "../../antlr/helpers";
import { FileContent } from "./elements";
import * as interfaces from "./interfaces";

export interface IFileDescriptionSerialized {
  filePath: string;
  content: interfaces.IFileContent;
}
export interface IFileDescription {
  filePath: string;
  content: FileContent;
}
export interface IRawDslFile {
  filePath: string;
  rawContent: string;
}
type DslCode = string;

export default class GraphApi {
  files: Record<string, IFileDescription> = {};
  constructor(rawDslFiles: IRawDslFile[]) {
    for (const { filePath, rawContent } of rawDslFiles) {
      const { ast } = antlrHelpers.createParseTreeWithErrors(rawContent);
      const fileJson = ast.accept(new JsonEmitterVisitor());
      this.files[filePath] = { filePath, content: new FileContent(fileJson) };
    }
  }
  public getFiles() {
    return this.files;
  }
  public serializeFiles(): Record<string, IFileDescriptionSerialized> {
    return Object.fromEntries(
      Object.entries(this.files).map(([key, value]) => [
        key,
        { filePath: value.filePath, content: value.content.toJson() },
      ])
    );
  }
  public convertFilesToDsl(): Record<string, DslCode> {
    return Object.fromEntries(Object.entries(this.files).map(([key, value]) => [key, value.content.toDsl(0)]));
  }
}
