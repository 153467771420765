import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  background: #161729;
  font-family: Menlo, Monaco, "Courier New", monospace;

  overflow-y: auto;
  box-sizing: border-box;

  code,
  span {
    user-select: text;
    -webkit-user-select: text;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 20px;
    color: #d9dae5;
  }

  code {
    display: block;
  }
`;
