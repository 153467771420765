import React, { memo, useEffect, useState } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import CodeEditor from "@uiw/react-textarea-code-editor";

import useNodeToCenter from "./useNodeToCenter";
import * as S from "./styled";
import { debounce } from "lodash";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  useNodeToCenter(xPos, yPos, data.highlight);
  const [code, setCode] = React.useState(data.snippet ?? "");
  const [debounceUpdate] = useState(() => debounce((snippet) => {
    data.update({ snippet });
    console.log(snippet);
  }, 1000));

  useEffect(() => {
    debounceUpdate(code);
  }, [code]);

  return (
    <S.Node className="drag-handle" style={{ maxWidth: 350 }} isSelected={selected || data.highlight}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />

      <CodeEditor
        value={code}
        language="js"
        placeholder="This function return data"
        onChange={(evn) => setCode(evn.target.value)}
        padding={4}
        style={{
          width: "100%",
          background: "transparent",
          color: "#fff",
          fontFamily: 'Menlo, Monaco, "Courier New", monospace',
        }}
      />

      <Handle
        id="b"
        type="source"
        position={Position.Right}
        style={{ top: "50%", right: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
    </S.Node>
  );
});
