import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import _ from "lodash";
import * as helpers from "../helpers";
import { RawElement } from "../common";
import { Expression } from "./Expression";

export class ContextVariableExpression extends Expression implements interfaces.IContextVariableExpression {
  elementType: DslElementType.ContextVariableExpression = DslElementType.ContextVariableExpression;
  variable: interfaces.OptionalValue<RawElement<string>>;

  constructor(obj: interfaces.IContextVariableExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.variable = helpers.ensureIsOptionalInstance(obj.variable, RawElement<string>);
  }
  toJson(): interfaces.IContextVariableExpression {
    const value = this.variable.value;
    const variable: interfaces.OptionalValue<interfaces.IRawElement<string>> =
      value === null ? { hasValue: false, value: null } : { hasValue: true, value: value.toJson() };
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      variable,
    };
  }
  toDsl(indent: number): string {
    return this.variable.hasValue ? `$${this.variable.value.toDsl(indent)}` : `$`;
  }
}
