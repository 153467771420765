import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "./Expression";
import * as helpers from "../helpers";

export class NullCollateExpression extends Expression implements interfaces.INullCollateExpression {
  elementType: DslElementType.NullCollateExpression = DslElementType.NullCollateExpression;
  nullable: Expression;
  defaultValue: Expression;

  constructor(obj: interfaces.INullCollateExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.nullable = helpers.ensureIsExpressionInstance(obj.nullable);
    this.defaultValue = helpers.ensureIsExpressionInstance(obj.defaultValue);
  }
  toJson(): interfaces.INullCollateExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      nullable: this.nullable.toJson(),
      defaultValue: this.defaultValue.toJson(),
    };
  }
  toDsl(indent: number): string {
    return [this.nullable.toDsl(indent), "??", this.defaultValue.toDsl(indent)].join(" ");
  }
}
