import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  width: 100%;

  padding: 8px 16px 8px 24px;
  background-color: #1f1f31;
`;

export const PlayButton = styled.button`
  margin: 0;

  width: 32px;
  height: 32px;

  background: none;
  border: none;

  cursor: pointer;

  rect {
    transition: 0.3s fill;
  }

  &:hover {
    rect {
      fill-opacity: 1;
    }
  }
`;

export const AudioInfo = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(100% - 75px);

  padding-left: 8px;
  padding-right: 13px;
`;

export const TimeBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AudioTime = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.4);
`;

export const SessionTime = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: #d9d9e2;

  display: flex;
  align-items: center;

  button {
    margin-left: 4px;
  }
`;

export const PlaybackRateBox = styled.div`
  width: 32px;
`;

export const PlaybackRate = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #d9d9e2;
  }

  svg {
    margin-top: 2px;
    margin-left: 1px;
  }
`;

export const SliderBox = styled.div`
  position: relative;
`;

export const Slider = styled.input`
  width: 100%;
  height: 2px;

  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  transition: 0.3s;

  outline: none;

  background: rgba(255, 255, 255, 0.16);
  border-radius: 19.5px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    transition: 0.3s;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #a1a1bd;
    z-index: 2;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    appearance: none;
    transition: 0.3s;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #a1a1bd;
    z-index: 2;
    cursor: pointer;
  }
`;

export const AudioMark = styled.div<{ offset: number; width: number; from: "ai" | "human" }>`
  position: absolute;

  z-index: 1;
  pointer-events: none;

  top: 15px;
  left: ${(p) => p.offset}px;

  width: ${(p) => p.width}px;
  height: 2px;

  background-color: ${(p) => (p.from === "ai" ? "#6B6ED8" : "#A2A4B6")};
`;

export const CopyAudioLink = styled.button`
  border: none;
  cursor: pointer;
  background: none;
`;
