import JSZip from "jszip";

export const mountFileSystem = async (zip: JSZip, fs) => {
  const mkdir = async (dir) => {
    const isExist = await exist(dir);
    if (isExist) return;
    await fs.promises.mkdir(dir);
  };

  const exist = (dir) => {
    return fs.promises
      .stat(dir)
      .then(() => true)
      .catch(() => false);
  };

  for (const [path, file] of Object.entries(zip.files)) {
    const parts = path.split("/");
    const head = parts.pop();
    let dist = "";

    for (const part of parts) {
      dist += "/" + part;
      await mkdir(dist);
    }

    dist += "/" + head;
    if (file.dir) {
      await mkdir(dist);
      continue;
    }

    const content = await file.async("string");
    await fs.promises.writeFile(dist, content);
  }
};
