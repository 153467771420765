import path from "path";
import Project from "../../explorer/Project";

import GraphService from "../dsl-storage/GraphService";
import { nodeBuild } from "../sandbox/bundler/esbuild";

const basicDependencies = {
  "@dasha.ai/sdk": "^0.8.6",
  cors: "^2.8.5",
  express: "^4.17.1",
  "sip.js": "^0.20.0",
  uuid: "^8.3.2",
  lodash: "4.17.21",
  moment: "2.29.3",
  openai: "4.26.1",
  completions: "2.9.1",
  "moment-timezone": "^0.5.43",
  "@googleapis/calendar": "^9.3.2"
};

class RuntimeStorage {
  readonly dir = "@dasha";
  readonly telegram = "telegram.js";
  readonly bootstrap = "bootstrap.js";
  readonly interceptor = "interceptor.dsl";
  readonly pkg = "package.json";
  readonly graph = "graph.json";

  constructor(readonly project: Project) {}

  async generateDeploy() {
    const zip = await this.project.zip();
    const bundle = await nodeBuild(zip);
    await this.project.updateContent("@dasha/deploy.js", bundle);
  }

  async updatePackage() {
    const pkg = await this.project.json<any>(this.pkg).catch(() => ({}));
    pkg.dependencies = basicDependencies;
    pkg.scripts = Object.assign({}, pkg.scripts);
    pkg.scripts.start = `node ./@dasha/deploy.js`;
    pkg.main = pkg.main || "./index.js";

    await this.project.updateContent(this.pkg, JSON.stringify(pkg, null, 4));
  }

  public async syncFiles(graphService: GraphService) {
    const nodes = Object.keys(graphService.nodesLinks);
    //const dslContent = interceptorTemplate(nodes);
    const graphContent = { nodesLinks: graphService.nodesLinks };

    await this.updatePackage();
    await this.project.createFolder(this.dir);
    await this.project.updateContent(path.resolve(this.dir, this.graph), graphContent);
    this.project.updateFiles();
  }
}

export default RuntimeStorage;
