import { Point } from "@projectstorm/geometry";
import { LinkModel, PointModel } from "@projectstorm/react-diagrams";
import { forEach } from "lodash";
import dagre from "dagre";

interface Options {
    graph: Record<string, any>,
    allowLink: (link: LinkModel) => boolean,
    includeLinks: boolean;
}

class Autolayout {
  redistribute(model, options: Options) {
    // Create a new directed graph
    const g = new dagre.graphlib.Graph({
      multigraph: true,
      compound: true,
    });

    g.setGraph(options.graph || {});
    g.setDefaultEdgeLabel(() => ({}));

    // set nodes
    forEach(model.getNodes(), (node) => {
      g.setNode(node.getID(), { width: 56, height: 56 });
    });

    forEach(model.getLinks(), (link) => {
      if (options.allowLink(link) === false) return;
      if (link.getSourcePort() && link.getTargetPort()) {
        g.setEdge({
          v: link.getSourcePort().getNode().getID(),
          w: link.getTargetPort().getNode().getID(),
          name: link.getID(),
        });
      }
    });

    // layout the graph
    dagre.layout(g);
    g.nodes().forEach((v) => {
      const node = g.node(v);
      model.getNode(v).setPosition(node.x - node.width / 2, node.y - node.height / 2);
    });

    // also include links?
    if (options.includeLinks) {
      g.edges().forEach((e) => {
        const edge = g.edge(e);
        const link = model.getLink(e.name);
        const points = [link.getFirstPoint()];

        for (let i = 1; i < edge.points.length - 1; i++) {
          points.push(
            new PointModel({
              link: link,
              position: new Point(edge.points[i].x, edge.points[i].y),
            })
          );
        }

        link.setPoints(points.concat(link.getLastPoint()));
      });
    }
  }
}

export default Autolayout;
