import styled from "styled-components";

export const StyledTree = styled.div`
  line-height: 1.75;
  z-index: 1;
`;

export const ActionsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  button {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }

  button::before {
    content: "";
    display: block;
    height: 100%;
    right: -32px;
    width: 69px;
    height: 32px;
    background: var(--tree-color);
    transition: 0.2s background;
    filter: blur(5px);
    position: absolute;
  }

  &:hover button {
    opacity: 1;
    pointer-events: all;
    transition: 0.2s;
  }
`;

export const StyledFile = styled.div<{ isSelected?: boolean }>`
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  font-weight: normal;
  --tree-color: ${(p) => (p.isSelected ? "#35317D" : "#1b1c2d")};
  background: var(--tree-color);
  transition: 0.2s background;
  cursor: pointer;

  &:hover {
    --tree-color: ${(p) => (p.isSelected ? "#35317D" : "#232435")};
  }

  svg {
    flex-shrink: 0;
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    height: 32px;
    color: ${(p) => (p.isSelected ? "#FFFFFF" : "#A1A1BD")};
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  input {
    flex: 1;
    width: 100%;
    height: 32px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    outline: none;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #13b8ff;
    background: transparent;
  }
`;

export const Collapse = styled.div<{ isOpen: boolean }>`
  height: max-content;
  max-height: ${(p) => (p.isOpen ? "800px" : "0px")};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

export const VerticalLine = styled.section`
  position: relative;
  :before {
    content: "";
    display: block;
    position: absolute;
    top: -2px; /* just to hide 1px peek */
    left: 1px;
    width: 0;
    height: 100%;
    border: 1px solid #dbdbdd;
    z-index: -1;
  }
`;
