import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { DslElement, RawElement } from "../common";
import { Expression } from "../expressions";

export class NamedArgument extends DslElement implements interfaces.INamedArgument {
  elementType: DslElementType.NamedArgument = DslElementType.NamedArgument;
  public name: RawElement<string>;
  public value: Expression;
  constructor(obj: interfaces.INamedArgument, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.value = helpers.ensureIsExpressionInstance(obj.value);
  }
  toJson(): interfaces.INamedArgument {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      value: this.value.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `${this.name.value}: ${this.value.toDsl(indent)}`;
  }
}
