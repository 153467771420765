import { makeAutoObservable } from "mobx";
import { AccountsManager } from "./Manager";
import { IAccount } from "./interface";


export class AccountsStore {
    public accountManager: AccountsManager;
    constructor(account?: IAccount|undefined) {
        this.accountManager = new AccountsManager(account);
        makeAutoObservable(this);
    }
}