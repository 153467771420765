import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";

export class TernaryExpression extends Expression implements interfaces.ITernaryExpression {
  elementType: DslElementType.TernaryExpression = DslElementType.TernaryExpression;
  condition: Expression;
  then: Expression;
  else: Expression;

  constructor(obj: interfaces.ITernaryExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.condition = helpers.ensureIsExpressionInstance(obj.condition);
    this.then = helpers.ensureIsExpressionInstance(obj.then);
    this.else = helpers.ensureIsExpressionInstance(obj.else);
  }
  toJson(): interfaces.ITernaryExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      condition: this.condition.toJson(),
      then: this.then.toJson(),
      else: this.else.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `${this.condition.toDsl(indent)} ? ${this.then.toDsl(indent)} : ${this.else.toDsl(indent)}`;
  }
}
