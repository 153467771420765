import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";

import Project from "../../core/explorer/Project";
import Workspace from "../../core/workspace/Workspace";
import { Explorer } from "../../core/explorer/Explorer";

import ProjectActions from "../../HeaderPanel/ProjectActions";
import { formatSpentTime } from "../../RunnerPanel/helpers";
import { projectTypes } from "../types";
import { Icon } from "../../uikit";
import { Tag } from "../Tag";
import * as S from "./styled";

interface Props {
  project: Project;
  explorer: Explorer;
  workspace: Workspace;
  onSelect?: () => void;
}

const ProjectItem: FC<Props> = ({ project, onSelect, explorer, workspace }) => {
  const metadata = project?.metadata;
  const isNoCode = metadata?.customMetaData?.projectType === "visual";
  const getSpent = () => formatSpentTime(new Date(metadata?.customMetaData?.viewed ?? 0));

  const [spentTime, setTime] = useState(() => getSpent());
  const filter = Object.values(projectTypes).find((type) => type.label !== "Recents" && type.filter(project));

  useEffect(() => {
    const id = setInterval(() => setTime(getSpent()), 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <S.ProjectCard onClick={onSelect}>
      <ProjectActions project={project} explorer={explorer} workspace={workspace}>
        <S.ActionMenu>
          <Icon name="menu" />
        </S.ActionMenu>
      </ProjectActions>

      <S.Time>
        {filter?.icon}
        <span>
          {filter?.label} · Opened {spentTime}
        </span>
      </S.Time>

      <h2>{metadata?.name??""}</h2>
      <S.Description>{metadata?.description??""}</S.Description>
      <S.Tags>
        <Tag id={isNoCode ? "nocode" : "code"} />
        {!metadata.isEditable && <Tag id="readonly" />}
      </S.Tags>
    </S.ProjectCard>
  );
};

export default ProjectItem;
