
import { AccountsStore } from "./AccountsStore"
import { createContext, useContext } from "react";

export const AccountsStoreContext = createContext<AccountsStore | null>(null)

export const useAccountsStore = () => {
  const context = useContext(AccountsStoreContext);
  if (context === null) {
    throw new Error(
      "You have forgotten to wrap your root component with AccountsStoreContext.Provider"
    );
  }
  return context;
};