import styled, { css } from "styled-components";

import {
  AddTrigger,
  AddPhraseField as SAddPhraseField,
  PhraseField,
} from "../../../../../RunnerPanel/TriggersEdit/styled";

export {
  Header,
  Body,
  Section,
  Title,
  ButtonBack,
  Attention,
  PhraseField,
} from "../../../../../RunnerPanel/TriggersEdit/styled";

export const RemoveValueBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  width: 24px;
  height: 24px;

  opacity: 0;
  transition: 0.3s opacity;

  cursor: pointer;

  position: absolute;
  right: 2px;
`;

export const ValueField = styled.div<{ isEditable: boolean }>`
  width: 100%;
  min-height: 34px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 5px 16px;

  background: ${(p) => (p.isEditable ? "none" : "rgba(255, 255, 255, 0.02)")};

  &:not(:first-child) {
    &:hover {
      ${RemoveValueBtn} {
        opacity: 1;
      }
    }
  }

  > span:first-child {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${AddTrigger} {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }

  &:hover {
    ${AddTrigger} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const AddValueBtn = styled.button`
  background: none;
  border: none;
  outline: none;

  margin-top: 8px;
  margin-left: 16px;
  padding: 0;
  cursor: pointer;

  transition: 0.3s color;

  font-weight: 600;
  font-size: 13px;
  line-height: 22px;

  display: flex;
  align-items: center;

  color: rgba(255, 255, 255, 0.75);

  span {
    margin-left: 2px;
  }

  path {
    transition: 0.3s fill-opacity;
  }

  &:hover {
    color: #fff;

    path {
      fill-opacity: 1;
    }
  }
`;

export const NewValueField = styled(PhraseField)`
  margin: 0;
  background: none !important;

  input {
    background: none;
    border: none;
    outline: none;

    width: 100%;

    padding: 0;

    font-family: var(--main-font);

    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;

    &:placeholder {
      font-family: var(--main-font);
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const Label = styled.label`
  input {
    visibility: hidden;
    opacity: 0;
  }

  display: flex;
  align-items: center;
  cursor: pointer;

  margin-top: 8px;

  > svg:first-child {
    margin-right: 2px;

    width: 16px;
    height: 16px;
  }

  > span:last-child {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.75);
  }
`;

const btn = css`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
`;

export const ResetPhraseBtn = styled.button`
  ${btn}
`;

export const AddPhraseBtn = styled.button`
  ${btn}

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background: rgba(255, 255, 255, 0.3);
    margin-right: 7px;
  }
`;

export const RemovePhraseBtn = styled.button`
  ${btn}

  position: absolute;
  right: 11px;

  opacity: 0;
  transition: 0.3s opacity;
`;

export const AddPhraseField = styled(SAddPhraseField)`
  ${ResetPhraseBtn} {
    right: 40px;
  }
`;

export const Phrase = styled(PhraseField)`
  &:hover {
    ${RemovePhraseBtn} {
      opacity: 1;
    }
  }
`;
