import * as elements from "./index";
import * as interfaces from "../interfaces";
import _ from "lodash";
import DslElementType from "../enums/DslElementType";

import { AssignmentStatement } from "./do-section-statements/AssignmentStatement";
import { ExitStatement } from "./do-section-statements/ExitStatement";
import { ExpressionStatement } from "./do-section-statements/ExpressionStatement";
import { ForStatement } from "./do-section-statements/ForStatement";
import { GotoStatement } from "./do-section-statements/GotoStatement";
import { GroupCommandStatement } from "./do-section-statements/GroupCommandStatement";
import { IfStatement } from "./do-section-statements/IfStatement";
import { RawFragmentStatement } from "./do-section-statements/RawFragmentStatement";
import { ReturnStatement } from "./do-section-statements/ReturnStatement";
import { VarStatement } from "./do-section-statements/VarStatement";
import { WaitStatement } from "./do-section-statements/WaitStatement";
import { DoStatement, TransitionCondition } from "./index";

import { TransitionTimeoutCondition } from "./sections/TransitionTimeoutCondition";
import { TransitionExpressionCondition } from "./sections/TransitionExpressionCondition";
import { Expression } from "./expressions";
import { LocationProvider } from "../../../monaco-helpers";

export function indentString(indent: number, character = "\t"): string {
  return character.repeat(indent);
}

export function indentList(elements: string[], indent: number, indentFirstList = false) {
  return [
    (indentFirstList ? indentString(indent) : "") + "{",
    elements.map((el) => `${indentString(indent + 1)}${el}`).join(",\n"),
    indentString(indent) + "}",
  ].join("\n");
}

export function ensureIsInstance<IT extends interfaces.IDslElement, T extends elements.common.DslElement>(
  obj: IT,
  T
): T {
  const instance: T = obj instanceof T ? obj : new T(obj);
  return instance;
}

export function ensureIsArrayOfInstances<IT extends interfaces.IDslElement, T extends elements.common.DslElement>(
  obj: IT[],
  T
): T[] {
  return obj.map((el) => ensureIsInstance<IT, T>(el, T));
}

export function ensureIsArrayOfConditionInstances(obj: interfaces.ITransitionCondition[]): TransitionCondition[] {
  return obj.map((el) => ensureIsConditionInstance(el));
}

export function ensureIsConditionInstance(obj: interfaces.ITransitionCondition): TransitionCondition {
  return isTransitionCondition(obj) ? obj : createTransitionCondition(obj);
}

export function ensureIsExpressionInstance(obj: interfaces.IExpression): elements.Expression {
  return isExpressionInstance(obj) ? obj : createExpressionInstace(obj);
}

export function ensureIsArrayOfDoStatementInstances(obj: interfaces.IDoStatement[]): DoStatement[] {
  return obj.map((el) => ensureIsDoStatementInstance(el));
}

export function ensureIsDoStatementInstance(obj: interfaces.IDoStatement): elements.DoStatement {
  return isDoStatementIstance(obj) ? obj : createDoStatementInstace(obj);
}

export function joinNullableStrings(lines: (string | null | undefined)[], sep: string): string {
  return lines.filter((el) => el !== null && el !== undefined && el.length !== 0).join(sep);
}

export function ensureIsOptionalInstance<IT extends interfaces.IDslElement, T extends elements.common.DslElement>(
  obj: interfaces.OptionalValue<IT>,
  T
): interfaces.OptionalValue<T> {
  if (obj.hasValue) {
    return { hasValue: true, value: ensureIsInstance(obj.value, T) };
  }
  return { hasValue: false, value: null };
}

export function ensureIsOptionalArrayOfInstances<
  IT extends interfaces.IDslElement,
  T extends elements.common.DslElement
>(obj: interfaces.OptionalValue<IT[]>, T): interfaces.OptionalValue<T[]> {
  if (obj.hasValue) {
    return { hasValue: true, value: ensureIsArrayOfInstances(obj.value, T) };
  }
  return { hasValue: false, value: null };
}

export function createDoStatementInstace(
  obj: interfaces.IDoStatement,
  locationProvider?: LocationProvider
): DoStatement {
  switch (obj.elementType) {
    case DslElementType.AssignmentStatement:
      return new AssignmentStatement(obj as interfaces.IAssignmentStatement, locationProvider);
    case DslElementType.ExitStatement:
      return new ExitStatement(obj as interfaces.IExitStatement, locationProvider);
    case DslElementType.ExpressionStatement:
      return new ExpressionStatement(obj as interfaces.IExpressionStatement, locationProvider);
    case DslElementType.ForStatement:
      return new ForStatement(obj as interfaces.IForStatement, locationProvider);
    case DslElementType.GotoStatement:
      return new GotoStatement(obj as interfaces.IGotoStatement, locationProvider);
    case DslElementType.GroupCommandStatement:
      return new GroupCommandStatement(obj as interfaces.IGroupCommandStatement, locationProvider);
    case DslElementType.IfStatement:
      return new IfStatement(obj as interfaces.IIfStatement, locationProvider);
    case DslElementType.RawFragmentStatement:
      return new RawFragmentStatement(obj as interfaces.IRawFragment, locationProvider);
    case DslElementType.ReturnStatement:
      return new ReturnStatement(obj as interfaces.IReturnStatement, locationProvider);
    case DslElementType.VarStatement:
      return new VarStatement(obj as interfaces.IVarStatement, locationProvider);
    case DslElementType.WaitStatement:
      return new WaitStatement(obj as interfaces.IWaitStatement, locationProvider);
    default:
      throw new Error(`Could not create Do Section Statement instance: unexpected object ${JSON.stringify(obj)}`);
  }
}

export function isDoStatementIstance(obj: any): obj is DoStatement {
  return obj instanceof DoStatement;
}

export function isTransitionCondition(obj: any): obj is TransitionCondition {
  return obj instanceof TransitionCondition;
}

export function createTransitionCondition(
  obj: interfaces.ITransitionCondition,
  locationProvider?: LocationProvider
): TransitionCondition {
  switch (obj.elementType) {
    case DslElementType.TransitionTimeoutCondition:
      return new TransitionTimeoutCondition(obj as interfaces.ITransitionTimeoutCondition, locationProvider);
    case DslElementType.TransitionExpressionCondition:
      return new TransitionExpressionCondition(obj as interfaces.ITransitionExpressionCondition, locationProvider);
    default:
      throw new Error(`Could not create Transition Condition instance: unexpected object ${JSON.stringify(obj)}`);
  }
}

export function isExpressionInstance(obj: any): obj is Expression {
  return obj instanceof Expression;
}

export function createExpressionInstace(obj: interfaces.IExpression, locationProvider?: LocationProvider): Expression {
  // console.log("expression obj.elementType", obj.elementType)
  switch (obj.elementType) {
    case DslElementType.ArrayAccessExpression:
      return new elements.ArrayAccessExpression(obj as interfaces.IArrayAccessExpression, locationProvider);
    case DslElementType.BinaryExpression:
      return new elements.BinaryExpression(obj as interfaces.IBinaryExpression, locationProvider);
    case DslElementType.BuiltinVariableExpression:
      return new elements.BuiltinVariableExpression(obj as interfaces.IBuiltinVariableExpression, locationProvider);
    case DslElementType.ConstantExpression:
      return new elements.ConstantExpression(obj as interfaces.IConstantExpression, locationProvider);
    case DslElementType.ContextVariableExpression:
      return new elements.ContextVariableExpression(obj as interfaces.IContextVariableExpression, locationProvider);
    case DslElementType.DigressionSwitchApplyExpression:
      return new elements.DigressionSwitchApplyExpression(
        obj as interfaces.IDigressionSwitchApplyExpression,
        locationProvider
      );
    case DslElementType.DigressionSwitchExpression:
      return new elements.DigressionSwitchExpression(obj as interfaces.IDigressionSwitchExpression, locationProvider);
    case DslElementType.FunctionCallExpression:
      return new elements.FunctionCallExpression(obj as interfaces.IFunctionCallExpression, locationProvider);
    case DslElementType.HighOrderFunctionCallExpression:
      return new elements.HighOrderFunctionCallExpression(
        obj as interfaces.IHighOrderFunctionCallExpression,
        locationProvider
      );
    case DslElementType.IsExpression:
      return new elements.IsExpression(obj as interfaces.IIsExpression, locationProvider);
    case DslElementType.LocalVariableExpression:
      return new elements.LocalVariableExpression(obj as interfaces.ILocalVariableExpression, locationProvider);
    case DslElementType.ObjectAccessExpression:
      return new elements.ObjectAccessExpression(obj as interfaces.IObjectAccessExpression, locationProvider);
    case DslElementType.ObjectExpression:
      return new elements.ObjectExpression(obj as interfaces.IObjectExpression, locationProvider);
    case DslElementType.ParenthesizedExpression:
      return new elements.ParenthesizedExpression(obj as interfaces.IParenthesizedExpression, locationProvider);
    case DslElementType.TernaryExpression:
      return new elements.TernaryExpression(obj as interfaces.ITernaryExpression, locationProvider);
    case DslElementType.UnaryExpression:
      return new elements.UnaryExpression(obj as interfaces.IUnaryExpression, locationProvider);
    case DslElementType.CastExpression:
      return new elements.CastExpression(obj as interfaces.ICastExpression, locationProvider);
    case DslElementType.DigressionVariableReferenceExpression:
      return new elements.DigressionVariableReferenceExpression(
        obj as interfaces.IDigressionVariableReferenceExpression,
        locationProvider
      );
    case DslElementType.NullCollateExpression:
      return new elements.NullCollateExpression(obj as interfaces.INullCollateExpression, locationProvider);
    default:
      throw new Error(`Could not create Expression instance: unexpected object ${JSON.stringify(obj)}`);
  }
}
