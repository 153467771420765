import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement, RawElement } from "../common";
import { DigressionVariable, OnExitSection, TransitionCondition, TransitionSection } from "../sections";
import { GroupCommandStatement } from "../do-section-statements";

export class Digression extends DslElement implements interfaces.IDigression {
  elementType: DslElementType.Digression = DslElementType.Digression;
  isGlobal: boolean;
  isPreprocessor: boolean;
  name: RawElement<string>;
  conditions: TransitionCondition[];
  variables: DigressionVariable[];
  doSection: GroupCommandStatement;
  transitionSection: interfaces.OptionalValue<TransitionSection>;
  onExitSection: interfaces.OptionalValue<OnExitSection>;

  constructor(obj: interfaces.IDigression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.isGlobal = obj.isGlobal;
    this.isPreprocessor = obj.isPreprocessor;
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.conditions = helpers.ensureIsArrayOfConditionInstances(obj.conditions);
    this.variables = helpers.ensureIsArrayOfInstances(obj.variables, DigressionVariable);
    this.doSection = helpers.ensureIsInstance(obj.doSection, GroupCommandStatement);
    this.transitionSection = helpers.ensureIsOptionalInstance(obj.transitionSection, TransitionSection);
    this.onExitSection = helpers.ensureIsOptionalInstance(obj.onExitSection, OnExitSection);
  }
  toJson(): interfaces.IDigression {
    return {
      elementType: this.elementType,
      location: this.location,
      isGlobal: this.isGlobal,
      isPreprocessor: this.isPreprocessor,
      name: this.name.toJson(),
      conditions: this.conditions.map((el) => el.toJson()),
      variables: this.variables.map((el) => el.toJson()),
      doSection: this.doSection.toJson(),
      transitionSection: interfaces.helpers.createOptionalValue(this.transitionSection.value?.toJson()),
      onExitSection: interfaces.helpers.createOptionalValue(this.onExitSection.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) +
        `${this.isGlobal ? "global " : ""}${this.isPreprocessor ? "preprocessor " : ""}digression ${this.name.value} {`,
      helpers.indentString(indent + 1) + `conditions {`,
      ...this.conditions.map((el) => `${helpers.indentString(indent + 2)}on ${el.toDsl(indent)};`),
      helpers.indentString(indent + 1) + `}`,
      ...this.variables.map((el) => `${helpers.indentString(indent + 1)}${el.toDsl(indent)};`),
      helpers.indentString(indent + 1) + "do ",
      this.doSection.toDsl(indent + 1),
      this.transitionSection.value?.toDsl(indent + 1),
      this.onExitSection.value?.toDsl(indent + 1),
      helpers.indentString(indent) + "}",
    ].join("\n");
  }
}
