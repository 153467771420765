import * as ast from "json-to-ast";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { isOneOrManyPhrasemapEntrySingle } from "./type-checkers";

export function AstPosToMonacoPos(position: ast.Position): monaco.Position {
  return new monaco.Position(position.line, position.column);
}

export function AstNodeToRange(treeNode: ast.ASTNode): monaco.Range | undefined {
  if (treeNode.loc === undefined) return undefined;
  return monaco.Range.fromPositions(AstPosToMonacoPos(treeNode.loc.start), AstPosToMonacoPos(treeNode.loc.end));
}

export const findPathToPos = (treeNode: ast.ValueNode, position: monaco.Position): any[] => {
  const path: ast.ASTNode[] = [];
  if (!AstNodeToRange(treeNode)?.containsPosition(position)) {
    return path;
  }
  const toVisit: ast.ASTNode[] = [treeNode];
  while (toVisit.length > 0) {
    let current = toVisit.pop();
    if (current === undefined) continue;

    if (AstNodeToRange(current)?.containsPosition(position)) {
      path.push(current);
    }
    if (current.type === "Array") {
      current = current as ast.ArrayNode;
      toVisit.push(
        ...(current as ast.ArrayNode).children.filter((ch) => AstNodeToRange(ch)?.containsPosition(position))
      );
    } else if (current.type === "Object") {
      toVisit.push(
        ...(current as ast.ObjectNode).children.filter((ch) => AstNodeToRange(ch)?.containsPosition(position))
      );
    } else if (current.type === "Property") {
      toVisit.push((current as ast.PropertyNode).value);
    }
  }
  return path;
};

export function isPlayableData(data: any) {
  return isOneOrManyPhrasemapEntrySingle(data);
}
