import GraphApi, { IFileDescriptionSerialized } from "@core/dsl/to-json/src/GraphApi";
import { uniq } from "lodash";
import path from "path";

class GraphService {
  private documents: Record<string, IFileDescriptionSerialized> = {};

  public nodesLinks: Record<string, string[]> = {};
  public imports = new Set<string>();
  public entry = "";

  updateFiles(entry: string, files: Record<string, string>) {
    const adapter = ([filePath, rawContent]) => ({ filePath, rawContent });
    const graphApi = new GraphApi(Object.entries(files).map(adapter));

    this.entry = entry;
    this.documents = graphApi.serializeFiles();
    this.imports = this.getImports(entry);
    this.nodesLinks = this.getNodesWithLinks(this.imports);
  }

  private getImports(entry: string): Set<string> {
    const imported = new Set<string>();
    const walkImports = (document: IFileDescriptionSerialized) => {
      const fileDir = path.dirname(document.filePath);
      imported.add(document.filePath);

      document.content.content.imports.forEach((importScript) => {
        const filePath = path.resolve(fileDir, JSON.parse(importScript.value)).slice(1);
        const deep = this.documents[filePath];

        if (deep && imported.has(deep.filePath) === false) {
          walkImports(deep);
        }
      });
    };

    walkImports(this.documents[entry]);
    return imported;
  }

  private getNodesWithLinks(files: Set<string>) {
    const nodes: Record<string, string[]> = {};
    Object.values(this.documents).forEach((document) => {
      if (files.has(document.filePath) === false) return;
      document.content.content.nodes.forEach((node) => {
        const trxs = node.transitionSection.value?.transitions ?? [];
        nodes[node.name.value] = uniq(trxs.map((trx) => trx.targetNodeName.value));
      });
    });

    return nodes;
  }
}

export default GraphService;
