// Based on https://github.com/okikio/bundle/blob/main/src/ts/plugins/virtual-fs.ts
import type { Plugin } from "esbuild-wasm";
import type { BundleConfigOptions } from "../configs/options";
import type { EVENTS } from "../configs/events";
import type { STATE } from "../configs/state";

import { inferLoader } from "../utils/loader";
import path from "path";
export const VIRTUAL_FILESYSTEM_NAMESPACE = "virtual-filesystem";
export const VIRTUAL_FS = (events: typeof EVENTS, state: typeof STATE, config: BundleConfigOptions): Plugin => {
  const FileSystem = config.filesystem;

  return {
    name: VIRTUAL_FILESYSTEM_NAMESPACE,
    setup(build) {
      build.onResolve({ filter: /.*/ }, (args) => {
        return {
          path: args.path,
          pluginData: args.pluginData ?? {},
          namespace: VIRTUAL_FILESYSTEM_NAMESPACE,
        };
      });

      build.onLoad({ filter: /.*/, namespace: VIRTUAL_FILESYSTEM_NAMESPACE }, async (args) => {
        if (FileSystem?.resolve == null || FileSystem.get == null) {
          return undefined;
        }

        const resolvedPath = await FileSystem.resolve(args.path, args?.pluginData?.importer);
        let content: string | Uint8Array | undefined;

        // Legacy sandbox :(
        const files = build.initialOptions.entryPoints;
        if (Array.isArray(files) && files?.includes(path.resolve(args.path))) {
          content = (await FileSystem.get(args.path, "string", args?.pluginData?.importer)) as string;
          if ((content as string)?.match(/await(\s+)?sandbox(\s+)?.(\s+)?execute/g)?.length) {
            content = `(async () => {${content}})()`;
          }
        } else {
          content = await FileSystem.get(args.path, "buffer", args?.pluginData?.importer);
        }

        return {
          contents: content,
          pluginData: {
            importer: resolvedPath,
          },
          loader: inferLoader(resolvedPath),
        };
      });
    },
  };
};
