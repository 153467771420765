import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";
import * as helpers from "../helpers";

export class ConstantExpression extends Expression implements interfaces.IConstantExpression {
  elementType: DslElementType.ConstantExpression = DslElementType.ConstantExpression;
  constant: RawElement<string>;

  constructor(obj: interfaces.IConstantExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.constant = helpers.ensureIsInstance(obj.constant, RawElement<string>);
  }
  toJson(): interfaces.IConstantExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      constant: this.constant.toJson(),
    };
  }
  toDsl(indent: number): string {
    return this.constant.value;
  }
}
