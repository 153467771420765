import * as React from 'react';

import GridModel from './Model';

const GridWidget = ({ model }: { model: GridModel }) => {
  const lines: React.ReactNode[] = [];
  const { width, height, visible, gap, column, zoom, offsetY, offsetX } = model.serialize();
  const size = (gap + column) * zoom;
  const offset = Math.round(offsetX / size);
  const base = Math.floor(width / size);

  if (!visible) {
    return null;
  }

  for (let i = -base - offset; i < base * 2 - offset; i++) {
    const x1 = i * (gap + column);
    const x2 = x1 + column;
    const cell = [x1, x2].map((x, j) => (
      <line
        key={`${i}_${j}`}
        stroke="rgba(255, 255, 255, 0.06)"
        strokeWidth={1}
        y1={-offsetY / zoom}
        y2={height / (zoom / 100)}
        x1={x}
        x2={x}
      />
    ));

    lines.push(...cell);
  }

  return <g className="graph-editor-grid">{lines}</g>;
};

export default GridWidget;
