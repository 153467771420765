import React from "react";
import { FolderStructure, FSStructure } from "../../core/account/filesystem";

export interface TreeProps {
  data: FSStructure[];
  selected?: string;
  isEditable?: boolean;
  ignore: string[];
  onSelect?: (node: FSStructure) => void;
  onRename?: (node: FSStructure, name: string) => void;
  onCreateFile?: (name: string, node?: FolderStructure) => void;
  onCreateFolder?: (name: string, node?: FolderStructure) => void;
  onDelete?: (node?: FSStructure) => void;
}

const TreeContext = React.createContext<TreeProps>({ data: [], ignore: [] });
const useTreeContext = () => React.useContext(TreeContext);

export { TreeContext, useTreeContext };
