import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import Profiler from "../core/profiler/ProfilerStorage";
import GridLayout from "../core/misc/GridLayout";
import Keyboard from "../core/misc/KeyboardManager";
import Gutter from "../uikit/Layout/Gutter";
import { GroupPanel } from "../uikit";

import StatisticsPanel from "../ProfilerPanel/components/StatisticsPanel";
import DialogPanel from "../RunnerPanel/DialogPanel";
import EditorPanel from "../EditorPanel";
import HeaderPanel from "../HeaderPanel";
import RunnerPanel from "../RunnerPanel";
import TerminalPanel from "../TerminalPanel";
import VersionsPanel from "../VersionsPanel";
import FilesPanel from "../FilesPanel";
import * as S from "./styled";
import { useStore } from "../core/api/GlobalStoreContext";
import UIManager from "../core/misc/UIManager";

const WorkspaceView: FC<{ projectId: string, projectVersion: string | undefined }> = (props) => {
  const { projectId, projectVersion } = props;
  const { workspace, explorer, sip } = useStore();
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const [tabs, setTabs] = useState<string[]>([]);

  useEffect(() => {
    explorer.openAsync(projectId, projectVersion).then(() => {
      workspace.setProject(explorer.opened);
      history.pushState({}, "switch", `/project/${explorer.opened.id}/version/${explorer.opened.version}`);
    }).catch((e) => UIManager.notice(`Failed to open project: ${e}`));
  }, [projectId, projectVersion, explorer, workspace])
  const project = workspace.project;

  useEffect(() => {
    const tabs = ["RANGE STATISTICS", "OUTPUT", "VERSIONS"];
    setTabs(workspace.opened instanceof Profiler ? tabs : tabs.slice(1));
  }, [workspace.opened]);

  useEffect(() => {
    Keyboard.addShiftShortcut("f", () => layout.toggleFullScreen());
    Keyboard.addShiftShortcut("u", () => layout.toggleCollapse("runner"));
    Keyboard.addShiftShortcut("p", () => layout.toggleCollapse("files"));
    Keyboard.addShiftShortcut("o", () => layout.toggleCollapse("tools"));
    Keyboard.addShortcut("s", () => workspace.project?.updateRemote());
    layout.bindGrid("#grid-workspace");
    return () => Keyboard.clearShortcuts();
  }, []);

  return (
    <S.Workspace id="grid-workspace">
      <HeaderPanel id="header"/>
      <EditorPanel id="editor" layout={layout} />

      {workspace.opened instanceof Profiler ? (
        <DialogPanel
          id="runner"
          workspace={workspace}
          session={workspace.opened.session}
          isOpen={layout.isExpanded("runner")}
          layout={layout}
          allowTransitionsDebug={true}
        />
      ) : (
        <RunnerPanel
          id="runner"
          workspace={workspace}
          audioController={sip}
          isOpen={layout.isExpanded("runner")}
          onChangeOpen={() => layout.toggleCollapse("runner")}
        />
      )}

      <FilesPanel id="files" workspace={workspace} isOpen={layout.isExpanded("files")} />

      <GroupPanel id="tools" tabs={tabs}>
        {workspace.opened instanceof Profiler && <StatisticsPanel profiler={workspace.opened} />}
        <TerminalPanel workspace={workspace} />
        {project?.metadata.isEditable && <VersionsPanel workspace={workspace} />}
      </GroupPanel>

      <Gutter
        id="gutter1"
        disabled={layout.isDisabled("files")}
        onMove={(dir, init) => layout.resize("files", dir, init)}
      />

      <Gutter
        id="gutter2"
        disabled={layout.isDisabled("runner")}
        onMove={(dir, init) => layout.resize("runner", -dir, init)}
      />

      <Gutter
        vertical
        id="gutter3"
        disabled={layout.isDisabled("tools")}
        onMove={(dir, init) => layout.resize("tools", dir, init)}
      />
    </S.Workspace>
  );
};

export default observer(WorkspaceView);
