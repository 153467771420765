import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { makeAutoObservable, runInAction } from "mobx";
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils'

export class CustomerStore {
  private readonly account: IAccount;
  public Info?: IPromiseBasedObservable<dasha.customer.CustomerInfo>;

  constructor(account: IAccount) {
    this.account = account;
    makeAutoObservable(this);
  }

  public TryReload() {
    if (this.Info === null || this.Info === undefined) {
      this.Reload();
    }
  }

  private Reload() {
    const a = this.account.connect();
    this.Info = fromPromise(dasha.customer.getCustomerInfo({account: a}));
  }
}
