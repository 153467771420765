import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { Button, Divider, Form, Grid, Input, Label, Message, Modal, TextArea } from "semantic-ui-react";
import { HistoryList } from "../uikit/gpt/History/historyList";
import { HistoryHolder } from "../uikit/gpt/History/types";
import { gptFunctionsStore } from "../uikit/gpt/Functions/types";
export interface TestCreateData {
  PromptName: string;
  TestName: string;
  TestDescription: string | undefined;
  InstanceId: string;
  expectedHistory: HistoryHolder;
}

export const AddTestModal: FC<{
  instanceId: string;
  initialPromptName: string | undefined;
  history: HistoryHolder;
  functions: gptFunctionsStore;
  onCommit: (data: TestCreateData) => void;
}> = observer(({ instanceId, initialPromptName, history, onCommit, functions }) => {
  const [promptName, setPromptName] = useState(initialPromptName);
  const [testName, setTestName] = useState("Test Name");
  const [description, setDescription] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  useEffect(() => {
    setStep(0);
    setPromptName(initialPromptName??promptName);
  }, [instanceId, open]);

  const step1 = () => (
    <Form>
      <Form.Field>
        <Label pointing="below" color={promptName === undefined || promptName === "" ? "red" : undefined}>
          Prompt name
        </Label>
        <Input focus placeHolder="prompt name" onChange={(e, data) => setPromptName(data.value)} value={promptName} />
      </Form.Field>
      <Form.Field>
        <Label pointing="below">Test case name</Label>
        <Input placeHolder="test case name" onChange={(e, data) => setTestName(data.value)} value={testName} />
      </Form.Field>
      <Form.Field>
        <Label pointing="below">Description</Label>
        <TextArea
          rows={4}
          placeHolder="test case description"
          onChange={(e, data) => setDescription(data.value?.toString() ?? "")}
          value={description}
        />
      </Form.Field>
    </Form>
  );
  const step0 = () => <HistoryList canEdit history={history} functions={functions}/>;

  return (
    <Modal open={open} trigger={<Button>Add test case</Button>} onOpen={() => setOpen(true)}>
      <Modal.Header>Add test case</Modal.Header>
      <Modal.Content>
        {step === 0 && step0()}
        {step === 1 && step1()}
      </Modal.Content>
      <Modal.Actions>
        {step === 0 && <Button onClick={() => setStep(1)}>Next</Button>}
        {step === 1 && (
          <>
            <Button onClick={() => setStep(0)}>Back</Button>
            <Button
              positive
              disabled={promptName === undefined || promptName === ""}
              onClick={() => {
                onCommit({
                  InstanceId: instanceId,
                  PromptName: promptName!,
                  TestDescription: description,
                  TestName: testName,
                  expectedHistory: history
                });
                setOpen(false);
              }}
            >
              Add
            </Button>
          </>
        )}
        <Button
          negative
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
});
