import * as dasha from "@dasha.ai/sdk/web";
import { Emitter } from "../../misc/emitter";

import sandboxRaw from "!!raw-loader!./snippets/sandbox.js";

import { AppCommunicatorProtocol, ExecuteConfig, CompilationError, AppType } from "./types";
import { buildDasha, bundler } from "./bundler/esbuild";
import { mountFileSystem } from "./utils";

export default class Sandbox implements AppCommunicatorProtocol {
  private stopConversation?: () => void;
  private cancelToken: dasha.CancelToken;
  private app?: dasha.Application<any, any>;
  private testApp?: dasha.Application<any, any>;
  private testJobId?: string;
  private chat?: dasha.chat.Chat;

  private sandboxHandler?: (this: Window, ev: MessageEvent<any>) => any;
  private sandboxIframe?: HTMLIFrameElement;
  public get iframe() {
    return this.sandboxIframe;
  }

  private logger = dasha.log.child({ label: "sandbox" });

  private readonly _onDidError = new Emitter<Error>();
  public readonly onDidError = this._onDidError.event;

  private readonly _onDidMessage = new Emitter<dasha.Transcription>();
  public readonly onDidMessage = this._onDidMessage.event;

  private readonly _onDidComplete = new Emitter<void>();
  public readonly onDidComplete = this._onDidComplete.event;

  private readonly _onDidDevLog = new Emitter<any>();
  public readonly onDidDevLog = this._onDidDevLog.event;

  private readonly _onDidDslError = new Emitter<CompilationError>();
  public readonly onDidDslError = this._onDidDslError.event;

  private readonly _onLogger = new Emitter<any>();
  public readonly onLogger = this._onLogger.event;

  private readonly _onDidChangeContext = new Emitter<Record<string, any>>();
  public readonly onDidChangeContext = this._onDidChangeContext.event;

  private readonly _onDidSandboxEvent = new Emitter<string>();
  public readonly onDidSandboxEvent = this._onDidSandboxEvent.event;

  private dslErrorsListener = ({ message }: { message: string }) => {
    const filename = message.match(/Failed to compile DSL: ((.+).dsl) /)?.[1];
    if (!filename) return;

    const errors = message.split(filename);
    const markers = errors.map((raw) => {
      const match = raw.trim().match(/line (.+):(.+?) (.+)/);
      if (!Array.isArray(match)) return null;

      const [, line, column, message] = match;
      return {
        line: +line,
        column: +column,
        message: message.split("Trace id")[0],
      };
    });

    this._onDidDslError.fire({
      filename,
      markers: markers.filter((v): v is CompilationError["markers"][0] => v != null),
    });
  };

  private patchExecute = async (executeFn, conv: dasha.SingleConversation) => {
    const iframeWindow: any = this.iframe?.contentWindow;
    if (iframeWindow == null) return;

    const sandbox = iframeWindow.sandbox;
    if (conv.input.disable_playground === true) {
      conv.audio = sandbox.audio as dasha.audio.AudioConfig;
      conv.sip = sandbox.sip as dasha.sip.SipConfig;
      return await executeFn.bind(conv)({
        channel: sandbox.channel,
        cancelToken: this.cancelToken,
      });
      return;
    }
    if (sandbox.conversation) 
      throw Error("Sandbox can execute only one conversation");
    sandbox.conversation = conv;

    this.app = conv._application;
    // @ts-ignore
    this.app.on("_disposed", async () => {
      this._onDidComplete.fire();
      await this.dispose();
    });

    conv.audio = sandbox.audio as dasha.audio.AudioConfig;
    conv.sip = sandbox.sip as dasha.sip.SipConfig;
    if (sandbox.channel === AppType.Text) {
      if (conv.input.disable_chat !== true) {
        this.chat = await iframeWindow.dasha.chat.createChat(conv);
      }
    }
    conv.on("transcription", (log) => this._onDidMessage.fire(log));
    conv.on("debugLog", (log: any) => this._onDidDevLog.fire(log));
    if (this.testJobId !== null && this.testJobId !== undefined && this.testApp !== undefined) {
      const data = await dasha.jobs.getDebugLog(this.testJobId);
      const parser = new dasha.TranscriptionParser();
      const result = new Array<dasha.Transcription>();
      parser.on("transcription", (tr) => result.push(tr));
      for (const message of data) {
        parser.push(message);
      }
      const example = result.map(x => `${x.speaker}: ${x.text}`).join("\n");
      const greeting = result[0].speaker === "human" ? result[0].text : null;
      const inputs: Record<string, any> = {example, greeting};
      if (conv.input.testerPrompt !== undefined) {
        inputs.prompt = conv.input.testerPrompt;
      }

      const testConv = this.testApp.createConversation(inputs);
      const testChat = await dasha.chat.createChat(testConv);
      
      testChat.on("gluedText", async (x) => await this.chat?.sendText(x));
      this.chat?.on("gluedText", async (x) => await testChat?.sendText(x));
      
      testChat.on("close", () => { try { this.chat?.close(); } catch {}});
      this.chat?.on("close", () => { try { testChat.close(); } catch {}});
      
      testConv.execute({channel: "text", cancelToken: this.cancelToken})
        .catch((err) => this._onDidError.fire(err as Error));

      const opened = new Promise((resolve, reject) => {
        testConv.once("debugLog", resolve);
      });
      await opened;

    }

    return await executeFn.bind(conv)({
      channel: sandbox.channel,
      cancelToken: this.cancelToken,
    });
  };

  public async ready(account: dasha.Account, canBeCancelled: boolean) {
    const iframeWindow = this.sandboxIframe?.contentWindow as any;
    if (iframeWindow == null) return;

    await iframeWindow.dasha.account.setAccount("default", account, { overwrite: true });
    await iframeWindow.dasha.account.setCurrentAccountName("default");

    this.cancelToken = new iframeWindow.dasha.CancelToken((cancel) => {
      if (canBeCancelled) {
        this.stopConversation = () => cancel();
      } else {
        this.stopConversation = undefined;
      }
    });

    iframeWindow.dasha.log.on("data", this.dslErrorsListener);
    iframeWindow.dasha.log.on("data", (log) => {
      this.logger[log.level](
        // eslint-disable-next-line no-control-regex
        log.message.replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, "")
      );
      this._onLogger.fire(log);
    });

    ["log", "error", "warn"].forEach((method) => {
      iframeWindow.console[method] = (...args) => {
        if (typeof args[0] == "string" && args[0].includes(`Z [`)) return;
        console[method](...args);
        console.log(args[0], args[0] instanceof Error);
        const formats = args
          .map((arg) => {
            if (arg instanceof Error) return arg.toString();
            return JSON.stringify(arg, null, 2);
          })
          .join("\n")
          // eslint-disable-next-line no-control-regex
          .replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, "");

        if (method === "log") method = "info";
        this.logger[method](formats);
      };
    });

    const patchExecute = this.patchExecute.bind(this);
    const QueuedExecute = iframeWindow.dasha.QueuedConversation.prototype.execute;
    iframeWindow.dasha.QueuedConversation.prototype.execute = async function () {
      return (await patchExecute(QueuedExecute, this)) as any;
    };

    const SingleExecute = iframeWindow.dasha.SingleConversation.prototype.execute;
    iframeWindow.dasha.SingleConversation.prototype.execute = async function () {
      return (await patchExecute(SingleExecute, this)) as any;
    };
  }

  private async findAgentSettingsFile(fs: any, dir: string = '/'): Promise<string | null> {
    try {
      const files = await fs.promises.readdir(dir);
      
      for (const file of files) {
        const path = `${dir}${dir.endsWith('/') ? '' : '/'}${file}`;
        
        try {
          const stats = await fs.promises.stat(path);
          
          if (stats.isDirectory()) {
            const result = await this.findAgentSettingsFile(fs, path);
            if (result) return result;
          } else if (file === 'agentSettings.json') {
            return path;
          }
        } catch (error) {
          console.warn(`Error accessing ${path}:`, error);
        }
      }
    } catch (error) {
      console.warn(`Error reading directory ${dir}:`, error);
    }
    
    return null;
  }

  private async loadAgentSettings(): Promise<Record<string, any>> {
    const iframeWindow: any = this.iframe?.contentWindow;
    if (!iframeWindow?.fs) return {};

    try {
      const settingsPath = await this.findAgentSettingsFile(iframeWindow.fs);
      console.log('Found agent settings at:', settingsPath);
      
      if (!settingsPath) {
        console.warn('No agentSettings.json found in filesystem');
        return {};
      }

      const data = await iframeWindow.fs.promises.readFile(settingsPath, 'utf8');
      console.log('Loaded agent settings from file:', data);
      return JSON.parse(data);
    } catch (error) {
      console.warn('Failed to load agent settings:', error);
      return {};
    }
  }

  public async execute(config: ExecuteConfig): Promise<void> {
    const dashaBundle = await buildDasha();
    const bundle = await bundler(config.zip);

    // We'll move the settings loading to after filesystem mounting
    const html = `<body>
        <div id="root"></div>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.10.1/jszip.min.js"></script>
        <script src="https://unpkg.com/filer@1.0.1/dist/filer.min.js"></script>
        <script>${sandboxRaw}</script>
        <script>${dashaBundle}</script>
        <script>
          // Patch the createConversation method to include our settings
          const originalCreateConversation = window.dasha.Application.prototype.createConversation;
          window.dasha.Application.prototype.createConversation = function(config) {
            console.log('Creating conversation with config:', config);
            console.log('Current sandbox agent settings:', window.sandbox.agentSettings);
            
            const finalConfig = {
              ...config,
              ...window.sandbox.agentSettings,
              llmModel: window.sandbox.agentSettings?.model, // for compatibility with old settings, will be removed in the future
            };
            
            console.log('Final conversation config:', finalConfig);
            return originalCreateConversation.call(this, finalConfig);
          };
          
          // Initialize with empty settings - will be populated later
          window.sandbox.agentSettings = {};
        </script>
        <script module>
          window.execute = async () => {
            ${bundle}
          }
          
          window.top.postMessage("ready");
        </script>
      </body>`;

    this.sandboxIframe = document.createElement("iframe");
    this.sandboxHandler = async ({ data, source }) => {
      const iframeWindow: any = this.sandboxIframe?.contentWindow;
      if (source !== iframeWindow || iframeWindow == null) return;
      this._onDidSandboxEvent.fire(data);

      if (data === "ready") {
        const fs = iframeWindow.fs;
        iframeWindow.sandbox.channel = config.type;
        iframeWindow.sandbox.endpoint = config.endpoint;
        iframeWindow.onerror = async (e) => {
          this.logger.error(e);
          this._onDidComplete.fire();
          await this.dispose();
        };

        try {
          await mountFileSystem(config.zip, fs);
          
          // Load settings after filesystem is mounted
          const agentSettings = await this.loadAgentSettings();
          console.log('Agent settings after fs mount:', agentSettings);
          iframeWindow.sandbox.agentSettings = agentSettings;
          
          this.testJobId = config.testAppJobId;
          if (config.testAppJobId !== undefined && config.testAppJobId !== null) {
            if (this.testApp === undefined) {
              const testZip = await fetch("/tester.zip");
              this.testApp = await dasha.deploy(new Uint8Array(await testZip.arrayBuffer()), { account: config.account });
              this.testApp.start({concurrency: 1});
            }
          }
          
          await this.ready(config.account, config.canBeCancelled);
          await iframeWindow.execute();
        } catch (e) {
          console.log(e);
          this._onDidComplete.fire();
          await this.dispose();
        }
        return;
      }
    };

    window.addEventListener("message", this.sandboxHandler);
    this.sandboxIframe.onload = () => {
      this.sandboxIframe?.contentDocument?.open();
      this.sandboxIframe?.contentDocument?.write(html);
      this.sandboxIframe?.contentDocument?.close();
    };
  }

  public async send(message: string): Promise<void> {
    await this.chat?.sendText(message);
  }

  public async dispose(): Promise<void> {
    this.logger.info("disposed");

    if (this.chat !== undefined) {
      await this.chat?.close().catch(() => 0);
      this.chat = undefined;
      // do not cancel
      this.stopConversation = undefined;
    }

    this._onDidSandboxEvent.dispose();
    this._onDidMessage.dispose();
    this._onDidComplete.dispose();
    this._onDidDevLog.dispose();
    this._onDidDslError.dispose();
    this._onDidError.dispose();
    this._onLogger.dispose();

    if (this.sandboxHandler) {
      window.removeEventListener("message", this.sandboxHandler);
    }

    this.stopConversation?.();
    this.stopConversation = undefined;
    await this.app?.stop({ waitUntilAllProcessed: true }).catch(() => 0);
    this.app?.dispose();
    this.app = undefined;
  }
}
