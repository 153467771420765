import styled from "styled-components";
import { PureButton } from "../uikit/Button";

export const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  background: rgba(247, 247, 253, 0.02);
  padding-bottom: 124px;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled(PureButton)<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${(p) => (p.isSelected ? "#35317D" : "#1B1B2D")};
  color: ${(p) => (p.isSelected ? "#ffffff" : "#A1A1BD")};

  height: 32px;
  padding: 0 14px;

  width: 100%;
  text-transform: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
  width: 50%;
  height: 40px;
  border-bottom: ${(p) => (p.isSelected ? "2px solid #5855F4" : "1px solid rgba(255, 255, 255, 0.05)")};
  color: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.2s color;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const Header = styled.div`
  padding: 12px 12px 8px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(161, 161, 189, 0.5);
    margin: 0;
  }

  div {
    display: flex;
  }

  button {
    transition: 0.2s opacity;
    margin-left: 12px;
    opacity: 0.75;
  }

  button:hover {
    opacity: 1;
  }
`;

export const File = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  height: 32px;
  width: 100%;
`;
