import { IAccount } from "../interface";
import RestApi from "./fetch";

export interface ProjectMetadata {
  id: string;
  name: string;
  description: string;
  isEditable: boolean;
  sharedAccess: boolean;
  createdAt: string;
  updatedAt: string;
  customMetaData: {
    tag?: "dasha";
    viewed?: number;
    projectType?: "code" | "visual";
    author?: string;
  };
}

export interface UploadProjectConfig {
  name: string;
  description: string;
  projectType?: "code" | "visual";
  sharedAccess?: boolean;
  content: Uint8Array;
  contentFolder?: string;
}

class ShareApi extends RestApi {
  readonly domain = "api/v1/share";

  async getProjects(account: IAccount): Promise<ProjectMetadata[]> {
    try {
      const fetch = await this.signFetch(account);
      const res = await fetch("project");

      const data = (await res.json()) as ProjectMetadata[];
      return data;
    } catch {
      return [];
    }
  }

  async getProject(id: string, account: IAccount): Promise<ProjectMetadata> {
    const fetch = await this.signFetch(account);
    const res = await fetch(`project/${id}`);
    const data = (await res.json()) as ProjectMetadata;
    return data;
  }

  async getProjectContent(id: string, account: IAccount): Promise<Uint8Array> {
    const fetch = await this.signFetch(account);
    const res = await fetch(`project/${id}/content`);
    const content = await res.arrayBuffer();
    return new Uint8Array(content);
  }

  async deleteProject(id: string, account: IAccount) {
    const fetch = await this.signFetch(account);
    const res = await fetch(`project/${id}`, { method: "DELETE" });
    return res.ok;
  }

  async updateProject(id: string, config: UploadProjectConfig, account: IAccount): Promise<ProjectMetadata> {
    const query = new URLSearchParams({
      "Name.Value": config.name,
      "Name.HasValue": "true",

      "Description.Value": config.description,
      "Description.HasValue": "true",
      "SharedAccess.HasValue": "true",

      "SharedAccess.Value": config.sharedAccess ? "true" : "false",
      "CustomMetaData.HasValue": "true",

      "CustomMetaData.Value": JSON.stringify({
        projectType: config.projectType ?? "code",
        author: account.name,
      })
    });

    const fetch = await this.signFetch(account);
    const res = await fetch(`project/${id}?${query}`, {
      method: "PATCH",
      body: config.content,
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });

    return await res.json();
  }

  async createProject(config: UploadProjectConfig, account: IAccount): Promise<ProjectMetadata> {
    const query = new URLSearchParams({
      Name: config.name,
      Description: config.description,
      SharedAccess: config.sharedAccess ? "true" : "false",
      CustomMetaData: JSON.stringify({
        projectType: config.projectType ?? "code",
        author: account.name,
      }),
    });
  
    const fetch = await this.signFetch(account);
    const res = await fetch(`project?${query}`, {
      method: "POST",
      body: config.content,
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });

    return await res.json();
  }
}

export default ShareApi;
