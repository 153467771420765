import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { RawElement } from "../common";
import { Expression } from "../expressions";
import { TransitionCondition } from "./TransitionCondition";

export class TransitionExpressionCondition
  extends TransitionCondition
  implements interfaces.ITransitionExpressionCondition
{
  elementType: DslElementType.TransitionExpressionCondition = DslElementType.TransitionExpressionCondition;
  condition: Expression;
  priority: interfaces.OptionalValue<RawElement<number>>;
  isOnConfident: boolean;
  tags: RawElement<string>[];

  constructor(obj: interfaces.ITransitionExpressionCondition, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.condition = helpers.ensureIsExpressionInstance(obj.condition);
    this.priority = helpers.ensureIsOptionalInstance(obj.priority, RawElement<number>);
    this.tags = helpers.ensureIsArrayOfInstances(obj.tags, RawElement<string>);
    this.isOnConfident = obj.isOnConfident;
  }
  toJson(): interfaces.ITransitionExpressionCondition {
    return {
      isTransitionCondition: true,
      elementType: this.elementType,
      location: this.location,
      condition: this.condition.toJson(),
      priority: interfaces.helpers.createOptionalValue(this.priority.value?.toJson()),
      tags: this.tags.map((el) => el.toJson()),
      isOnConfident: this.isOnConfident,
    };
  }
  toDsl(indent: number): string {
    return [
      this.condition.toDsl(indent),
      this.priority.hasValue ? ` priority ${this.priority.value.toDsl(indent)}` : "",
      this.isOnConfident ? " when confident" : "",
      this.tags.length > 0 ? ` tags: ${this.tags.map((el) => el.value).join(",")}` : "",
    ].join("");
  }
}
