import { LoadingStep, SessionProtocol } from "@core/workspace/session-storage/types";
import { useEffect, useRef } from "react";

const useSandbox = (session: SessionProtocol | null | undefined) => {
  const sandboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sandboxRef.current == null) return;
    if (session?.sandboxIframe) {
      sandboxRef.current.appendChild(session.sandboxIframe);
    } else {
      sandboxRef.current.innerHTML = "";
    }

    const iframeBody = session?.sandboxIframe?.contentDocument?.body;
    if (iframeBody) {
      iframeBody.style.fontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;
      iframeBody.style.background = "#161729";
      iframeBody.style.color = "#fff";
    }

    const timer = setInterval(() => {
      const iframeBody = session?.sandboxIframe?.contentDocument?.body;
      if (sandboxRef.current == null || iframeBody == null) return;
      const childrenCount = iframeBody.querySelector("#root")?.children?.length || 0;
      sandboxRef.current.style.display = childrenCount > 0 ? "block" : "none";
    }, 1000);

    return () => clearInterval(timer);
  }, [session?.sandboxIframe]);

  useEffect(() => {
    if (sandboxRef.current == null) return;
    const isRunning = session?.status === LoadingStep.Running;
    sandboxRef.current.style.bottom = isRunning ? "57px" : "86px";
  }, [session?.status]);

  return sandboxRef;
};

export default useSandbox;
