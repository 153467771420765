import React, { FC, ReactNode, useState, useEffect } from "react";
import styled from "styled-components";
import { Tabs, Tab } from "./Tabs";

const Group = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #161729;
`;

const Control = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(247, 247, 253, 0.02);
`;

interface Props {
  id: string;
  tabs: string[];
  children: ReactNode;
}

export const GroupPanel: FC<Props> = ({ id, tabs, children }) => {
  const [tab, setTab] = useState<string>(() => tabs[0]);
  const elements = React.Children.toArray(children);
  const selected = tabs.indexOf(tab);

  useEffect(() => {
    setTab(tabs[0]);
  }, [tabs]);

  return (
    <Group id={id}>
      <Control>
        <Tabs>
          {tabs.slice(0, elements.length).map((name) => (
            <Tab key={name} onClick={() => setTab(name)} isSelected={name === tab}>
              {name}
            </Tab>
          ))}
        </Tabs>
      </Control>
      {elements[selected]}
    </Group>
  );
};
