import styled, { css } from "styled-components";

export const Tabs = styled.ul`
  list-style-type: none;
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.li<{ isSelected: boolean }>`
  box-sizing: border-box;
  width: fit-content;
  height: 48px;

  white-space: nowrap;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #a1a1bd;

  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  cursor: pointer;
  transition: background 0.2s;

  button {
    margin-left: 8px;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: #161729;
      color: #fff;
    `}
`;
