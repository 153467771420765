import { Statement } from '@dasha.ai/sql.js';
import mapKeys from 'lodash/mapKeys';

const toLowerFirstCharKeyIteratee = (_: any, key: string): string => {
  if (!key?.length) {
    return key;
  }

  return key[0].toLowerCase() + key.substring(1);
};

export async function all<T>(statement: Statement, ...args: any[]): Promise<T[]> {
  const results: T[] = [];

  statement.bind(args);

  while (statement.step()) {
    const object = statement.getAsObject();

    if (Object.values(object).some((x) => x !== undefined)) {
      results.push((mapKeys(object, toLowerFirstCharKeyIteratee) as unknown) as T);
    }
  }

  return results;
}

export async function get<T>(statement: Statement, ...args: any[]): Promise<T | undefined> {
  const result = statement.getAsObject(args);

  if (result !== undefined && Object.values(result).some((x) => x !== undefined)) {
    return (mapKeys(result, toLowerFirstCharKeyIteratee) as unknown) as T;
  }

  return undefined;
}

// the promise here is to support the interface of the sqlite3
export async function run(statement: Statement, ...args: any[]): Promise<void> {
  return statement.run(args);
}
