import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 24px;
`;

export const Card = styled.div`
  max-width: 264px;
  width: 100%;
  height: 68px;

  background: #161729;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px;
  margin-right: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

export const CardTitle = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: rgba(217, 217, 226, 0.75);

  margin: 0;
`;

export const CardValue = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
  color: #ffffff;

  margin: 0;
`;
