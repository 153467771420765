import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Dropdown, Input, TextArea, Message, Modal, Button } from "semantic-ui-react";
import "./styles.css";
import { CopyInput } from "../uikit/CopyInput";
import { useStore } from "../core/api/GlobalStoreContext";

export const VoIPCallReceiver: FC<{ }> = ({ }) => {
  const { sip } = useStore();
  const [open, setOpen] = useState(false);
  const [uri, setUri] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setError(undefined);
    const fetchData = async () => {
      setUri(await sip.initialize());
    };
    fetchData().catch((e) => setError(e.message));
  }, [sip]);

  const onClose = useCallback(() => {
    setError(undefined);
    const fetchData = async () => {
      await sip.hangup();
    };
    fetchData()
      .catch((e) => setError(e.message))
      .finally(() => setOpen(false));
  }, [sip]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button icon="phone">Receive call in browser</Button>}
    >
      <Modal.Header>Receive call in browser</Modal.Header>
      <Modal.Content>
        {error && <Message negative>{error} </Message>}
        <h3>Call to this uri instead on phone number in Dasha.AI application</h3>
        { uri && (<CopyInput clipboard={uri} loading={uri === undefined} tooltipText="#connectSafe argument" type="text" />) }
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={onClose}>
          Finish
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
