import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import DSLEditor from "../core/legacy-graph-storage/DSLEditor";
import TextLoading from "../uikit/TextLoading";
import Workspace from "../core/workspace/Workspace";
import TextStorage from "../core/workspace/TextStorage";
import { Explorer } from "../core/explorer/Explorer";
import Profiler from "../core/profiler/ProfilerStorage";
import GridLayout from "../core/misc/GridLayout";
import ProfilerPanel from "../ProfilerPanel";

import LegacyGraphEditor from "./LegacyGraphEditor";
import ProjectTabs from "./ProjectTabs";
import CodeEditor from "./CodeEditor";
import * as S from "./styled";
import { useStore } from "../core/api/GlobalStoreContext";

interface Props {
  id: string;
  layout: GridLayout;
}

const EditorPanel: FC<Props> = ({ id, layout }) => {
  const {workspace, explorer} = useStore();

  const storage = workspace.opened;
  const project = workspace.project;


  if (explorer.isLoading) {
    return (
      <S.Container id={id}>
        <TextLoading title="Open and download the application">
          The application is in the process of downloading <br />
          this may take some time. Please wait
        </TextLoading>
      </S.Container>
    );
  }

  return (
    <S.Container id={id}>
      {storage?.isFullscreen !== true && <ProjectTabs workspace={workspace} />}

      <div style={{ position: "relative", flex: 1, display: explorer.isLoading ? "none" : "block", overflowY: "auto" }}>
        {storage instanceof TextStorage && (
          <CodeEditor storage={storage} isReadonly={project?.versions[project.version].isReadonly} />
        )}

        {storage && storage instanceof DSLEditor && storage.isLoading === false && (
          <LegacyGraphEditor isRunning={workspace.isRunning} storage={storage} />
        )}

        {storage instanceof Profiler && (
          <ProfilerPanel
            id="profiler"
            layout={layout}
            profiler={storage}
            account={explorer.account}
            dashaapp={workspace.storages.dashaapp}
            dataset={workspace.storages.dataset}
          />
        )}
      </div>
    </S.Container>
  );
};

export default observer(EditorPanel);
