export enum ReasonType {
  UnknownReason = 0,
  SttWithNluReason = 1,
}

export enum TypeOfSttResponse {
  Potential = 0,
  Final = 1,
  Confident = 2,
}

export interface NluResponseContainer {
  nluResponses: NluFact[];
  grammar: {
    [key: string]: {
      [key: string]: string;
    }[];
  };
}

export interface FactDetails {
  skill: string;
  weight: string;
  likelihood: string;
  location: Location;
}

export interface NluIntentFact extends FactDetails {
  type: "Intent";
  intent: string | null;
  state: string;
  sentenceType: string;
}

export interface NluDataFact extends FactDetails {
  type: "Data";
  data: {
    [key: string]: string;
  };
  factType: string;
}

export type NluFact = NluDataFact | NluIntentFact;

export interface Location {
  start: string;
  end: string;
}

export interface TransitionKey {
  /**
   * Transition by which crossed (DSL naming)
   */
  transitionId: string | null;
  id: string;
  /**
   * Node from which crossed
   */
  fromNode: string;
  /**
   * Node to which crossed
   */
  toNode: string;
  /**
   * Name of block in which this transition contains
   */
  blockName: string;
  /**
   * Path is a language server id of elements
   */
  sourceNodePath: string;
  targetNodePath: string;
  transitionPath: string | null;
  blockPath: string;
}

export interface TransitionInformation extends TransitionKey {
  id: string; //Transition id
  decisionCount: number;
  uniqDecisionCount: number;
  stack?: string;
  transitionType:
    | "BlockCall"
    | "BlockReturn"
    | "Goto"
    | "Normal"
    | "Digression"
    | "Preprocessor"
    | "DigressionReturn"
    | "PreprocessorReturn"
    | "Global"
    | "GlobalReturn"
    | "GlobalPreprocessor"
    | "GlobalPreprocessorReturn";
}
