import { DeserializeEvent } from "@projectstorm/react-canvas-core";
import { DefaultPortModel, NodeModel, NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeType } from "@core/profiler/ProfilerGraph/types";
import LinkModel from "../DirectionalLink/Model";
import StraightLinkModel from "../StraightLink/Model";
import Engine from "..";
import DirectionalLinkModel from "../DirectionalLink/Model";

class CircleNodeModel extends NodeModel<NodeModelGenerics> {
  static TYPE = "circle-node";

  readonly in = new DefaultPortModel(true, "in");
  readonly out = new DefaultPortModel(false, "out");
  public freeDirections = false;
  public type = NodeType.Node;

  public customTooltip?: Record<string, string | number>;
  public customTransitions?: number;
  public name: string;

  constructor(readonly engine: Engine, id: string) {
    super({ type: CircleNodeModel.TYPE, id });
    this.name = id;

    this.addPort(this.in);
    this.addPort(this.out);
  }

  deserialize(event: DeserializeEvent<this>): void {
    super.deserialize(event);
  }

  get transitions() {
    if (this.customTransitions != null) {
      return this.customTransitions;
    }

    const links = Object.values(this.in.getLinks());
    return links.reduce((acc, link) => {
      if (!(link instanceof LinkModel) && !(link instanceof StraightLinkModel)) return acc;
      const types = ["DigressionReturn", "BlockReturn", "PreprocessorReturn"];
      if (types.includes(link.transitionType)) return acc;
      return acc + link.uniqueDecisionCount;
    }, 0);
  }

  getExternals() {
    const externals: Record<string, { goto: number; return: number }> = {};
    let output = Object.values(this.out.getLinks());
    let input = Object.values(this.in.getLinks());

    if (this.type !== NodeType.Node) {
      [input, output] = [output, input];
    }

    output.forEach((link) => {
      if (!(link instanceof LinkModel) && !(link instanceof StraightLinkModel)) return;
      const types = ["Digression", "BlockCall", "Preprocessor"];
      if (types.includes(link.transitionType)) {
        const data = { goto: link.decisionCount, return: 0 };
        const key = this.type !== NodeType.Node ? link.sourceNodePath : link.targetNodePath;
        externals[key] = data;
      }
    });

    input.forEach((link) => {
      if (!(link instanceof LinkModel) && !(link instanceof StraightLinkModel)) return;
      const types = ["DigressionReturn", "BlockReturn", "PreprocessorReturn"];
      if (types.includes(link.transitionType)) {
        const key = this.type !== NodeType.Node ? link.targetNodePath : link.sourceNodePath;
        const data = externals[key] ?? { goto: 0, return: 0 };
        data.return = link.decisionCount;
        externals[key] = data;
      }
    });

    return externals;
  }

  getAbandoned() {
    const output = Object.values(this.out.getLinks());
    const input = Object.values(this.in.getLinks());
    let calls = 0;

    input.forEach((link) => {
      if (!(link instanceof LinkModel)) return;
      calls += link.decisionCount;
    });

    output.forEach((link) => {
      if (!(link instanceof LinkModel)) return;
      calls -= link.decisionCount;
    });

    return Math.max(0, calls);
  }

  get tooltip() {
    if (this.customTooltip != null) {
      return this.customTooltip;
    }

    const externals = this.getExternals();
    const tooltip: Record<string, number | string> = {};
    let notReturned = 0;

    tooltip["Node Type"] = this.type;
    Object.entries(externals).forEach(([id, data]) => {
      if (data.goto - data.return > 0) {
        tooltip[id] = `${data.goto} - ${data.return} = ${data.goto - data.return}`;
        notReturned += data.goto - data.return;
      }
    });

    tooltip["Total not returned"] = notReturned;
    tooltip["Abandoned"] = this.getAbandoned();
    return tooltip;
  }

  findLink(target: CircleNodeModel): DirectionalLinkModel | null {
    const finded = Object.values(this.out.getLinks()).find((link) => link.getTargetPort() === target.in);
    if (finded instanceof DirectionalLinkModel) return finded ?? null;
    return null;
  }

  serialize() {
    return {
      ...super.serialize(),
      transitions: this.transitions,
      nodeType: this.type,
    };
  }
}

export default CircleNodeModel;
