import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table } from "semantic-ui-react";
import "./styles.css";
import { OutboundConfig } from "@dasha.ai/sdk/web/sip/outbound-configs";
import { Loader } from "semantic-ui-react";
import { OutboundVoIPEditor } from "./OutboundVoIPEditor";
import { useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";

export const OutboundVoIP: FC<{ }> = ({ }) => {
  const { account } = useStore();
  const { customerId } = useParams();
  const [outboundConfigs, setOutboundConfigs] = useState<Record<string, OutboundConfig>>({});
  const [loading, setLoading] = useState(true);

  const onUpdate = useCallback((name: string, value: OutboundConfig | undefined) => {
    let copy = { ...outboundConfigs };
    if (value === undefined) {
      delete copy[name];
    } else {
      copy[name] = value;
    }    
    setOutboundConfigs(copy);
  }, [outboundConfigs])


  useEffect(() => {
    const fetchData = async () => {
      const a = account.connectAs(customerId);
      const c = await dasha.sip.outboundConfigs.listConfigs({ account: a });
      setOutboundConfigs(c);
      setLoading(false);
    };
    fetchData();
  }, [account]);

  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Outbound VoIP</h2>
      <Loader active={loading}>Loading..</Loader>      
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Edit</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Server</Table.HeaderCell>
            <Table.HeaderCell>Domain</Table.HeaderCell>
            <Table.HeaderCell>Transport</Table.HeaderCell>
            <Table.HeaderCell>Auth user</Table.HeaderCell>
            <Table.HeaderCell>From user</Table.HeaderCell>
            <Table.HeaderCell>CPS Limit</Table.HeaderCell>
            <Table.HeaderCell>Source Ips</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(outboundConfigs).map(([k, c]) => {
            return (
              <Table.Row>
                <Table.Cell> <OutboundVoIPEditor account={account} existingName={k} onUpdate={onUpdate} customerId={customerId}/> </Table.Cell>
                <Table.Cell> {k} </Table.Cell>
                <Table.Cell> {c.server} </Table.Cell>
                <Table.Cell> {c.domain} </Table.Cell>
                <Table.Cell> {c.transport} </Table.Cell>
                <Table.Cell> {c.account} </Table.Cell>
                <Table.Cell> {c.fromUser} </Table.Cell>
                <Table.Cell> {c.cps ?? "Unlimited"} </Table.Cell>
                <Table.Cell> {c.sourceIps?.join(",")} </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <OutboundVoIPEditor account={account} existingName={undefined} onUpdate={onUpdate} customerId={customerId}/>
          </Table.Row>
        </Table.Footer>        
      </Table>
    </div>
  );
};
