import React, { FC } from "react";
import styled from "styled-components";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Container = styled.div<{ isDisabled: boolean }>`
  pointer-events: ${p => p.isDisabled ? 'none' : 'all'};
  margin: 12px 0;
  width: 100%;
  flex-shrink: 0;

  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;

  .react-tel-input {
    width: 100%;
    height: 100%;
  }

  .flag-dropdown,
  .flag-dropdown.open,
  .flag-dropdown.open .selected-flag {
    background: transparent;
    border-radius: 0;
    border: none;
  }

  .selected-flag:hover {
    background: transparent;
  }

  .flag {
    image-rendering: pixelated;
  }

  .country-list {
    background: #242536;
    border-radius: 8px;
    color: #ffffff;

    .flag {
      margin: 0;
      margin-right: 8px;
    }

    .country {
      display: flex;
      align-items: center;
    }

    .country:hover,
    .country.active {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .react-tel-input .selected-flag .arrow {
    width: 16px;
    height: 16px;
    border: none;
    margin-top: -8px;
    display: ${p => p.isDisabled ? 'none' : 'block'};
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4yOTUyIDUuMjkyNTJDMTEuNjg2IDQuOTAyMiAxMi4zMTkxIDQuOTAyNTQgMTIuNzA5NCA1LjI5MzI3QzEzLjA5OTggNS42ODQgMTMuMDk5NCA2LjMxNzE3IDEyLjcwODcgNi43MDc0OEw4LjcwNDQxIDEwLjcwNzVDOC4zMTM3NCAxMS4wOTc3IDcuNjgwNzEgMTEuMDk3NSA3LjI5MDM3IDEwLjcwNjlMMy4yOTI2OSA2LjcwNjlDMi45MDIyOCA2LjMxNjI2IDIuOTAyNDYgNS42ODMxIDMuMjkzMSA1LjI5MjY5QzMuNjgzNzQgNC45MDIyOCA0LjMxNjkgNC45MDI0NiA0LjcwNzMxIDUuMjkzMUw3Ljk5ODI2IDguNTg1OTZMMTEuMjk1MiA1LjI5MjUyWiIgZmlsbD0iI2M4YzhjZCIvPgo8L3N2Zz4K);
  }

  .react-tel-input .country-list .country .dial-code {
    color: #acacac;
  }

  .react-tel-input .country-list .country.highlight {
    background: rgba(255, 255, 255, 0.2);
  }

  .form-control {
    background: ${(p) => (p.isDisabled ? "transparent" : "#242536")};
    padding-left: ${p => p.isDisabled ? '40px' : '55px'};
    border-radius: 8px;
    color: #ffffff;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

interface Props {
  value: string;
  style?: any;
  onChange?: (value: string) => void;
}

export const PhoneInput: FC<Props> = ({ value, style, onChange }) => {
  return (
    <Container style={style} isDisabled={onChange == null}>
      <ReactPhoneInput country={"us"} value={value} onChange={onChange} />
    </Container>
  );
};
