import styled from "styled-components";

export const StyledFile = styled.div<{ isSelected?: boolean }>`
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  font-weight: normal;
  --tree-color: ${(p) => (p.isSelected ? "#35317D" : "#1b1c2d")};
  background: var(--tree-color);
  transition: 0.2s background;
  cursor: pointer;

  &:hover {
    --tree-color: ${(p) => (p.isSelected ? "#35317D" : "#232435")};
  }

  svg {
    flex-shrink: 0;
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    height: 32px;
    color: ${(p) => (p.isSelected ? "#FFFFFF" : "#A1A1BD")};
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  input {
    flex: 1;
    width: 100%;
    height: 32px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    outline: none;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #13b8ff;
    background: transparent;
  }
`;