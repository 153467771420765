import { IProjectsLibrary } from "./interface";

import { IAccount } from "../interface";
import { UploadProjectConfig } from "../api/share";
import api from "../api";

class RemoteLibrary implements IProjectsLibrary {
  private account: IAccount;
  constructor(account: IAccount) {
    this.account = account;
  }

  async getProjects() {
    return await api.share.getProjects(this.account);
  }

  async getProjectContent(id: string): Promise<Uint8Array | null> {
    return await api.share.getProjectContent(id, this.account);
  }

  async updateProject(id: string, config: UploadProjectConfig) {
    return await api.share.updateProject(id, config, this.account);
  }

  async createProject(config: UploadProjectConfig) {
    return await api.share.createProject(config, this.account);
  }

  async deleteProject(id: string) {
    return await api.share.deleteProject(id, this.account);
  }
}

export default RemoteLibrary;
