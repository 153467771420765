import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { IDisposable } from "../../misc/emitter";
import SimpleTtsSpeaker from "../../SimpleTtsSpeaker";

export default class StopTtsSpeakerPlugin {
  private nowPlayingCondition?: monaco.editor.IContextKey<boolean>;
  private actionDisposer?: IDisposable;
  private mouseListenerDisposer?: IDisposable;
  private speaker: SimpleTtsSpeaker;

  constructor(speaker: SimpleTtsSpeaker) {
    this.speaker = speaker;
  }

  activate(editor: monaco.editor.IStandaloneCodeEditor) {
    this.nowPlayingCondition = editor.createContextKey("speaker-is-active-condition", false);

    this.mouseListenerDisposer = editor.onMouseDown((event: monaco.editor.IEditorMouseEvent) => {
      this.nowPlayingCondition?.set(this.speaker.isActive());
    });

    this.actionDisposer = editor.addAction({
      id: "stop-tts-speaker-action",
      label: "Stop playing phrase",
      precondition: "speaker-is-active-condition",
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1.5,

      run: () => {
        this.speaker.stop();
      },
    });
  }

  dispose() {
    this.actionDisposer?.dispose();
    this.mouseListenerDisposer?.dispose();
  }
}
