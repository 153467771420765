import React, { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";

import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import ProfilerTable from "@core/profiler/ProfilerTable";
import { Phrase } from "@core/profiler/types";
import Cluster from "@core/profiler/Cluster";
import GridLayout from "@core/misc/GridLayout";

import Row from "../Row";
import * as S from "./styled";

interface Props {
  profiler: ProfilerTable;
  dataset: DatasetStorage | undefined;
  layout: GridLayout;
}

const Table: FC<Props> = ({ profiler, dataset, layout }) => {
  const listEl = useRef<VariableSizeList>(null);
  const rowHeights = useRef({});
  const [visibleRange, setVisibleRange] = useState<[number, number]>([0, 0]);
  const [overscanRange, setOverscanRange] = useState<[number, number]>([0, 0]);

  const handleExpand = (row: Cluster, expanded: boolean) => {
    listEl.current?.resetAfterIndex(0);
    row.toggleExpand(expanded);
  };

  const handleShowDialog = (phrase: Phrase) => {
    layout.expandPanel("runner");
    void profiler.openSession(phrase, () => listEl.current?.resetAfterIndex(0));
  };

  const getRowHeight = (index: number) => rowHeights.current?.[index] || 32;
  const setRowHeight = (index: number, size: number) => {
    if (!rowHeights.current) return;
    if (rowHeights.current[index] === size) return;

    rowHeights.current = {
      ...rowHeights.current,
      [index]: size,
    };

    if (Object.keys(rowHeights.current).length >= visibleRange[1]) {
      listEl.current?.resetAfterIndex(0);
    }
  };

  useEffect(() => {
    listEl.current?.resetAfterIndex(0);
  }, [profiler.filter?.filteredData]);

  return (
    <S.Table $isRunnerOpen={layout.isExpanded("runner")} className="data-markup-table">
      <AutoSizer style={{ width: "100%", height: "100%" }}>
        {({ width, height }) => (
          <VariableSizeList
            ref={listEl}
            width={width}
            height={height}
            itemCount={profiler.filter?.filteredData.length}
            itemSize={getRowHeight}
            overscanCount={20}
            onItemsRendered={(indexes) => {
              setVisibleRange([indexes.visibleStartIndex, indexes.visibleStopIndex]);
            }}
          >
            {({ index, style }) => {
              const cluster = profiler.filter?.filteredData[index];
              if (!cluster) return null;

              return (
                <Row
                  style={style}
                  index={index}
                  searchRequest={profiler.filter?.searchRequest || ""}
                  dataset={dataset}
                  key={cluster.id}
                  cluster={cluster}
                  currentSessionPhrase={profiler.currentSessionPhrase}
                  setRowHeight={setRowHeight}
                  onExpand={handleExpand}
                  onShowDialog={handleShowDialog}
                />
              );
            }}
          </VariableSizeList>
        )}
      </AutoSizer>
    </S.Table>
  );
};

export default observer(Table);
