import {
  DefaultLabelFactory,
  DefaultLinkFactory,
  DefaultNodeFactory,
  DefaultPortFactory,
  DiagramEngine,
  DiagramModel,
  LinkLayerFactory,
  NodeLayerFactory,
} from "@projectstorm/react-diagrams";
import { SelectionBoxLayerFactory } from "@projectstorm/react-canvas-core";
import { action, makeObservable, observable } from "mobx";

import { TransitionInformation, TransitionKey } from "@core/profiler/storage/model/stuff";
import ProfilerStorage from "../storage";

import ZoomCanvasAction from "./actions/ZoomCanvasAction";
import ShowTooltipAction from "./actions/ShowTooltipAction";
import InteractiveStates from "./states/InteractiveStates";

import { CirclePortFactory } from "./CircleNode/Port";
import CircleNodeFactory from "./CircleNode/Factory";
import CircleNodeModel from "./CircleNode/Model";

import TooltipFactory from "./Tooltip/Factory";
import LabelFactory from "./Label/Factory";
import GridFactory from "./Grid/Factory";
import GridModel from "./Grid/Model";

import StraightLinkFactory from "./StraightLink/Factory";
import StraightLinkModel from "./StraightLink/Model";
import DirectionalLinkFactory from "./DirectionalLink/Factory";
import DirectionalLinkModel from "./DirectionalLink/Model";

import Autolayout from "./Autolayout";
import { NodeType } from "./types";
import { Emitter } from "monaco-editor";

class GraphModel extends DiagramModel {
  grid: GridModel;

  constructor(readonly engine: ProfilerGraph) {
    super();
    this.grid = new GridModel(engine);
    this.grid.setParent(this);
    this.addLayer(this.grid);
    //this.setLocked(true);
  }

  getCircleNode(id: string): CircleNodeModel | null {
    const node = super.getNode(id);
    if (node instanceof CircleNodeModel) return node;
    return null;
  }
}

class ProfilerGraph extends DiagramEngine {
  states = new InteractiveStates();
  graphModel = new GraphModel(this);
  autolayout = new Autolayout();
  openedNode: string | null = null;

  private readonly _onDidSelectTransition = new Emitter<TransitionKey | null>();
  public readonly onDidSelectTransition = this._onDidSelectTransition.event;

  constructor(readonly storage: ProfilerStorage) {
    super({ registerDefaultZoomCanvasAction: false, registerDefaultDeleteItemsAction: false });
    makeObservable(this, { openedNode: observable, closePreview: action });

    this.getLayerFactories().registerFactory(new NodeLayerFactory());
    this.getLayerFactories().registerFactory(new LinkLayerFactory());
    this.getLayerFactories().registerFactory(new SelectionBoxLayerFactory());
    this.getLayerFactories().registerFactory(new GridFactory());
    this.getLayerFactories().registerFactory(new TooltipFactory());

    this.getLabelFactories().registerFactory(new DefaultLabelFactory());
    this.getNodeFactories().registerFactory(new DefaultNodeFactory());
    this.getLinkFactories().registerFactory(new DefaultLinkFactory());
    this.getPortFactories().registerFactory(new DefaultPortFactory());

    this.getNodeFactories().registerFactory(new CircleNodeFactory());
    this.getPortFactories().registerFactory(new CirclePortFactory());
    this.getLinkFactories().registerFactory(new DirectionalLinkFactory());
    this.getLinkFactories().registerFactory(new StraightLinkFactory());
    this.getLabelFactories().registerFactory(new LabelFactory());

    this.getActionEventBus().registerAction(new ShowTooltipAction());
    this.getActionEventBus().registerAction(new ZoomCanvasAction());
    this.getStateMachine().pushState(this.states);
    this.setModel(this.graphModel);

    const handle = this.registerListener({
      canvasReady: () => {
        this.autolayoutGraph();
        handle.deregister();
      },
    });
  }

  async prepareData() {
    const info = await this.storage.getTransitionInformations();

    for (const trx of info) {
      const [, sourceBlock] = trx.sourceNodePath.split(":");
      const [, targetBlock] = trx.targetNodePath.split(":");

      if (sourceBlock !== "" && targetBlock !== "") continue;

      if (trx.transitionType === "BlockCall") {
        if (sourceBlock !== "") continue;
        if (this.graphModel.getNode(trx.sourceNodePath) == null) {
          const node = new CircleNodeModel(this, trx.sourceNodePath);
          this.graphModel.addNode(node);
          node.name = trx.fromNode;
        }

        const blockId = trx.sourceNodePath + "->" + trx.blockName;
        console.log("BlockCall", blockId);

        const block = new CircleNodeModel(this, blockId);
        this.graphModel.addNode(block);
        block.type = NodeType.Block;
        block.name = trx.blockName;

        const source = this.graphModel.getNode(trx.sourceNodePath) as CircleNodeModel;
        const link = new DirectionalLinkModel();
        link.setSourcePort(source.out);
        link.setTargetPort(block.in);
        link.addTransition(trx);

        this.graphModel.addLink(link);
        continue;
      }

      if (trx.transitionType === "BlockReturn") {
        // if (targetBlock !== "") continue;
        // const blockId = trx.targetNodePath + "->" + sourceBlock;
        // console.log("BlockReturn", blockId);

        // if (this.graphModel.getNode(blockId) == null) {
        //   const node = new CircleNodeModel(this, blockId);
        //   this.graphModel.addNode(node);
        //   node.name = sourceBlock;
        // }

        // if (this.graphModel.getNode(trx.targetNodePath) == null) {
        //   const node = new CircleNodeModel(this, trx.targetNodePath);
        //   this.graphModel.addNode(node);
        //   node.name = trx.toNode;
        // }

        // const source = this.graphModel.getNode(blockId) as CircleNodeModel;
        // const target = this.graphModel.getNode(trx.targetNodePath) as CircleNodeModel;
        // const link = new DirectionalLinkModel(trx);

        // console.log("return", { source, target, link });
        // link.setSourcePort(source.out);
        // link.setTargetPort(target.in);
        // this.graphModel.addLink(link);

        continue;
      }

      if (this.graphModel.getNode(trx.sourceNodePath) == null) {
        const node = new CircleNodeModel(this, trx.sourceNodePath);
        this.graphModel.addNode(node);
        node.name = trx.fromNode;
      }

      if (this.graphModel.getNode(trx.targetNodePath) == null) {
        const node = new CircleNodeModel(this, trx.targetNodePath);
        this.graphModel.addNode(node);
        node.name = trx.toNode;
      }

      const isHidden = trx.transitionType != "Normal" && trx.transitionType != "Goto";
      const cluster = await this.storage.getClustersByTransitionTableId(trx.id);
      const source = this.graphModel.getNode(trx.sourceNodePath) as CircleNodeModel;
      const target = this.graphModel.getNode(trx.targetNodePath) as CircleNodeModel;

      if (trx.transitionType === "Digression") {
        target.type = NodeType.Digression;
      }

      if (trx.transitionType === "Preprocessor") {
        target.type = NodeType.Preprocessor;
      }

      const findedLink = Object.values(source.out.getLinks()).find((link) => link.getTargetPort() === target.in);
      if (findedLink instanceof DirectionalLinkModel) {
        findedLink.addTransition(trx);
        continue;
      }

      const link = new DirectionalLinkModel(cluster?.title);
      link.setSourcePort(source.out);
      link.setTargetPort(target.in);
      link.setHidden(isHidden);
      link.addTransition(trx);

      this.graphModel.addLink(link);
    }
  }

  autolayoutGraph() {
    this.getCanvas().style.opacity = "0";
    setTimeout(() => {
      this.autolayout.redistribute(this.model, {
        allowLink: (link) => link.getOptions().extras.isHidden === false,
        includeLinks: true,
        graph: {
          rankdir: "LR",
          ranksep: 120,
          edgesep: 60,
        },
      });

      this.zoomToFitNodes({ margin: 100 });
      this.getCanvas().style.opacity = "1";
    }, 0);
  }

  closePreview() {
    const mainNode = this.graphModel.getNode(this.openedNode ?? "");

    this.setModel(this.graphModel);
    this.zoomToFitNodes({ margin: 200, nodes: mainNode ? [mainNode] : [] });
    this.openedNode = null;
  }

  revealTransition(link: DirectionalLinkModel | StraightLinkModel | null) {
    this._onDidSelectTransition.fire(link?.transitions[0] ?? null);
  }

  previewNode(source: CircleNodeModel) {
    const startNode = this.graphModel.getCircleNode(source.getID());
    if (startNode == null) return;
    this.openedNode = source.getID();

    const model = new DiagramModel();
    const transitions: TransitionInformation[][] = [];

    Object.values(startNode.in.getLinks()).map((link: DirectionalLinkModel) => {
      transitions.push(link.transitions);
    });

    Object.values(startNode.out.getLinks()).map((link: DirectionalLinkModel) => {
      transitions.push(link.transitions);
    });

    for (const trx of transitions) {
      if (model.getNode(trx[0].sourceNodePath) == null) {
        const node = new CircleNodeModel(this, trx[0].sourceNodePath);
        const origin = this.graphModel.getCircleNode(trx[0].sourceNodePath);
        node.customTransitions = origin?.transitions;
        node.customTooltip = origin?.tooltip;
        node.type = origin?.type ?? NodeType.Node;
        node.name = origin?.name ?? trx[0].sourceNodePath;
        node.freeDirections = true;
        model.addNode(node);
      }

      if (model.getNode(trx[0].targetNodePath) == null) {
        const node = new CircleNodeModel(this, trx[0].targetNodePath);
        const origin = this.graphModel.getCircleNode(trx[0].targetNodePath);
        node.customTransitions = origin?.transitions;
        node.customTooltip = origin?.tooltip;
        node.type = origin?.type ?? NodeType.Node;
        node.name = origin?.name ?? trx[0].targetNodePath;
        node.freeDirections = true;
        model.addNode(node);
      }

      const source = model.getNode(trx[0].sourceNodePath) as CircleNodeModel;
      const target = model.getNode(trx[0].targetNodePath) as CircleNodeModel;
      const origin = source.findLink(target);

      const link = new StraightLinkModel(origin?.phrase);
      link.setSourcePort(source.out);
      link.setTargetPort(target.in);
      trx.forEach((t) => link.addTransition(t));
      model.addLink(link);
    }

    this.getCanvas().style.opacity = "0";
    this.setModel(model);

    const nodes = model.getNodes().filter((n) => n.getID() !== startNode.getID());
    const angle = (Math.PI * 2) / nodes.length;
    nodes.forEach((el, i) => {
      const [x, y] = [300 * Math.cos(angle * i), 300 * Math.sin(angle * i)];
      el.setPosition(x, y);
    });

    setTimeout(() => {
      this.zoomToFitNodes({ margin: 200 });
      this.getCanvas().style.opacity = "1";
    }, 0);
  }
}

export default ProfilerGraph;
