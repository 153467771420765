import toAST from "json-to-ast";
import * as ast from "json-to-ast";
import { editor } from "monaco-editor";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import Project from "../../explorer/Project";
import TextStorage from "../TextStorage";
import SimpleTtsSpeaker from "../../SimpleTtsSpeaker";
import PlayPhrasePlugin, { ParseSynthParamsFunctionSignature } from "../plugins/PlayPhrasePlugin";
import StopTtsSpeakerPlugin from "../plugins/StopTtsSpeakerPlugin";

import { Phrasemap } from "./generated/phrasemap-types.d";

import { isPlayableData, findPathToPos, AstNodeToRange } from "./ast-helpers";
import { isIPhrase, isIArgumentId } from "./type-checkers";
import { SynthesizeVoiceOptions } from "../../SimpleTtsSpeaker";
import { OneOrManyPhrasemapEntrySingle } from "./generated/phrasemap-types.d";

interface Options {
  project: Project;
  path: string;
}

class PhrasemapStorage extends TextStorage {
  document: Phrasemap | null = null;
  ast: ast.ValueNode | null = null;
  speaker: SimpleTtsSpeaker | null;

  private playPhrasePlugin: PlayPhrasePlugin | null = null;
  private stopTtsSpeaker: StopTtsSpeakerPlugin | null = null;

  constructor(options: Options) {
    super(options.project, options.path, "phrasemap.json");
    this.isPinned = true;
    try {
      this.speaker = SimpleTtsSpeaker.Instance;
    } catch (e) {
      console.log(`Could not initialize tts speaker in phrasemap storage: ${e.message}`);
    }

    try {
      const speaker = SimpleTtsSpeaker.Instance;
      this.playPhrasePlugin = new PlayPhrasePlugin(speaker, this.tryParseSynthParams);
      this.stopTtsSpeaker = new StopTtsSpeakerPlugin(speaker);
    } catch (e) {
      console.log(`Could not initialize playPhrase plugin in DslStorage: ${e.message}`);
    }

    this.disposables.push(
      this.model.onDidChangeContent(async () => {
        try {
          this.ast = toAST(this.model.getValue());
          this.document = JSON.parse(this.model.getValue());
        } catch {
          this.document = null;
        }
      })
    );
  }

  openInEditor(editor: editor.IStandaloneCodeEditor): void {
    super.openInEditor(editor);
    this.playPhrasePlugin?.activate(editor);
    this.stopTtsSpeaker?.activate(editor);
  }
  closeInEditor(editor: monaco.editor.ICodeEditor): void {
    super.closeInEditor(editor);
    this.playPhrasePlugin?.dispose();
    this.stopTtsSpeaker?.dispose();
  }
  public getPhraseIds(): string[] {
    return Object.keys(this.document?.default?.phrases ?? {});
  }

  private tryParseSynthParams: ParseSynthParamsFunctionSignature = (position: monaco.Position) => {
    return new Promise((resolve, reject) => {
      const playableNode = this.tryFindLargestPlayableNode(position);
      const playableNodeRange = AstNodeToRange(playableNode);
      if (playableNodeRange === undefined) {
        reject(new Error(`Unexpected error: node range is undefined`));
        return;
      }
      let playableObject: OneOrManyPhrasemapEntrySingle = JSON.parse(this.model.getValueInRange(playableNodeRange));

      let voiceOptions: SynthesizeVoiceOptions = {};

      if (this.document === null) {
        reject(new Error(`Phrasemap is invalid.`));
        return;
      }

      if (!Array.isArray(playableObject)) {
        playableObject = [playableObject];
      }
      const validSinglePlayables = playableObject.map((singlePlayable) => {
        if (isIArgumentId(singlePlayable) || isIPhrase(singlePlayable)) {
          return singlePlayable;
        }
        return undefined;
      });
      if (validSinglePlayables.includes(undefined)) {
        reject(new Error(`Phrase contains invalid elements.`));
        return;
      }

      if (this.document.default.voiceInfo) {
        voiceOptions = this.document.default.voiceInfo;
      }
      /** override default voiceInfo params with specified in first phrase */
      const firstPraseVoiceInfo = validSinglePlayables[0]?.voiceInfo;
      if (firstPraseVoiceInfo) {
        voiceOptions = { ...voiceOptions, ...firstPraseVoiceInfo };
      }

      const text = validSinglePlayables
        .map((singlePlayable) => {
          if (isIPhrase(singlePlayable)) {
            return singlePlayable.text;
          } else if (isIArgumentId(singlePlayable)) {
            return singlePlayable.id;
          }
        })
        .join(" ");

      resolve({
        playableData: { text, voiceOptions },
        playableDataRanges: [playableNodeRange],
      });
    });
  };

  private tryFindLargestPlayableNode(position: monaco.Position): ast.ASTNode {
    if (this.ast == null) throw new Error(`Ast tree is null`);
    const path = findPathToPos(this.ast, position);
    console.log("path", path)
    let largestPlayableNode: ast.ASTNode | undefined;
    for (let i = 0; i < path.length; ++i) {
      const node = path[i];
      const nodeRange = AstNodeToRange(node);
      if (nodeRange === undefined) continue;
      const nodeText = this.model.getValueInRange(nodeRange);
      try {
        const parsed = JSON.parse(nodeText);
        if (!isPlayableData(parsed)) continue;
        /** if object's parent is 'random' then it is not playable, skip it */
        if (path[i - 1]?.key?.value === "random") continue;
        largestPlayableNode = node;
        break;
      } catch (e) {
        continue;
      }
    }
    if (largestPlayableNode === undefined) {
      throw new Error(`Could not find any playable ast node`);
    }
    return largestPlayableNode;
  }
}

export default PhrasemapStorage;
