import styled from "styled-components";

import { Badge as TriggerBadge } from "../../../../../RunnerPanel/TriggersEdit/styled";

export const Container = styled.div`
  font-family: var(--main-font);
  font-size: 13px;
  line-height: 24px;
  color: #d9d9e2;

  > div {
    padding: 0;
  }
`;

export const Badge = styled.span`
  box-sizing: border-box;

  display: inline-block;

  width: 16px;
  height: 16px;

  font-weight: 600;
  font-size: 9px;
  line-height: 15px;

  text-align: center;

  color: rgba(255, 255, 255, 0.5);

  margin-left: 6px;

  background: rgba(255, 255, 255, 0.16);
  border-radius: 50%;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;

  .search-text {
    background: rgba(255, 182, 0, 0.16);
    border-radius: 2px;
  }
`;

export const Label = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;

  position: relative;

  font-weight: 600;

  background-color: #252536;

  min-height: 32px;

  box-sizing: border-box;

  border: 1px solid rgba(255, 255, 255, 0.06);

  cursor: pointer;
`;

export const LabelBtn = styled.div<{ $expanded: boolean }>`
  display: flex;
  align-items: stretch;
  flex: 1;

  width: 100%;
  max-width: 452px;
  min-width: 452px;

  padding: 4px 6px;
  border-right: 1px solid rgba(255, 255, 255, 0.06);

  background: none;

  svg {
    transform: rotate(${(props) => (props.$expanded ? 0 : -90)}deg);
    margin-right: 2px;
  }

  & > span {
    display: flex;
    align-items: flex-start;
  }

  & > span:first-child,
  & > span:last-child {
    padding-top: 4px;
  }
`;

export const Data = styled.div`
  display: flex;

  /* max-width: 396px; */

  flex: 1;

  padding: 8px;

  border-right: 1px solid rgba(255, 255, 255, 0.06);

  & > span:first-child {
    display: flex;
    align-items: flex-start;
    margin-right: 6px;
  }

  & > span:last-child {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    > * {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }

  ${TriggerBadge} {
    border: none;

    &:hover {
      border: none;
    }
  }
`;

export const SubTable = styled.div`
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;

  > div:nth-child(2n + 1) {
    background-color: #1b1c2d;
  }

  > div:nth-child(2n) {
    background-color: #161729;
  }
`;
