import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface WelcomeScreenProps {
  onStartBuilding: () => void;
}

const WelcomeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  background: #0F172A;
  grid-area: projects;
`;

const ContentWrapper = styled.div`
  max-width: 32rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const Hero = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #FFFFFF;
`;

const ActionButton = styled.button<{ primary?: boolean }>`
  width: 100%;
  padding: 24px;
  background: ${props => props.primary ? '#4F46E5' : '#1E293B'};
  border-radius: 12px;
  border: none;
  color: white;
  text-align: left;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: ${props => props.primary ? '#4338CA' : '#334155'};
    transform: translateY(-2px);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
`;

const ButtonTitle = styled.span`
  font-weight: 500;
`;

const ButtonDescription = styled.p<{ primary?: boolean }>`
  font-size: 14px;
  margin: 0;
  color: ${props => props.primary ? '#C7D2FE' : '#94A3B8'};
  padding-left: 32px;
`;

const DocLink = styled.a`
  font-size: 14px;
  color: #94A3B8;
  text-decoration: none;
  text-align: center;
  display: block;
  
  &:hover {
    color: #CBD5E1;
  }
`;

const ActionGrid = styled.div`
  display: grid;
  gap: 16px;
`;

export function WelcomeScreen({ onStartBuilding }: WelcomeScreenProps) {
  return (
    <WelcomeContainer>
      <ContentWrapper>
        <Hero>
          <Title>Welcome to Dasha Studio</Title>
        </Hero>

        <ActionGrid>
          <ActionButton primary onClick={onStartBuilding}>
            <ButtonContent>
              <i className="icon rocket" />
              <ButtonTitle>Start Building</ButtonTitle>
            </ButtonContent>
            <ButtonDescription primary>
              Explore templates and create your first AI agent
            </ButtonDescription>
          </ActionButton>

          <ActionButton onClick={() => window.open('https://calendly.com/vlad-chernyshov/onboarding-session', '_blank')}>
            <ButtonContent>
              <i className="icon users" />
              <ButtonTitle>Schedule Onboarding</ButtonTitle>
            </ButtonContent>
            <ButtonDescription>
              Get guided help from our team
            </ButtonDescription>
          </ActionButton>
        </ActionGrid>

        <DocLink 
          href="https://docs.dasha.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read the documentation
        </DocLink>
      </ContentWrapper>
    </WelcomeContainer>
  );
} 