import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement } from "../common";
import { OnExitNamedDescription } from "./OnExitNamedDescription";

export class OnExitSection extends DslElement implements interfaces.IOnExitSection {
  elementType: DslElementType.OnExitSection = DslElementType.OnExitSection;
  descriptions: OnExitNamedDescription<string>[];
  default: interfaces.OptionalValue<OnExitNamedDescription<"default">>;
  digression: interfaces.OptionalValue<OnExitNamedDescription<"digression">>;

  constructor(obj: interfaces.IOnExitSection, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.descriptions = helpers.ensureIsArrayOfInstances(obj.descriptions, OnExitNamedDescription<string>);
    this.default = helpers.ensureIsOptionalInstance(obj.default, OnExitNamedDescription<"default">);
    this.digression = helpers.ensureIsOptionalInstance(obj.digression, OnExitNamedDescription<"digression">);
  }
  toJson(): interfaces.IOnExitSection {
    return {
      elementType: this.elementType,
      location: this.location,
      descriptions: this.descriptions.map((el) => el.toJson()),
      default: interfaces.helpers.createOptionalValue(this.default.value?.toJson()),
      digression: interfaces.helpers.createOptionalValue(this.digression.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        helpers.indentString(indent) + "onexit {",
        ...this.descriptions.map((el) => el.toDsl(indent + 1)),
        this.default.hasValue ? this.default.value.toDsl(indent + 1) : null,
        this.digression.hasValue ? this.digression.value.toDsl(indent + 1) : null,
        helpers.indentString(indent) + "}",
      ],
      "\n"
    );
  }
}
