import * as React from "react";
import { randomUUID } from "crypto";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";

import CircleNodeModel from "./Model";
import NodeWidget from "./Widget";

class CircleNodeFactory extends AbstractReactFactory<CircleNodeModel, DiagramEngine> {
  constructor() {
    super(CircleNodeModel.TYPE);
  }

  generateReactWidget(event) {
    return <NodeWidget engine={this.engine} node={event.model} />;
  }

  generateModel(event) {
    return new CircleNodeModel(this.engine, randomUUID(), 0);
  }
}

export default CircleNodeFactory;
