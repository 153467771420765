import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement, RawElement } from "../common";
import { GroupCommandStatement } from "../do-section-statements";
import { OnExitSection, TransitionSection } from "../sections";

export class Node extends DslElement implements interfaces.INode {
  elementType: DslElementType.Node = DslElementType.Node;
  isStartNode: boolean;
  name: RawElement<string>;
  commentSection: interfaces.OptionalValue<RawElement<string>>;
  doSection: GroupCommandStatement;
  transitionSection: interfaces.OptionalValue<TransitionSection>;
  onExitSection: interfaces.OptionalValue<OnExitSection>;

  constructor(obj: interfaces.INode, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.isStartNode = obj.isStartNode;
    this.commentSection = helpers.ensureIsOptionalInstance(obj.commentSection, RawElement<string>);
    this.doSection = helpers.ensureIsInstance(obj.doSection, GroupCommandStatement);
    this.transitionSection = helpers.ensureIsOptionalInstance(obj.transitionSection, TransitionSection);
    this.onExitSection = helpers.ensureIsOptionalInstance(obj.onExitSection, OnExitSection);
  }
  toJson(): interfaces.INode {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      isStartNode: this.isStartNode,
      commentSection: interfaces.helpers.createOptionalValue(this.commentSection.value?.toJson()),
      doSection: this.doSection.toJson(),
      transitionSection: interfaces.helpers.createOptionalValue(this.transitionSection.value?.toJson()),
      onExitSection: interfaces.helpers.createOptionalValue(this.onExitSection.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        helpers.indentString(indent) + `${this.isStartNode ? "start node " : "node "}${this.name.value} {`,
        this.commentSection.hasValue ? helpers.indentString(indent + 1) + `comment ${this.commentSection.value.toDsl(0)}` : null,
        helpers.indentString(indent + 1) + "do ",
        this.doSection.toDsl(indent + 1),
        this.transitionSection.value?.toDsl(indent + 1),
        this.onExitSection.value?.toDsl(indent + 1),
        helpers.indentString(indent) + "}",
      ],
      "\n"
    );
  }
}
