import React, { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";

import useNodeToCenter from "./useNodeToCenter";
import * as S from "./styled";

export default memo<NodeProps<any>>(({ data, xPos, yPos, selected, isConnectable }) => {
  useNodeToCenter(xPos, yPos, data.highlight);

  return (
    <S.Node className="drag-handle" style={{ width: 140 }} isSelected={selected || data.highlight}>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{ top: "50%", left: -16, width: 12, height: 12, background: "#555" }}
        isConnectable={isConnectable}
      />
      <S.Label>SET CONTEXT</S.Label>
      <S.Select value={data.contextKey} onChange={(e) => data.update({ contextKey: e.target.value })}>
        {data.context.length == 0 && <option>No context</option>}
        {data.context.map((variable) => (
          <option key={variable.id}>{variable.key}</option>
        ))}
      </S.Select>

      <S.Input
        onChange={(e) => data.update({ contextValue: e.target.value })}
        style={{ marginTop: 4, width: "100%" }}
        value={data.contextValue}
      />
    </S.Node>
  );
});
