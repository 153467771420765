import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as utils from "../helpers";
import { DslElement, RawElement } from "../common";
export class TypeAlias extends DslElement implements interfaces.ITypeAlias {
  elementType: DslElementType.TypeAlias = DslElementType.TypeAlias;
  name: RawElement<string>;
  type: RawElement<string>;

  constructor(obj: interfaces.ITypeAlias, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = utils.ensureIsInstance(obj.name, RawElement<string>);
    this.type = utils.ensureIsInstance(obj.type, RawElement<string>);
  }
  toJson(): interfaces.ITypeAlias {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      type: this.type.toJson(),
    };
  }
  toDsl(indent: number): string {
    return helpers.indentString(indent) + `type ${this.name.toDsl(indent)} = ${this.type.toDsl(indent)};`
  }
}
