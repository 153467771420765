const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const formatDate = (timestamp: number, withMonth = false): string => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const fh = hours < 10 ? "0" + hours : hours;
  const fm = minutes < 10 ? "0" + minutes : minutes;
  const fs = seconds < 10 ? "0" + seconds : seconds;

  let month = "";
  if (withMonth) {
    month = `(${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()})`;
  }

  return fh + ":" + fm + ":" + fs + " " + ampm + " " + month;
};

export const toDateTime = (time: string | number) => {
  const date = new Date(time);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
} 

export const formatSpentTime = (date: Date) => {
  const MINUTES = 1000 * 60
  const DAY = MINUTES * 60 * 24;

  if (Date.now() - +date <= DAY) {
    const minutes = Math.round((Date.now() - +date) / MINUTES);
    if (minutes === 0) return `now`;
    return minutes >= 60 ? `${Math.round(minutes / 60)} hours ago` : `${minutes} minutes ago`
  }
  
  return `${Math.round((Date.now() - +date) / DAY)} days ago`;
};

export const formatDuration = (seconds: number): string => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};
