import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "../core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button, Pagination, DropdownItemProps } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import { createDownloadLink } from "../core/profiler/utils";
import { Link, useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";



const getColumnValue = (columnName: string, obj: any) => {
  const subcols = columnName.split(".");
  for (var c of subcols) {
    if (obj === undefined || obj === null) {
      break;
    }
    obj = obj[c];
  }
  return JSON.stringify(obj);
}

const isObject = function(a) {
  return (!!a) && (a.constructor === Object);
};

const buildColumnListRecursive = (obj: any, target: Set<string>, root: string, depth: number) => {
  const columns = obj;
  for (const currentColumn of Object.keys(obj)) {
    const fullPath = root != "" ? `${root}.${currentColumn}` : currentColumn;
    target.add(fullPath);
    if (isObject(obj[currentColumn]) && depth + 1 < 2) {
      buildColumnListRecursive(obj[currentColumn], target, fullPath, depth + 1);
    }
  }
}

const availableStatuses: DropdownItemProps[] = [{text: "Failed", value: "Failed"}, {text: "Completed", value: "Completed"}];

export const OnlineProfilerTable: FC<{ filters: dasha.filters.IFilter[], jobIds: string[], page_size: number, download_name: string }> = observer(({ filters, jobIds, page_size, download_name }) => {
  const { account } = useStore();
  const [dataRows, setDataRows] = useState<dasha.onlineprofiler.ProfilerEntity[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<{key: string; value: string; text: string;}[]>([]);

  const [selectedColumns, setSelectedColumns] = useState(["completedTime", "applicationName"]);

  useEffect(() => {
    if (filters.length === 0 && jobIds.length == 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      const a = account.connect();
      const innerFilter = [...filters];
      if (jobIds.length > 0) {
        innerFilter.push(new dasha.filters.IdsFilter(jobIds.slice((page-1) * page_size, page*page_size)));
      }

      const result = await dasha.onlineprofiler.getData(page_size, jobIds.length == 0 ? (page - 1)*page_size: 0, innerFilter, { account: a });
      if (jobIds.length == 0) {
        if (result.length < page_size) {
          setTotalPages(page);
        } else {
          setTotalPages(page + 1);
        }
      } else {
        setTotalPages(Math.max(1, Math.floor((jobIds.length + page_size - 1)/page_size)));
      }
      setDataRows(result);

      const newCols = new Set<string>();
      
      for (const row of result) {
        buildColumnListRecursive(row, newCols, "", 0);
      }
      setColumns([...newCols].map((x) => ({ key: x, text: x, value: x })));
    };
    fetchData().finally(() => setLoading(false));
  }, [account, filters, page, jobIds]);


  const exportToCsv = useCallback(() => {
    const fetchData = async () => {
      const a = account.connect();
      const csvData = await dasha.onlineprofiler.getCsvBlob(filters, { account: a });
      createDownloadLink([csvData], "text/csv", download_name);
    };
    fetchData().finally(() => setLoading(false));
    
  }, [account, filters, download_name]);


  return (
    <>
      <h2>Columns</h2>
      <Dropdown
        placeholder="Columns"
        fluid
        multiple
        search
        selection
        options={columns}
        value={selectedColumns}
        onChange={(e, { value }) => setSelectedColumns(value as string[])}
      />

      <Loader active={loading}>Loading..</Loader>
      {download_name !== "" &&<Button onClick={exportToCsv} disabled={loading}> Download CSV </Button>}
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Inspect</Table.HeaderCell>
            {selectedColumns.map((c) => (
              <Table.HeaderCell>{ c }</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(dataRows).map(([k, c]) => {
            return (
              <Table.Row negative={c.taskStatus === "Failed"}>
                <Table.Cell><Link to={`/inspector/${c.jobId}`} target="_blank" rel="noopener noreferrer">Inspect</Link></Table.Cell>
                {selectedColumns.map((col) => (
                  <Table.Cell>{ getColumnValue(col, c) }</Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>

          </Table.Row>
        </Table.Footer>
      </Table>
      <Pagination activePage={page} totalPages={totalPages} onPageChange={(e, value) => setPage(value.activePage as number)} />
    </>
  );
});
