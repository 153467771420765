import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";

export class IsExpression extends Expression implements interfaces.IIsExpression {
  elementType: DslElementType.IsExpression = DslElementType.IsExpression;
  expression: Expression;
  not: boolean;
  checkType: RawElement<string>;

  constructor(obj: interfaces.IIsExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.expression = helpers.ensureIsExpressionInstance(obj.expression);
    this.not = obj.not;
    this.checkType = helpers.ensureIsInstance(obj.checkType, RawElement<string>);
  }
  toJson(): interfaces.IIsExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      expression: this.expression.toJson(),
      not: this.not,
      checkType: this.checkType.toJson(),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [this.expression.toDsl(indent), "is", this.not ? "not" : null, this.checkType.value],
      " "
    );
  }
}
