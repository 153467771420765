import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DoStatement } from "./DoStatement";
import { Expression } from "../expressions";
import { RawElement } from "../common";

export class AssignmentStatement extends DoStatement implements interfaces.IAssignmentStatement {
  elementType: DslElementType.AssignmentStatement = DslElementType.AssignmentStatement;
  lValue: RawElement<string>;
  rValue: Expression;
  operation: "=" | "+=" | "-=";

  constructor(obj: interfaces.IAssignmentStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.lValue = helpers.ensureIsInstance(obj.lValue, RawElement<string>);
    this.rValue = helpers.ensureIsExpressionInstance(obj.rValue);
    this.operation = obj.operation;
  }
  toJson(): interfaces.IAssignmentStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      lValue: this.lValue.toJson(),
      rValue: this.rValue.toJson(),
      operation: this.operation,
    };
  }
  toDsl(indent: number): string {
    return [helpers.indentString(indent), "set ", `${this.lValue.value} ${this.operation} ${this.rValue.toDsl(indent)}`, ";"].join("");
  }
}
