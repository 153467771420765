import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Modal, Input, Dropdown, Message, Icon, Confirm } from "semantic-ui-react";
import "./styles.css";
import { OutboundConfig } from "@dasha.ai/sdk/web/sip/outbound-configs";

function GetTrigger(existingName: string | undefined) {
  if (existingName === undefined) {
    return <Button>Add configuration</Button>;
  }
  return <Icon name="edit" />;
}
export const OutboundVoIPEditor: FC<{
  account: IAccount;
  customerId: string | undefined;
  existingName: string | undefined;
  onUpdate: (name: string, cfg: OutboundConfig | undefined) => void;
}> = ({ account, customerId, existingName, onUpdate }) => {
  const transports = Array.from(["tcp", "udp"]).map((x) => ({ text: x, value: x, key: x }));
  const [open, setOpen] = React.useState(false);
  const [authUser, setAuthUser] = React.useState<string | undefined>(undefined);
  const [fromUser, setFromUser] = React.useState<string | undefined>(undefined);
  const [cfgName, setCfgName] = React.useState(existingName);
  const [server, setServer] = React.useState<string | undefined>(undefined);
  const [domain, setDomain] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined | null>(undefined);
  const [transport, setTransport] = React.useState<"tcp" | "udp">("udp");
  const [error, setError] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [originalConfig, setOriginalConfig] = React.useState<OutboundConfig | undefined>();
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  React.useEffect(() => {
    if (existingName === undefined) {
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = account.connectAs(customerId);
        const result = await dasha.sip.outboundConfigs.getConfigByName(existingName, { account: a });
        setDomain(result.domain);
        setServer(result.server);
        setAuthUser(result.account);
        setTransport(result.transport);
        setFromUser(result.fromUser);
        setLoading(false);
        setOriginalConfig(result);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData().catch((e) => console.error(e));
  }, [account, existingName]);

  const addCb = React.useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        if (authUser === undefined) {
          throw new Error("Auth user is required");
        }
        if (server === undefined) {
          throw new Error("Server is required");
        }
        if (transport === undefined) {
          throw new Error("Transport is required");
        }
        const a = account.connectAs(customerId);
        const result = await dasha.sip.outboundConfigs.createConfig(
          cfgName!!,
          {
            account: authUser,
            server: server,
            transport: transport,
            domain: domain,
            password: password ?? undefined,
            fromUser: fromUser === "" ? undefined : fromUser,
          },
          {
            account: a,
          }
        );
        onUpdate(cfgName!!, result);
        setOpen(false);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [account, setError, setLoading, password, cfgName, transport, domain, authUser, fromUser, onUpdate]);

  const deleteCb = React.useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = account.connectAs(customerId);
        const result = await dasha.sip.outboundConfigs.deleteConfig(existingName!!, {
          account: a,
        });
        onUpdate(existingName!!, undefined);
        setOpen(false);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e.Message);
      });
  }, [account, setError, setLoading, existingName]);

  const updateCb = React.useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = account.connectAs(customerId);
        const result = await dasha.sip.outboundConfigs.updateConfig(
          existingName!!,
          {
            account: authUser,
            server: server,
            transport: transport,
            domain: domain,
            password: password,
            fromUser: fromUser === "" ? null : fromUser,
          },
          {
            account: a,
          }
        );
        onUpdate(existingName!!, result);
        setOpen(false);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [
    account,
    setError,
    setLoading,
    password,
    cfgName,
    existingName,
    authUser,
    fromUser,
    transport,
    domain,
    server,
    originalConfig,
    onUpdate,
  ]);

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={GetTrigger(existingName)}>
      <Modal.Header>Outbound VoIP Configuration</Modal.Header>
      {error && <Message negative>{error}</Message>}
      <Modal.Content>
        <h3>Name</h3>
        <Input
          disabled={existingName !== undefined}
          placeholder="Configuration name"
          value={cfgName}
          onChange={(e, { value }) => setCfgName(value)}
        />
        <h3>Server (IP or DNS name)</h3>
        <Input placeholder="my-name.pstn.twilio.com" value={server} onChange={(e, { value }) => setServer(value)} />
        <h3>Domain (Optional DNS name)</h3>
        <Input placeholder="my-name.pstn.twilio.com" value={domain} onChange={(e, { value }) => setDomain(value)} />

        <h3>Transport</h3>
        <Dropdown
          placeholder="Select Priority"
          fluid
          search
          selection
          options={transports}
          value={transport}
          onChange={(e, { value }) => setTransport(value)}
        />

        <h3>Auth user (DID in most cases)</h3>
        <Input
          placeholder="UserName"
          value={authUser}
          onChange={(e, { value }) => {
            setAuthUser(value);
          }}
        />

        <h3>From user (Optional, DID in most cases)</h3>
        <Input
          placeholder="+YXXXXXXXXXX"
          value={fromUser}
          onChange={(e, { value }) => {
            setFromUser(value);
          }}
        />

        <h3>Password (Optional)</h3>
        <Input
          placeholder="Password"
          value={password}
          onChange={(e, { value }) => {
            setPassword(value);
          }}
          type="password"
        />
        <Button
          content="Clear password"
          labelPosition="right"
          onClick={() => setPassword(null)}
          disabled={password === null}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setOpen(false)}>
          Cancel
        </Button>
        {existingName === undefined && (
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={() => addCb()}
            positive
            disabled={cfgName === undefined}
          />
        )}
        {existingName !== undefined && (
          <>
            <Button content="Update" labelPosition="right" icon="checkmark" onClick={() => updateCb()} positive />
            <Button negative onClick={() => setConfirmDelete(true)}>
              Delete
            </Button>
            <Confirm
              content={`Are you sure Delete ${existingName}`}
              open={confirmDelete}
              onCancel={() => setConfirmDelete(false)}
              onConfirm={() => deleteCb()}
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};
