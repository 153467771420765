import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

import { DoStatement } from "./DoStatement";
import { RawElement } from "../common";

export class WaitStatement extends DoStatement implements interfaces.IWaitStatement {
  elementType: DslElementType.WaitStatement = DslElementType.WaitStatement;
  transitions: interfaces.OptionalValue<RawElement<string>[]>;

  constructor(obj: interfaces.IWaitStatement, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    if (obj.transitions.hasValue) {
      this.transitions = {
        hasValue: true,
        value: helpers.ensureIsArrayOfInstances(obj.transitions.value, RawElement<string>),
      };
    } else {
      this.transitions = {
        hasValue: false,
        value: null,
      };
    }
  }
  toJson(): interfaces.IWaitStatement {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      transitions: interfaces.helpers.createOptionalValue(this.transitions.value?.map((el) => el.toJson())),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent),
      "wait ",
      this.transitions.hasValue
        ? helpers.indentList(
            this.transitions.value.map((el) => el.toDsl(indent)),
            indent
          )
        : "*",
      ";",
    ].join("");
  }
}
