import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table } from "semantic-ui-react";
import "./styles.css";
import { useParams } from "react-router-dom";
import { useStore } from "../core/api/GlobalStoreContext";


export const CustomerInfo: FC<{ }> = observer(() => {
  const { customer } = useStore();
  useEffect(() => {
    customer.TryReload();
  }, []);

  return (
    <>
      {customer.Info && customer.Info.state === "fulfilled" && (
        <>
          <h2>Customer info</h2>
          <Table celled>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Name
                    </Table.Cell>
                    <Table.Cell>
                        {customer.Info.value.name}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Id
                    </Table.Cell>
                    <Table.Cell>
                        {customer.Info.value.id}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Max Concurrency
                    </Table.Cell>
                    <Table.Cell>
                        {customer.Info.value.maxConcurrency}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
});

