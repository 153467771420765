import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { DslElement } from "../common";
import { PositionalArgument } from "./PositionalArgument";
import { NamedArgument } from "./NamedArgument";
import * as helpers from "../helpers";

export class FunctionArguments extends DslElement implements interfaces.IFunctionArguments {
  elementType: DslElementType.FunctionArguments = DslElementType.FunctionArguments;
  positional: PositionalArgument[];
  named: NamedArgument[];

  constructor(obj: interfaces.IFunctionArguments, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.positional = helpers.ensureIsArrayOfInstances(obj.positional, PositionalArgument);
    this.named = helpers.ensureIsArrayOfInstances(obj.named, NamedArgument);
  }

  toJson(): interfaces.IFunctionArguments {
    return {
      elementType: this.elementType,
      location: this.location,
      positional: this.positional.map((arg) => arg.toJson()),
      named: this.named.map((arg) => arg.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [...this.positional.map((arg) => arg.toDsl(indent)), ...this.named.map((arg) => arg.toDsl(indent))].join(", ");
  }
}
