import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";
import { ObjectProperty } from "./ObjectProperty";

export class ObjectExpression extends Expression implements interfaces.IObjectExpression {
  elementType: DslElementType.ObjectExpression = DslElementType.ObjectExpression;
  properties: ObjectProperty[];

  constructor(obj: interfaces.IObjectExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.properties = helpers.ensureIsArrayOfInstances(obj.properties, ObjectProperty);
  }
  toJson(): interfaces.IObjectExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      properties: this.properties.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return helpers.indentList(this.properties.map(prop => prop.toDsl(indent)), indent);
    return ["{", this.properties.map((prop) => prop.toDsl(indent)).join(", "), "}"].join("");
  }
}
