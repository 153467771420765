import React, { useRef, useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";

import Workspace from "../core/workspace/Workspace";
import { AudioControllerProtocol } from "../core/SIPClient";
import { LoadingStep, RunnerType } from "../core/workspace/session-storage/types";

import { ActionButton, ChooseButton, Icon, StrokeButton, PhoneInput } from "../uikit";
import { PureButton } from "../uikit/Button";
import { ReactComponent as SkeletonSvg } from "./skeleton.svg";

import { formatDuration } from "./helpers";
import useSandbox from "./useSandbox";
import SessionControls from "./SessionControls";
import FieldInput from "./FieldInput";
import Session from "./Session";
import Loading from "./Loading";
import * as S from "./styled";
import { PaymentRequired } from "../widgets/TrialRequiredWidget";
import { CallPermissionRequired } from "../widgets/CallPermissionRequired";
import { IAccount } from "../core/account/interface";

// Custom hook for feature checking
const useFeatureCheck = (account: IAccount | null) => {
  const [isPSTNEnabled, setIsPSTNEnabled] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkFeature = async () => {
      if (!account) {
        setIsLoading(false);
        return;
      }

      try {
        console.log('Checking PSTN calls feature...');
        const a = account.connect();

        const result = await dasha.customer.checkFeature("pstn_calls", { account: a });
        console.log('PSTN calls feature check result:', result);
        setIsPSTNEnabled(result.isEnabled);
      } catch (error) {
        console.error('Failed to check PSTN feature:', error);
        setIsPSTNEnabled(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkFeature();
  }, [account]);

  return { isPSTNEnabled, isLoading };
};

interface Props {
  workspace: Workspace;
  audioController: AudioControllerProtocol;
}

export const ConversationRunner = observer<Props>(({ audioController, workspace }) => {
  const { isPSTNEnabled, isLoading } = useFeatureCheck(workspace.account);
  const [phone, setPhone] = useState<string>("");
  const [pickPhoneView, setPickPhoneView] = useState(false);
  const microIconRef = useRef<HTMLSpanElement>(null);

  const session = workspace.sessions?.activeSession;
  const isHideControls =
    (workspace.isRunning && session !== workspace.sessions?.lastSession) ||
    workspace.sessions?.activeSession?.isReadOnly;

  const setupRun = async (type: RunnerType, endpoint?: string) => {
    if (type === RunnerType.Call) return setPickPhoneView(true);
    await workspace.run(type, endpoint);
  };

  const runCall = async () => {
    await workspace.run(RunnerType.Call, phone);
    setPickPhoneView(false);
  };

  const paymentNotRequired = useCallback(
    () => {
      const sess = workspace.sessions?.activeSession;
      if (sess !== undefined && sess !== null)
          sess.paymentRequired = false;
    }, [workspace]
  );

  const callPermissionNotRequired = useCallback(
    () => {
      const sess = workspace.sessions?.activeSession;
      if (sess !== undefined && sess !== null)
          sess.callPermissionRequired = false;
    }, [workspace]
  );

  useEffect(() => {
    const handler = audioController.onFrequencyDataFromMicrophone((buffer) => {
      const dynamic = (buffer.reduce((acc, value) => acc + value, 0) / (buffer.length * 256)) * 20;
      if (microIconRef.current) {
        microIconRef.current.style.opacity = Math.max(0.5, dynamic).toString();
      }
    });

    return () => handler.dispose();
  }, [microIconRef.current]);

  if (session == null) {
    return (
      <S.RunnerMenu>
        <p>Try using a browser window</p>
        <ActionButton onClick={() => workspace.run(RunnerType.Audio)}>Speak to Dasha</ActionButton>
        <StrokeButton onClick={() => workspace.run(RunnerType.Text)}>Text chat with Dasha</StrokeButton>

        {!isLoading && isPSTNEnabled && (
          <>
            <p>or</p>
            <PhoneInput style={{ height: 40 }} value={phone} onChange={setPhone} />
            <ActionButton disabled={phone.length < 8} onClick={() => workspace.run(RunnerType.Call, phone)}>
              Get a call
            </ActionButton>
          </>
        )}
      </S.RunnerMenu>
    );
  }

  if (pickPhoneView) {
    return (
      <S.Container>
        <Session session={session} workspace={workspace} allowTransitionsDebug={workspace?.sessions?.allowTransitionDebug}/>
        <S.Control hidden={isHideControls}>
          <PureButton style={{ height: "100%", marginRight: 8 }} onClick={() => setPickPhoneView(false)}>
            <Icon name="cursor-back" />
          </PureButton>
          <S.ControlFlex style={{ height: 32 }}>
            <PhoneInput style={{ height: 32 }} value={phone} onChange={setPhone} />
          </S.ControlFlex>
          <S.CallButton disabled={phone.length < 8} $state="start" onClick={runCall}>
            <Icon name="call" />
          </S.CallButton>
        </S.Control>
      </S.Container>
    );
  }

  if (workspace.isRunning && !session?.timeStarted && isHideControls == false) {
    return <Loading status={session.status} onStop={() => workspace.stop()} />;
  }

  if (session.paymentRequired) {
    return <PaymentRequired onClose={paymentNotRequired} />
  }

  if (session.callPermissionRequired) {
    return <CallPermissionRequired onClose={callPermissionNotRequired} />
  }

  if (session.timeEnded) {
    return (
      <S.Container>
        <Session session={session} workspace={workspace} allowTransitionsDebug={workspace?.sessions?.allowTransitionDebug}/>
        <S.Control hidden={isHideControls}>
          <ChooseButton
            onChange={(type) => setupRun(type)}
            value={session.type}
            items={[
              ...(isPSTNEnabled ? [
                { value: RunnerType.Call, label: "Make a phone call", button: "re-start call" },
              ] : []),
              { value: RunnerType.Audio, label: "Talk to Dasha in a browser", button: "re-start talk" },
              { value: RunnerType.Text, label: "Chat to Dasha in a browser", button: "re-start chat" },
              { value: RunnerType.Test, label: "Run a test like this conversation", button: "re-start test" },
            ]}
          />
        </S.Control>
      </S.Container>
    );
  }

  if (session.type === RunnerType.Audio) {
    return (
      <S.Container>
        <Session session={session} workspace={workspace} allowTransitionsDebug={workspace?.sessions?.allowTransitionDebug}/>
        <S.Control hidden={isHideControls}>
          <S.ControlFlex>
            <span ref={microIconRef}>
              <Icon name="microphone" />
            </span>
            <S.CallDuration>Talk duration: {formatDuration(session.duration)}</S.CallDuration>
          </S.ControlFlex>
          <S.StopButton onClick={() => workspace.stop()} />
        </S.Control>
      </S.Container>
    );
  }

  if (session.type === RunnerType.Text) {
    return (
      <S.Container>
        <Session session={session} workspace={workspace} allowTransitionsDebug={workspace?.sessions?.allowTransitionDebug}/>
        <S.Control hidden={isHideControls}>
          <S.ControlFlex>
            <FieldInput isLoading={session.isSending} onSubmit={(value) => void session.send(value)} />
          </S.ControlFlex>
          <S.StopButton onClick={() => workspace.stop()} />
        </S.Control>
      </S.Container>
    );
  }

  if (session.type === RunnerType.Test) {
    return (
      <S.Container>
        <Session session={session} workspace={workspace} allowTransitionsDebug={workspace?.sessions?.allowTransitionDebug}/>
        <S.Control hidden={isHideControls}>
          <S.StopButton onClick={() => workspace.stop()} />
        </S.Control>
      </S.Container>
    );
  }

  if (session.type === RunnerType.Call) {
    return (
      <S.Container>
        <Session session={session} workspace={workspace} allowTransitionsDebug={workspace?.sessions?.allowTransitionDebug}/>
        <S.Control hidden={isHideControls}>
          <S.ControlFlex style={{ height: 32 }}>
            <PhoneInput style={{ width: "fit-content" }} value={phone} />
            <S.CallDuration>Call duration: {formatDuration(session.duration)}</S.CallDuration>
          </S.ControlFlex>
          <S.CallButton $state="end" onClick={() => workspace.stop()}>
            <Icon name="call" />
          </S.CallButton>
        </S.Control>
      </S.Container>
    );
  }

  return null;
});

const ConversationRunnerPanel = observer<Props & { id: string; isOpen: boolean; onChangeOpen: (v: boolean) => void }>(
  ({ id, isOpen, onChangeOpen, audioController, workspace }) => {
    const session = workspace.sessions?.activeSession;
    const sandboxRef = useSandbox(session);

    if (workspace.project == null || workspace.sessions == null) {
      return (
        <S.Panel id={id} isOpen={isOpen}>
          {isOpen && <SkeletonSvg style={{ margin: "auto" }} />}
        </S.Panel>
      );
    }

    return (
      <S.Panel id={id} isOpen={isOpen}>
        <S.ToggleButton onClick={() => onChangeOpen(!isOpen)}>
          <Icon name="arrow-left" angle={isOpen ? -180 : 0} />
        </S.ToggleButton>

        <SessionControls storage={workspace.sessions} />
        <ConversationRunner audioController={audioController} workspace={workspace} />
        <S.SandboxContainer ref={sandboxRef} />
      </S.Panel>
    );
  }
);

export default ConversationRunnerPanel;
