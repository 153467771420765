import type { OnResolveArgs, OnResolveResult, Plugin, PluginBuild } from "esbuild-wasm";
import type { BundleConfigOptions } from "../configs/options";
import type { EVENTS } from "../configs/events";
import type { STATE } from "../configs/state";

import { parsePackageName } from "../utils/parse-package-name";
import { EXTERNALS_NAMESPACE } from "./external";
import { HTTP_RESOLVE } from "./http";

import { getCDNUrl, DEFAULT_CDN_HOST } from "../utils/util-cdn";
import { isBareImport } from "../utils/path";

/** Alias Plugin Namespace */
export const ALIAS_NAMESPACE = "alias-globals";

/**
 * Checks if a package has an alias
 *
 * @param id The package to find an alias for
 * @param aliases An object with package as the key and the package alias as the value, e.g. { "fs": "memfs" }
 */
export const isAlias = (id: string, aliases = {}) => {
  if (!isBareImport(id)) return false;

  const aliasKeys = Object.keys(aliases);
  // eslint-disable-next-line no-useless-escape
  const path = id.replace(/^node\:/, "");
  const pkgDetails = parsePackageName(path);

  return aliasKeys.find((it: string): boolean => {
    return pkgDetails.name === it; // import 'foo' & alias: { 'foo': 'bar@5.0' }
  });
};

/**
 * Resolution algorithm for the esbuild ALIAS plugin
 *
 * @param aliases An object with package as the key and the package alias as the value, e.g. { "fs": "memfs" }
 * @param host The default host origin to use if an import doesn't already have one
 * @param logger Console log
 */
export const ALIAS_RESOLVE = (aliases = {}, host = DEFAULT_CDN_HOST, events: typeof EVENTS, build: PluginBuild) => {
  return async (args: OnResolveArgs): Promise<OnResolveResult | undefined> => {
    // eslint-disable-next-line no-useless-escape
    const path = args.path.replace(/^node\:/, "");
    const { path: argPath } = getCDNUrl(path);

    if (isAlias(argPath, aliases)) {
      const pkgDetails = parsePackageName(argPath);
      const aliasPath = aliases[pkgDetails.name];
      return HTTP_RESOLVE(
        host,
        events,
        build
      )({
        ...args,
        path: aliasPath,
      });
    }
  };
};

/**
 * Esbuild ALIAS plugin
 *
 * @param aliases An object with package as the key and the package alias as the value, e.g. { "fs": "memfs" }
 * @param host The default host origin to use if an import doesn't already have one
 * @param logger Console log
 */
export const ALIAS = (events: typeof EVENTS, state: typeof STATE, config: BundleConfigOptions): Plugin => {
  // Convert CDN values to URL origins
  const { origin: host } = !/:/.test(config?.cdn ?? "") ? getCDNUrl(config?.cdn + ":") : getCDNUrl(config?.cdn ?? "");
  const aliases = config.alias ?? {};
  return {
    name: ALIAS_NAMESPACE,
    setup(build) {
      // Intercept import paths starting with "http:" and "https:" so
      // esbuild doesn't attempt to map them to a file system location.
      // Tag them with the "http-url" namespace to associate them with
      // this plugin.
      // eslint-disable-next-line no-useless-escape
      build.onResolve({ filter: /^node\:.*/ }, (args) => {
        if (isAlias(args.path, aliases)) return ALIAS_RESOLVE(aliases, host, events, build)(args);

        return {
          path: args.path,
          namespace: EXTERNALS_NAMESPACE,
          external: true,
        };
      });

      // We also want to intercept all import paths inside downloaded
      // files and resolve them against the original URL. All of these
      // files will be in the "http-url" namespace. Make sure to keep
      // the newly resolved URL in the "http-url" namespace so imports
      // inside it will also be resolved as URLs recursively.
      build.onResolve({ filter: /.*/ }, ALIAS_RESOLVE(aliases, host, events, build));
      build.onResolve({ filter: /.*/, namespace: ALIAS_NAMESPACE }, ALIAS_RESOLVE(aliases, host, events, build));
    },
  };
};
