import React, { useState } from "react";
import styled from "styled-components";

import useResize from "../../uikit/hooks/useResize";
import { Icon, StrokeButton } from "../../uikit";
import TextLoading from "../../uikit/TextLoading";
import { LoadingStep } from "../../core/workspace/session-storage/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  height: 100%;
  padding: 12px 71px;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StopButton = styled(StrokeButton)`
  width: fit-content;
  margin-bottom: 12px;
  flex-shrink: 0;

  &:before {
    content: "";
    background: #ffffff;
    border-radius: 1px;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
`;

const locales = {
  [LoadingStep.Deploying]: "Deploying your application to Dasha Cloud Platform...",
  [LoadingStep.Activating]: "Launching the application and activating AI skills...",
  [LoadingStep.Training]: "Training the neural networks...",
  [LoadingStep.Connecting]: "Establishing a connection and starting the conversation...",
  [LoadingStep.Running]: "Establishing a connection and starting the conversation..."
};

const Loading = ({ onStop, status }) => {
  const [isCompact, setCompact] = useState(false);
  const ref = useResize<HTMLDivElement>((rect) => {
    setCompact(rect.height < 350);
  });

  return (
    <Container ref={ref}>
      {isCompact == false && (
        <TextLoading title={locales[status]}>
          The application is launching. It should take a few seconds. If you provided new training data, it may take up
          to two minutes.
        </TextLoading>
      )}
    
      <StopButton onClick={onStop}>
        stop the conversation {isCompact && <Icon name="spinner" width={16} height={16} style={{ marginLeft: 8 }} />}
      </StopButton>
    </Container>
  );
};

export default Loading;
