import React, { FC, useEffect, useState, useCallback } from "react";
import { Message } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import Profiler, { ProfilerPage } from "@core/profiler/ProfilerStorage";
import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import DashaappStorage from "@core/workspace/DashaappStorage";
import GridLayout from "@core/misc/GridLayout";
import { IAccount } from "@core/account/interface";
import { ActionButton, Icon, StrokeButton } from "../uikit";

import ProfileInfo from "./components/ProfileInfo";
import ProfilerWidget from "./components/ProfilerWidget";
import Filter from "./components/DataMarkup/components/Filter";
import Table from "./components/DataMarkup/components/Table";
import { uploadProfile } from "./uploadProfile";
import * as S from "./styled";
import ConversationsTable from "./components/ConversationsTable";

interface Props {
  id: string;
  account: IAccount;
  profiler: Profiler;
  dashaapp?: DashaappStorage;
  dataset?: DatasetStorage;
  layout: GridLayout;
}

const ProfilerPanel: FC<Props> = ({ profiler, dataset, dashaapp, layout }) => {
  const [loadProfileModal, toggleLoadProfileModal] = useState(false);
  const handleLoad = useCallback((dump) => {
    toggleLoadProfileModal(false);
    const loadProfiler = async (profiler, dump) => {
      await profiler.deserialize(dump);
    }
    loadProfiler(profiler, dump).catch(()=>toggleLoadProfileModal(true));
  }, [profiler]);

  const handleProfile = async () => {
    const { name, content } = await uploadProfile();
    await profiler.deserializeFromFile(name, content);
  };

  useEffect(() => {
    if (profiler.document != null) return;
    void profiler.tryLocalDeserialize();
  }, []);

  if (profiler.loadingProgress) {
    return (
      <S.Loading>
        <Icon name="spinner" style={{ width: 32 }} />
        <p>{profiler.loadingProgress?.description}</p>
        <StrokeButton onClick={() => profiler.cancelLoadingProfile()}>Cancel</StrokeButton>
      </S.Loading>
    );
  }

  const profileWidget = (
    <ProfilerWidget
      onClose={() => toggleLoadProfileModal(false)}
      onLoad={handleLoad}
      dashaapp={dashaapp}
      isOpen={loadProfileModal}
    />
  );

  if (profiler.table === null || profiler.table === undefined) {
    return (
      <S.Loading>
        {profileWidget}
        <p>
          To start profiling a conversation,
          <br />
          you need to download past conversations
        </p>
        <ActionButton onClick={() => toggleLoadProfileModal(true)}>Start profile</ActionButton>
        <StrokeButton onClick={handleProfile}>Upload .dashasp</StrokeButton>
      </S.Loading>
    );
  }

  return (
    <div style={{ position: "relative", display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
      {profileWidget}
      <ProfileInfo
        profiler={profiler}
        dataset={dataset}
        onUpload={handleProfile}
        onLoad={() => toggleLoadProfileModal(true)}
      />

      {profiler.selectedPage === ProfilerPage.CONVERSATIONS && profiler.conversationsTable != null && (
        <ConversationsTable layout={layout} table={profiler.conversationsTable} />
      )}

      {profiler.selectedPage === ProfilerPage.GRAPH && profiler.graph != null && (
        <>
          <S.GraphEditor engine={profiler.graph}></S.GraphEditor>
          <svg height="0" id="edge-markers">
            <defs>
              <marker id="link-end" markerWidth="5" markerHeight="6" refX="2.5" refY="3" orient="auto">
                <path
                  d="M5.00002 3L2.12649e-05 0.11325L2.45115e-05 5.88675L5.00002 3ZM2.81162e-07 3.5L0.500023 3.5L0.500023 2.5L-2.81162e-07 2.5L2.81162e-07 3.5Z"
                  fill="#008A69"
                />
              </marker>
              <marker id="link-start" markerWidth="6" markerHeight="6" refX="2" refY="3">
                <circle cx="3" cy="3" r="3" transform="rotate(-90 3 3)" fill="#D9D9E2" />
              </marker>

              <marker id="offset-link-end" markerWidth="5" markerHeight="6" refX="32.3" refY="3" orient="auto">
                <path
                  d="M5.00002 3L2.12649e-05 0.11325L2.45115e-05 5.88675L5.00002 3ZM2.81162e-07 3.5L0.500023 3.5L0.500023 2.5L-2.81162e-07 2.5L2.81162e-07 3.5Z"
                  fill="#008A69"
                />
              </marker>
            </defs>
          </svg>

          {profiler.graph.openedNode && (
            <S.GraphNavigation onClick={() => profiler.graph?.closePreview()}>Back to full graph</S.GraphNavigation>
          )}
        </>
      )}

      {profiler.selectedPage === ProfilerPage.TABLE && profiler.table != null && (
        <>
          <Filter profiler={profiler} />
          <StrokeButton disabled={profiler.table.isExporting} onClick={() => profiler?.table?.exportToCsv() }>
            {profiler?.table?.isExporting ? "Exporting" : "Export to CSV"}
          </StrokeButton>
          <Table profiler={profiler.table} dataset={dataset} layout={layout} />
        </> 
      )}
    </div>
  );
};

export default observer(ProfilerPanel);
