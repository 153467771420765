import * as React from "react";
import styled from "styled-components";

import ProfilerGraph from "..";
import LinkModel from "../DirectionalLink/Model";
import TransitionLabel from "./Model";

export interface LabelWidgetProps {
  model: TransitionLabel;
  engine: ProfilerGraph;
}

export const Label = styled.div<{ selected: boolean }>`
  user-select: none;
  pointer-events: auto;
  background: #161729;
  border: 0.903846px solid #008a69;
  border-radius: 14px;
  height: 13px;
  padding: 0 3.5px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 9.94231px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
`;

const LabelWidget: React.FC<LabelWidgetProps> = ({ model, engine }) => {
  if (model.getParent().getOptions().extras.isHidden) return null;
  const scale = model.angle < -90 || model.angle > 90 ? -1 : 1;

  return (
    <Label
      selected={model.isSelected()}
      data-label-id={model.getID()}
      data-label-parent={model.getParent()?.getID()}
      style={{ transform: `rotate(${model.angle}deg) scale(${scale}, ${scale})` }}
      onDoubleClick={() => {
        const link = model.getParent() as LinkModel;
        engine.revealTransition(link);
      }}
      onMouseDown={() => {
        const link = model.getParent() as LinkModel;
        model.getParentCanvasModel().clearSelection();
        link.setLabelSelected(model.getID());
      }}
    >
      {model.value}
    </Label>
  );
};

export default LabelWidget;
