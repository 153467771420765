import React, { FC, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Button } from "semantic-ui-react";
import { Card, Table, TextArea, Form, Label } from "semantic-ui-react";
import CopyButtonAction from "../../CopyButtonAction";
import "../index.css";
import { gptFunction, Parameter } from "./types";
import { ActionButton } from "../..";
import { PlaceholderInput } from "../../Input/placeholderInput";
import { Icon } from "../../Icon";

interface Props {
  f: gptFunction;
}

export const FunctionEditorCard: FC<Props> = observer(({ f }) => {
  const [expanded, setExpanded] = useState(false);
  const getParamRow = (name: string, param: Parameter) => {
    return (
      <Table.Row>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{param.type}</Table.Cell>
        <Table.Cell>
          <TextArea
            className="dasha-textarea"
            rows={(param.description as string).split("\n").length + 1}
            value={param.description as string}
            onChange={(e, data) => {
              f.updateParameterScheme(name, "description", data.value?.toString() ?? "")
            }}
          />
        </Table.Cell>
      </Table.Row>
    );
  };
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <PlaceholderInput
            defaultValue={f.Name}
            onSubmit={(x) => {
              f.setName(x);
            }}
            onCancel={() => {}}
          />
        </Card.Header>
        <Card.Description>
          <Button basic circular icon size="tiny" onClick={() => setExpanded(!expanded)}>
            <Icon name="arrow" angle={expanded ? 180 : 0} />
          </Button>
          {!expanded && <>{f.Description}</>}
        </Card.Description>
        {expanded && (
          <Card.Content extra>
            <Form>
              <Label>Description</Label>
              <TextArea
                label="Description"
                rows={f.Description.split("\n").length + 1}
                placeholder="Description"
                value={f.Description}
                className="dasha-textarea"
                onChange={(event, data) => {
                  f.setDescription(data.value?.toString() ?? "");
                }}
              />
              <Label>Returns</Label>
              <TextArea
                label="Returns"
                rows={f.Returns.split("\n").length + 1}
                placeholder="Returns"
                value={f.Returns}
                className="dasha-textarea"
                onChange={(event, data) => {
                  f.setReturns(data.value?.toString() ?? "");
                }}
              />
              <Table>
                <Table.Header>
                  <Table.Cell>Parameter</Table.Cell>
                  <Table.Cell>Type</Table.Cell>
                  <Table.Cell>Description</Table.Cell>
                </Table.Header>
                <Table.Body>{Object.entries(f.Parameters).map((param) => getParamRow(param[0], param[1]))}</Table.Body>
              </Table>
            </Form>
            <CopyButtonAction tooltipText="Copy JavaDoc" clipboard={f.GetJavaDoc()} hasIcon={false}>
              Copy JavaDoc
            </CopyButtonAction>
            <ActionButton onClick={() => f.ResetChanges()}>Reset changes</ActionButton>
          </Card.Content>
        )}
      </Card.Content>
    </Card>
  );
});
