import { BezierCurve } from "@projectstorm/geometry";
import { DefaultLinkModel } from "@projectstorm/react-diagrams";
import { TransitionInformation } from "@core/profiler/storage/model/stuff";

import TransitionLabel from "../Label/Model";
import NodeModel from "../CircleNode/Model";
import LinkLabelModel from "../Label/Model";
import CircleNodeModel from "../CircleNode/Model";
import { generateArcPath } from "../arcPath";
import { uuid4 } from "@sentry/utils";

export default class StraightLinkModel extends DefaultLinkModel {
  static type = "straight-link";

  label = new LinkLabelModel("0");
  transitions: TransitionInformation[] = [];
  isHidden = false;

  styles = {
    areaWidth: 6,
    areaColor: "#008A69",
    areaSelectionOpacity: 0.15,
    areaOpacity: 0,

    angle: 0,
    width: 1,
    markerEnd: "offset-link-end",
    markerStart: "",
    color: "#008A69",
    selectionColor: "#008A69",
  };

  constructor(readonly phrase = "") {
    super({
      id: uuid4(),
      type: StraightLinkModel.type,
      extras: { isHidden: false },
    });

    this.addLabel(this.label);
  }

  addTransition(trx: TransitionInformation) {
    this.transitions.push(trx);
    this.label.value = this.decisionCount.toString();
  }

  get decisionCount() {
    return this.transitions.reduce((acc, trx) => acc + trx.decisionCount, 0);
  }

  get uniqueDecisionCount() {
    return this.transitions.reduce((acc, trx) => acc + trx.uniqDecisionCount, 0);
  }

  get transitionType() {
    return this.transitions[0].transitionType;
  }

  get sourceNodePath() {
    return this.transitions[0].sourceNodePath;
  }

  get targetNodePath() {
    return this.transitions[0].targetNodePath;
  }

  get tooltip() {
    return {
      From: this.transitions[0].sourceNodePath,
      To: this.transitions[0].targetNodePath,
      Decision: this.decisionCount,
      "Unique Decision": this.uniqueDecisionCount,
      Phrase: this.phrase,
    };
  }

  setHidden(v: boolean) {
    this.options.extras.isHidden = v;
  }

  setSelected(v: boolean) {
    super.setSelected(v);
    this.setLabelSelected(null);
  }

  removeLabels() {
    this.labels.forEach((label) => label.remove());
    this.labels = [];
  }

  getSelectedLabel() {
    return this.getLabels().find((label) => label.isSelected());
  }

  setLabelSelected(id: string | null) {
    const labels = this.getLabels() as TransitionLabel[];
    labels.forEach((label) => label.setSelected(label.getID() === id));
  }

  getTargetNode() {
    return this.getTargetPort()?.getNode() as NodeModel;
  }

  getSourceNode() {
    return this.getSourcePort()?.getNode() as NodeModel;
  }

  getLink(): { id: string; from: string; to: string } {
    const target = this.getTargetNode();
    const source = this.getSourceNode();
    return { id: this.getID(), from: source?.getID(), to: target?.getID() };
  }

  getAngle(): number {
    const a = this.getFirstPoint().getPosition();
    const b = this.getLastPoint().getPosition();
    const vec = { x: b.x - a.x, y: b.y - a.y };
    return Math.atan2(vec.y, vec.x) * (180 / Math.PI);
  }

  getLoopSvgPath() {
    const curve = new BezierCurve();
    const point = this.getFirstPoint().getPosition().clone();
    const top = point.clone();
    const left = point.clone();

    top.translate(0, -150);
    left.translate(-150, 50);
    curve.setSource(point.clone());
    curve.setTarget(point.clone());
    curve.setSourceControl(top);
    curve.setTargetControl(left);

    return curve.getSVGCurve();
  }

  getSmartSvgPath() {
    const { from } = this.getLink();
    const target = this.getTargetPort()?.getNode() as CircleNodeModel;
    const isReturned = Object.values(target.out.getLinks()).find(
      (link) => link.getTargetPort().getNode().getID() === from
    );

    let offset = 0;
    let side = 1;

    if (isReturned) {
      const sourcePort = this.getSourcePort()?.getCenter() || {};
      const targetPort = this.getTargetPort()?.getCenter() || {};
      const angle = Math.atan2(sourcePort.y - targetPort.y, sourcePort.x - targetPort.x);

      side = angle > Math.PI ? 1 : -1;
      offset = 10;
    }

    if (offset) {
      const a = this.getFirstPoint().getPosition().clone();
      const b = this.getLastPoint().getPosition().clone();

      return generateArcPath(a, b, offset * side);
    }
  }

  getLineSvgPath() {
    const a = this.getFirstPoint().getPosition().clone();
    const b = this.getLastPoint().getPosition().clone();
    return ["M", a.x, a.y, "L", b.x, b.y].join(" ");
  }

  getSVGPath() {
    const { from, to } = this.getLink();

    if (!to) {
      return this.getLineSvgPath();
    }

    if (from === to) {
      return this.getLoopSvgPath();
    }

    return this.getSmartSvgPath() || this.getLineSvgPath();
  }

  serialize() {
    this.styles.angle = this.getAngle();
    return {
      ...super.serialize(),
      ...this.styles,
    };
  }
}
