import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import UIManager from "../../core/misc/UIManager";
import SessionsStorage from "../../core/workspace/session-storage/SessionsStorage";
import { SessionProtocol } from "../../core/workspace/session-storage/types";
import { MenuOption, PopupMenu, Tooltip } from "../../uikit/popup";
import { PureButton } from "../../uikit/Button";
import { formatDate } from "../helpers";
import { Icon } from "../../uikit";
import * as S from "./styled";

interface Props {
  storage: SessionsStorage;
}

enum ControlMessages {
  edited,
  unrecognized,
}

const SessionControls: FC<Props> = ({ storage }) => {
  const [type, setType] = useState<null | ControlMessages>(null);
  const [index, setIndex] = useState(0);

  const states = {
    [ControlMessages.unrecognized]: {
      type: ControlMessages.unrecognized,
      tooltip: "You have unrecognized phrases",
      messages: storage.activeSession?.getUnrecognizedMessages() ?? [],
      icon: "warning",
    },
    [ControlMessages.edited]: {
      type: ControlMessages.edited,
      tooltip: "Phrases with changes",
      messages: storage.activeSession?.getEditedMessages() ?? [],
      icon: "lab",
    },
  };

  const selectType = (newType: ControlMessages | null) => {
    storage.activeSession?.selectMessage(null);
    setType(newType);
    setIndex(0);
  };

  const sessionLabel = (session: SessionProtocol | null) => {
    if (storage.isRunning && session?.timeStarted == null) return "";
    return session?.timeInitialized ? formatDate(session.timeStarted ?? session.timeInitialized, true) : "";
  };

  const actions: MenuOption[] = storage.sessions.map((session) => ({
    label: sessionLabel(session),
    action: () => storage.selectSession(session),
    isSelected: session === storage.activeSession,
    isEnable: true,
  }));

  const settings: MenuOption[] = [
    {
      action: async () => {
        const jobId = storage.activeSession?.jobId;
        if (jobId == null) return;
        await navigator.clipboard.writeText(jobId);
        UIManager.notice("JobId was copied");
      },
      isEnable: storage.activeSession?.jobId != null,
      label: "Copy JobId",
      isSelected: false,
    },
    {
      label: storage.allowTransitionDebug ? "Transitions debug enabled" : "Transitions debug disabled",
      action: () => storage.toggleTransitionDebug(),
      isSelected: false,
      isEnable: true,
    },
  ];

  useEffect(() => {
    if (type == null) return setIndex(0);
    let next = index < 0 ? states[type].messages.length - 1 : index;
    next = next >= states[type].messages.length ? 0 : next;

    const msg = states[type].messages[next];
    storage.activeSession?.selectMessage(msg.id);
    setIndex(next);
  }, [
    type,
    index,
    states[ControlMessages.edited].messages.length,
    states[ControlMessages.unrecognized].messages.length,
  ]);

  if (storage.lastSession == null) return null;
  if (storage.isRunning) {
    return (
      <S.Container>
        <S.SessionDate disabled>{sessionLabel(storage.activeSession)}</S.SessionDate>
        <PopupMenu actions={settings} position="bottom right" offsetY={8}>
          <PureButton>
            <Icon name="menu" />
          </PureButton>
        </PopupMenu>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <PopupMenu actions={actions} position="bottom left" offsetY={-4}>
        <S.SessionDate>
          <Icon name="chevron-down" />
          {storage.activeSession ? sessionLabel(storage.activeSession) : "SESSION"}
        </S.SessionDate>
      </PopupMenu>

      {type == null && (
        <S.Stats>
          {Object.values(states).map((state) => (
            <Tooltip text={state.tooltip} position="top right" key={state.type}>
              <div>
                <PureButton disabled={state.messages.length === 0} onClick={() => selectType(state.type)}>
                  <Icon name={state.icon} />
                  <span>{state.messages.length}</span>
                </PureButton>
              </div>
            </Tooltip>
          ))}

          <PopupMenu actions={settings} position="bottom right" offsetY={8}>
            <PureButton>
              <Icon name="menu" />
            </PureButton>
          </PopupMenu>
        </S.Stats>
      )}

      {type != null && (
        <S.Control>
          <Icon name={states[type].icon} />
          <span>
            {index + 1}/{states[type].messages.length}
          </span>
          <PureButton onClick={() => setIndex(index - 1)}>
            <Icon name="cursor-up" />
          </PureButton>
          <PureButton onClick={() => setIndex(index + 1)}>
            <Icon name="cursor-down" />
          </PureButton>
          <PureButton onClick={() => selectType(null)}>
            <Icon name="close" />
          </PureButton>
        </S.Control>
      )}
    </S.Container>
  );
};

export default observer(SessionControls);
