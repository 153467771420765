import { OutputData } from "@core/workspace/session-storage/types";
import React, { FC, useState } from "react";
import { Accordion, AccordionContent, AccordionTitle } from "semantic-ui-react";
import * as uikit from "../../uikit";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../uikit/popup";

import * as S from "./styled";

interface Props {
  data: OutputData;
}

const OutputInfo: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const handleToggle = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  const getValueObject = (key, value, idx) => {
    if (value !== null) {
      value = JSON.stringify(value, undefined, 1);
    } else {
      value = "null";
    }

    if (value.length > 50) {
      return (
        <li key={key}>
          <AccordionTitle
            active={selected === idx}
            index={idx}
            onClick={() => (selected === idx ? setSelected(undefined) : setSelected(idx))}
          >
            <S.Key>"{key}":</S.Key>
            <uikit.Icon name="arrow" angle={selected === idx ? 180 : 0} />
          </AccordionTitle>
          <AccordionContent active={selected === idx}>
            <S.Value>{value}</S.Value>
          </AccordionContent>
        </li>
      );
    }
    return (
      <li key={key}>
        <S.Key>"{key}":</S.Key>
        <S.Value>{value}</S.Value>
      </li>
    );
  };
  return (
    <S.Container>
      <Tooltip text={isOpen ? "Hide info" : "Show info"} position="top center">
        <S.ToggleButton onClick={handleToggle}>
          <span>Info log</span>
          <uikit.Icon name="arrow" angle={isOpen ? 180 : 0} />
        </S.ToggleButton>
      </Tooltip>
      {isOpen && (
        <Accordion>
          <S.Info>{Array.from(Object.keys(data)).map((key, idx) => getValueObject(key, data[key], idx))}</S.Info>
        </Accordion>
      )}
    </S.Container>
  );
};

export default OutputInfo;
