import * as antlr4 from "antlr4";
import graphLexer from "./generated/graphLexer";
import graphParser from "./generated/graphParser";
import { ErrorListener, ParserRuleContext, Token } from "antlr4";
import { GraphErrorListener } from "./GraphErrorListener";

export function isTerminal(ctx: antlr4.ParserRuleContext) {
  return (ctx as any).ruleIndex === undefined;
}

export function createParseTree(input: string, errorListener?: ErrorListener<Token>) {
  const chars = new antlr4.InputStream(input);
  const lexer = new graphLexer(chars);
  const tokens = new antlr4.CommonTokenStream(lexer);
  const parser: graphParser = new graphParser(tokens);
  parser.enableChannel = (x) => {};
  parser.disableChannel = (x) => {};
  if (errorListener) {
    parser.removeErrorListeners();
    parser.addErrorListener(errorListener);
  }
  const tree = parser.file();
  return { tree, parser };
}

export function createParseTreeWithErrors(input: string) {
  const errorListener = new GraphErrorListener();
  const chars = new antlr4.InputStream(input);
  const lexer = new graphLexer(chars);
  const tokens = new antlr4.CommonTokenStream(lexer);
  const parser = new graphParser(tokens);
  parser.enableChannel = (x) => {};
  parser.disableChannel = (x) => {};
  if (errorListener) {
    parser.removeErrorListeners();
    parser.addErrorListener(errorListener);
  }
  const ast = parser.file();
  const errors = errorListener.getErrors();
  return { ast, parser, errors };
}

export function collectContextParents(context: antlr4.ParserRuleContext) {
  const result: antlr4.ParserRuleContext[] = [];
  let current: ParserRuleContext |null = context;
  while (current !== null) {
    result.push(current);
    if (current.parentCtx === null) break;
    current = current.parentCtx ?? null;
  }
  return result;
}
