import { LinkModel, DiagramEngine, PortModel, DefaultLinkModel } from "@projectstorm/react-diagrams";
import { AbstractModelFactory } from "@projectstorm/react-canvas-core";

export class CirclePortModel extends PortModel {
  static TYPE = "circle-port";

  constructor() {
    super({ type: CirclePortModel.TYPE, name: "port" });
  }

  createLinkModel(): LinkModel {
    return new DefaultLinkModel();
  }
}

export class CirclePortFactory extends AbstractModelFactory<PortModel, DiagramEngine> {
  constructor() {
    super(CirclePortModel.TYPE);
  }

  generateModel(event): PortModel {
    return new CirclePortModel();
  }
}
