import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { Dropdown, Input, TextArea, Message } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import { ActionButton } from "../uikit";
import WaveSurfer from "wavesurfer.js";
import { useStore } from "../core/api/GlobalStoreContext";

interface HistoryItem {
  text: string;
  speed: number;
  speaker: string;
  language: string;
  blob: Blob;
}

interface HistoryItemCombo {
  text: string;
  key: string;
  value: HistoryItem;
}

export const Tts: FC<{ }> = ({ }) => {
  const { account } = useStore();
  const languages = {
    "en-US": ["kate", "justina", "linda", "anna", "cass", "jack"],
    "ru-RU": ["anna", "elizaveta", "svetlana", "alena", "yurii", "alexandra", "zlatamira"],
    "ar": ["clar"],
  };
  const language_keys = Object.keys(languages).map((x) => ({ text: x, value: x, key: x }));
  const texts = {
    "en-US": "Hi! How are you doing?",
    "ru-RU": "Добрый день!",
    "ar": "مساء الخير",
  };

  const [language, setLanguage] = useState("en-US");
  const [speaker, setSpeaker] = useState("kate");
  const [speakers, setSpeakers] = useState([]);
  const [text, setText] = useState(texts["en-US"]);
  const [loading, setLoading] = useState(false);
  const [speed, setSpeed] = useState(1.0);
  const containerRef = useRef();
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [history, setHistory] = useState<HistoryItem[]>([]);

  useEffect(() => {
    setSpeakers(languages[language].map((x) => ({ text: x, value: x, key: x })));
    setSpeaker(languages[language][0]);
    setText(texts[language]);
  }, [language, setSpeakers, setText]);

  const onSynth = useCallback(() => {
    setLoading(true);
    setError(undefined);
    const fetchData = async () => {
      const a = account.connect();
      const providerName = "dasha";
      const options = { providerName, account: a };
      const voice = {
        speed: speed,
        speaker: speaker,
        lang: language,
        variation: 0,
        emotion: "",
      };

      const buffer = await dasha.tts.synthesize(text, voice, options);
      const blob = new Blob([buffer], { type: "audio/mp3" });
      wavesurfer?.loadBlob(blob);

      const newItem: HistoryItemCombo = {
        text: language + "/" + speed + "/" + speaker + "/" + text,
        key: text + language + speaker + speed,
        value: {
          text: text,
          speed: speed,
          speaker: speaker,
          language: language,
          blob: blob,
        },
      };
      const newHistory = [...history, ...[newItem]];
      if (newHistory.length > 5) {
        newHistory.shift();
      }
      setHistory(newHistory);
    };

    fetchData()
      .catch((e) => setError(e.message))
      .then(() => setLoading(false));
  }, [account, language, speaker, text, speed, setError, setHistory, history, wavesurfer]);

  useEffect(() => {
    if (!containerRef.current) return;
    const ws = WaveSurfer.create({
      container: containerRef.current,
      plugins: [],
      height: 200,
    });
    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [containerRef]);

  const onHistory = useCallback(
    (x) => {
      setLanguage(x.language);
      setSpeaker(x.speaker);
      setSpeed(x.speed);
      setText(x.text);
      wavesurfer?.loadBlob(x.blob);
    },
    [setLanguage, setSpeaker, setSpeed, setText, wavesurfer]
  );

  const onPlayClick = useCallback(() => {
    if (wavesurfer === undefined) {
      return;
    }
    wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
  }, [wavesurfer]);

  return (
    <div className="defaultRoot" id="grid-workspace">
      <div style={{ width: 400 }}>
        <h2>Text To Speech</h2>
        {error && <Message negative>Error {error}</Message>}
        <Loader active={loading}>Loading..</Loader>
        <h2>Language</h2>
        <Dropdown
          placeholder="Select Language"
          fluid
          search
          selection
          options={language_keys}
          value={language}
          onChange={(e, { value }) => setLanguage(value as string)}
        />
        <h2>Speaker</h2>
        <Dropdown
          placeholder="Select Speaker"
          fluid
          search
          selection
          options={speakers}
          value={speaker}
          onChange={(e, { value }) => setSpeaker(value as string)}
        />
        <h2>Speed: {speed}</h2>
        <Input
          value={speed}
          onChange={(e) => {
            var number = Number.parseFloat(e.target.value);
            setSpeed(number);
          }}
          type="range"
          min={0.5}
          max={2}
          step={0.1}
        />
        <h2>Text</h2>
        <TextArea
          value={text}
          placeholder="Your text"
          style={{ minHeight: 100, minWidth: 400 }}
          onChange={(e, { value }) => {
            setText(value);
          }}
        />
        <ActionButton onClick={onSynth} disabled={wavesurfer === undefined}>
          Synthesize
        </ActionButton>
      </div>
      <h2>History</h2>
      <Dropdown
        placeholder="Select From History"
        fluid
        search
        selection
        options={history}
        onChange={(e, { value }) => onHistory(value)}
      />
      <ActionButton onClick={onPlayClick}>[{wavesurfer?.isPlaying() ? "Pause" : "Play"}]</ActionButton>
      <div ref={containerRef} className="waveform" />
    </div>
  );
};
