import * as dasha from "@dasha.ai/sdk/web";
import path from "path";
import Project from "../explorer/Project";
import TextStorage from "./TextStorage";
import { IAccount } from "@core/account/interface";

class DashaappStorage extends TextStorage {
  dsl: string | null = null;
  dataset: string | null = null;
  phrasemap: string | null = null;
  graph: string | null = null;
  applicationName: string | null = null;

  constructor(readonly project: Project, readonly path: string, private readonly account: IAccount) {
    super(project, path, "dashaapp.json");
    this.isPinned = true;
  }

  public async getApplications() {
    const document = await this.project.json<any>(this.path).catch(() => ({}));
    const account = this.account.connect();
    const applications = await dasha.applications.getApplications({ account });
    this.applicationName = document.nam;
    return applications.filter((application) => application.name === document.name);
  }

  async updateConfig() {
    const document = await this.project.json<any>(this.path).catch(() => ({}));

    let file = document.dialogue?.file;
    this.dsl = file ? path.resolve(this.directory, file).slice(1) : null;

    file = document.nlu?.customIntents?.file;
    this.dataset = file ? path.resolve(this.directory, file).slice(1) : null;

    file = document.nlg?.file ?? document.nlg?.signatureFile;
    this.phrasemap = file ? path.resolve(this.directory, file).slice(1) : null;

    file = document.dialogue?.view;
    this.graph = file ? path.resolve(this.directory, file).slice(1) : null;
  }

  dispose() {
    // Add any Dashaapp-specific disposal logic here
    super.dispose();
  }
}

export default DashaappStorage;
