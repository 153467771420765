import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "./Expression";
import { RawElement } from "../common";
import { helpers } from "..";

export class DigressionSwitchExpression extends Expression implements interfaces.IDigressionSwitchExpression {
  elementType: DslElementType.DigressionSwitchExpression = DslElementType.DigressionSwitchExpression;
  command: "save" | "enable" | "disable";
  digressionList: RawElement<string>[];

  constructor(obj: interfaces.IDigressionSwitchExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.command = obj.command;

    this.digressionList = obj.digressionList.every((el) => el instanceof RawElement<string>)
      ? (obj.digressionList as RawElement<string>[])
      : obj.digressionList.map((el) => new RawElement(el));
  }
  toJson(): interfaces.IDigressionSwitchExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      command: this.command,
      digressionList: this.digressionList.map((el) => el.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      "digression",
      this.command,
      helpers.indentList(this.digressionList.map(el => el.toDsl(indent)), indent)
    ].join(" ")
  }
}
