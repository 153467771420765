import { observable, action, makeObservable, toJS, computed, runInAction } from "mobx";
import { getIncomers, getOutgoers } from "react-flow-renderer";

import { FileStorage } from "../workspace/TextStorage";
import Project from "../explorer/Project";

import SimpleTtsSpeaker from "../SimpleTtsSpeaker";
import DatasetStorage from "../workspace/dataset-storage/DatasetStorage";

import {
  generateAppInit,
  generateConditionalNode,
  generateSimpleNode,
  generatFunction,
  OperationSetContext,
  TransitionProperties,
  generateDigression,
  generateRoot,
} from "./generate";
import { Pair } from "./helpers";

export type GraphElement = any;

enum NodeTypes {
  contextNode = "startNode",
  setContext = "setContext",
  external = "external",
  condition = "condition",
  reaction = "reaction",
  sayText = "sayText",
  finishDialog = "finishDialog",
  reactionTimeout = "reactionTimeout",
  interruptible = "interruptible",
}

export interface ContextVariable {
  id: number;
  key: string;
  value: string;
}

interface Options {
  project: Project;
  dataset: DatasetStorage;
}

export const ANY_INTENT = "any phrase";
export const SENTIMENT_POSITIVE = "system: yes";
export const SENTIMENT_NEGATIVE = "system: no";
const SENTIMENTS = {
  [SENTIMENT_NEGATIVE]: "negative",
  [SENTIMENT_POSITIVE]: "positive",
};

class DSLEditor implements FileStorage {
  public path = "graph.json";
  public name = "Graph Editor";

  public isInvalid = false;
  public isReadonly = false;
  public isDraft = false;
  public isLoading = false;
  public isPinned = true;
  public elements: Map<string, GraphElement>;

  constructor(readonly options: Options) {
    makeObservable(this, {
      elements: observable,
      isLoading: observable,
      isReadonly: observable,
      isDraft: observable,

      context: computed,
      graphElements: computed,

      loadGraph: action,
      updateNodeData: action,
      moveNodes: action,
      removeElements: action,
      highlightNode: action,
      connectNode: action,
      createNode: action,
    });

    this.isReadonly = options.project.metadata.isEditable === false;
    this.elements = new Map<string, GraphElement>();
    void this.loadGraph();
  }

  dispose(): void {
    // nope
  }

  async loadGraph() {
    this.isLoading = true;
    const options = {
      position: { x: 0, y: 0 },
      id: NodeTypes.contextNode,
      type: NodeTypes.contextNode,
      data: {},
    };

    const map = await this.options.project
      .json<[string, GraphElement][]>(this.path)
      .catch<[string, GraphElement][]>(() => [[NodeTypes.contextNode, options]]);

    await runInAction(async () => {
      this.isLoading = false;
      this.elements = new Map(map);
      await this.saveGraph();
    });
  }

  async saveGraph() {
    await this.options.project.updateContent("graph.js", this.serializeJS());
    await this.options.project.updateContent("app/main.dsl", this.serializeDsl());

    const elements = this.getElements().map((el) => [el.id, toJS(el)]);
    await this.options.project.updateContent(this.path, elements);
  }

  getElements() {
    return Array.from(this.elements.values());
  }

  get context(): ContextVariable[] {
    return this.elements.get(NodeTypes.contextNode)?.data?.context ?? [];
  }

  get graphElements() {
    return this.getElements().map((el) => ({
      ...toJS(el),
      dragHandle: ".drag-handle",
      data: {
        ...el.data,
        intents: this.intents,
        entities: this.entities,
        context: this.context,
        speaker: SimpleTtsSpeaker.Instance,
        update: (data, zIndex) => this.updateNodeData(el.id, data, zIndex),
      },
    }));
  }

  get intents() {
    return [ANY_INTENT, SENTIMENT_POSITIVE, SENTIMENT_NEGATIVE].concat(this.options.dataset.intents);
  }

  get entities() {
    return ["numberword"].concat(this.options.dataset.entities);
  }

  updateNodeData(id, data, zIndex) {
    if (this.isReadonly) return;

    const el = this.elements.get(id);
    if (el) {
      this.elements.set(id, { ...el, style: { zIndex }, data: { ...el.data, ...data } });
      void this.saveGraph();
    }
  }

  moveNodes = (nodes: any[]) => {
    if (this.isReadonly) return;

    nodes.forEach((node) => {
      const el = this.elements.get(node.id);
      if (el) this.elements.set(node.id, { ...el, position: node.position });
    });

    void this.saveGraph();
  };

  removeElements = (elms) => {
    if (this.isReadonly) return;

    elms.forEach((el) => {
      if (el.id === NodeTypes.contextNode) return;
      this.elements.delete(el.id);
    });

    void this.saveGraph();
  };

  highlightNode(id: string) {
    const nodeId = id.split("_")[0];
    this.elements.forEach((el) => {
      const newElement = { ...el, data: { ...el.data, highlight: el.id === nodeId } };
      this.elements.set(el.id, newElement);
    });
  }

  connectNode = (params) => {
    if (this.isReadonly) return;

    const source = this.elements.get(params.source)?.type;
    const target = this.elements.get(params.target)?.type;

    const pair = new Pair(source, target);
    if (target === NodeTypes.condition && source !== NodeTypes.external) {
      return;
    }

    const isConnectable = !pair.isEqual([NodeTypes.reaction, NodeTypes.reaction]);
    if (!isConnectable) return;

    const id = "edge" + +new Date();
    const style = {
      id,
      style: { strokeWidth: 3 },
      data: { intent: ANY_INTENT, priority: 10 },
    };

    this.elements.set(id, Object.assign(style, params));
    void this.saveGraph();
  };

  createNode = (type, x, y) => {
    if (this.isReadonly) return;

    const id = "node" + +new Date();
    this.elements.set(id, {
      id,
      type,
      position: { x, y },
      data: {
        value: "",
        speed: 1,
        interruptibleDelay: 100,
        intent: this.intents[0],
        contextKey: this.context[0]?.key,
        triggers: [],
        timeoutTrigger: 1000,
        contextValue: "",
        priority: 1,
      },
    });

    void this.saveGraph();
  };

  getExternalsNames(): string[] {
    return this.getElements()
      .filter((el) => el.type === NodeTypes.external)
      .map((el) => el.id);
  }

  serializeJS() {
    const snippets = this.getElements().flatMap((el) => {
      if (el.type !== NodeTypes.external) return [];
      return [generatFunction(el.id, el.data.snippet)];
    });

    return snippets.join("\n") + generateAppInit();
  }

  serializeDigression(node, elements): string[] {
    if (node.type !== NodeTypes.reaction) return [];
    const gotos = getOutgoers(node, elements);
    const { intent, priority } = node.data;
    const operations = this.extractOperations(gotos);
    const trigger: TransitionProperties = {
      name: node.id,
      anyPhrase: intent == ANY_INTENT,
      sentiment: SENTIMENTS[intent],
      intent: intent == ANY_INTENT ? null : intent,
      isEntity: this.entities.includes(intent),
      priority,
      ...operations,
    };

    const entryNode = this.elements.get(operations.nextNode);
    const blockTree = entryNode ? this.serializeTree(entryNode, elements, true) : [];
    return [generateDigression(trigger, blockTree)];
  }

  extractOperations(elements) {
    const setContexts: OperationSetContext[] = [];
    let nextNode = "terminateNode";
    let isFinish = false;

    elements.forEach((node) => {
      switch (node.type) {
        case NodeTypes.setContext: {
          setContexts.push({
            value: node.data.contextValue,
            key: node.data.contextKey,
          });
          break;
        }

        case NodeTypes.finishDialog: {
          isFinish = true;
          break;
        }

        case NodeTypes.external:
        case NodeTypes.sayText: {
          nextNode = node.id;
        }
      }
    });

    return { setContexts, nextNode, isFinish };
  }

  serializeNode(node, outgoers, fromBlock) {
    if (node.type === NodeTypes.setContext) return;
    if (node.type === NodeTypes.finishDialog) return;
    if (node.type === NodeTypes.interruptible) return;

    if (node.type === NodeTypes.condition) {
      return this.serializeConditionNode(node, fromBlock);
    }

    const transitions: TransitionProperties[] = [];
    const setContexts: OperationSetContext[] = [];
    let interruptible;
    let isFinish = false;

    outgoers.forEach((edge, i) => {
      const transitionId = `transition${i}`;
      switch (edge.type) {
        case NodeTypes.reaction: {
          const elements = Array.from(this.elements.values());
          const gotos = getOutgoers(edge, elements);
          const { intent, priority } = edge.data;

          transitions.push({
            name: transitionId,
            intent: intent == ANY_INTENT ? null : intent,
            anyPhrase: intent == ANY_INTENT,
            sentiment: SENTIMENTS[intent],
            isEntity: this.entities.includes(intent),
            priority,
            ...this.extractOperations(gotos),
          });

          break;
        }

        case NodeTypes.reactionTimeout: {
          const elements = Array.from(this.elements.values());
          const gotos = getOutgoers(edge, elements);
          const { timeoutTrigger = 1000, priority } = edge.data;

          transitions.push({
            name: transitionId,
            timeoutTrigger,
            priority,
            ...this.extractOperations(gotos),
          });

          break;
        }

        case NodeTypes.setContext: {
          setContexts.push({
            value: edge.data.contextValue,
            key: edge.data.contextKey,
          });
          break;
        }

        case NodeTypes.interruptible: {
          interruptible = {
            delay: edge.data.interruptDelay,
            triggers: edge.data.triggers,
          };
          break;
        }

        case NodeTypes.finishDialog: {
          isFinish = true;
          break;
        }

        default: {
          transitions.push({
            name: transitionId,
            nextNode: edge.id,
            priority: 0,
          });
        }
      }
    });

    const instantNode = this.getInstantNode(transitions);
    return generateSimpleNode({
      id: node.id,
      intents: this.intents,
      entities: this.entities,
      externalFunction: node.data.snippet,
      speed: node.data.speed,
      sayText: node.data.sayText,
      transitions: instantNode ? [] : transitions,
      fromBlock,
      isFinish,
      setContexts,
      instantNode,
      interruptible,
    });
  }

  getInstantNode(transitions: TransitionProperties[]): string | undefined {
    if (transitions.length !== 1) return;
    const { anyPhrase, nextNode, timeoutTrigger } = transitions[0];
    if (timeoutTrigger != null) return;
    if (anyPhrase == null) return nextNode;
  }

  // TODO: Add triggers by conditions (digressions/transitions)
  serializeConditionNode(node, isDigression) {
    const elements = Array.from(this.elements.values());
    const edges = elements.filter((edge) => edge.source === node.id);

    const trueId = node.id + "_true";
    const trueOutgouers = edges
      .filter((edge) => edge.sourceHandle === "true")
      .map((edge) => this.elements.get(edge.target));

    const falseId = node.id + "_false";
    const falseOutgouers = edges
      .filter((edge) => edge.sourceHandle === "false")
      .map((edge) => this.elements.get(edge.target));

    const trueData = { id: trueId, type: "node", data: {} };
    const trueNode = this.serializeNode(trueData, trueOutgouers, isDigression);

    const falseData = { id: falseId, type: "node", data: {} };
    const falseNode = this.serializeNode(falseData, falseOutgouers, isDigression);
    const conditionNode = generateConditionalNode({
      id: node.id,
      true: trueId,
      false: falseId,
      condition: '$returned == "true"',
    });

    return [conditionNode, trueNode, falseNode].join("\n");
  }

  serializeTree(node, elements, fromBlock): string[] {
    const nodes = new Map<string, any[]>();
    const flattenTree = (out) => {
      if (nodes.get(out.id) != null) return;
      const outgoers = getOutgoers(out, elements);
      nodes.set(out.id, outgoers);
      outgoers.forEach((v) => flattenTree(v));
    };

    flattenTree(node);

    return Array.from(nodes.entries()).map(([id, outgoers]) => {
      return this.serializeNode(this.elements.get(id), outgoers, fromBlock);
    });
  }

  serializeDsl() {
    const elements = Array.from(this.elements.values());
    const rootNode = this.elements.get(NodeTypes.contextNode);
    const rootCode = generateRoot(this.context, this.getExternalsNames(), rootNode.id);
    const nodes = this.serializeTree(rootNode, elements, false);
    elements.forEach((el) => {
      if (el.type === NodeTypes.reaction) {
        if (getIncomers(el, elements).length) return;
        nodes.push(...this.serializeDigression(el, elements));
      }
    });

    return rootCode + nodes.join("\n");
  }
}

export default DSLEditor;
