import React, { FC } from "react";

import Engine from "..";
import { NodeType } from "../types";
import WidgetNode from "./Model";
import * as S from "./styled";

export interface Props {
  node: WidgetNode;
  engine: Engine;
}

const CirclelNode: FC<Props> = ({ engine, node }) => {
  const style = { borderWidth: node.type === NodeType.Node ? '1px' : '3px' };

  if (node.freeDirections) {
    return (
      <div onDoubleClick={() => engine.previewNode(node)}>
        <S.Port port={node.in} engine={engine}>
          <S.Port port={node.out} engine={engine}>
            <S.Node style={style}>
              <S.Name>{node.name}</S.Name>
              {node.transitions}
            </S.Node>
          </S.Port>
        </S.Port>
      </div>
    );
  }

  return (
    <S.Node onDoubleClick={() => engine.previewNode(node)} style={style}>
      <S.PortIn port={node.in} engine={engine} />
      <S.PortOut port={node.out} engine={engine} />
      <S.Name>{node.name}</S.Name>
      {node.transitions}
    </S.Node>
  );
};

export default CirclelNode;
