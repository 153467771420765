import { IAccount } from "@core/account/interface";

export const getIntelemediaPods = async (acc: IAccount) => {
  const account = await acc.connect();
  const response = await fetch("https://intelemedia-main.dev.az.dasha.ai/podList", {
    headers: {
      Authorization: `Bearer ${account.apiKey}`,
    },
  });

  if (response.ok === false) return [];
  const res = await response.json();
  return JSON.parse(res);
};
