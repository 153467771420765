export interface Position {
  x: number;
  y: number;
}

export enum NodeType {
  Digression = "Digression",
  Preprocessor = "Preprocessor",
  Block = "Block",
  Node = "Node",
}
