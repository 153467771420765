import React, { useState } from "react";
import styled from "styled-components";
import { Icon, StrokeButton } from "..";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const Title = styled.p`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 24px;
`;

const Description = styled.p`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  margin-top: 8px;
`;

const TextLoading = ({ title, children }) => {
  return (
    <Body>
      <Icon name="spinner" width={40} height={40} />
      <Title>{title ?? "Loading..."} </Title>
      <Description>{children}</Description>
    </Body>
  );
};

export default TextLoading;
