import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";

import { DoStatement } from "./DoStatement";

export class RawFragmentStatement extends DoStatement implements interfaces.IRawFragment {
  elementType: DslElementType.RawFragmentStatement = DslElementType.RawFragmentStatement;
  value: string;

  constructor(obj: interfaces.IRawFragment, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.value = obj.value;
  }
  toJson(): interfaces.IRawFragment {
    return {
      isDoSectionStatement: true,
      elementType: this.elementType,
      location: this.location,
      value: this.value,
    };
  }
  toDsl(indent: number): string {
    const completedFragment = this.value.endsWith(";") ? this.value : this.value + ";";
    return `${helpers.indentString(indent)}${completedFragment}`;
  }
}
