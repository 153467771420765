import React, { FC } from "react";
import styled from "styled-components";

import analytics from "./core/misc/analytics";
import { MenuOption, PopupMenu } from "./uikit/popup";
import { Icon } from "./uikit";

const QWidget = styled.div`
  z-index: 10;
  cursor: pointer;
  transition: 0.2s opacity;
  position: fixed;
  bottom: 24px;
  right: 24px;

  &:hover {
    opacity: 0.8;
  }
`;

const HelpWidget: FC<{ offset?: number }> = () => {
  const actions: MenuOption[] = [
    {
      label: "Start onboarding",
      action: () => 0,
      isEnable: true,
    },
    {
      isEnable: true,
      label: "Ask the community",
      action: () => {
        analytics.track("OPEN_COMMUNITY");
        window.open("https://community.dasha.ai", "_blank");
      },
    },
    {
      label: "Watch tutorial",
      action: () => 0,
      isEnable: true,
    },
    {
      label: "Documentation",
      action: () => window.open("https://docs.dasha.ai", "_blank"),
      isEnable: true,
    },
  ];

  return (
    <PopupMenu actions={actions} on="click" position="top right" arrow offsetY={8}>
      <QWidget>
        <Icon name="question" />
      </QWidget>
    </PopupMenu>
  );
};

export default HelpWidget;
