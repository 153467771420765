import { action, makeObservable, observable } from "mobx";
import debounce from "lodash/debounce";

enum SizeType {
  min,
  max,
  snap,
  free,
}

const GRID_LAYOUT = "__gridLayout2";

class GridLayout {
  sizes = {
    files: {
      disabled: false,
      current: 200,
      min: 20,
      snap: 200,
      max: 460,
    },
    tools: {
      disabled: false,
      current: 140,
      min: 48,
      snap: 0,
      max: 448,
    },
    runner: {
      disabled: true,
      current: 480,
      min: 20,
      snap: 480,
      max: 480,
    },
  };

  @observable
  states = {
    files: SizeType.free,
    tools: SizeType.free,
    runner: SizeType.free,
  };

  gridEl?: HTMLElement;

  constructor() {
    makeObservable(this);
  }

  @action
  resize(id: string, dir: number, init: boolean) {
    if (init) return;

    const { min, max, snap, current } = this.sizes[id];
    if (snap === 0) {
      this.changeSize(id, Math.min(max, Math.max(min, current + dir)));
      return;
    }

    const size = current === min ? min : Math.min(max, Math.max(snap, current + dir));

    if (size === snap && dir < -60) {
      this.changeSize(id, min);
      return;
    }

    if (size === min && dir > snap - min - 60) {
      this.changeSize(id, snap);
      return;
    }

    this.changeSize(id, size);
  }

  @action
  changeSize(id: string, size: number) {
    const { min, max, snap } = this.sizes[id];

    switch (size) {
      case min:
        this.changeState(id, SizeType.min);
        break;

      case max:
        this.changeState(id, SizeType.max);
        break;

      case snap:
        this.changeState(id, SizeType.snap);
        break;

      default:
        this.changeState(id, SizeType.free);
    }

    this.sizes[id].current = size;
    this.gridEl?.style.setProperty(`--${id}`, size + "px");
    this.saveLayout();
  }

  @action
  changeState(id: string, type: SizeType) {
    if (this.states[id] === type) return;
    this.states[id] = type;
  }

  @action
  expandPanel(id: string) {
    const { max, snap, beforeCollapse } = this.sizes[id];
    this.changeSize(id, beforeCollapse || snap || max);
  }

  @action
  collapsePanel(id: string) {
    const { min, current } = this.sizes[id];
    if (this.states[id] !== SizeType.min) {
      this.sizes[id].beforeCollapse = current;
    }
    this.changeSize(id, min);
  }

  @action
  toggleCollapse(id: string) {
    if (this.states[id] === SizeType.min) {
      this.expandPanel(id);
    } else {
      this.collapsePanel(id);
    }
  }

  isExpanded(id: string) {
    return this.states[id] !== SizeType.min;
  }

  isDisabled(id: string) {
    return this.sizes[id].disabled === true;
  }

  bindGrid(selector: string) {
    const el = document.querySelector<HTMLElement>(selector);
    if (el == null) return;

    this.gridEl = el;
    this.sizes = this.loadLayout();
    Object.entries(this.sizes).forEach(([id, state]) => this.changeSize(id, state.current));
  }

  saveLayout = debounce(() => {
    localStorage.setItem(GRID_LAYOUT, JSON.stringify(this.sizes));
  }, 1000);

  loadLayout() {
    try {
      const json = localStorage.getItem(GRID_LAYOUT);
      if (json == null) return this.sizes;
      return JSON.parse(json);
    } catch {
      return this.sizes;
    }
  }

  async toggleFullScreen() {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      }
    }
  }
}

export default GridLayout;
