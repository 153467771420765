import React, { FC, useEffect, useState } from "react";
import { TestCard } from "./testCard";
import * as S from "../styled";

import { observer } from "mobx-react-lite";
import { Loader, Message } from "semantic-ui-react";
import { Prompt } from "../../core/api/prompts/PromptStore";

export const TestCardsView: FC<{ prompt: Prompt; onSelect(id: string); onRun(id: string, times: number) }> = observer(
  ({ prompt, onSelect, onRun }) => {
    return (
      <>
        <S.Content id="testCases">
          <S.TestCards>
            {<Loader active={prompt.loading} content={"Loading test cases"}></Loader>}
            {!prompt.loading && prompt.testCases.length === 0 && (
              <Message>Utilize our inspector tool to incorporate prompts and tests from conversations.</Message>
            )}
            {prompt.testCases.map((test) => (
              <TestCard prompt={prompt} item={test} onSelect={onSelect} onRun={onRun} />
            ))}
          </S.TestCards>
        </S.Content>
      </>
    );
  }
);
