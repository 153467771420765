import { createGlobalStyle } from "styled-components";

export const ScrollbarStyle = createGlobalStyle`
    * {
        ::-webkit-scrollbar {
            width: 16px;
            height: 16px;
            position: absolute;
        }

        ::-webkit-scrollbar-corner {
          background: rgba(255, 255, 255, 0.15);
        }

        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 20px;
            border: 4px solid transparent;
            background-clip: content-box;
            background-color: rgba(255, 255, 255, 0.15);
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: rgba(255, 255, 255, 0.25);
        }
    }
`;
