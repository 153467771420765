// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waveform {
    color: rgba(255, 255, 255, 0.9) !important;
    ::part(region-content) { margin-top: 0px !important; }
}

.dasha-semantic-table {
    background: rgba(0,0,0,0) !important;
}

.positive_row {
    background: rgba(30,220,60,127) !important;
}

.dasha-textarea {
    width: 100%;
    background: rgba(0,0,0,0);
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/PromptsView/index.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,yBAAyB,0BAA0B,EAAE;AACzD;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".waveform {\n    color: rgba(255, 255, 255, 0.9) !important;\n    ::part(region-content) { margin-top: 0px !important; }\n}\n\n.dasha-semantic-table {\n    background: rgba(0,0,0,0) !important;\n}\n\n.positive_row {\n    background: rgba(30,220,60,127) !important;\n}\n\n.dasha-textarea {\n    width: 100%;\n    background: rgba(0,0,0,0);\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
