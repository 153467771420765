import { useEffect } from "react";
import { useZoomPanHelper } from "react-flow-renderer";

const useNodeToCenter = (x, y, isToggle) => {
  const { setCenter } = useZoomPanHelper();

  useEffect(() => {
    if (!isToggle) return;
    setCenter(x + 100, y + 50, 0.8, 700);
  }, [isToggle]);
};

export default useNodeToCenter