import React, { FC, useState, useEffect, useCallback } from "react";
import ReactDiffViewer from "react-diff-viewer";
import "./index.css";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Label,
  List,
  ListItem,
  Loader,
  Message,
  Modal,
  Segment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TabPane,
  TextArea,
} from "semantic-ui-react";
import { ActionButton, Icon } from "../uikit";
import { GptOptionsTable } from "../uikit/gpt/Options/gptOptions";
import CopyButtonAction from "../uikit/CopyButtonAction";
import { GptArgsTable } from "../uikit/gpt/Args/gptArgs";
import { HistoryList } from "../uikit/gpt/History/historyList";
import { FunctionsCard } from "../uikit/gpt/Functions/functionsCard";
import { Observer, observer } from "mobx-react-lite";
import { Prompt } from "../core/api/prompts/PromptStore";
import { PromptVersion } from "../core/api/prompts/PromptVersion";
import { TestCase } from "../core/api/prompts/TestCase";
import { HistoryHolder } from "../uikit/gpt/History/types";
import UIManager from "../core/misc/UIManager";
import { TestCaseOptions } from "./testCaseOptions";

interface Props {
  prompt: Prompt;
  version: PromptVersion;
  testCase?: TestCase;
}

export const GptEmulatorInternalModal: FC<Props> = observer(({ prompt, version, testCase }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const formatConfidence = (confidence: number | undefined | null) => {
    if (confidence === null || confidence === undefined) {
      return "";
    }
    return `(${(confidence * 100).toFixed(0)}%)`;
  };
  const panes = [
    {
      menuItem: "Prompt",
      pane: (
        <TabPane attached={false}>
          <Input
            placeholder={"prompt name"}
            onChange={(e, data) => prompt.setName(data.value)}
            value={prompt.name ?? ""}
          />
          <TextArea
            rows={10}
            placeholder="Prompt"
            value={version.prompt}
            className="dasha-textarea"
            onChange={(event, data) => version.setPrompt(data.value as string)}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CopyButtonAction tooltipText="Copy Prompt" clipboard={"`" + version.prompt + "`"} hasIcon={false}>
              Copy prompt
            </CopyButtonAction>
          </div>
        </TabPane>
      ),
    },
    {
      menuItem: "PromptDiff",
      pane: (
        <TabPane attached={false}>
          <ReactDiffViewer
            styles={{
              variables: {
                dark: {
                  diffViewerBackground: "none",
                  diffViewerTitleBackground: "rgba(0,0,0,0.15)",
                  codeFoldBackground: "rgba(0,0,0,0.15)",
                  gutterBackground: "rgba(0,0,0,0.15)",
                  gutterBackgroundDark: "rgba(0,0,0,0.15)",
                  emptyLineBackground: "rgba(0,0,0,0.15)",
                },
              },
            }}
            useDarkTheme
            oldValue={version.initialPrompt}
            newValue={version.prompt}
            splitView={true}
          />
          <ActionButton
            disabled={version.initialPrompt === version.prompt}
            onClick={() => version.setPrompt(version.initialPrompt)}
          >
            {" "}
            Reset prompt changes{" "}
          </ActionButton>
        </TabPane>
      ),
    },
    {
      menuItem: "Options",
      pane: (
        <TabPane attached={false}>
          <GptOptionsTable options={version.gptOptions} functions={version.functions}/>
          <ActionButton
            disabled={version.gptOptions?.isDirty === false}
            onClick={() => version.gptOptions?.ResetChanges()}
          >
            {" "}
            Reset options changes{" "}
          </ActionButton>
        </TabPane>
      ),
    },
    {
      menuItem: "Functions",
      pane: (
        <TabPane attached={false}>
          <FunctionsCard f={version.functions} />
          <ActionButton disabled={!version.isDirty} onClick={() => version.functions.Reset()}>
            {" "}
            Reset functions changes{" "}
          </ActionButton>
        </TabPane>
      ),
    },
    {
      menuItem: "Test case",
      pane: <TabPane attached={false}>{testCase && <TestCaseOptions testCase={testCase} />}</TabPane>,
    },
    {
      menuItem: "This test args",
      pane: (
        <TabPane attached={false}>
          {testCase?.args && (
            <>
              <GptArgsTable args={testCase.args} />
              <ActionButton onClick={() => testCase.args.ResetChanges()}> Reset args changes </ActionButton>
            </>
          )}
          {!testCase && <Message>Select test to continue</Message>}
        </TabPane>
      ),
    },
    {
      menuItem: "This test results",
      active: testCase !== undefined,
      pane: (
        <TabPane attached={false}>
          {!testCase && <Message>Select test to continue</Message>}
          {testCase && (
            <Segment style={{ overflowY: "auto", maxHeight: "82vh" }}>
              <Table celled className="dasha-semantic-table" columns={4}>
                <TableHeader>
                  <Table.Row>
                    <TableHeaderCell key="ref_header">Reference</TableHeaderCell>
                    {testCase.testResult?.map((x, idx) => (
                      <TableHeaderCell key={`result_${idx}`} negative={x.status === "Failed"}>
                        <p>
                          <Icon
                            width={16}
                            height={16}
                            style={{ marginRight: "4px" }}
                            name={x.status === "Failed" ? "test-failed" : "test-success"}
                          />{" "}
                          {x.status} {formatConfidence(x.actualResult?.confidence)}: {x.statusDescription}
                        </p>
                      </TableHeaderCell>
                    ))}
                  </Table.Row>
                </TableHeader>

                <TableBody>
                  <TableRow>
                    <TableCell key="ref_result">
                      <HistoryList
                        canEdit={true}
                        canEditPrefix={true}
                        history={testCase.refResult}
                        prefix={testCase.history}
                        functions={version.functions}
                      />
                    </TableCell>
                    {testCase?.testResult?.map((x, idx) => (
                      <TableCell key={`test_result_${idx}`}>
                        <HistoryList history={new HistoryHolder(x?.actualResult?.generatedMessages ?? [])} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Segment>
          )}
        </TabPane>
      ),
    },
  ];

  return (
    <>
      <Tab renderActiveOnly={false} menu={{ pointing: true }} panes={panes} />
      <Loader active={loading}>Loading..</Loader>
      {error && <Message negative>{error}</Message>}
    </>
  );
});
