import styled from "styled-components";
import { StrokeButton } from "../uikit";
import { PureButton } from "../uikit/Button";

export const SandboxContainer = styled.div<{ isInteractive?: boolean }>`
  display: none;
  position: absolute;
  top: 48px;
  left: 0;
  bottom: 57px;
  right: 0;
  z-index: 10;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const ToggleButton = styled(PureButton)`
  position: absolute;
  top: 12px;
  left: -12px;
  background: #161729;
  border: 1px solid #6f6cfa;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  z-index: 10;

  svg {
    fill: #6f6cfa;
  }
`;

export const Header = styled.div`
  width: 100%;
  overflow-x: auto;

  display: flex;
  justify-content: space-between;
  background: rgba(247, 247, 253, 0.02);
`;

export const RunnerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px;
  box-sizing: border-box;
  height: 100%;

  button {
    width: 100%;
    margin: 12px 0;
  }

  p {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #ffffff;
    margin: 4px 0;
  }
`;

export const Panel = styled.div<{ isOpen }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  div {
    visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  flex: 1;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Control = styled.div<{ hidden?: boolean }>`
  display: ${(p) => (p.hidden ? "none" : "flex")};
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  flex-shrink: 0;

  width: 100%;
  padding: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
`;

export const ControlFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 12px;
  flex: 1;
`;

export const CallDuration = styled.span`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
  flex-shrink: 0;
`;

export const CallButton = styled.button<{ $state: "start" | "end" }>`
  background: ${(p) => (p.$state === "start" ? "#02CCCB" : "#F24649")};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  border: none;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  svg {
    margin: -15px;
    transform-origin: center;
    transition: 0.3s transform;
    transform: rotateZ(${(p) => (p.$state === "start" ? 0 : 225)}deg);
  }

  &:disabled {
    cursor: default;
    svg {
      opacity: 0.5 !important;
    }
  }
`;

export const StopButton = styled(StrokeButton)`
  border-radius: 50%;
  height: 32px;
  width: 32px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;

  &:after {
    background: #ffffff;
    border-radius: 1px;
    display: block;
    content: "";
    width: 12px;
    height: 12px;
  }
`;
