import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "../expressions";
import { DslElement, RawElement } from "../common";

export class ContextVariable extends DslElement implements interfaces.IContextVariable {
  elementType: DslElementType.ContextVariable = DslElementType.ContextVariable;
  name: RawElement<string>;
  type: RawElement<string>;
  initialValue: interfaces.OptionalValue<Expression>;

  constructor(obj: interfaces.IContextVariable, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = helpers.ensureIsInstance(obj.name, RawElement<string>);
    this.type = helpers.ensureIsInstance(obj.type, RawElement<string>);
    if (obj.initialValue.hasValue) {
      this.initialValue = {
        hasValue: true,
        value: helpers.ensureIsExpressionInstance(obj.initialValue.value),
      };
    } else {
      this.initialValue = { hasValue: false, value: null };
    }
  }
  toJson(): interfaces.IContextVariable {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      type: this.type.toJson(),
      initialValue: interfaces.helpers.createOptionalValue(this.initialValue.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent) + this.name.value,
      `: ${this.type.value}`,
      this.initialValue.hasValue ? ` = ${this.initialValue.value.toDsl(indent)}` : "",
      ";"
    ].join("")
  }
}
