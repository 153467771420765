import * as React from "react";
import Project from "../core/explorer/Project";

import Modal from "../uikit/Modal";
import { ModalTitle } from "../uikit/Modal/styled";
import { ActionButton, Icon, StrokeButton } from "../uikit";
import useRename from "./useRename";

interface Props {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
}

const RenameWidget: React.FC<Props> = ({ isOpen, project, onClose }) => {
  const { renameInputs, isDisabled, name, description } = useRename(project);
  const [isLoading, setLoading] = React.useState(false);

  const submit = async () => {
    setLoading(true);
    await project.rename(name, description);
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} isDisabled={isLoading} onClose={onClose}>
      <ModalTitle>Edit app details</ModalTitle>
      {renameInputs()}

      <div style={{ display: "flex", marginTop: 16 }}>
        <StrokeButton disabled={isLoading} style={{ flex: 1 }} onClick={onClose}>
          Close unchanged
        </StrokeButton>
        <ActionButton disabled={isDisabled || isLoading} style={{ flex: 1, marginLeft: 16 }} onClick={submit}>
          {isLoading && <Icon name="spinner" style={{ marginRight: 8, width: 18 }} />}
          Save changed
        </ActionButton>
      </div>
    </Modal>
  );
};

export default RenameWidget;
