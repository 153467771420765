import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import * as antlrHelpers from "./antlr/helpers";
import * as antlr4 from "antlr4";

export type LocationProvider = monaco.IRange | monaco.IRange[] | antlr4.ParserRuleContext | antlr4.Token | undefined;
export function createRange(locationProvider: LocationProvider): monaco.IRange {
  if (isIRange(locationProvider)) {
    const range = locationProvider;
    return range;
  }
  if (
    Array.isArray(locationProvider) &&
    locationProvider.length > 0 &&
    locationProvider.find((el) => !isIRange(el)) === undefined
  ) {
    const ranges = locationProvider;
    const firstRange = ranges[0];
    const lastRange = ranges[ranges.length - 1];
    const range = {
      startLineNumber: firstRange.startLineNumber,
      startColumn: firstRange.startColumn,
      endLineNumber: lastRange.endLineNumber,
      endColumn: lastRange.endColumn,
    };
    return range;
  }
  if (locationProvider instanceof antlr4.Token) {
    const token = locationProvider;
    return tokenToRange(token);
  }
  try {
    const ctx = locationProvider;
    return ctxToRange(ctx);
  } catch (e) {
    /** locationProvider is not instance of ParserRuleContext or TerminalNodeImpl */
  }
  throw new Error(`Unsupported type of location provider`);
}

export function getValueInRange(source: string, range: monaco.IRange) {
  const lines = source.split("\n");
  const firstLine = lines[range.startLineNumber - 1]?.slice(range.startColumn - 1);
  const middleLines = lines.slice(range.startLineNumber, range.endLineNumber - 1);
  const lastLine = lines[range.endLineNumber - 1]?.slice(undefined, range.endColumn - 1);
  return [firstLine, ...middleLines, lastLine].join("\n");
}

export function createRangeFromPositions(start: monaco.IPosition, end: monaco.IPosition): monaco.IRange {
  return {
    startLineNumber: start.lineNumber,
    startColumn: start.column,
    endLineNumber: end.lineNumber,
    endColumn: end.column,
  };
}

export function createPosition(lineNumber: number, column: number): monaco.IPosition {
  return { lineNumber, column };
}

export function rangeContainsPosition(range: monaco.IRange, position: monaco.IPosition): boolean {
  const positionInLines = range.startLineNumber <= position.lineNumber && position.lineNumber <= range.endLineNumber;
  /** position is in different line */
  if (!positionInLines) return false;
  /** range is single line */
  if (range.startLineNumber === range.endLineNumber)
    return range.startColumn <= position.column && position.column <= range.endColumn;
  /** position is in first line */
  if (range.startLineNumber === position.lineNumber) return range.startColumn <= position.column;
  /** position is in last line */
  if (range.endLineNumber === position.lineNumber) return position.column <= range.endColumn;
  /** position is strictly between lines */
  return true;
}

export function isIRange(o: any): o is monaco.IRange {
  const requiredProperties = ["startLineNumber", "startColumn", "endLineNumber", "endColumn"];
  try {
    let check = true;
    requiredProperties.forEach((p) => {
      check &&= o[p] !== undefined;
    });
    return check;
  } catch (e) {
    return false;
  }
}

export function ctxToRange(ctx: any): monaco.IRange {
  const startToken = antlrHelpers.isTerminal(ctx) ? ctx.symbol : ctx.start;
  const stopToken = antlrHelpers.isTerminal(ctx) ? ctx.symbol : ctx.stop;
  /** Note: antlr token columns are zero-indexed, token stop includes the last symbol */
  const start = createPosition(startToken.line, startToken.column + 1);
  const length = stopToken.stop - startToken.start;
  const endColumn =
    startToken.column === stopToken.column && startToken.line === stopToken.line
      ? stopToken.column + length + 1
      : stopToken.column + 1;
  const stop = createPosition(stopToken.line, endColumn);
  return createRangeFromPositions(start, stop);
}

export function tokenToRange(token: any): monaco.IRange {
  /** Note: antlr token columns are zero-indexed, token stop includes the last symbol */
  const start = createPosition(token.line, token.column + 1);
  const length = token.stop - token.start;
  const stop = createPosition(token.line, token.column + length + 1);
  return createRangeFromPositions(start, stop);
}
