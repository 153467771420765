// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v7vZ5QTYJqPipAptHWUD::after {
    content: "✨";
    display: inline-block;
    font-size: 1.2em;
    cursor: pointer;
    margin: 0 8px;
}

.Kb4WH3QzNmWRuyvG7wYV::after {
    content: "⌛";
    display: inline-block;
    font-size: 1.2em;
    margin: 0 8px;
}
.JZXtsyDQCTi3VGatb55R::after {
    content: "🛠";
    display: inline-block;
    font-size: 1.2em;
    margin: 0 8px;
}
`, "",{"version":3,"sources":["webpack://./src/core/workspace/dataset-storage/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,aAAa;IACb,qBAAqB;IACrB,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".paraphrase::after {\n    content: \"✨\";\n    display: inline-block;\n    font-size: 1.2em;\n    cursor: pointer;\n    margin: 0 8px;\n}\n\n.loading::after {\n    content: \"⌛\";\n    display: inline-block;\n    font-size: 1.2em;\n    margin: 0 8px;\n}\n.debugger::after {\n    content: \"🛠\";\n    display: inline-block;\n    font-size: 1.2em;\n    margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paraphrase": `v7vZ5QTYJqPipAptHWUD`,
	"loading": `Kb4WH3QzNmWRuyvG7wYV`,
	"debugger": `JZXtsyDQCTi3VGatb55R`
};
export default ___CSS_LOADER_EXPORT___;
