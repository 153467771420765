import { loadWASM } from "onigasm";
import { Registry } from "monaco-textmate";
import { wireTmGrammars } from "monaco-editor-textmate";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

// @ts-ignore
import onigasm from "onigasm/lib/onigasm.wasm";

import KeyboardManager from "../misc/KeyboardManager";
import TextStorage from "../workspace/TextStorage";

import dashaappScheme from "./resources/schemas/dashaapp.json";
import phrasemapScheme from "./resources/schemas/phrasemap.json";
import dslGrammars from "./resources/grammars.js";
import theme from "./resources/theme.json";

class Editor {
  static readonly Instance = new Editor();
  readonly options = new Map<string, string>();
  readonly element = document.createElement("div");
  readonly editor: monaco.editor.IStandaloneCodeEditor;
  private storage: TextStorage | null = null;
  private readonlyMessage = "Readonly";

  constructor() {
    monaco.languages.register({ id: "dsl", extensions: ["dsl"] });
    monaco.languages.register({ id: "json", extensions: ["dashaapp", "json"] });
    monaco.languages.setLanguageConfiguration("dsl", {
      comments: { lineComment: "//", blockComment: ["/*", "*/"] },
      brackets: [
        ["(", ")"],
        ["{", "}"],
        ["[", "]"],
      ],
      surroundingPairs: [
        { open: "(", close: ")" },
        { open: "[", close: "]" },
        { open: "{", close: "}" },
        { open: '"', close: '"' },
      ],
    });
    monaco.editor.defineTheme("dashaTheme", theme as monaco.editor.IStandaloneThemeData);

    this.element.style.width = "100%";
    this.element.style.height = "100%";
    this.editor = monaco.editor.create(this.element, {
      theme: "dashaTheme",
      automaticLayout: true,
      fixedOverflowWidgets: true,
      minimap: { enabled: false },
      tabSize: 2,

      scrollbar: {
        horizontalScrollbarSize: 8,
        verticalScrollbarSize: 8,
      },
    });

    this.editor.onKeyDown((e) => {
      KeyboardManager.handleKeyDown(e.browserEvent);
    });

    const messageContribution: any = this.editor.getContribution("editor.contrib.messageController");
    this.editor.onDidAttemptReadOnlyEdit(() => {
      messageContribution.showMessage(this.readonlyMessage, this.editor.getPosition());
    });

    void this.highlightDsl();
    this.loadSchemas();

    this.editor.addAction({
      id: "editor-wordwrap",
      label: "Toggle word wrap",
      contextMenuGroupId: "1_modification",
      contextMenuOrder: 3,
      keybindings: [],

      run: () => {
        let value = this.options.get("editor.wordWrap") ?? "off";
        value = value === "off" ? "on" : "off";
        this.options.set("editor.wordWrap", value)
        this.updateStorage(this.storage);
      },
    });

    monaco.languages.registerCodeLensProvider("*", {
      provideCodeLenses: () => ({
        lenses: [],
        dispose: () => 0,
      }),
    });

    // compiler options
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES2020,
      allowNonTsExtensions: true,
      moduleResolution: 2,
      allowSyntheticDefaultImports: true,
      allowJs: true,
      strictNullChecks: true,
      skipLibCheck: true,
    });

    monaco.languages.typescript.typescriptDefaults.addExtraLib(
      `
        declare module "*" {}
        const sandbox: { endpoint: string; } | null;
      `,
      "file:///index"
    );
  }

  clearSchemas() {
    this.validateJson([]);
  }

  loadSchemas() {
    this.validateJson([
      { files: ["dashaapp.json"], schema: dashaappScheme },
      { files: ["phrasemap.json"], schema: phrasemapScheme },
    ]);
  }

  validateJson(list: { files: string[]; schema: unknown }[]): void {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      // @ts-ignore
      trailingCommas: "error",
      enableSchemaRequest: true,
      schemas: list.map(({ files, schema }) => ({
        uri: monaco.Uri.file(files[0]).toString(),
        fileMatch: files,
        schema,
      })),
    });
  }

  async toggleFullScreen() {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      }
    }
  }

  updateOptions(storage: TextStorage | null) {
    this.editor.updateOptions({
      readOnly: storage?.isReadonly,
      wordWrap: this.options.get("editor.wordWrap") ?? "off",
    });
  }

  updateStorage(storage: TextStorage | null, options = { isReadonly: false, readonlyMessage: "Readonly" }) {
    if (this.storage === storage) {
      return;
    }
    this.storage?.closeInEditor(this.editor);
    this.readonlyMessage = options.readonlyMessage;
    this.storage = storage;

    this.editor.setModel(storage?.model ?? null);
    this.storage?.openInEditor(this.editor);
    this.updateOptions(storage);
  }

  revealRange(from: number, to: number): void {
    const range = new monaco.Range(from, 0, to, 0);
    this.editor.revealRangeInCenter(range, monaco.editor.ScrollType.Smooth);
    this.editor.setSelection(range);
  }

  async highlightDsl(): Promise<void> {
    await loadWASM(onigasm);
    const registry = new Registry({
      getGrammarDefinition: async () => {
        return {
          format: "json",
          content: dslGrammars,
        };
      },
    });

    const grammars = new Map();
    grammars.set("dsl", "source.dashaScriptingLanguage");
    await wireTmGrammars(monaco, registry, grammars, this.editor);
  }
}

export default Editor;
