import React, { FC, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import TextStorage from "@core/workspace/TextStorage";
import Editor from "@core/code-editor";
import * as S from "./styled";

interface Props {
  storage: TextStorage | null;
  isReadonly?: boolean;
}

const EditorPanel: FC<Props> = ({ storage, isReadonly }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.appendChild(Editor.Instance.element);
  }, []);

  useEffect(() => {
    Editor.Instance.updateStorage(storage, {
      isReadonly: isReadonly || storage?.isReadonly || false,
      readonlyMessage: isReadonly
        ? "Can't edit version during active deployment"
        : "You do not have access to edit this project",
    });
  }, [storage, isReadonly]);

  return <S.Container ref={ref} />;
};

export default observer(EditorPanel);
