import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { observer } from "mobx-react-lite";
import "./styles.css";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerQueues } from "./CustomerQueues";
import { FailedConversations } from "./FailedConversations";

const DiagnosticsPanel: FC<{}> = ({ }) => {
  return (
    <div className="defaultRoot" id="grid-workspace">
        <CustomerInfo/>
        <CustomerQueues/>
        <FailedConversations/>
    </div>
  );
};

export default observer(DiagnosticsPanel);
