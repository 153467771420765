import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { makeAutoObservable, runInAction } from "mobx";

export class GroupsStore {
  private readonly account: IAccount;
  public Loading: boolean;
  public Groups: Map<string, dasha.groups.GroupInfo>;
  constructor(account: IAccount) {
    this.account = account;
    this.Groups = new Map<string, dasha.groups.GroupInfo>();
    makeAutoObservable(this);
  }

  public TryReload() {
    if (this.Groups.size === 0 && !this.Loading) {
      this.Reload();
    }
  }

  public GetById(id: string): [string, dasha.groups.GroupInfo] | undefined {
    for (var [k,v] of this.Groups) {
        if (v.id === id) {
            return [k, v];
        }
    }

    return undefined;
  }

  private Reload() {
    this.Loading = true;
    const a = this.account.connect();
    dasha.groups
      .listGroups({ account: a })
      .then((groups) => {
        runInAction(() => {
          for (var [k, v] of Object.entries(groups)) {
            this.Groups.set(k, v);
          }
        });
      })
      .finally(() => {
        runInAction(() => {
          this.Loading = false;
        });
      });
  }
}
