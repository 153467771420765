import * as React from "react";
import { observer } from "mobx-react-lite";

import DatasetStorage from "../../core/workspace/dataset-storage/DatasetStorage";
import { ActionButton, Icon } from "../../uikit";
import { PureButton, StrokeButton } from "../../uikit/Button";
import { Tooltip } from "../../uikit/popup";

import IntentEditor from "./IntentEditor";
import * as S from "./styled";

interface Props {
  phrase: string;
  dataset: DatasetStorage;
  isProfiler?: boolean;
  onCreate: (name: string) => void;
  onClose: () => void;
}

const CreateIntent: React.FC<Props> = ({ phrase, dataset, isProfiler, onClose, onCreate }) => {
  const [editor] = React.useState(() => {
    const editor = new IntentEditor(dataset);
    editor.setTemplatePhrase(phrase);
    editor.applyTemplate();
    return editor;
  });

  const handleKeyDown = (e) => e.key === "Enter" && editor.applyTemplate();
  const handleCreate = () => {
    onCreate(editor.name);
    dataset.createIntent(editor.serialize());
  };

  return (
    <>
      <S.Header>
        {!isProfiler && (
          <S.ButtonBack onClick={onClose}>
            <Icon name="cursor-back" />
          </S.ButtonBack>
        )}

        <h1>Create a new intent</h1>

        <ActionButton disabled={editor.isValid == false} onClick={handleCreate}>
          save
        </ActionButton>
        {isProfiler && <StrokeButton onClick={onClose}>cancel</StrokeButton>}
      </S.Header>
      <S.Body>
        <S.Section>
          <S.Title>
            <p>
              Intent name ({editor.name.length}/{editor.maxNameLength} characters)
            </p>

            <Tooltip text="Intents" position="top center">
              <PureButton>
                <Icon name="info" />
              </PureButton>
            </Tooltip>
          </S.Title>

          <S.AddPhraseField>
            <input
              value={editor.name}
              onChange={(e) => editor.setName(e.target.value)}
              placeholder="Type a uniq intent name without whitespaces"
            />
          </S.AddPhraseField>

          {editor.isValidName === false && <S.Attention>This name already exist in {dataset.name}</S.Attention>}
        </S.Section>

        <S.Section>
          <S.Title>
            <p>Entering phrases</p>
            <Tooltip text="Entities" position="top center">
              <PureButton>
                <Icon name="info" />
              </PureButton>
            </Tooltip>
          </S.Title>

          <S.AddPhraseField>
            <input
              value={editor.templatePhrase}
              onKeyDown={handleKeyDown}
              onChange={(e) => editor.setTemplatePhrase(e.target.value)}
              placeholder="Type a phrase and press 'Enter' to add"
            />

            {editor.templatePhrase.length > 0 && <PureButton onClick={() => editor.applyTemplate()}>Add</PureButton>}
          </S.AddPhraseField>
        </S.Section>

        <S.Section>
          <S.Title>
            <p>List of phrases</p>
          </S.Title>

          {editor.phrases.length < 5 && (
            <S.Attention>Attention: At least 5 phrases are required to create an intent</S.Attention>
          )}

          {editor.phrases.map((phrase, i) => (
            <S.PhraseField key={phrase.id} isLoading={phrase.generating}>
              <span
                suppressContentEditableWarning={true}
                contentEditable={i === 0 ? "false" : ("plaintext-only" as any)}
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onFocus={() => editor.renamePhrase(phrase, phrase.text)}
                onBlur={(e) => {
                  if (e.target.textContent?.length) editor.renamePhrase(phrase, e.target.textContent);
                  else editor.removePhrase(phrase.id);
                }}
              >
                {phrase.text}
              </span>

              {phrase.generated && <S.GeneratedDecorator />}

              {phrase.generating ? (
                <div>
                  <Icon name="spinner" style={{ width: 16 }} />
                </div>
              ) : (
                <div>
                  <Tooltip text="Generate paraphrase" position="top center">
                    <PureButton onClick={() => editor.paraphrase(phrase)}>
                      <Icon name="generate" />
                    </PureButton>
                  </Tooltip>

                  <Tooltip text="Remove phrase" position="top center">
                    <PureButton disabled={i === 0} onClick={() => editor.removePhrase(phrase.id)}>
                      <Icon name="trash" />
                    </PureButton>
                  </Tooltip>
                </div>
              )}
            </S.PhraseField>
          ))}
        </S.Section>
      </S.Body>
    </>
  );
};

export default observer(CreateIntent);
