import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { makeAutoObservable, runInAction } from "mobx";

export class DiagnosticsStore {
  private readonly account: IAccount;
  public Queues?: dasha.diagnostics.QueuesInformation;
  public Limits?: dasha.diagnostics.Limits;
  public Instances: dasha.diagnostics.InstanceInformation[] = [];
  constructor(account: IAccount) {
    this.account = account;
    makeAutoObservable(this);
  }

  public Reload() {
    const a = this.account.connect();
    
    dasha.diagnostics.getQueueStates({account: a})
      .then((data) => { this.Queues = data })
      .catch(() => {});
    dasha.diagnostics.getLimits({account: a})
      .then((data) => { this.Limits = data })
      .catch(() => {});
    dasha.diagnostics.getInstances({account: a})
      .then((data) => { this.Instances = data })
      .catch(() => {});
  }
}
