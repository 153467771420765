import React, { FC } from "react";

import { ReactComponent as CheckboxIcon } from "../../../../assets/checkbox.svg";
import { ReactComponent as UncheckboxIcon } from "../../../../assets/uncheckbox.svg";
import { ReactComponent as MinusCheckboxIcon } from "../../../../assets/minus-checkbox.svg";

import * as S from "./styled";

interface Props {
  checked: boolean;
  isReadOnly?: boolean;
  isPartiallySelected?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: FC<Props> = ({ checked, isReadOnly, isPartiallySelected, onChange }) => {
  const handleChange = () => {
    onChange?.(!checked);
  };

  return (
    <S.Container $checked={checked} $isReadOnly={isReadOnly}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      {isPartiallySelected && <MinusCheckboxIcon />}
      {checked && !isPartiallySelected && <CheckboxIcon />}
      {!checked && !isPartiallySelected && <UncheckboxIcon />}
    </S.Container>
  );
};

export default Checkbox;
