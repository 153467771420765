import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { Icon } from "../uikit";

export const InputField = styled.div`
  position: relative;
  min-height: 32px;
  width: 100%;

  svg {
    cursor: pointer;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }

  textarea {
    margin: 0;
    padding-right: 34px;
    height: 32px;
    resize: none;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 8px;
    border: none;
    padding: 2px 8px;
    margin-bottom: -5px;
    box-sizing: border-box;
    color: #fff;
    width: 100%;

    &:focus {
      outline: none;
    }

    &,
    &::placeholder {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
    }

    &:disabled {
      cursor: default;
      color: rgba(255, 255, 255, 0.5);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

interface Props {
  onSubmit: (value: string) => void;
  isLoading: boolean;
}

const FieldInput: FC<Props> = ({ onSubmit, isLoading }) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isLoading || ref.current == null) return;
    ref.current.value = "";
    ref.current.focus();
  }, [isLoading]);

  const onInput = (e) => {
    const elem = e.target;
    elem.style.height = "1px";
    elem.style.height = elem.scrollHeight + "px";
  };

  const onKeyDown = (e) => {
    if (e.key != "Enter") return;
    e.preventDefault();
    submit();
  };

  const submit = () => {
    if (ref.current == null) return;
    onSubmit(ref.current.value);
  };

  return (
    <InputField>
      <textarea disabled={isLoading} ref={ref} onInput={onInput} onKeyDown={onKeyDown} placeholder="Enter text" />
      {isLoading ? <Icon name="spinner" width={16} height={16} /> : <Icon name="arrow-up" onClick={submit} />}
    </InputField>
  );
};

export default FieldInput;
