import { LabelModel } from "@projectstorm/react-diagrams";
import LinkModel from "../DirectionalLink/Model";

class LinkLabelModel extends LabelModel {
  static type = "transition-label";
  value: string;
  angle = 0;

  constructor(value: string) {
    super({ offsetY: 0, type: LinkLabelModel.type });
    this.value = value || "";
  }

  serialize() {
    this.angle = (this.getParent() as LinkModel)?.styles.angle || 0;

    return {
      ...super.serialize(),
      value: this.value,
      angle: this.angle,
    };
  }
}

export default LinkLabelModel;
