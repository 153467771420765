import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import UIManager from "../../../core/misc/UIManager";
import { IDisposable } from "../../misc/emitter";
import api from "../../account/api";

import { findLiteralInRange } from "./ast.helpers";
import DatasetStorage from "./DatasetStorage";
import styles from "./styles.module.css";

class ParaphrasePlugin {
  private handleMouseDown?: IDisposable;
  private handleAction?: IDisposable;
  constructor(readonly storage: DatasetStorage) {}

  activate(editor: monaco.editor.IStandaloneCodeEditor) {
    this.handleMouseDown = editor.onMouseDown((e) => this.onClickDecorator(e.event.target));
    this.handleAction = editor.addAction({
      id: "paraphrase-plugin",
      label: "Generate Paraphrase",
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10, monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_G],
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1.5,
      run: async () => {
        try {
          const range = editor.getSelection();
          if (range == null) return;
          await this.generateParaphrase(range.startLineNumber, range.endLineNumber);
        } catch (e) {
          console.error(e);
          UIManager.notice("Something wrong. Failed to generate paraphrases");
        }
      },
    });
  }

  dispose() {
    this.handleMouseDown?.dispose();
    this.handleAction?.dispose();
  }

  onClickDecorator({ classList }: HTMLElement) {
    if (classList.contains(styles.paraphrase) == false) return;

    const location = Array.from(classList).find((cl) => cl.includes("loc_"));
    if (!location) return;

    const [, startLineNumber, startColumn, endLineNumber, endColumn] = location?.split("_")?.map(Number) ?? [];
    const range = { startColumn, startLineNumber, endColumn, endLineNumber };
    const pushRange = {
      startLineNumber,
      startColumn: startColumn,
      endColumn: startColumn,
      endLineNumber: startLineNumber,
    };

    const indent = this.storage.model
      .getValueInRange({ startLineNumber, endLineNumber: startLineNumber, startColumn: 0, endColumn: Infinity })
      .split('"')[0];

    setTimeout(() => {
      const operations: monaco.editor.IIdentifiedSingleEditOperation[] = [{ range, text: null }];
      if (classList.contains("border") == false) {
        operations.push({ range: pushRange, text: ",\n" + indent });
      }

      this.storage.model.pushEditOperations(null, operations, () => null);
    }, 100);
  }

  async generateParaphrase(from: number, to: number) {
    const ast = this.storage.ast;
    if (ast == null) {
      UIManager.notice("Before generate paraphrases fix json file");
      return;
    }

    const literals = findLiteralInRange(ast, from, to);
    const path = literals[0].path;
    const key = path.join(".");

    const phrases = literals.slice(0, 10).filter((literal) => literal.path.join(".") === key);
    const phrasesLiterals = phrases.map((v) => v.value);

    this.storage.addDecorators(key, phrasesLiterals, styles.loading);

    // Template entites syntax to paraphrases markup
    const entities: string[][] = [];
    const processing = phrases.map((phrase, i) => {
      entities[i] = [];
      return phrase.value.replaceAll(/(\(.+?\))*\[(.+?)\]/g, (a, b, entity) => {
        entities[i].push(entity);
        return `[entity_${entities[i].length}]`;
      });
    });

    const account = this.storage.project.account;
    const data = await api.paraphrase.paraphase(processing, account).catch((error) => {
      this.storage.removeDecorators(key, phrasesLiterals, styles.loading);
      throw error;
    });

    const document = this.storage.document;
    const editable: string[] = path.reduce((acc, k) => acc[k], document);
    this.storage.removeDecorators(key, phrasesLiterals, styles.loading);

    const finalPhrases = data.flatMap(({ paraphrases }, i) =>
      paraphrases.flatMap((phrase) => {
        if (editable.includes(phrase)) return [];
        const index = editable.findIndex((existed) => existed === phrases[i]?.value);
        if (index == null) return [];

        // Replace paraphrases syntax to phrqses with existng entites markup
        const templatedPhrase = phrase.replaceAll(/\[entity_(\d+?)\]/g, (entity, num) => {
          const t = entities[i]?.[num - 1];
          return t ? `[${t}]` : entity;
        });

        editable.splice(index + 1, 0, templatedPhrase);
        return [templatedPhrase];
      })
    );

    const edit = {
      range: this.storage.model.getFullModelRange(),
      text: JSON.stringify(document, null, 2),
    };

    this.storage.addDecorators(key, finalPhrases, styles.paraphrase);
    this.storage.model.pushEditOperations(null, [edit], () => null);
  }
}

export default ParaphrasePlugin;
