import * as React from "react";

import { Explorer } from "../core/explorer/Explorer";
import Project from "../core/explorer/Project";

import { ActionButton, Icon, StrokeButton } from "../uikit";
import { ModalTitle } from "../uikit/Modal/styled";
import Modal from "../uikit/Modal";
import useRename from "./useRename";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  explorer: Explorer;
  project: Project;
  onClose: () => void;
}

const DuplicateWidget: React.FC<Props> = ({ isOpen, explorer, project, onClose }) => {
  const { renameInputs, isDisabled, name, description } = useRename(project, true);
  const [isLoading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const submit = async () => {
    setLoading(true);
    const zip = await project.zip();
    const content = await zip.generateAsync({ type: "uint8array" });
    const { projectType } = project.metadata.customMetaData;
    const newProject = await explorer.createFromZip({ name, description, projectType, content });
    setLoading(false);
    onClose();
    navigate(`/project/${newProject.id}`);
  };

  return (
    <Modal isDisabled={isLoading} isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Clone this app</ModalTitle>
      {renameInputs("Cloned app name", "Cloned app description")}

      <div style={{ display: "flex", marginTop: 16 }}>
        <StrokeButton disabled={isLoading} style={{ flex: 1 }} onClick={onClose}>
          Сancel
        </StrokeButton>
        <ActionButton disabled={isDisabled || isLoading} style={{ flex: 1, marginLeft: 16 }} onClick={submit}>
          {isLoading && <Icon name="spinner" style={{ marginRight: 8, width: 18 }} />}
          Duplicate
        </ActionButton>
      </div>
    </Modal>
  );
};

export default DuplicateWidget;
