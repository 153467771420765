import { makeObservable, action, observable, toJS } from "mobx";
import debounce from "lodash/debounce";

import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import Cluster from "./Cluster";
import { hasItemInFiltersItem } from "./utils";
import { MenuOption } from "../../uikit/popup";
import { DebouncedFunc } from "lodash";

type FilterType = "selectedNodesTo" | "selectedNodesFrom" | "selectedEntities" | "selectedIntents";

class Filter {
  public searchRequest = "";
  public selectedNodesTo: Set<string> = new Set();
  public selectedNodesFrom: Set<string> = new Set();
  public selectedIntents: Set<string> = new Set();
  public selectedEntities: Set<string> = new Set();
  public nodesTo: MenuOption[] = [];
  public nodesFrom: MenuOption[] = [];
  public intents: MenuOption[] = [];
  public entities: MenuOption[] = [];
  public filteredData: Cluster[] = [];

  debounced: DebouncedFunc<any> | null = null;
  data: Cluster[] = [];

  constructor(data: Cluster[], private readonly dataset: DatasetStorage | undefined) {
    makeObservable(this, {
      searchRequest: observable,
      selectedNodesTo: observable,
      selectedNodesFrom: observable,
      selectedIntents: observable,
      selectedEntities: observable,
      nodesTo: observable,
      nodesFrom: observable,
      intents: observable,
      entities: observable,
      filteredData: observable,
      setFilterItems: action,
      setSearchRequest: action,
      selectFilterOption: action,
      resetSelectedFilters: action,
    });

    this.data = data;
    this.filteredData = data;
    this.setFilterItems();
  }

  setFilterItems() {
    const entitiesInData: Set<string> = new Set();
    const intentsInData: Set<string> = new Set();

    this.filteredData.forEach((cluster) => {
      cluster.phrases.forEach((phrase) => {
        const { toNode, fromNode } = phrase.transition;

        if (!this.nodesTo.find((nodeTo) => nodeTo.label === toNode)) {
          this.nodesTo.push({
            action: () => this.selectFilterOption("selectedNodesTo", toNode),
            isSelected: this.selectedNodesTo.has(toNode),
            isEnable: true,
            label: toNode,
          });
        }

        if (!this.nodesFrom.find((nodeFrom) => nodeFrom.label === fromNode)) {
          this.nodesFrom.push({
            action: () => this.selectFilterOption("selectedNodesFrom", fromNode),
            isSelected: this.selectedNodesFrom.has(fromNode),
            isEnable: true,
            label: fromNode,
          });
        }
      });

      cluster.triggers.forEach((trigger) => {
        if (trigger.type === "intent" && !intentsInData.has(trigger.name)) {
          intentsInData.add(trigger.name);
        }

        if (trigger.type === "entity" && !entitiesInData.has(trigger.name)) {
          entitiesInData.add(trigger.name);
        }
      });
    });

    this.intents = this.dataset?.intents.map((intent) => {
      if (intentsInData.has(intent)) {
        return {
          action: () => this.selectFilterOption("selectedIntents", intent),
          isSelected: this.selectedIntents.has(intent),
          isEnable: true,
          label: intent,
        };
      }

      return {
        action: () => this.selectFilterOption("selectedIntents", intent),
        isSelected: this.selectedIntents.has(intent),
        isEnable: false,
        label: intent,
      };
    }) ?? [];

    Array.from(intentsInData).forEach((intent) => {
      if (!this.intents.find((i) => intent === i.label)) {
        this.intents.push({
          action: () => this.selectFilterOption("selectedIntents", intent),
          isSelected: this.selectedIntents.has(intent),
          isEnable: true,
          label: intent,
        });
      }
    });

    this.entities = this.dataset?.entities.map((entity) => {
      if (entitiesInData.has(entity)) {
        return {
          action: () => this.selectFilterOption("selectedEntities", entity),
          isSelected: this.selectedIntents.has(entity),
          isEnable: true,
          label: entity,
        };
      }

      return {
        action: () => this.selectFilterOption("selectedEntities", entity),
        isSelected: this.selectedIntents.has(entity),
        isEnable: false,
        label: entity,
      };
    }) ?? [];

    Array.from(entitiesInData).forEach((entity) => {
      if (!this.entities.find((i) => entity === i.label)) {
        this.entities.push({
          action: () => this.selectFilterOption("selectedEntities", entity),
          isSelected: this.selectedEntities.has(entity),
          isEnable: true,
          label: entity,
        });
      }
    });
  }

  updateDataByFilters() {
    this.filteredData = this.data.filter((cluster) => {
      if (this.searchRequest) {
        cluster.toggleExpand(true);
      } else {
        cluster.toggleExpand(false);
      }

      let hasPhrasesWithFilters = false;

      cluster.phrases.forEach((phrase) => {
        const hasNodesTo = hasItemInFiltersItem(phrase.transition.toNode, this.selectedNodesTo);
        const hasNodesFrom = hasItemInFiltersItem(phrase.transition.fromNode, this.selectedNodesFrom);

        console.log({ hasNodesFrom, hasNodesTo });

        if (!hasNodesTo || !hasNodesFrom) {
          phrase.isInFilter = false;
          return;
        }

        let hasFilterEntities = this.selectedEntities.size === 0;
        let hasFilterIntents = this.selectedIntents.size === 0;

        if (
          this.selectedEntities.size > 0 &&
          Array.from(this.selectedEntities).some((entity) =>
            phrase.markableMessage.triggers.find((trigger) => trigger.name === entity)
          )
        ) {
          hasFilterEntities = true;
        }

        if (
          this.selectedIntents.size > 0 &&
          Array.from(this.selectedIntents).some((intent) =>
            phrase.markableMessage.triggers.find((trigger) => trigger.name === intent)
          )
        ) {
          hasFilterIntents = true;
        }

        if (
          hasFilterEntities &&
          hasFilterIntents &&
          hasNodesFrom &&
          hasNodesTo &&
          phrase.markableMessage.message.toLowerCase().includes(this.searchRequest.toLowerCase().trim())
        ) {
          hasPhrasesWithFilters = true;
          phrase.isInFilter = true;
        } else {
          phrase.isInFilter = false;
        }
      });

      if (!hasPhrasesWithFilters) {
        return false;
      }

      return true;
    });
  }

  public setSearchRequest(value: string) {
    if (value === "" || value.trim()) {
      this.searchRequest = value;
    }

    if (this.debounced) {
      this.debounced.cancel();
    }

    this.debounced = debounce(this.updateDataByFilters, 300);
    this.debounced();
  }

  public resetSelectedFilters(type: FilterType) {
    this[type].clear();

    this.updateDataByFilters();
  }

  public selectFilterOption(type: FilterType, name: string) {
    const isIncluded = this[type].has(name);

    if (isIncluded) {
      this[type].delete(name);
    } else {
      this[type].add(name);
    }

    if (type === "selectedNodesFrom") {
      const existingNodesTo = new Set();

      this.data.forEach((cluster) => {
        cluster.phrases.forEach((phrase) => {
          if (phrase.transition.fromNode === name) {
            existingNodesTo.add(phrase.transition.toNode);
          }
        });
      });

      this.nodesTo = this.nodesTo.map((option) => {
        if (existingNodesTo.has(option.label)) return { ...option, isEnable: true };

        return { ...option, isEnable: false };
      });
    }

    this.updateDataByFilters();
  }
}

export default Filter;
