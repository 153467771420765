import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { Icon } from "./Icon";
import { Tooltip } from "./popup";

const Btn = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Open Sans";
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);

  svg {
    margin-left: 4px;
  }
`;

interface Props {
  tooltipText: React.ReactNode;
  clipboard: string;
  hasIcon: boolean;
}

const CopyButton: FC<Props> = ({ tooltipText, clipboard, hasIcon, children }) => {
  const [isCopyied, setIsCopyied] = useState(false);
  const [timer, setTimer] = useState<null | NodeJS.Timeout>(null);

  const handleClick = async () => {
    try {
      await navigator?.clipboard.writeText(clipboard);
      setIsCopyied(true);

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }

      const newTimer = setTimeout(() => {
        clearTimeout(newTimer);
        setIsCopyied(false);
      }, 2000);

      setTimer(newTimer);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Tooltip position="top center" text={isCopyied ? "Copied" : tooltipText}>
      <Btn onClick={handleClick}>
        {children}
        { (hasIcon !== false) && <Icon name="copy-link-icon" />}
      </Btn>
    </Tooltip>
  );
};


export default observer(CopyButton);
