import styled from "styled-components";
import { Node } from "../SubRow/styled";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /* border-collapse: collapse; */

  user-select: auto;

  box-sizing: border-box;
`;

export const Table = styled.div<{ $isRunnerOpen: boolean }>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-collapse: collapse;

  user-select: auto;

  box-sizing: border-box;
  }
`;
