import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { Card, Segment } from "semantic-ui-react";
import { FunctionEditorCard } from "./functionEditor";
import { gptFunctionsStore } from "./types";

interface Props {
  f: gptFunctionsStore;
}

export const FunctionsCard: FC<Props> = observer(({ f }) => {
  return (
    <Segment style={{overflowY: "auto", maxHeight: '70vh'}}>
    <Card.Group itemsPerRow={1}>
      {f.Functions.map((x) => (
        <FunctionEditorCard f={x} />
      ))}
    </Card.Group>
    </Segment>
  );
});
