import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { Icon } from "./Icon";
import { Tooltip } from "./popup";
import { ActionButton } from "./Button";


interface Props {
  tooltipText: React.ReactNode;
  clipboard: (()=>string) | string;
  hasIcon: boolean;
}

const CopyButtonAction: FC<Props> = ({ tooltipText, clipboard, hasIcon, children }) => {
  const [isCopyied, setIsCopyied] = useState(false);
  const [timer, setTimer] = useState<null | NodeJS.Timeout>(null);

  const handleClick = async () => {
    try {
      const result = typeof(clipboard) === "string" ? clipboard : clipboard();
      await navigator?.clipboard.writeText(result);
      setIsCopyied(true);

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }

      const newTimer = setTimeout(() => {
        clearTimeout(newTimer);
        setIsCopyied(false);
      }, 2000);

      setTimer(newTimer);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Tooltip position="top center" text={isCopyied ? "Copied" : tooltipText}>
      <ActionButton onClick={handleClick}>
        {children}
        { (hasIcon !== false) && <Icon name="copy-link-icon" />}
      </ActionButton>
    </Tooltip>
  );
};


export default observer(CopyButtonAction);
