import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import Cluster from "@core/profiler/Cluster";
import { Phrase } from "@core/profiler/types";
import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import { MarkupType } from "@core/workspace/session-storage/devlogs";
import { ReactComponent as TransitionIcon } from "../../../../assets/transition.svg";

import { ReactComponent as DialogIcon } from "../../../../assets/dialog.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/plus.svg";

import { AddTrigger } from "../../../../../RunnerPanel/TriggersEdit/styled";
import MarkupPhrase from "../../../../../RunnerPanel/TriggersEdit/MarkupPhrase";
import CreateIntent from "../../../../../RunnerPanel/TriggersEdit/CreateIntent";
import Trigger from "../../../../../RunnerPanel/TriggersEdit/Trigger";
import SearchSelect from "../../../../../uikit/SearchSelect";
import Modal from "../../../../../uikit/Modal";

import Checkbox from "../Checkbox";
import * as S from "./styled";
import CreateEntity from "../CreateEntity";
import { Tooltip } from "../../../../../uikit/popup";

interface Props {
  searchRequest: string;
  dataset: DatasetStorage | undefined;
  cluster: Cluster;
  currentSessionPhrase: string | null;
  onShowDialog: (phrase: Phrase) => void;
}

interface IntentsProps {
  phrase: Phrase;
  dataset: DatasetStorage  | undefined;
}

const Intents = observer<IntentsProps>(({ phrase, dataset }) => {
  const [isPushIntent, setIsPushIntent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectPosition, setSelectPosition] = useState("top");
  const msg = phrase.markableMessage;

  useEffect(() => {
    if (!isPushIntent) return;

    const tableEl = document.querySelector(".data-markup-table > div > div");
    if (!tableEl) return;

    setSelectPosition(() => (tableEl.scrollTop - tableEl.clientHeight > 200 ? "bottom" : "top"));
  }, [isPushIntent]);

  const handleChange = (intent: string) => {
    setIsPushIntent(false);
    msg.addIntent(intent);
    msg.activeAll();
  };

  const handleCreate = (intent: string) => {
    setIsModalOpen(false);
    msg.addIntent(intent);
    msg.activeAll();
  };

  return (
    <div>
      {msg.triggers.map((trigger) => (
        <Trigger
          key={trigger.id}
          trigger={trigger}
          phrase={msg.message}
          readOnly={trigger.readOnly}
          isProfilerTrigger
          onMark={(mark: MarkupType) => {
            msg.markupTrigger(trigger.id, mark);
            msg.activeAll();
          }}
        />
      ))}
      {isPushIntent && dataset && (
        <SearchSelect
          focuses
          selected=""
          position={selectPosition}
          options={dataset.intents.filter((v) => msg.intents.includes(v) === false)}
          onRemove={() => setIsPushIntent(false)}
          onBlur={() => setIsPushIntent(false)}
          onChange={handleChange}
          newBtn={
            <S.NewIntentBtn onClick={() => setIsModalOpen(true)}>
              <PlusIcon />
              <i>Create a new intent</i>
            </S.NewIntentBtn>
          }
        />
      )}
      {!isPushIntent && dataset && <AddTrigger onClick={() => setIsPushIntent(true)}>+ Add intent</AddTrigger>}
      <Modal isOpen={isModalOpen} style={{ padding: 0 }}>
        <CreateIntent
          isProfiler
          dataset={dataset}
          phrase={msg.message}
          onClose={() => setIsModalOpen(false)}
          onCreate={handleCreate}
        />
      </Modal>
    </div>
  );
});

const SubRow: FC<Props> = ({ dataset, cluster, currentSessionPhrase, onShowDialog }) => {
  const [isCreatorEntityOpen, toggleIsCreatorEntityOpen] = useState(false);
  const [phraseForNewEntity, setPhraseForNewEntity] = useState<string | null>(null);
  const [currentPhrase, setCurrentPhrase] = useState<Phrase | null>(null);
  const [newEntityLoc, setNewEntityLoc] = useState<[number, number] | null>(null);

  return (
    <>
      {cluster.phrases.map((phrase) => {
        const msg = phrase.markableMessage;
        if (phrase.isInFilter === false) return null;

        return (
          <div key={`${phrase.uuid}`}>
            <S.Container $selected={currentSessionPhrase === phrase.uuid}>
              <div>
                <MarkupPhrase
                  dataset={dataset}
                  editable={msg}
                  onNewEntityClick={(value: string, loc: [number, number]) => {
                    setPhraseForNewEntity(value);
                    setCurrentPhrase(phrase);
                    setNewEntityLoc(loc);
                    toggleIsCreatorEntityOpen(true);
                  }}
                />
                <S.DialogBtn onClick={() => onShowDialog(phrase)}>
                  <DialogIcon />
                </S.DialogBtn>
              </div>
              <div>
                <S.CheckboxContainer>
                  <Checkbox
                    isReadOnly={!msg.hasInteractiveTriggers}
                    checked={msg.isMarked || !msg.hasInteractiveTriggers}
                    onChange={() => (msg.isMarked ? msg.deactiveAll() : msg.markCorrectPhrase())}
                  />
                </S.CheckboxContainer>
                <Intents phrase={phrase} dataset={dataset} />
              </div>
              <S.Node>
                <Tooltip
                  position={["top center", "top right"]}
                  text={
                    <S.NodeTooltip>
                      <span>
                        <b>Node from:</b>
                        <i>{phrase.transition.fromNode}</i>
                      </span>
                      <span>
                        <b>Node to:</b>
                        <i>{phrase.transition.toNode}</i>
                      </span>
                    </S.NodeTooltip>
                  }
                >
                  <div>
                    <span>
                      <TransitionIcon />
                    </span>
                  </div>
                </Tooltip>
              </S.Node>
            </S.Container>
          </div>
        );
      })}
      <Modal isOpen={isCreatorEntityOpen} style={{ padding: 0 }}>
        {isCreatorEntityOpen && (
          <CreateEntity
            hasArrowBack={false}
            phrase={phraseForNewEntity || ""}
            dataset={dataset}
            onClose={() => {
              setPhraseForNewEntity(null);
              toggleIsCreatorEntityOpen(false);
              setCurrentPhrase(null);
            }}
            onCreate={(entity: string) => {
              if (newEntityLoc === null) return;
              if (currentPhrase === null) return;
              currentPhrase.markableMessage.markupPhrase(
                newEntityLoc[0],
                newEntityLoc[1],
                entity,
                null,
                phraseForNewEntity
              );
              setPhraseForNewEntity(null);
              toggleIsCreatorEntityOpen(false);
              setCurrentPhrase(null);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default observer(SubRow);
