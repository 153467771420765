import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as utils from "../helpers";
import { DslElement, RawElement } from "../common";
import { Expression } from "../expressions";

export class ArgumentDeclaration extends DslElement implements interfaces.IArgumentDeclaration {
  elementType: DslElementType.ArgumentDeclaration = DslElementType.ArgumentDeclaration;
  name: RawElement<string>;
  type: RawElement<string>;
  defaultValue: interfaces.OptionalValue<Expression>;

  constructor(obj: interfaces.IArgumentDeclaration, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.name = utils.ensureIsInstance(obj.name, RawElement<string>);
    this.type = utils.ensureIsInstance(obj.type, RawElement<string>);
    if (obj.defaultValue.hasValue) {
      this.defaultValue = interfaces.helpers.createOptionalValue(utils.ensureIsExpressionInstance(obj.defaultValue.value));
    } else {
      this.defaultValue = interfaces.helpers.createOptionalValue<Expression>(null);
    }
  }
  toJson(): interfaces.IArgumentDeclaration {
    return {
      elementType: this.elementType,
      location: this.location,
      name: this.name.toJson(),
      type: this.type.toJson(),
      defaultValue: interfaces.helpers.createOptionalValue(this.defaultValue.value?.toJson()),
    };
  }
  toDsl(indent: number): string {
    return [
      helpers.indentString(indent),
      this.name.value,
      ": ",
      this.type.value,
      this.defaultValue.hasValue ? ` = ${this.defaultValue.value.toDsl(indent)}` : "",
    ].join("");
  }
}
