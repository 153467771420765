import * as helpers from "../helpers";
import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import { Expression } from "../expressions";
import { ContextVariable } from "./ContextVariable";
import { RawElement } from "../common";

export class RootContextVariable extends ContextVariable implements interfaces.IRootContextVariable {
  elementType: DslElementType.ContextVariable = DslElementType.ContextVariable;
  isRootContextVariable: true = true as const;
  modifier: interfaces.OptionalValue<"input" | "output">;

  constructor(obj: interfaces.IRootContextVariable, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.modifier = interfaces.helpers.createOptionalValue(obj.modifier.value);
  }
  toJson(): interfaces.IRootContextVariable {
    return {
      ...super.toJson(),
      isRootContextVariable: true,
      modifier: interfaces.helpers.createOptionalValue(this.modifier.value),
    };
  }
  toDsl(indent: number): string {
    return helpers.joinNullableStrings(
      [
        helpers.indentString(indent),
        this.modifier.hasValue ? this.modifier.value : null,
        this.name.value,
        `: ${this.type.value}`,
        this.initialValue.hasValue ? ` = ${this.initialValue.value.toDsl(indent)}` : null,
        ";",
      ],
      ""
    );
  }
}
