import React from "react";
import { observer } from "mobx-react-lite";

import { Tabs, Tab } from "../../uikit";
import { PureButton } from "../../uikit/Button";
import { FileIcon } from "../../FilesPanel/Tree/FileIcons";
import Workspace from "../../core/workspace/Workspace";

import { ReactComponent as CloseIcon } from "./close.svg";
import * as S from "./styled";

const ProjectTabs = observer<{ workspace: Workspace }>(({ workspace }) => {
  const tabs = workspace.files.map((file) => (
    <Tab key={file.path} onClick={() => workspace.selectFile(file)} isSelected={workspace.opened === file}>
      <FileIcon style={{ marginRight: 8 }} isInvalid={file.isInvalid} name={file.path} />
      <span style={{ marginTop: -3 }}>{file.name}</span>

      <PureButton
        onClick={(e) => {
          e.stopPropagation();
          workspace.closeFile(file);
        }}
      >
        <CloseIcon />
      </PureButton>
    </Tab>
  ));

  return (
    <S.Container>
      <Tabs style={{ overflow: "auto", flexShrink: 2 }}>{tabs}</Tabs>
    </S.Container>
  );
});

export default ProjectTabs;
