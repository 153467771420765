import JSZip from "jszip";
import path from "path";
import * as bundlejs from "../bundlejs";
import { dashaPlugin, depsPlugin } from "./deps";

const mountFileSystem = async (zip: JSZip) => {
  bundlejs.FileSystem.clear();

  await Promise.all(
    Object.entries(zip.files).map(async ([path, file]) => {
      if (file.dir || !["ts", "json", "js", "jsx", "tsx"].includes(path.split(".").pop() ?? "")) return;
      const raw = await file.async("uint8array");
      bundlejs.setFile("/" + path, raw);
    })
  );
};

export const bundler = async (zip: JSZip) => {
  await mountFileSystem(zip);

  let deps: Record<string, string> = {};
  let entry: string | null = null;

  try {
    const pkgJson = await bundlejs.getFile("/package.json", "string");
    const pkg = JSON.parse(pkgJson as string);
    deps = pkg["dashastudio:deps"];
    entry = pkg["dashastudio:main"] || pkg.main;
    if (entry == null) throw Error();
  } catch {
    return `throw Error("Create valid package.json with \\"main\\" property")`;
  }

  const result = await bundlejs.build({
    cdn: "https://unpkg.com",
    entryPoints: [path.resolve(entry)],
    esbuild: {
      target: ["esnext"],
      format: "cjs",
      bundle: true,
      minify: false,
      treeShaking: true,
      platform: "browser",
      plugins: [depsPlugin()],
      define: {
        __NODE__: `false`,
        "process.env.NODE_DEBUG": "false",
        "process.env.NODE_ENV": `"production"`,
        global: "window",
      },
    },
    alias: {
      "@sentry/node": "@sentry/browser",
      axios: "https://unpkg.com/axios@1.1.3/dist/axios.min.js",
      uuid: "uuid-browser",
      ...deps,
    },
  });

  return result.contents[0]?.text ?? "";
};

export const buildDasha = async () => {
  bundlejs.FileSystem.clear();
  bundlejs.setFile(
    "/index.js",
    `
      import * as dasha from "@dasha.ai/sdk"
      window.dasha = dasha
    `
  );

  const result = await bundlejs.build({
    cdn: "https://unpkg.com",
    entryPoints: ["/index.js"],
    esbuild: {
      target: ["esnext"],
      format: "cjs",
      bundle: true,
      minify: false,
      treeShaking: true,
      platform: "browser",
      plugins: [dashaPlugin()],
      define: {
        __NODE__: `false`,
        "process.env.NODE_DEBUG": "false",
        "process.env.NODE_ENV": `"production"`,
        global: "window",
      },
    },
    alias: {
      buffer: "buffer-browserify",
      assert: "assert-polyfill",
      process: "process/browser",
    },
  });

  return result.contents[0]?.text ?? "";
};

export const nodeBuild = async (zip: JSZip) => {
  await mountFileSystem(zip);
  let entry: string | null = null;

  try {
    const pkgJson = await bundlejs.getFile("/package.json", "string");
    const pkg = JSON.parse(pkgJson as string);
    entry = pkg["dashastudio:main"] || pkg.main;
    if (entry == null) throw Error();
  } catch {
    return `throw Error("Create valid package.json with \\"main\\" property")`;
  }

  const result = await bundlejs.build({
    cdn: "https://cdn.skypack.dev",
    entryPoints: [path.resolve(entry)],
    esbuild: {
      target: ["node14"],
      format: "cjs",
      bundle: true,
      minify: false,
      treeShaking: true,
      platform: "node",
      external: ["@dasha.ai/sdk", "uuid", "cors", "express", "lodash", "moment", "moment-timezone", "openai","completions","@googleapis/calendar"],
    },
  });

  return result.contents[0]?.text ?? "";
};