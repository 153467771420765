import styled, { css, keyframes } from "styled-components";
import { PureButton } from "../uikit/Button";

export const Layout = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;

  display: grid;
  height: 100vh;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 64px 130px 1fr;
  grid-template-areas:
    "header header" "aside projects" "aside projects"
    #header {
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  #aside {
    grid-area: aside;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
  }

  #actions {
    grid-area: actions;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }

  #projects {
    grid-area: projects;
  }
`;

export const Title = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #eceff8;
  margin: 0;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 24px;

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(217, 217, 226, 0.5);
    margin: 0;
  }

  span {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
    color: #fff;
  }

  svg {
    margin-left: 4px;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  padding: 24px 5%;
  margin: auto;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const Projects = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 24px));
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  padding-bottom: 80px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, calc(33% - 16px));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, calc(50% - 16px));
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Aside = styled.div`
  background: #1b1b2d;
  overflow-y: scroll;
`;

export const Header = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 12px;
`;

export const Option = styled.div<{ isSelected: boolean }>`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  cursor: pointer;
  height: 32px;

  transition: 0.2s background, 0.2s color;
  color: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  background: ${(p) => (p.isSelected ? "#35317D" : "#1B1B2D")};

  svg {
    margin-right: 6px;
  }

  path {
    fill: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  }

  &:hover {
    background: ${(p) => (p.isSelected ? "#35317D" : "#232435")};
  }
`;

export const ActionMenu = styled(PureButton)`
  position: absolute;
  top: 20px;
  right: 16px;

  &::before {
    content: "";
    background: rgba(0, 0, 0, 0.05);
    filter: blur(5px);
    width: 30px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const statusToColor = (status: string) => {
  switch (status) {
    case "Failed":
      return "rgba(255, 0, 0, 0.3)";
    case "Succeed":
      return "#00674b";
  }
  return "#1e1f33";
};
/*
;

export const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;

  background: rgba(22, 23, 41, 0.75);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${show} 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
`;

*/

const show = keyframes`
  0% {
    background: rgba(22, 23, 41, 1);
  }
  50% {
    background: #1e1f33;
  }
  100% {
    background: rgba(22, 23, 41, 1);
  }
`

const blink = (status: string) => {
  if (status === "Queued" || status === "InProgress") {
    return css`
      animation: ${show} 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) infinite;
    `;
  }

  return css`
  background: #1e1f33;
  `;
};


export const TestCard = styled.div<{ status: string }>`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.2s border;
  min-height: 120px;
  border: 1px solid transparent;
  position: relative;
  
  border-color: ${(p) => statusToColor(p.status)};

  ${(p) => blink(p.status)}
  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    color: #eceff8;
    margin: 0;
  }

  ${ActionMenu} {
    transition: 0.2s opacity;
    opacity: 0;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.3);
    ${ActionMenu} {
      opacity: 1;
    }
  }
`;

export const Description = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #d9d9e2;
  margin: 0;
  margin-bottom: 12px;
`;

export const TestCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 24px));
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  padding-bottom: 80px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, calc(33% - 16px));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, calc(50% - 16px));
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
