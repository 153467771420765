import { DevLogMessage } from "@core/workspace/session-storage/devlogs";
import { IHistoryMessage } from "@dasha.ai/sdk/web/gpt";
import { makeAutoObservable } from "mobx";

function transformToHistory(message: DevLogMessage, instanceId: string): IHistoryMessage | undefined {
  if (message.msgId === "GptFunctionCallRequestMessage" && message.InstanceId === instanceId) {
    return {
      type: "call",
      functionCallName: message.FunctionName,
      functionCallArgs: message.Args,
    };
  }

  if (message.msgId === "GptResponseMessage" && message.InstanceId === instanceId) {
    return {
      type: "Text",
      text: message.Message,
      thinking: message.Metadata?.Thinking ?? undefined,
      source: 0,
    };
  }

  return undefined;
}

export type IHistoryMessageWithId = IHistoryMessage & { id: number };

export class HistoryHolder {
  public Messages: IHistoryMessageWithId[] = [];
  public InitialMessages: IHistoryMessageWithId[] = [];
  private nextId: number = 0;

  private AddIdToItem(msg: IHistoryMessage): IHistoryMessageWithId {
    const id = ++this.nextId;
    return { ...msg, ...{id: id }};
  }

  constructor(history: IHistoryMessage[]) {
    makeAutoObservable(this);
    this.Messages = [...history.map((x) => this.AddIdToItem(x))];
    this.InitialMessages = [...this.Messages];
  }

  public get isDirty(): boolean {
    if (this.Messages.length !== this.InitialMessages.length) {
      return true;
    }
    return JSON.stringify(this.Messages) !== JSON.stringify(this.InitialMessages);
  }

  public static fromLogs(messages: DevLogMessage[], instanceId: string) {
    const result: IHistoryMessage[] = [];
    for (var x of messages) {
      const m = transformToHistory(x, instanceId);
      if (m !== undefined) {
        result.push(m);
      }
    }
    return new HistoryHolder(result);
  }

  public deleteMessage(msg: IHistoryMessageWithId) {
    this.Messages = this.Messages.filter((x) => x.id!==msg.id);
  }

  public Replace(msg: IHistoryMessageWithId, newItem: IHistoryMessage) {
    const index = this.Messages.findIndex((x) => x.id === msg.id);
    if (index < 0)
    {
      return;
    }
    this.Messages = [...this.Messages.slice(0, index), this.AddIdToItem(newItem), ... this.Messages.slice(index + 1) ];
  }

  public CreateAfter(msg: IHistoryMessageWithId | undefined, newItem: IHistoryMessage): IHistoryMessageWithId | undefined {
    var newItemWithId = this.AddIdToItem(newItem);
    if (msg === undefined) {
      this.Messages = [newItemWithId, ...this.Messages];
      return newItemWithId;
    }
    const index = this.Messages.findIndex((x) => x.id === msg.id);
    if (index < 0)
    {
      return undefined;
    }
    this.Messages = [...this.Messages.slice(0, index+1), newItemWithId, ... this.Messages.slice(index+1) ];
    return newItemWithId;
  }
}
