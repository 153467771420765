import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ModalTitle } from "../uikit/Modal/styled";
import Modal from "../uikit/Modal";
import { ActionButton } from "../uikit";
import { Text} from "./styled";


export const PaymentRequired: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [open, setOpen] = React.useState(true)

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalTitle>Free Trial Required To Run This App</ModalTitle>

      <Text style={{ marginTop: 8 }}>
        <p>To run LLM-based apps (e.g. GPT-based apps), you need free trial access to Dasha. To request it, please book a meeting using <Link target="_blank" to="https://calendly.com/vlad-chernyshov/45-minute-meeting-broad">this link</Link>. We’ll guide you through the process and answer any questions you may have.</p>
        <p>Alternatively, you can use your own OpenAI API key by passing it as the “openai_apikey” variable, but the latency will be higher (see our <Link target="_blank" to="https://docs.dasha.ai/en-us/default/gpt/">docs</Link>).</p>
      </Text>

      <div style={{ display: "flex", marginTop: 16 }}>
        <ActionButton style={{ flex: 1 }} onClick={onClose}>
          I UNDERSTAND
        </ActionButton>
      </div>
    </Modal>
  )
}
