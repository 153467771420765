import * as interfaces from "../../interfaces";
import DslElementType from "../../enums/DslElementType";
import { LocationProvider } from "../../../../monaco-helpers";
import * as helpers from "../helpers";
import { Expression } from "./Expression";

export class ParenthesizedExpression extends Expression implements interfaces.IParenthesizedExpression {
  elementType: DslElementType.ParenthesizedExpression = DslElementType.ParenthesizedExpression;
  expression: Expression;

  constructor(obj: interfaces.IParenthesizedExpression, locationProvider?: LocationProvider) {
    super(obj, locationProvider);
    this.expression = helpers.ensureIsExpressionInstance(obj.expression);
  }
  toJson(): interfaces.IParenthesizedExpression {
    return {
      isExpression: true,
      elementType: this.elementType,
      location: this.location,
      expression: this.expression.toJson(),
    };
  }
  toDsl(indent: number): string {
    return `(${this.expression.toDsl(indent)})`;
  }
}
