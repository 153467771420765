import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import { ActionButton, Icon, StrokeButton } from "../../../../../uikit";
import EntityEditor, { Value } from "./EntityEditor";

import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as BucketIcon } from "./assets/bucket.svg";
import { ReactComponent as CheckboxCheckedIcon } from "./assets/radio-checked.svg";
import { ReactComponent as CheckboxIcon } from "./assets/radion-unchecked.svg";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";

import * as S from "./styled";
import { Tooltip } from "../../../../../uikit/popup";
import { PureButton } from "../../../../../uikit/Button";
import Trigger from "../../../../../RunnerPanel/TriggersEdit/Trigger";
import { MarkupType } from "@core/workspace/session-storage/devlogs";
import { AddTrigger } from "../../../../../RunnerPanel/TriggersEdit/styled";
import SearchSelect from "../../../../../uikit/SearchSelect";

interface Props {
  phrase: string;
  hasArrowBack?: boolean;
  dataset: DatasetStorage;
  onClose: () => void;
  onCreate: (name: string) => void;
}

const ValueField: FC<{ editor: EntityEditor; index: number; value: Value }> = observer((props) => {
  const { value, isEditable, synonyms } = props.value;
  const { editor, index } = props;
  const [isPushSynonym, setIsPushSynonym] = useState(false);

  return (
    <S.ValueField isEditable={isEditable}>
      <span
        suppressContentEditableWarning={true}
        contentEditable={isEditable ? ("plaintext-only" as any) : false}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        onFocus={() => editor.renameValue(index, value)}
        onBlur={(e) => {
          if (e.target.textContent?.length) editor.renameValue(index, e.target.textContent);
          else editor.removeValue(index);
        }}
      >
        {value}
      </span>
      {synonyms.map((synonym) => (
        <Trigger
          key={synonym.id}
          phrase=""
          trigger={synonym}
          onMark={(mark: MarkupType) => mark === MarkupType.exclude && editor.removeSynonym(index, synonym.id)}
        />
      ))}
      {isPushSynonym && (
        <SearchSelect
          focuses
          selected=""
          options={[]}
          saveOnEnter
          onRemove={() => setIsPushSynonym(false)}
          onBlur={() => setIsPushSynonym(false)}
          onChange={(item: string) => editor.addSynonym(index, item)}
        />
      )}
      {!isPushSynonym && <AddTrigger onClick={() => setIsPushSynonym(true)}>+ Add synonym</AddTrigger>}
      {isEditable && (
        <S.RemoveValueBtn onClick={() => editor.removeValue(index)}>
          <BucketIcon />
        </S.RemoveValueBtn>
      )}
    </S.ValueField>
  );
});

const CreateEntity: FC<Props> = ({ phrase, hasArrowBack, dataset, onClose, onCreate }) => {
  const [editor] = useState(() => new EntityEditor(phrase, dataset));
  const [newPhrase, setNewPhrase] = useState("");
  const [isPushNewValue, setIsPushNewValue] = useState(false);

  const handleCreate = () => {
    dataset.createEntity(editor.serialize());
    onCreate(editor.name);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      editor.addPhrase(newPhrase);
    }
  };

  return (
    <>
      <S.Header>
        {hasArrowBack && (
          <S.ButtonBack onClick={onClose}>
            <Icon name="cursor-back" />
          </S.ButtonBack>
        )}

        <h1>Create a new entity</h1>

        <ActionButton disabled={!editor.isValid} onClick={handleCreate}>
          create
        </ActionButton>
        {!hasArrowBack && <StrokeButton onClick={onClose}>cancel</StrokeButton>}
      </S.Header>
      <S.Body>
        <S.Section>
          <S.Title>
            <p>
              Entity name ({editor.name.length}/{editor.maxNameLength} characters)
            </p>

            <Tooltip text="Entity name" position="top center">
              <PureButton>
                <Icon name="info" />
              </PureButton>
            </Tooltip>
          </S.Title>

          <S.AddPhraseField>
            <input
              value={editor.name}
              onChange={(e) => editor.setName(e.target.value)}
              placeholder="Type a uniq entity name without whitespaces"
            />
          </S.AddPhraseField>

          {editor.isValidName === false && <S.Attention>This name already exist in {dataset.name}</S.Attention>}
        </S.Section>

        <S.Label>
          <input type="checkbox" checked={editor.isOpenSet} onChange={() => editor.toggleIsOpenSet()} />
          {editor.isOpenSet ? <CheckboxCheckedIcon /> : <CheckboxIcon />}
          <span>Open Set</span>
        </S.Label>

        <S.Section style={{ padding: "24px 0 0 0" }}>
          <S.Title style={{ padding: "0 16px" }}>
            <p>Entity value</p>
          </S.Title>
          {editor.values.map((value, i) => (
            <ValueField key={value.value} value={value} index={i} editor={editor} />
          ))}
          {isPushNewValue && (
            <S.NewValueField>
              <input
                type="text"
                placeholder="Type a value"
                onBlur={(e) => {
                  if (e.target.value) {
                    editor.addValue(e.target.value);
                  }

                  setIsPushNewValue(false);
                }}
              />
            </S.NewValueField>
          )}
          <S.AddValueBtn onClick={() => setIsPushNewValue(true)}>
            <PlusIcon /> <span>Add value</span>
          </S.AddValueBtn>
        </S.Section>

        <S.Section>
          <S.Title>
            <p>Entering phrases (optional)</p>
            <Tooltip text="Entities" position="top center">
              <PureButton>
                <Icon name="info" />
              </PureButton>
            </Tooltip>
          </S.Title>

          <S.AddPhraseField>
            <input
              value={newPhrase}
              onKeyDown={handleKeyDown}
              onChange={(e) => setNewPhrase(e.target.value)}
              placeholder="Type a phrase and press 'Enter' to add"
            />
            {newPhrase && (
              <S.ResetPhraseBtn onClick={() => setNewPhrase("")}>
                <CrossIcon />
              </S.ResetPhraseBtn>
            )}
            {newPhrase && <S.AddPhraseBtn onClick={() => editor.addPhrase(newPhrase)}>Add</S.AddPhraseBtn>}
          </S.AddPhraseField>
        </S.Section>

        <S.Section>
          <S.Title>
            <p>List of phrases</p>
          </S.Title>

          {editor.phrases.map((phrase) => (
            <S.Phrase key={phrase.id}>
              <span
                suppressContentEditableWarning={true}
                contentEditable={"plaintext-only" as any}
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onFocus={() => editor.renamePhrase(phrase.id, phrase.value)}
                onBlur={(e) => {
                  if (e.target.textContent?.length) editor.renamePhrase(phrase.id, e.target.textContent);
                  else editor.removePhrase(phrase.id);
                }}
              >
                {phrase.value}
              </span>
              <S.RemovePhraseBtn onClick={() => editor.removePhrase(phrase.id)}>
                <CrossIcon />
              </S.RemovePhraseBtn>
            </S.Phrase>
          ))}
        </S.Section>
      </S.Body>
    </>
  );
};

export default observer(CreateEntity);
