import React, { useState, useEffect, StrictMode } from "react";
import { observer } from "mobx-react-lite";

import ExplorerView from "./ExplorerView";
import { RoutedWorkspaceView } from "./WorkspaceView";

import { Icon, ScrollbarStyle } from "./uikit";
import HelpWidget from "./HelpWidget";
import * as S from "./styled";
import { BrowserRouter, Route, Routes, Navigate, useSearchParams, redirect, useParams } from "react-router-dom";
import { ActionButton } from "./uikit";
import styled from "styled-components";
import { GlobalStoreContext } from "./core/api/GlobalStoreContext";
import { GlobalStore } from "./core/api/GlobalStore";
import { AccountsStore } from "./core/account/AccountsStore";
import { AccountsStoreContext } from "./core/account/AccountsStoreContext";

const Title = styled.p`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 24px;
`;

const App = () => {
  const [auth] = useState(() => new AccountsStore());
  const [store] = useState<GlobalStore>(() => new GlobalStore(auth.accountManager.account));
  auth.accountManager.onDidAccount((e) => {
    store.SwitchAccount(e);
  });

  if (!auth.accountManager.isInitalized) {
    return (
      <S.LoadingApp>
        <Icon name="spinner" style={{ width: 32 }} />
        <p>Loading Dasha Studio</p>
      </S.LoadingApp>
    );
  }

  if (!auth.accountManager.isAuth) {
    return (
      <S.App>
        <Title>Please sign in to start exploring Dasha Playground</Title>
        <ActionButton
          onClick={() => auth.accountManager.login()}
          style={{ height: "15%", width: "20%", top: "30%", left: "40%", position: "absolute" }}
        >
          SIGN IN DASHA.AI
        </ActionButton>
        <ActionButton
          onClick={() => auth.accountManager.login()}
          style={{ height: "15%", width: "20%", top: "50%", left: "40%", position: "absolute" }}
        >
          SIGN UP DASHA.AI
        </ActionButton>
      </S.App>
    );
  }

  return (
    <StrictMode>
    <BrowserRouter>
      <S.App>
        <AccountsStoreContext.Provider value={auth}>
          <GlobalStoreContext.Provider value={store}>
            <ScrollbarStyle />
            <HelpWidget />
            <Routes>
              <Route path="/project/:projectId" element={<RoutedWorkspaceView />} />
              <Route path="/project/:projectId/version/:projectVersion" element={<RoutedWorkspaceView />} />
              <Route element={<ExplorerView />} path="*" />
            </Routes>
          </GlobalStoreContext.Provider>
        </AccountsStoreContext.Provider>
      </S.App>
    </BrowserRouter>
    </StrictMode>
  );
};

export default observer(App);
