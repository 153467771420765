import styled from "styled-components";

export const Container = styled.div<{ $dateRange: { start: Date; end: Date } }>`
  width: 100%;

  .react-datepicker-wrapper,
  .react-datepicker-time__input,
  .react-datepicker-time__input-container,
  .react-datepicker__input-time-container {
    width: 100%;
  }

  .react-datepicker__input-time-container {
    margin: 0;
    padding: 8px;
    box-sizing: border-box;
  }

  .react-datepicker-time__caption {
    display: none;
  }

  .react-datepicker-time__input {
    margin: 0 !important;
  }

  .react-datepicker {
    background: #1d1e31;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    border: none;

    font-family: inherit;
    font-size: 12px;
    line-height: 20px;
    color: #fff;

    width: 100%;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: center;
  }

  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 2px;
  }

  .react-datepicker-popper {
    margin-top: 0;
    width: calc(100% - 48px);
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    top: 23px;
  }

  .react-datepicker__navigation:focus {
    outline: 1px solid #5855f4;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #fff;

    left: 29px;
  }

  .react-datepicker__navigation--next {
    border-left-color: #fff;

    right: 29px;
  }

  .react-datepicker__header {
    background: none;
    border: none;
    border-radius: 0;

    padding: 24px 32px 0 32px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 16px 32px 32px 32px;
  }

  .react-datepicker__current-month {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;

    margin-bottom: 16px;

    color: #d9d9e2;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0;
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 8px;
    border-radius: 50%;

    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;

    font-weight: 600;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 0.2px;

    color: #d9d9e2;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--outside-month {
    color: rgba(217, 217, 226, 0.5);
    opacity: 1 !important;
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background: none;
    position: relative;
    z-index: 0;
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start),
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-end),
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-start),
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-end) {
    &:last-child::before,
    &:first-child::after {
      width: 16px !important;
    }

    &:last-child::after {
      right: 0 !important;
      border-radius: 0 50% 50% 0;
    }

    &:first-child::before {
      left: 0 !important;
      border-radius: 50% 0 0 50%;
    }
  }

  .react-datepicker__day--range-start::before,
  .react-datepicker__day--range-end::after {
    display: none !important;
  }

  .react-datepicker__day--selecting-range-start::before,
  .react-datepicker__day--selecting-range-end::after {
    display: none !important;
  }

  .react-datepicker__day--in-range::before,
  .react-datepicker__day--in-range::after,
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-start)::before,
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-end)::after {
    content: "";
    position: absolute !important;
    width: 32px !important;
    height: 32px !important;
    background-color: rgba(71, 144, 255, 0.5);
    z-index: -1;
  }

  .react-datepicker__day--in-range::after,
  .react-datepicker__day--in-selecting-range::after {
    right: -16px !important;
  }

  .react-datepicker__day--in-range::before,
  .react-datepicker__day--in-selecting-range::before {
    left: -16px !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: #4790ff;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    position: relative;
    border-radius: 50%;
    z-index: 0;
  }

  .react-datepicker__day--range-start {
    &:before {
      right: 0;
    }
  }

  .react-datepicker__day--range-end {
    &:before {
      left: 0;
    }
  }

  .react-datepicker__day--range-start.react-datepicker__day--range-end {
    &:before {
      display: none;
    }
  }

  .react-datepicker__day:focus {
    outline: 1px solid rgb(0, 127, 212);
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--disabled:focus,
  .react-datepicker__day--disabled:hover {
    outline: none;
    cursor: initial;
    background: none;
    opacity: 0.4;
  }
`;

export const CustomInput = styled.div`
  box-sizing: border-box;
  position: relative;

  width: 100%;

  font-family: var(--main-font);
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: #d9d9e2;
  text-align: left;

  border-radius: 8px;

  background-color: #2b2b3d;

  padding: 8px;

  svg {
    position: absolute;
    right: 8px;
    top: 10px;
  }
`;

export const TimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;

  input::-webkit-calendar-picker-indicator {
    transition: 0.1s transform;
    filter: invert(100%);
  }

  input::-webkit-calendar-picker-indicator:hover {
    transform: scale(1.05);
  }

  input {
    width: 100% !important;
  }
`;

export const TimeLabel = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0 0 6px;
`;

export const Input = styled.input`
  flex-grow: 1;
  background: none;
  outline: none;
  border: none;

  font-family: var(--main-font);
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: #d9d9e2;

  :focus {
    outline: none;
  }

  :disabled {
    opacity: 0.4;
  }
`;
